import { createSelector } from "reselect";

const analyticsSelector = (state: any) => state.analyticsReducer;

const songsTopSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.songsTop
);
const top4SongsSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.top4Songs
);

const sourcesTopSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.sourcesTop
);
const top4SourceSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.top4Sources
);

const territoriesTopSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.territoriesTop
);
const top4TerritoriesSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.top4Territories
);

const incomeTypesTopSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.incomeTypesTop
);
const top4IncomeTypesSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.top4IncomeTypes
);

const songsLoadingSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.songsLoading
);
const sourcesLoadingSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.sourcesLoading
);
const incomesLoadingSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.incomesLoading
);
const territoriesLoadingSelector = createSelector(
  analyticsSelector,
  appStaticReducer => appStaticReducer.territoriesLoading
);

export {
  incomesLoadingSelector,
  incomeTypesTopSelector,
  songsLoadingSelector,
  songsTopSelector,
  sourcesLoadingSelector,
  sourcesTopSelector,
  territoriesLoadingSelector,
  territoriesTopSelector,
  top4IncomeTypesSelector,
  top4SongsSelector,
  top4SourceSelector,
  top4TerritoriesSelector
};
