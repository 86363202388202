import React, { useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import cn from "classnames";
import moment from "moment";

import styles from "./UploadList.module.scss";

import Icon from "../../../../components/shared/Icon";
import { UploadListProps } from "./types";
import UploadItem from "./UploadItem";

const UploadList: React.FC<UploadListProps> = ({
  onDelete,
  onCancel,
  uploadsProgress,
  uploadedFiles
}) => {
  const verifiedUploadedFilesCount = useMemo(() => {
    if (!uploadedFiles?.length) return 0;
    return uploadedFiles?.filter(file => file.validationStatus === "VALID")
      .length;
  }, [uploadedFiles]);

  const allUploaded = useMemo(() => {
    if (!uploadedFiles?.length) return false;
    return !uploadedFiles?.some(file => file.fileStatus === "DRAFT");
  }, [uploadedFiles]);

  const uploadedFilesCount = useMemo(
    () => uploadedFiles.filter(file => file.validationStatus !== null) || 0,
    [uploadedFiles]
  );

  const sortedFileList = useMemo(
    () =>
      uploadedFiles
        .sort((a, b) => {
          if (!a.uploadedAt || !b.uploadedAt) return 0;
          const dateA = moment(a.uploadedAt);
          const dateB = moment(b.uploadedAt);

          if (dateA.isBefore(dateB)) return 1;
          if (dateB.isBefore(dateA)) return -1;
          return 0;
        })
        .sort((a, b) => {
          if (a.invalidationReason && !b.invalidationReason) return -1;
          if (!a.invalidationReason && b.invalidationReason) return 1;
          if (!a.invalidationReason && !b.invalidationReason) return 0;
          if (a.invalidationReason && b.invalidationReason) {
            if (a.invalidationReason > b.invalidationReason) return -1;
            if (a.invalidationReason < b.invalidationReason) return 1;
          }
          return 0;
        }),
    [uploadedFilesCount]
  );

  const failedDocuments = useMemo(
    () => uploadedFiles.filter(file => file.invalidationReason).length || 0,
    [uploadedFiles]
  );

  if (!uploadedFiles) return null;

  const uploadStatus = !allUploaded ? (
    <span>
      <Icon kind="document_upload" className={styles.statusIcon} />
      {`Uploaded ${verifiedUploadedFilesCount} of ${uploadedFiles.length}.`}
    </span>
  ) : (
    <span>
      <Icon kind="success_circle" />
      {`${verifiedUploadedFilesCount} of ${uploadedFiles.length} files successfully uploaded.`}
    </span>
  );
  const errorStatus =
    failedDocuments > 0 ? (
      <span>
        <Icon kind="warning" fillColor={"red"} className={styles.statusIcon} />
        {`${failedDocuments} Errors.`}
      </span>
    ) : null;
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.listHeader, styles.container)}>
        <p className={styles.statusMessage}>
          {uploadStatus}
          {errorStatus}
        </p>
      </div>
      <div className={cn(styles.list, styles.withGuidelines, styles.container)}>
        <Virtuoso
          data={sortedFileList}
          totalCount={sortedFileList.length}
          itemContent={(_, file) => (
            <UploadItem
              key={file.id}
              file={file}
              handleDelete={onDelete}
              cancelUpload={onCancel}
              percentage={uploadsProgress[file.id] || 0}
            />
          )}
        />
      </div>
    </div>
  );
};

export default UploadList;
