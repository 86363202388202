import React from "react";
import cn from "classnames";

import styles from "./Content.module.scss";

const ExtendableContent = ({ children, className }) => {
  return <div className={cn(styles.content, className)}>{children}</div>;
};

export default ExtendableContent;
