import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import cn from "classnames";

import styles from "./InputField.module.scss";

import Condition from "../../shared/Condition";

type InputFieldProps = UseControllerProps & {
  placeholder?: string;
  className?: string;
  limit?: number;
};

const InputField: React.FC<InputFieldProps> = ({
  placeholder,
  className,
  limit,
  ...rest
}) => {
  const { field } = useController(rest);
  return (
    <div className={cn(styles.inputContainer, className)}>
      <input
        {...field}
        maxLength={limit}
        className={styles.input}
        placeholder={placeholder}
        type="text"
      />
      <Condition
        condition={!!limit}
        Truthy={
          <span>
            {field?.value?.length || 0}/{limit || 60}
          </span>
        }
      />
    </div>
  );
};

export default InputField;
