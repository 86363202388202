import { Reducer } from "redux";

import { AdvanceTypes } from "./types";
export interface IHistory {
  amount: number;
  message?: string;
  requestDate: string | Date;
}
export const advanceInitialState: {
  data: { history: IHistory[]; requestAllowed: boolean } | null;
  loading: boolean | undefined;
} = {
  data: null,
  loading: true
};
export const advanceReducer: Reducer<any, any> = (
  state = advanceInitialState,
  action
) => {
  switch (action.type) {
    case AdvanceTypes.GET_ADVANCE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case AdvanceTypes.GET_ADVANCE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AdvanceTypes.GET_ADVANCE_ERROR: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
