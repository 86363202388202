import { useRef, useState } from "react";
import { Popover } from "@material-ui/core";
import classNames from "classnames";

import styles from "./Button.module.scss";
import { ReactComponent as ChevronDown } from "../../../icons/chevron_down_2.svg";
import { ReactComponent as LoadingIcon } from "../../../icons/loading.svg";

import {
  ButtonIconPosition,
  ButtonMenuItemProps,
  ButtonProps,
  ButtonType
} from "./Button.types";

const Button = (props: ButtonProps) => {
  const elementRef = useRef<HTMLButtonElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const className = classNames(
    styles.btn,
    (typeof props.variant === "undefined" ||
      props.variant === ButtonType.Default) &&
      `${styles.btn_default} ${props.iconPosition ===
        ButtonIconPosition.IconLeft &&
        styles.btn_icon_left} ${props.iconPosition ===
        ButtonIconPosition.IconRight && styles.btn_icon_right}`,
    props.variant === ButtonType.Text &&
      `${styles.btn_text} ${props.iconPosition ===
        ButtonIconPosition.IconLeft &&
        styles.btn_icon_left} ${props.iconPosition ===
        ButtonIconPosition.IconRight && styles.btn_icon_right}`,
    props.variant === ButtonType.Primary &&
      `${styles.btn_primary} ${props.iconPosition ===
        ButtonIconPosition.IconLeft &&
        styles.btn_icon_left} ${props.iconPosition ===
        ButtonIconPosition.IconRight && styles.btn_icon_right}`,
    props.className
  );

  const menuItemRender = (
    menuItemProps: ButtonMenuItemProps,
    i: number
  ): JSX.Element => {
    const onMenuItemClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      setIsMenuOpen(false);
      menuItemProps.onClick?.(e);
    };
    return (
      <button className={styles.menuItem} key={i} onClick={onMenuItemClick}>
        {menuItemProps.text}
      </button>
    );
  };

  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    props.onClick?.(e);
    if (props.menuItems) {
      setIsMenuOpen(true);
    }
  };

  return (
    <>
      <button
        className={className}
        disabled={props.disabled}
        onClick={onClick}
        ref={elementRef}
        type={props.type}
      >
        {props.loading && <LoadingIcon className={styles.btn_icon_loader} />}
        <span className={styles.btn_title}>{props.text}</span>
        {props.children}
        {props.menuItems &&
          props.menuItems.length > 0 &&
          !props.hideDropdownIcon && (
            <ChevronDown className={styles.dropdownIcon} />
          )}
      </button>
      <Popover
        anchorEl={elementRef.current}
        onClose={() => setIsMenuOpen(false)}
        open={isMenuOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {props.menuItems?.map((item, i) => menuItemRender(item, i))}
      </Popover>
    </>
  );
};
export default Button;
