import React, { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  Updater,
  useReactTable
} from "@tanstack/react-table";
import cn from "classnames";

import styles from "./UploadTable.module.scss";

import { SizeState } from "../../../page/StatementUpload/types";
import Condition from "../../shared/Condition";
import Icon from "../Icon";
import PaginationNumbers from "../Pagination";
import { UploadTableProps } from "./UploadTable.types";

const UploadTable = <TData, TValue>({
  loading,
  className,
  data,
  columns,
  onRowClick = () => ({}),
  pagination,
  withPagination,
  onSort,
  ...rest
}: UploadTableProps<TData, TValue>) => {
  const Skeleton = () => {
    return <div className={cn(styles.skeleton)} />;
  };

  const defaultColumnSizing = {
    size: 200,
    minSize: 20,
    maxSize: 400
  };

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pageCount, setPageCount] = useState(0);

  const tableData = React.useMemo(() => (loading ? Array(5).fill({}) : data), [
    loading,
    data
  ]);
  tableData;

  const handleSortChange = (sortingState: Updater<SortingState>) => {
    const sortingValue =
      typeof sortingState === "function" ? sortingState(sorting) : sortingState;
    setSorting(sortingState);
    if (withPagination) table.setPageIndex(0);

    onSort?.(sortingValue);
  };

  const columnsWithLoading = React.useMemo(
    () =>
      loading
        ? columns.map(column => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [loading, columns]
  );

  const table = useReactTable<TData>({
    ...rest,
    columns: columnsWithLoading,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    defaultColumn: defaultColumnSizing,
    manualPagination: !!withPagination,
    manualSorting: !!onSort,
    onSortingChange: onSort && handleSortChange,
    rowCount: pagination?.totalItems,
    state: {
      sorting
    },
    getRowId: (row: TData) => (row as any).id
  });

  const rows = table.getRowModel().rows;

  const handleRowClick = (event: React.MouseEvent, row: Row<TData>) => {
    const selection = window?.getSelection()?.toString() ?? "";
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(row, event);
    }
  };

  useEffect(() => {
    if (pagination) {
      table.setPageIndex(pagination.defaultPage ?? 0);
      setPageCount(Math.ceil(pagination.totalItems / pagination.defaultSize));
    }
  }, [pagination?.totalItems, pagination?.defaultSize]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.tableContainer}>
          <table className={cn(styles.table, className)}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className={cn(styles.row, styles.heading)}
                >
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      style={{
                        width:
                          header.column.getSize() ||
                          header.column.columnDef.size
                      }}
                      className={cn(styles.cell, {
                        [styles.actionCell]: header.column.id.startsWith(
                          "action"
                        )
                      })}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div
                        className={styles.header}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        <span className={styles.title}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </span>
                        <Condition
                          condition={header.column.getCanSort()}
                          Truthy={
                            {
                              asc: <Icon kind="sort_up" />,
                              desc: <Icon kind="sort_down" />
                            }[header.column.getIsSorted() as string] ?? (
                              <Icon kind="sort" />
                            )
                          }
                        />
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {data?.length || loading ? (
                <Virtuoso
                  className={styles.virtuoso}
                  data={rows}
                  totalCount={rows.length}
                  initialItemCount={rows.length}
                  itemContent={(_, row) => {
                    if (!row) return null;
                    return (
                      <tr
                        className={styles.row}
                        key={((row.original as unknown) as { id: string }).id}
                        onClick={event => handleRowClick(event, row)}
                      >
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td
                              key={cell.id}
                              className={cn(styles.cell, {
                                [styles.actionCell]: cell.column.id.startsWith(
                                  "action"
                                )
                              })}
                              style={{
                                width:
                                  cell.column.getSize() ||
                                  cell.column.columnDef.size
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }}
                />
              ) : null}

              {data && !data.length && !loading && (
                <div className={styles.noResult}>No results found</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Condition
        condition={!!withPagination && !!pagination && !!pageCount}
        Truthy={
          <div className={styles.paginationWrap}>
            <PaginationNumbers
              changePageRequest={(page: any) => {
                table.setPageIndex(page);
                if (pagination?.fetchData) {
                  pagination.fetchData({
                    skip: page * pagination.defaultSize,
                    take: pagination.defaultSize
                  });
                }
              }}
              totalAmount={pageCount}
              currentPage={table.getState().pagination.pageIndex + 1}
              onChangeSize={(size: SizeState) => {
                table.setPageIndex(pagination?.defaultPage ?? 0);
                pagination?.onSizeChange(size);
              }}
              size={pagination?.defaultSize}
            />
          </div>
        }
      />
    </>
  );
};

export default UploadTable;
