export enum UserAction {
  Archive = "ARCHIVE",
  Reactivate = "REACTIVATE"
}

export enum UserType {
  Other = "OTHER",
  Songwriter = "SONGWRITER"
}

export enum UserStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Suspended = "SUSPENDED",
  Registered = "REGISTERED",
  Archived = "ARCHIVED",
  Invited = "INVITED",
  PENDING_REGISTERED = "PENDING_REGISTERED"
}

export enum UserRole {
  ADMIN = "ADMIN",
  User = "USER",
  SUPER_ADMIN = "SUPER_ADMIN",
  EXTERNAL_USER = "EXTERNAL_USER"
}

export interface FormItem {
  company?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastActive?: Date;
  lastName?: string;
  requestedAt?: Date;
  role?: UserRole;
  status?: UserStatus;
  statusUpdatedAt?: Date;
  statusUpdatedBy?: string;
  title?: string;
  type?: UserType;
}
