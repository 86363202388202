import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./DeleteSelectedPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Button from "../../WCMButton";

type ConfirmationPopupType = {
  onClick: () => Promise<void>;
  title: string;
};
const DeleteSelectedPopup = ({ onClick, title }: ConfirmationPopupType) => {
  const modal = useSelector(modalSelector);

  const { SetOpenModalAction } = useActions();
  const [isDeleting, setIsDeleting] = useState(false);
  const closeModal = useCallback(() => {
    setIsDeleting(false);
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);

  useEffect(() => () => setIsDeleting(false), [setIsDeleting]);

  return (
    <div className={styles.setAddUserModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "DeleteSelected"}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.buttonsContainer}>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={async () => {
              setIsDeleting(true);
              try {
                await onClick();
              } finally {
                setIsDeleting(false);
              }
            }}
            isLoading={isDeleting}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteSelectedPopup;
