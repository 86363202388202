import { FC, PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import cn from "classnames";

import styles from "./Layout.module.scss";

import { useActions } from "../../hooks/useActions";
import { profileSelector } from "../../store/appStatic/selectors";
import { getUrlParams } from "../../utils";
import Footer from "./Footer";
import Header from "./Header";
import ManagementHeader from "./UserManagementLayout/ManagementHeader";

const Layout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { authState } = useOktaAuth();
  const { pathname } = useLocation();
  const history = useHistory();
  const params = getUrlParams(history.location.search);
  const profile = useSelector(profileSelector);
  const { setProfileAction } = useActions();

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      return;
    }
    if (!profile) {
      setProfileAction(history);
    }
    if (profile) {
      localStorage.setItem("profileEmail", profile.email);
    }
  }, [authState?.isAuthenticated, history, profile, setProfileAction]);

  if (pathname.includes("mobile")) {
    return (
      <div style={{ background: "black" }}>
        <div>{children}</div>
      </div>
    );
  }
  return (
    <div className={cn(styles.layout_wrapper)}>
      {!pathname.includes("user-management") && <Header />}
      <div
        className={cn(
          !pathname.includes("user-management") ? styles.layout : styles.wrap
        )}
      >
        <div
          className={cn(
            !pathname.includes("user-management")
              ? cn(
                  profile && !params.workCode && !pathname.includes("landing")
                    ? styles.children
                    : styles.children_nonavbar
                )
              : styles.userManagementLayout,
            {
              [styles.children_black]: pathname.includes("/dashboard")
            },
            styles.children
          )}
        >
          {pathname.includes("user-management") && <ManagementHeader />}
          <div
            className={cn(pathname.includes("user-management") && styles.child)}
          >
            {children}
          </div>
        </div>
        <Footer hide={pathname.includes("user-management")} />
      </div>
    </div>
  );
};

export default Layout;
