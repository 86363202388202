import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./TotalEarnings.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import CurrencyRateShort from "../../../components/shared/CurrencyRateShort";
import PayeeCodeInfo from "../../../components/shared/PayeeCodeInfo";

type TotalEarningsType = {
  value: string;
  percent: number;
  isIncrement?: boolean;
  className?: string;
};

const TotalEarnings: React.FC<TotalEarningsType> = ({
  value,
  percent,
  isIncrement = true,
  className = ""
}) => {
  const { t } = useTranslation("translation");
  return (
    <div className={cn(styles.totalEarnings, className)}>
      <p className={styles.header}>
        <PayeeCodeInfo
          name={t(`analytics.totalEarnings`)}
          id={2}
          hasIcon
          isBig
          isBigIcon
          tooltipText={t(`ledger.totalRoyaltyEarnedInPeriod`)}
        />
      </p>
      <div className={styles.wrap}>
        <p className={styles.value}>
          <CurrencyRateShort rate={value} id="analytics.totalEarnings" />
        </p>
        <p
          className={cn(styles.percent, { [styles.isIncrement]: !isIncrement })}
        >
          {`${percent}%`}
          {isIncrement ? (
            <UpIcon className={styles.upIcon} />
          ) : (
            <DownIcon className={styles.upIcon} />
          )}
        </p>
      </div>
    </div>
  );
};

export default TotalEarnings;
