import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import styles from "./DownloadTypeSelector.module.scss";

import Icon from "../../../../components/shared/Icon";
import { downloadRadioOptions } from "../../constants";
import { DownloadTypeSelectorProps } from "./DownloadTypeSelector.types";

const DownloadTypeSelector: React.FC<DownloadTypeSelectorProps> = ({
  isMobile,
  isVisible,
  downloadType,
  setDownloadType
}) => {
  const { t } = useTranslation("translation");

  const radioOptions = [
    { value: "PDF", id: downloadRadioOptions.pdf },
    { value: "CSV", id: downloadRadioOptions.csv },
    {
      value: t("statementsPage.downloadInfo.all"),
      id: downloadRadioOptions.all
    }
  ];

  return (
    <>
      <div className={styles.container}>
        <div
          className={cn(
            styles.downloadTypeSelector,
            isVisible ? styles.visible : styles.hidden,
            { [styles.isMobile]: isMobile }
          )}
        >
          <Tooltip
            title={
              <p>
                {t("statementsPage.downloadInfo.statementFormatDescription")}
              </p>
            }
          >
            <div className={styles.description}>
              <p className={styles.text}>
                {t("statementsPage.downloadInfo.statementFormat")}
              </p>
              <Icon kind="white_info_icon_hovered" className={styles.icon} />
            </div>
          </Tooltip>
          <div className={styles.typeOptions}>
            {radioOptions.map(option => (
              <label className={styles.option} key={option.id}>
                <input
                  type="radio"
                  {...option}
                  checked={option.id === downloadType}
                  onChange={e => {
                    setDownloadType(e.currentTarget?.id);
                  }}
                  className={styles.input}
                />
                <span className={styles.radioButton} />
                <span className={styles.radioLabel}>{option.value}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadTypeSelector;
