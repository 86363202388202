import React, { useCallback } from "react";

import styles from "./LandingPageCard.module.scss";

import Card from "../../../components/shared/Card";

const LandingPageCard = ({ data, handleRowClick }: any) => {
  const handleClick = useCallback(el => {
    handleRowClick(el);
  }, []);
  return (
    <>
      {data?.map((el: any) => (
        <Card
          key={el?.workTitle}
          handleCardClick={() => handleClick(el)}
          className={styles.card}
        >
          <Card.Content>
            <Card.Items label={el?.fullName} title={" "} />
          </Card.Content>
        </Card>
      ))}
    </>
  );
};

export default LandingPageCard;
