import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "../Menu.module.scss";

import Index from "../../../../shared/Icon";
import { MenuItem as TMenuItem } from "../types";

type MenuItemProps = {
  item: TMenuItem;
  setOpenMenu?: (newValue: boolean) => void;
};
const MenuItem: React.FC<MenuItemProps> = ({ item }) => {
  const { t } = useTranslation("translation");
  const { label, slug, query } = item;
  const history = useHistory();

  const outbound = slug.startsWith("http");

  if (!slug) return null;

  return (
    <div
      className={cn(styles.item, {
        [styles.item_active]: history.location.pathname.includes(slug),
        [styles[item.className || ""]]: item.className
      })}
    >
      <Link
        to={{ pathname: slug, search: query }}
        target={outbound ? "_blank" : "_self"}
      >
        {t(label)}
      </Link>
      {item.icon && <Index kind={item.icon} />}
    </div>
  );
};

export default MenuItem;
