import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./styles.module.scss";

import { useActions } from "../../../hooks/useActions";

const PayeeCodeColumn = ({ data }: any) => {
  const { SetOpenModalAction } = useActions();
  const { searchedValue } = useSelector(
    (state: any) => state.architectUsersReducer
  );

  const handleInpersonatePopupOpen = (user: any) => {
    const mappedUser = {
      ...user,
      title: user.payeeAccount,
      email: user.client.userEmail,
      isPayee: true,
      id: user.client.userId
    };
    SetOpenModalAction({ isOpen: true, type: "impersonate", user: mappedUser });
  };
  const payeeCode = data?.payeeCode;
  const fullMatch = payeeCode === searchedValue;
  return payeeCode ? (
    <div className="d-flex flex-col h-full justify-center">
      <div
        onClick={() => handleInpersonatePopupOpen(data)}
        className={cn("text-13", styles.tag, {
          [styles.match]: fullMatch
        })}
      >
        {payeeCode}
      </div>
    </div>
  ) : (
    <div className="d-flex flex-col h-full justify-center"> - </div>
  );
};
export default PayeeCodeColumn;
