import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { AuthTransaction } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import cn from "classnames";

import styles from "./Login.module.scss";

import Loader from "../../../components/shared/Loading";
import Button, { ButtonType } from "../../../components/shared/UiButton";
import CheckBoxFiled from "../../../components/shared/UiCheckBox";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import TextField from "../../../components/shared/UiTextField";
import {
  createCookieInMount,
  getRememberedUserInCookie
} from "../../../configs/utils";
import { jc } from "../../../lib/styles";
import { textParams } from "../../../utils";
import { cleanupLocalStorage } from "../../../utils/localStorage";
import { withSourceParam } from "../../../utils/url";
import MfaForm from "../MfaForm";

const OktaSignInWidget = () => {
  const { t, i18n } = useTranslation("translation");
  const { authState, oktaAuth } = useOktaAuth();

  const [data, setData] = useState<any>(null);

  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, isValid }
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<null | boolean>(false);
  const [mfaTransaction, setMfaTransaction] = useState<AuthTransaction | null>(
    null
  );

  useEffect(() => {
    if (getRememberedUserInCookie()) {
      reset({ username: getRememberedUserInCookie() });
    }
  }, []);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      history.push("/");
      return;
    }
    // Cleanup local storage settings from previous sessions
    cleanupLocalStorage();
  }, [history, authState?.isAuthenticated]);

  const originalUri = withSourceParam("/");

  const loginUser = async (username: string, password: string) => {
    setError(null);
    setIsLoading(true);
    try {
      if (data?.checkbox) {
        createCookieInMount("rememberedUser", username, 12);
      }
      const transaction = await oktaAuth.signInWithCredentials({
        password,
        username
      });
      if (transaction.status === "SUCCESS") {
        await oktaAuth.signInWithRedirect({
          originalUri,
          sessionToken: transaction.sessionToken
        });
        return;
      }
      if (
        transaction.status === "MFA_ENROLL" ||
        transaction.status === "MFA_ENROLL_ACTIVATE" ||
        transaction.status === "MFA_REQUIRED"
      ) {
        setMfaTransaction(transaction);
        return;
      }
      setError("resetPasswordPage.errorMessageUnknown");
      window.NREUM.log(
        `CRITICAL: Authentication failed | ${transaction?.status} | user - ${username}`
      );
      setIsLoading(false);
    } catch (e) {
      const response: any = e;
      const newError = response?.data?.debug || response?.data?.message;
      setError(
        newError || textParams(t(`loginPage.errorIncorrectEmailPassword`))[0]
      );
      setIsLoading(false);
    }
  };

  const onMfaError = (): void => {
    setIsLoading(false);
    setMfaTransaction(null);
  };

  const onSubmit = async ({ username, password, checkbox }: any) => {
    setData({
      username: username,
      password: password,
      checkbox: checkbox
    });
    await loginUser(username, password);
  };

  const ChangeStyleDependOnLanguage = (lng: string, className?: string) => {
    return jc(
      (lng === "LAS" ||
        lng === "ES" ||
        lng === "RU" ||
        lng === "DE" ||
        lng === "FR" ||
        lng === "NL") &&
        `${styles.wrapColumn}`,
      className
    );
  };

  if (mfaTransaction) {
    return (
      <MfaForm
        onError={onMfaError}
        onSuccess={sessionToken =>
          oktaAuth.signInWithRedirect({ originalUri, sessionToken })
        }
        transaction={mfaTransaction}
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={cn(styles.login, "container")}>
      <form onSubmit={handleSubmit(onSubmit)} className={cn(styles.loginForm)}>
        <div className={styles.titleWrap}>
          <h2 className={styles.title}>{t(`loginPage.login`)}</h2>
          <span
            className={cn(
              styles.title_decore,
              "ff-Chappell",
              i18n.language === "RU" && styles.title_decore_ru
            )}
          >
            {t(`loginPage.accessMore`)}
          </span>
        </div>
        {error && (
          <ResponseAlert
            type={ResponseAlertType.Error}
            onClick={() => setError(null)}
            className={styles.error}
          >
            <div>
              <div>{t(error)}</div>
              {error !== "resetPasswordPage.errorMessageUnknown" && (
                <NavLink to={"/forgot-password"} className={styles.forgot}>
                  {textParams(t(`loginPage.errorIncorrectEmailPassword`))[1]}
                </NavLink>
              )}
            </div>
          </ResponseAlert>
        )}
        <TextField
          className={styles.inputs}
          type={"text"}
          label={t(`inputs.email`)}
          placeholder={"user@example.com"}
          {...register("username", {
            required: "field is mandatory",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address"
            },
            onChange: event => {
              setValue("username", event.target.value.replace(/\s/g, ""));
            }
          })}
        />
        <TextField
          className={styles.inputs}
          type={"password"}
          label={t(`inputs.password`)}
          {...register("password", { required: "field is mandatory" })}
        />
        <div className={styles.checkboxWrap}>
          <CheckBoxFiled
            label={t(`loginPage.rememberMe`)}
            className={cn(
              styles.checkbox,
              i18n.language !== "FR" ? styles.shrinkCheckbox : ""
            )}
            {...register("checkbox")}
          />
          <NavLink to={"/forgot-password"} className={styles.link}>
            {t(`loginPage.troubleToLogin`)}
          </NavLink>
        </div>
        <div
          className={cn(
            styles.loginWrap,
            ChangeStyleDependOnLanguage(i18n.language)
          )}
        >
          <NavLink to={"/registration"} className={styles.register}>
            {t(`loginPage.createNewAccount`)}
          </NavLink>
          <Button
            type={"submit"}
            variant={ButtonType.Primary}
            className={styles.button}
            disabled={!isDirty || !isValid || isLoading}
            loading={isLoading}
          >
            {t(`buttons.login`)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OktaSignInWidget;
