import React from "react";
import { components, MenuProps } from "react-select";

import styles from "../SelectField.module.scss";

const Menu: React.FC<MenuProps> = ({ children, ...rest }) => {
  return (
    <components.Menu {...rest}>
      <div className={styles.menu}>{children}</div>
    </components.Menu>
  );
};

export default Menu;
