import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./PublishPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Condition from "../../../shared/Condition";
import Icon from "../../../shared/Icon";
import Button from "../../WCMButton";

type PublishPopupProps = {
  publish: () => Promise<any>;
  exit: () => void;
};
const PublishPopup: React.FC<PublishPopupProps> = ({ publish, exit }) => {
  const { SetOpenModalAction } = useActions();
  const modal = useSelector(modalSelector);
  const [isPublished, setIsPublished] = useState(false);
  const [isPublishedLoading, setIsPublishedLoading] = useState<boolean>(false);
  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);

  const publishDocuments = () => {
    setIsPublishedLoading(true);
    publish().finally(() => {
      setIsPublishedLoading(false);
      setIsPublished(true);
    });
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "PublishUploadedDocuments"}
      onRequestClose={toggleModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.container}>
        <Condition
          condition={!isPublished}
          Truthy={
            <>
              <div className={styles.content}>
                <div className={styles.title}>{`Publish ${
                  modal.queryParams?.uploadedFiles?.length
                } ${
                  modal.queryParams?.uploadedFiles?.length > 1
                    ? "documents"
                    : "document"
                }?`}</div>
              </div>
              <div className={styles.buttonsContainer}>
                <Button variant="text" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button
                  onClick={publishDocuments}
                  isLoading={isPublishedLoading}
                >
                  Publish
                </Button>
              </div>
            </>
          }
          Falsy={
            <>
              <div className={styles.content}>
                <div className={styles.title}>
                  <Icon kind="success_circle" />
                  {`${modal.queryParams?.uploadedFiles?.length} ${
                    modal.queryParams?.uploadedFiles?.length > 1
                      ? "documents"
                      : "document"
                  } successfully published`}
                </div>
              </div>
              <div className={styles.exitButtonContainer}>
                <Button
                  onClick={() => {
                    toggleModal();
                    exit();
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          }
        />
      </div>
    </Modal>
  );
};

export default PublishPopup;
