export const getUserLocalLanguage = () => {
  let language = "EN";
  const ln = navigator.language.toLowerCase();

  if (ln.includes("da")) {
    language = "DA";
  }
  if (ln.includes("de")) {
    language = "DE";
  }
  if (ln.includes("ru")) {
    language = "RU";
  }
  if (ln.includes("zh")) {
    language = "ZH";
  }
  if (ln.includes("cv")) {
    language = "CV";
  }
  if (ln === "zh_HK".toLowerCase() || ln.includes("zh_HANS")) {
    language = "SC";
  }
  if (ln.includes("pt")) {
    language = "PT";
  }
  if (ln.includes("no")) {
    language = "NO";
  }
  if (ln.includes("nl")) {
    language = "NL";
  }
  if (ln.includes("ms")) {
    language = "MS";
  }
  if (ln.includes("es")) {
    language = "ES";
  }
  if (ln.includes("es-")) {
    language = "LAS";
  }
  if (ln.includes("fi")) {
    language = "FI";
  }
  if (ln.includes("fr")) {
    language = "FR";
  }
  if (ln.includes("it")) {
    language = "IT";
  }
  if (ln.includes("ko")) {
    language = "KO";
  }
  return language;
};
