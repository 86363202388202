import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import styles from "./SelectFlow.module.scss";

import Condition from "../../../../components/shared/Condition";
import Select from "../../../../components/userManagement/Select";
import { options } from "../../constants";
import { FieldTypes, SelectFlowProps } from "./types";

const SelectFlow: React.FC<SelectFlowProps> = ({
  setFinalState,
  disabled,
  defaultValues
}) => {
  const { control, watch, setValue, reset } = useForm<FieldTypes>({
    defaultValues
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues as FieldTypes);
    }
  }, [defaultValues]);

  defaultValues;
  const {
    uploadType,
    statementFrequency,
    statementYear,
    statementDate
  } = watch();

  const frequencyFlag = Boolean(
    uploadType?.value !== "tax-doc" && uploadType?.value
  );
  const yearFlag = Boolean(frequencyFlag && statementFrequency?.value);
  const dateFlag = Boolean(yearFlag && statementYear?.value);

  const dateOptions = useMemo(() => {
    if (!statementFrequency?.value || !statementYear?.value) return [];
    return options.statementDate(statementYear?.value)[
      statementFrequency?.value
    ];
  }, [statementYear?.value, statementFrequency?.value]);

  useEffect(() => {
    if (statementFrequency?.value && statementYear?.value) {
      setValue("statementDate", defaultValues.statementDate || null);
    }
  }, [statementFrequency?.value, statementYear?.value]);

  useEffect(() => {
    if (uploadType?.value === "tax-doc") {
      reset({
        uploadType: uploadType
      });
    }
  }, [uploadType?.value]);

  useEffect(() => {
    if (uploadType?.value === "tax-doc") {
      setFinalState({ documentType: uploadType?.value });
    } else if (statementDate?.value && uploadType?.value) {
      setFinalState({
        documentType: uploadType.value,
        ...statementDate.value
      });
    } else setFinalState(null);
  }, [uploadType?.value, statementDate?.value]);
  useEffect(() => {
    localStorage.setItem("uploadTableType", uploadType?.value || "");
  }, [uploadType]);
  return (
    <div className={styles.wrapper}>
      <Select
        disabled={disabled}
        control={control}
        name={"uploadType"}
        placeholder="Select Document Type"
        options={options.uploadType}
      />
      <Condition
        condition={frequencyFlag}
        Truthy={
          <Select
            disabled={disabled}
            control={control}
            name={"statementFrequency"}
            placeholder="Document Frequency"
            options={options.statementFrequency}
          />
        }
      />
      <Condition
        condition={yearFlag}
        Truthy={
          <Select
            disabled={disabled}
            control={control}
            name={"statementYear"}
            placeholder="Document Year"
            options={options.statementYear}
          />
        }
      />
      <Condition
        condition={dateFlag}
        Truthy={
          <Select
            disabled={disabled}
            control={control}
            name={"statementDate"}
            placeholder="Document Date"
            options={dateOptions}
          />
        }
      />
    </div>
  );
};

export default SelectFlow;
