/**
 * Trims object recursively
 * @param obj JSON object which should be trimmed
 * @param keepObjInstance If 'true' object will be modified instead of creating a new instance
 * @returns Trimmed object with the same type as obj argument
 */
export const trimObject = <T>(obj: T, keepObjInstance = false): T => {
  if (typeof obj === "undefined" || obj === null) {
    return obj;
  }
  if (typeof obj === "string") {
    return obj.trim() as T;
  }
  if (Array.isArray(obj)) {
    if (keepObjInstance) {
      obj.forEach((o, i, arr) => (arr[i] = trimObject<T>(o)));
      return obj;
    }
    return obj.map(o => trimObject<T>(o)) as T;
  }
  if (typeof obj === "object") {
    const objInstance = keepObjInstance ? obj : { ...obj };
    for (const prop in objInstance) {
      if (Object.hasOwn(objInstance, prop)) {
        objInstance[prop] = trimObject(objInstance[prop]);
      }
    }
    return objInstance;
  }
  return obj;
};
