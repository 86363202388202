import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./SetContactUsModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

import { useActions } from "../../../../hooks/useActions";
import ContactUs from "../../../../page/ContactUs";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";

const SetContactUsModal = () => {
  const dealsModal = useSelector(dealsModalSelector);
  const { SetOpenModal } = useActions();
  const [success, setSuccess] = useState(false);
  const toggleModal = () => {
    setSuccess(false);
    SetOpenModal({ type: "", isOpen: false });
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={dealsModal.isOpen && dealsModal.type === "contactUs"}
      onRequestClose={toggleModal}
      className={styles.contactUsModal}
      overlayClassName={styles.myoverlay}
    >
      <div className={styles.wrapper}>
        {!success && (
          <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
        )}
        <ContactUs isModal setSuccess={setSuccess} />
      </div>
    </Modal>
  );
};

export default SetContactUsModal;
