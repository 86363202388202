import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./CancelUploadPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { UploadedFile } from "../../../../page/StatementUpload/types";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Button from "../../WCMButton";

type CancelUploadPopupProps = {
  cancelAllUploads: () => Promise<unknown>;
};

type QueryParams = {
  file: UploadedFile;
  cancelUploadById?: (id: string) => Promise<unknown>;
};

const CancelUploadPopup: React.FC<CancelUploadPopupProps> = ({
  cancelAllUploads
}) => {
  const modal = useSelector(modalSelector);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelUpload = async () => {
    setIsLoading(true);
    queryParams?.cancelUploadById
      ? await queryParams.cancelUploadById(queryParams?.file?.id)
      : await cancelAllUploads();
    setIsLoading(false);

    toggleModal();
  };

  const queryParams = modal?.queryParams as QueryParams;

  const { SetOpenModalAction } = useActions();

  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);

  const message = queryParams?.file?.fileName ? (
    <>
      <span>Cancel upload for?</span>
      <span>`{queryParams?.file?.fileName}`</span>
    </>
  ) : (
    <>
      <span>Cancel all file uploads?</span>
      <span>Any progress will be lost.</span>
    </>
  );

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "CancelUpload"}
      onRequestClose={toggleModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.title}>{message}</div>
        <div className={styles.buttonsContainer}>
          <Button
            isLoading={isLoading}
            onClick={() => {
              handleCancelUpload();
            }}
          >
            Yes, Cancel Upload
          </Button>
          <Button variant="text" onClick={toggleModal} disabled={isLoading}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelUploadPopup;
