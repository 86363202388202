import React from "react";
import cn from "classnames";

import styles from "./Chip.module.scss";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete_icon.svg";

export interface IChip {
  label: string;
  value?: string;
  typeName?: string;
  type: string;
  itemValue: string;
  handleClick: (
    type: string,
    itemValue?: string,
    e?: React.MouseEvent<HTMLElement>
  ) => void;
}
const Chip = (props: IChip) => {
  const { label, typeName, itemValue, type, handleClick } = props;

  return (
    <div className={cn(styles.container)}>
      {typeName ? <span>{typeName}: </span> : null}
      <span className={cn(styles.container_value)}>{label}</span>
      <span
        className={cn(styles.container_delete)}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          handleClick(type, itemValue, e);
        }}
      >
        <DeleteIcon />
      </span>
    </div>
  );
};
export default Chip;
