import { Tooltip } from "@material-ui/core";

import { formatCurrency } from "../../../utils/format";
import { FormattedCurrencyProps } from "./FormattedCurrency.types";

/**
 * Renders currency with tooltip
 * @param props React component properties
 * @returns JSX element
 */
const FormattedCurrency = (props: FormattedCurrencyProps): JSX.Element => {
  const text = formatCurrency(props.value, props.currency);
  const title =
    typeof props.value === "number"
      ? formatCurrency(props.value, props.currency, "standard")
      : null;

  // Use tooltip if title is available
  if (title) {
    return (
      <Tooltip title={title}>
        <span className={props.className}>{text}</span>
      </Tooltip>
    );
  }

  return <span className={props.className}>{text}</span>;
};

export default FormattedCurrency;
