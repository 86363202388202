import React, { useCallback } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";
import moment from "moment/moment";

import styles from "../SetFilterModal/FilterModal.module.scss";
interface DatePickerModalProps {
  type: "HISTORY" | "SAVED_UPLOADS" | "DOWNLOAD";
}
import { useActions } from "../../../../hooks/useActions";
import {
  downloadDateRangeModalSelector,
  downloadDateRangeSelector,
  historyDateRangeModalSelector,
  historyDateRangeSelector,
  modalSelector,
  savedUploadsDateRangeModalSelector,
  savedUploadsDateRangeSelector
} from "../../../../store/architectUsersStore/selectors";
import RangeDatePicker from "../../../userManagement/RangeDatePicker/RangeDatePicker";
import Button from "../../../userManagement/WCMButton";
const DatePickerModal = (props: DatePickerModalProps) => {
  const { type } = props;
  const modal = useSelector(modalSelector);
  const historyDateRange = useSelector(historyDateRangeSelector);
  const downloadDateRange = useSelector(downloadDateRangeSelector);
  const historyDateRangeModal = useSelector(historyDateRangeModalSelector);
  const downloadDateRangeModal = useSelector(downloadDateRangeModalSelector);
  const savedUploadsDateRange = useSelector(savedUploadsDateRangeSelector);
  const savedUploadsDateRangeModal = useSelector(
    savedUploadsDateRangeModalSelector
  );
  const {
    SetOpenModalAction,
    SetHistoryDateRangeModal,
    SetSavedUploadsDateRangeModal,
    SetDownloadDateRangeModal
  } = useActions();
  const closeModal = useCallback(() => {
    const type =
      modal.type.filter((el: string) => el !== "rangeDatePickerModal") || [];
    SetOpenModalAction({
      isOpen: true,
      type: type
    });
  }, [SetOpenModalAction, modal]);
  const handleChange = useCallback(
    (item: { endDate: string; startDate: string; label?: string }) => {
      const dateRangeFilter = {
        ...item,
        filterDateRange: [
          {
            name: "createdAt",
            operation: "ge",
            value: moment(item.startDate).format("YYYY-MM-DD")
          },
          {
            name: "createdAt",
            operation: "le",
            value: moment(item.endDate).format("YYYY-MM-DD") + "T23:59:59"
          }
        ]
      };
      if (type === "HISTORY") {
        SetHistoryDateRangeModal(dateRangeFilter);
      }
      if (type === "DOWNLOAD") {
        SetDownloadDateRangeModal(dateRangeFilter);
      }
      if (type === "SAVED_UPLOADS") {
        SetSavedUploadsDateRangeModal(dateRangeFilter);
      }
    },
    [
      type,
      savedUploadsDateRangeModal,
      historyDateRangeModal,
      downloadDateRangeModal
    ]
  );
  const handleClick = useCallback(
    (clickType: string) => {
      if (clickType === "confirm") {
        closeModal();
      }
      if (clickType === "clear") {
        closeModal();
        if (type === "HISTORY") {
          SetHistoryDateRangeModal(null);
        }
        if (type === "DOWNLOAD") {
          SetDownloadDateRangeModal(null);
        }
        if (type === "SAVED_UPLOADS") {
          SetSavedUploadsDateRangeModal(null);
        }
      }
      if (clickType === "cancel") {
        closeModal();
        if (type === "HISTORY") {
          SetHistoryDateRangeModal(historyDateRange);
        }
        if (type === "DOWNLOAD") {
          SetDownloadDateRangeModal(downloadDateRange);
        }
        if (type === "SAVED_UPLOADS") {
          SetSavedUploadsDateRangeModal(savedUploadsDateRange);
        }
      }
    },
    [
      historyDateRange,
      downloadDateRange,
      historyDateRangeModal,
      downloadDateRangeModal,
      savedUploadsDateRangeModal,
      savedUploadsDateRange,
      type
    ]
  );
  return (
    <div className={styles.setDatePickerRangeModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type.includes("rangeDatePickerModal")}
        onRequestClose={closeModal}
        className={cn(styles.datePickerModal)}
        overlayClassName={styles.datePickerModal_overlay}
      >
        <RangeDatePicker
          initialDateRange={{
            startDate:
              type === "HISTORY"
                ? historyDateRange?.startDate || new Date()
                : type === "DOWNLOAD"
                ? downloadDateRange?.startDate || new Date()
                : savedUploadsDateRange?.startDate || new Date(),
            endDate:
              type === "HISTORY"
                ? historyDateRange?.endDate || new Date()
                : type === "DOWNLOAD"
                ? downloadDateRange?.endDate || new Date()
                : savedUploadsDateRange?.endDate || new Date()
          }}
          open={true}
          onChange={item => handleChange(item)}
          toggle={closeModal}
        />
        <div className={cn(styles.datePickerModal_buttons)}>
          <Button onClick={() => handleClick("cancel")} variant="text">
            Cancel
          </Button>
          <Button onClick={() => handleClick("clear")} variant="text">
            Clear
          </Button>
          <Button onClick={() => handleClick("confirm")} variant="outlined">
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default DatePickerModal;
