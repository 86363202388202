export interface ErrorDetails {
  /**
   * Client Portal error code
   */
  errorCode?: string;
  /**
   * Network status code
   */
  status?: number;
}

/**
 * Safely parses error details from a network request
 * @param e Error
 * @returns Error details
 */
export const getErrorDetails = (e: unknown): ErrorDetails => {
  if (
    e &&
    typeof e === "object" &&
    "errorCode" in e &&
    typeof e.errorCode === "string"
  ) {
    // OKTA error
    return { errorCode: e.errorCode };
  }

  if (
    e &&
    typeof e === "object" &&
    "response" in e &&
    e.response &&
    typeof e.response === "object"
  ) {
    const errorCode =
      "data" in e.response &&
      e.response.data &&
      typeof e.response.data === "object" &&
      "errorCode" in e.response.data &&
      typeof e.response.data.errorCode === "string"
        ? e.response.data.errorCode
        : undefined;
    const status =
      "status" in e.response && typeof e.response.status === "number"
        ? e.response.status
        : undefined;
    return { errorCode, status };
  }
  return { errorCode: undefined, status: undefined };
};
