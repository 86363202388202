import { ArchitectUsersTypes } from "./types";

const GetArchitectUsersAction = (body: any, isScroll: boolean): any => ({
  type: ArchitectUsersTypes.ARCHITECT_USERS_REQUEST,
  payload: { body, isScroll }
});

const MetaDataAction = (): any => ({
  type: ArchitectUsersTypes.METADATA_REQUEST
});
const ModifiersAction = (): any => ({
  type: ArchitectUsersTypes.MODIFIERS_REQUEST
});
const UploaderListAction = (status: string): any => ({
  type: ArchitectUsersTypes.UPLOADER_REQUEST,
  payload: { status }
});
const SetLoading = (isLoading: boolean): any => ({
  type: ArchitectUsersTypes.ARCHITECT_USERS_LOADING,
  payload: isLoading
});

const SetMetaDataLoading = (isLoading: boolean): any => ({
  type: ArchitectUsersTypes.METADATA_LOADING,
  payload: isLoading
});
const SetModifiersLoading = (isLoading: boolean): any => ({
  type: ArchitectUsersTypes.MODIFIERS_LOADING,
  payload: isLoading
});
const SetUploaderListLoading = (isLoading: boolean): any => ({
  type: ArchitectUsersTypes.UPLOADER_LOADING,
  payload: isLoading
});

const SetUploadTableType = (uploadTableType: string): any => ({
  type: ArchitectUsersTypes.SET_UPLOAD_TABLE_TYPE,
  payload: uploadTableType
});

const SetBody = (body: any): any => ({
  type: ArchitectUsersTypes.SET_BODY_ACTION,
  payload: body
});

//HISTORY FILTER
const SetHistoryFilters = (historyFilters: any): any => {
  localStorage.setItem("historyFilters", JSON.stringify(historyFilters));
  return {
    type: ArchitectUsersTypes.SET_HISTORY_FILTERS_ACTION,
    payload: historyFilters
  };
};
const SetHistoryFilterModal = (historyFilterModal: any): any => {
  localStorage.setItem(
    "historyFilterModal",
    JSON.stringify(historyFilterModal)
  );
  return {
    type: ArchitectUsersTypes.SET_HISTORY_MODAL_FILTERS_ACTION,
    payload: historyFilterModal
  };
};
const SetHistoryDateRange = (historyDateRange: any): any => {
  localStorage.setItem("historyDateRange", JSON.stringify(historyDateRange));
  return {
    type: ArchitectUsersTypes.SET_HISTORY_DATE_RANGE_ACTION,
    payload: historyDateRange
  };
};
const SetHistoryDateRangeModal = (historyDateRangeModal: any): any => {
  localStorage.setItem(
    "historyDateRangeModal",
    JSON.stringify(historyDateRangeModal)
  );
  return {
    type: ArchitectUsersTypes.SET_HISTORY_MODAL_DATE_RANGE_ACTION,
    payload: historyDateRangeModal
  };
};
const GenerateHistoryFilter = (generatedHistoryFilters: any): any => {
  localStorage.setItem(
    "generatedHistoryFilters",
    JSON.stringify(generatedHistoryFilters)
  );
  return {
    type: ArchitectUsersTypes.SET_HISTORY_GENERATED_FILTERS_ACTION,
    payload: generatedHistoryFilters
  };
};
//HISTORY FILTER

//DOWNLOAD FILTER
const SetDownloadFilters = (downloadFilters: any): any => {
  localStorage.setItem("downloadFilters", JSON.stringify(downloadFilters));
  return {
    type: ArchitectUsersTypes.SET_DOWNLOAD_FILTERS_ACTION,
    payload: downloadFilters
  };
};
const SetDownloadFilterModal = (downloadFilterModal: any): any => {
  localStorage.setItem(
    "downloadFilterModal",
    JSON.stringify(downloadFilterModal)
  );
  return {
    type: ArchitectUsersTypes.SET_DOWNLOAD_MODAL_FILTERS_ACTION,
    payload: downloadFilterModal
  };
};
const SetDownloadDateRange = (downloadDateRange: any): any => {
  localStorage.setItem("downloadDateRange", JSON.stringify(downloadDateRange));
  return {
    type: ArchitectUsersTypes.SET_DOWNLOAD_DATE_RANGE_ACTION,
    payload: downloadDateRange
  };
};
const SetDownloadDateRangeModal = (downloadDateRangeModal: any): any => {
  localStorage.setItem(
    "downloadDateRangeModal",
    JSON.stringify(downloadDateRangeModal)
  );
  return {
    type: ArchitectUsersTypes.SET_DOWNLOAD_MODAL_DATE_RANGE_ACTION,
    payload: downloadDateRangeModal
  };
};

//DOWNLOAD FILTER

//SAVED UPLOADS
const SetSavedUploadsFilters = (savedUploadsFilters: any): any => {
  localStorage.setItem(
    "savedUploadsFilters",
    JSON.stringify(savedUploadsFilters)
  );
  return {
    type: ArchitectUsersTypes.SET_SAVED_UPLOADS_FILTERS_ACTION,
    payload: savedUploadsFilters
  };
};
const SetSavedUploadsFilterModal = (savedUploadsFilterModal: any): any => {
  localStorage.setItem(
    "savedUploadsFilterModal",
    JSON.stringify(savedUploadsFilterModal)
  );
  return {
    type: ArchitectUsersTypes.SET_SAVED_UPLOADS_MODAL_FILTERS_ACTION,
    payload: savedUploadsFilterModal
  };
};
const SetSavedUploadsDateRange = (savedUploadsDateRange: any): any => {
  localStorage.setItem(
    "savedUploadsDateRange",
    JSON.stringify(savedUploadsDateRange)
  );
  return {
    type: ArchitectUsersTypes.SET_SAVED_UPLOADS_DATE_RANGE_ACTION,
    payload: savedUploadsDateRange
  };
};
const SetSavedUploadsDateRangeModal = (
  savedUploadsDateRangeModal: any
): any => {
  localStorage.setItem(
    "savedUploadsDateRangeModal",
    JSON.stringify(savedUploadsDateRangeModal)
  );
  return {
    type: ArchitectUsersTypes.SET_SAVED_UPLOADS_MODAL_DATE_RANGE_ACTION,
    payload: savedUploadsDateRangeModal
  };
};
const GenerateSavedUploadsFilter = (generatedSavedUploadsFilters: any): any => {
  localStorage.setItem(
    "generatedSavedUploadsFilters",
    JSON.stringify(generatedSavedUploadsFilters)
  );
  return {
    type: ArchitectUsersTypes.SET_SAVED_UPLOADS_GENERATED_FILTERS_ACTION,
    payload: generatedSavedUploadsFilters
  };
};
//SAVED UPLOADS

const SetFilters = (filters: any): any => {
  localStorage.setItem("filters", JSON.stringify(filters));
  return {
    type: ArchitectUsersTypes.SET_FILTERS_ACTION,
    payload: filters
  };
};

const setModalFilter = (modalFilter: any): any => {
  localStorage.setItem("modalFilter", JSON.stringify(modalFilter));
  return {
    type: ArchitectUsersTypes.SET_MODAL_FILTERS_ACTION,
    payload: modalFilter
  };
};
const GenerateFilter = (generatedFilters: any): any => {
  localStorage.setItem("generatedFilters", JSON.stringify(generatedFilters));
  return {
    type: ArchitectUsersTypes.SET_GENERATED_FILTERS_ACTION,
    payload: generatedFilters
  };
};
const SetDateRange = (dateRange: any): any => {
  localStorage.setItem("dateRange", JSON.stringify(dateRange));
  return {
    type: ArchitectUsersTypes.SET_DATE_RANGE_ACTION,
    payload: dateRange
  };
};
const GetArchitectUsersSuccessAction = (data: any, isScroll: boolean): any => ({
  type: ArchitectUsersTypes.ARCHITECT_USERS_SUCCESS,
  payload: { data, isScroll }
});

const GetMetaDataSuccessAction = (data: any): any => ({
  type: ArchitectUsersTypes.METADATA_SUCCESS,
  payload: { data }
});

const GetModifiersSuccessAction = (data: any): any => ({
  type: ArchitectUsersTypes.MODIFIERS_SUCCESS,
  payload: { data }
});
const GetUploaderListSuccessAction = (data: any): any => ({
  type: ArchitectUsersTypes.UPLOADER_SUCCESS,
  payload: { data }
});
const SetOpenModalAction = (modal: {
  isOpen: boolean;
  type: string | string[] | any;
  keepOpen?: boolean;
  user?: any;
  queryParams?: any;
  fileName?: string;
}): any => ({
  type: ArchitectUsersTypes.OPEN_MODAL_ADD_USER,
  payload: modal
});
const SetSearchedValue = (data: any): any => ({
  type: ArchitectUsersTypes.SET_SEARCH_VALUE,
  payload: data
});
const SetUMSTypeSearch = (data: { label: string; value: string }): any => {
  localStorage.setItem("UMSTypeSearch", JSON.stringify(data));
  return {
    type: ArchitectUsersTypes.SET_UMS_SEARCH_TYPE,
    payload: data
  };
};
const ClearModalFilters = (): any => {
  localStorage.removeItem("modalFilter");
  return {
    type: ArchitectUsersTypes.SET_MODAL_FILTERS_ACTION,
    payload: []
  };
};
const ClearFilters = (): any => {
  localStorage.removeItem("filters");
  return {
    type: ArchitectUsersTypes.SET_FILTERS_ACTION,
    payload: []
  };
};

export {
  ClearFilters,
  ClearModalFilters,
  GenerateFilter,
  GenerateHistoryFilter,
  GenerateSavedUploadsFilter,
  GetArchitectUsersAction,
  GetArchitectUsersSuccessAction,
  GetMetaDataSuccessAction,
  GetModifiersSuccessAction,
  GetUploaderListSuccessAction,
  MetaDataAction,
  ModifiersAction,
  SetBody,
  SetDateRange,
  SetDownloadDateRange,
  SetDownloadDateRangeModal,
  SetDownloadFilterModal,
  SetDownloadFilters,
  SetFilters,
  SetHistoryDateRange,
  SetHistoryDateRangeModal,
  SetHistoryFilterModal,
  SetHistoryFilters,
  SetLoading,
  SetMetaDataLoading,
  setModalFilter,
  SetModifiersLoading,
  SetOpenModalAction,
  SetSavedUploadsDateRange,
  SetSavedUploadsDateRangeModal,
  SetSavedUploadsFilterModal,
  SetSavedUploadsFilters,
  SetSearchedValue,
  SetUMSTypeSearch,
  SetUploaderListLoading,
  SetUploadTableType,
  UploaderListAction
};
