import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import styles from "./PayeeCodeInfo.module.scss";
import { ReactComponent as ArrowDownIcon } from "../../../icons/percentage_arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../icons/percentage_arrow_up.svg";
import { ReactComponent as InfoIcon } from "../../../icons/white_info_icon.svg";
import { ReactComponent as InfoIconHovered } from "../../../icons/white_info_icon_hovered.svg";

import { dealsModalSelector } from "../../../store/dealsStore/selectors";

export interface PayeeCodeInfoType {
  name: string;
  label?: string | any;
  hasIcon?: boolean;
  isBig?: boolean;
  isBigIcon?: boolean;
  alignRight?: boolean;
  hasBorder?: boolean;
  longBorder?: boolean;
  hasButton?: boolean;
  className?: string;
  currentBalancePosition?: string;
  currentBalancePercentage?: number;
  labelClick?: (e: any) => void;
  payeeCodeLabelChildren?: JSX.Element;
  payeeCodeNameChildren?: JSX.Element;
  labelRef?: any;
  isMiddle?: boolean;
  isMedium?: boolean;
  infoText?: string;
  tooltipText?: string;
  id?: number | string;
  [key: string]: any;
}

function PayeeCodeInfo(props: PayeeCodeInfoType) {
  const dealsModal = useSelector(dealsModalSelector);
  return (
    <>
      <div
        ref={props.labelRef}
        className={cn(
          styles.item,
          {
            [styles.item_big]: props.isBig,
            [styles.item_border]: props.hasBorder
          },
          "payeeCodeElement",
          props.className ? props.className : ""
        )}
      >
        <div
          onClick={props.labelClick}
          className={cn(
            styles.item_content,
            {
              [styles.item_content_border_long]: props.longBorder
            },
            "payeeContent"
          )}
        >
          <div
            className={cn(styles.item_name, {
              [styles.item_name_big]: props.isBig
            })}
          >
            <span className="payeeCodeTitle">
              {props.payeeCodeNameChildren && (
                <span>{props.payeeCodeNameChildren}</span>
              )}
              <span className="payeeCodeName">{props.name}</span>
            </span>
            {props.tooltipText && props.hasIcon && (
              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{ __html: props.tooltipText }}
                  />
                }
              >
                <span
                  className={cn(styles.icon_wrapper, "icon_wrapper", {
                    [styles.bigIcon]: props?.isBigIcon
                  })}
                >
                  <InfoIcon className={cn(styles.icon)} width={50} />
                  <InfoIconHovered
                    className={cn(styles.icon, styles.icon_hovered)}
                  />
                </span>
              </Tooltip>
            )}
          </div>
          <div
            data-for={props.dataForInLabel}
            data-tip=""
            className={cn(
              styles.item_label,
              styles.item_label_percent,
              {
                [styles.item_label_big]: props.isBig,
                [styles.item_label_alignRight]: props.alignRight,
                [styles.item_label_middle]: props.isMiddle,
                [styles.item_label_medium]: props.isMedium
              },
              "payeeCodeLabel"
            )}
          >
            <span className={cn(styles.percent_label)}>{props.label}</span>
            {props.currentBalancePercentage ? (
              <>
                <span className={cn(styles.percent_text)}>
                  <span
                    className={cn(styles.item_label_percentage, {
                      [styles.item_label_percentage_dec]:
                        props.currentBalancePosition === "DECREMENT"
                    })}
                  >
                    {props.currentBalancePercentage &&
                      props.currentBalancePercentage}
                    %
                  </span>
                  {props.currentBalancePosition === "INCREMENT" && (
                    <ArrowUpIcon className={cn(styles.precentageicon)} />
                  )}
                </span>
                {props.currentBalancePosition === "DECREMENT" && (
                  <ArrowDownIcon
                    className={cn(
                      styles.precentageicon,
                      styles.precentageicon_rotate
                    )}
                  />
                )}
              </>
            ) : (
              ""
            )}
            <span
              className={cn(
                styles.payeeCodeLabelChildren,
                dealsModal.type === "royaltyPeriod" && dealsModal.isOpen
                  ? styles.payeeCodeLabelChildren_rotate
                  : styles.payeeCodeLabelChildren
              )}
            >
              {props.payeeCodeLabelChildren}
            </span>
          </div>
        </div>
        {props.hasButton && (
          <button className={cn(styles.payeeCodeBlock_btn)}>
            Request Advance
          </button>
        )}
        {props.infoText && (
          <span className={cn(styles.item_info)}>
            {props?.infoText && props.infoText}
          </span>
        )}
      </div>
    </>
  );
}

export default PayeeCodeInfo;
