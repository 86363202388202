import React from "react";
import Select, { components } from "react-select";

import { ReactComponent as Glob } from "../../../icons/black_globus.svg";

function SelectField(props) {
  const styles = {
    menu: styles => ({
      ...styles,
      border: "1px solid #CBCBCB",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)"
    }),
    control: (styles, state) => ({
      ...styles,
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)"
      },
      backgroundColors: "white",
      boxShadow: "none",
      borderRadius: "0",
      borderColor: state.isFocused
        ? "rgba(217,168,27,1)"
        : "rgba(0, 0, 0, 0.23)"
      // borderWidth: state.isFocused ? "2px" : "1px"
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "2px 5%"
      // borderRadius: "0"
    }),
    container: provided => ({
      ...provided,
      backgroundColor: "red",
      borderRadius: "0",
      zIndex: "1000"
    }),
    placeholder: styles => ({
      ...styles,
      fontStyle: "italic"
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: "white",
        cursor: "pointer",
        color: state.isSelected
          ? "#383838"
          : state.isDisabled
          ? "#383838"
          : "#383838",
        boxShadow: "1px 1px 1px white",
        borderLeft: "2px solid transparent",
        "&:hover": {
          backgroundColor: state.isSelected
            ? "#F4F4F4"
            : state.isDisabled
            ? "#F4F4F4"
            : "#F4F4F4",
          color: "#383838",
          borderLeft: "2px solid black"
        }
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: "black",
      backgroundColor: "#E5E5E5",
      borderRadius: "0",
      fontSize: "11px"
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: "#E5E5E5",
      borderRadius: "1rem",
      overflow: "hidden"
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: "black",
      backgroundColor: "#E5E5E5",
      borderRadius: "0",
      ":hover": {
        backgroundColor: "#E5E5E5"
      }
    })
  };
  return (
    <Select
      className="wcm-select"
      classNamePrefix="wcm-select"
      closeMenuOnSelect={false}
      components={{
        ClearIndicator: ClearIndicatorProps => {
          return (
            <components.ClearIndicator {...ClearIndicatorProps}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
              >
                <path
                  d="M6.5 0.625C2.89453 0.625 0 3.54492 0 7.125C0 10.7305 2.89453 13.625 6.5 13.625C10.0801 13.625 13 10.7305 13 7.125C13 3.54492 10.0801 0.625 6.5 0.625ZM6.5 12.8125C3.35156 12.8125 0.8125 10.2734 0.8125 7.125C0.8125 4.00195 3.35156 1.4375 6.5 1.4375C9.62305 1.4375 12.1875 4.00195 12.1875 7.125C12.1875 10.2734 9.62305 12.8125 6.5 12.8125ZM9.19141 3.97656C9.01367 3.84961 8.75977 3.84961 8.60742 4.02734L6.5 6.51562L4.36719 4.02734C4.21484 3.84961 3.96094 3.84961 3.7832 3.97656C3.60547 4.12891 3.60547 4.38281 3.73242 4.56055L5.94141 7.125L3.73242 9.71484C3.60547 9.89258 3.60547 10.1465 3.7832 10.2988C3.85938 10.3496 3.96094 10.375 4.0625 10.375C4.16406 10.375 4.26562 10.3496 4.36719 10.248L6.5 7.75977L8.60742 10.248C8.70898 10.3496 8.81055 10.375 8.9375 10.375C9.01367 10.375 9.11523 10.3496 9.19141 10.2988C9.36914 10.1465 9.36914 9.89258 9.24219 9.71484L7.0332 7.125L9.24219 4.56055C9.36914 4.38281 9.36914 4.12891 9.19141 3.97656Z"
                  fill="#646464"
                />
              </svg>
            </components.ClearIndicator>
          );
        },
        DropdownIndicator: DropdownIndicatorProps => {
          return props.hasGlob ? (
            <components.DropdownIndicator {...DropdownIndicatorProps}>
              <Glob />
            </components.DropdownIndicator>
          ) : (
            <components.DropdownIndicator {...DropdownIndicatorProps}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="7"
                viewBox="0 0 13 7"
                fill="none"
              >
                <path
                  d="M11.873 6.0293C11.7969 6.13086 11.6953 6.15625 11.5938 6.15625C11.4922 6.15625 11.3906 6.13086 11.3145 6.05469L6.3125 1.43359L1.28516 6.0293C1.13281 6.18164 0.878906 6.18164 0.726562 6.00391C0.574219 5.85156 0.574219 5.59766 0.751953 5.44531L6.0332 0.570312C6.18555 0.417969 6.41406 0.417969 6.56641 0.570312L11.8477 5.44531C12.0254 5.62305 12.0254 5.87695 11.873 6.0293Z"
                  fill="#646464"
                />
              </svg>
            </components.DropdownIndicator>
          );
        }
      }}
      isMulti
      options={props.options}
      styles={styles}
      placeholder={props.placeholder}
      maxMenuHeight={315}
      menuIsOpen={props.menuIsOpen}
      menuPortalTarget={document.body}
      hideSelectedOptions={false}
      isSearchable={props.isSearchable || false}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      value={props.value}
    />
  );
}

export default SelectField;
