export enum DocumentTypes {
  "TAX" = "tax-doc",
  "SELF_BILLING_INVOICE" = "self-billing-invoice",
  "REMITTANCE_ADVICE" = "remittance-advice",
  "STATEMENT" = "statement-doc",
  "ROYALTY_STATEMENT" = "royalty-statement"
}

export enum DocumentType {
  REMITTANCE_ADVICE = "REMITTANCE_ADVICE",
  ROYALTY_STATEMENT = "ROYALTY_STATEMENT",
  SELF_BILLING_INVOICE = "SELF_BILLING_INVOICE",
  STATEMENT = "STATEMENT",
  TAX = "TAX"
}

export interface DocumentItem {
  documentType: DocumentType;
  downloadCount: number;
  endDate: string;
  fileName: null;
  id: string;
  publishedAt: string;
  startDate: string;
  uploadedAt: string;
  year: number;
}
