import React from "react";
import cn from "classnames";

import styles from "./CountryWidget.module.scss";

import Chart from "../Chart";

export interface CountryWidgetProps extends React.PropsWithChildren<unknown> {
  country: string;
  val: string;
  chartData?: any;
  weekly_trend?: string;
}

const CountryWidget = (props: CountryWidgetProps) => {
  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.head)}>{props.country}</div>
      <div className={cn(styles.body)}>
        <div className={cn(styles.amount)}>{props.val}</div>
        <div className={cn(styles.chart_info)}>
          <p className="font-black">Weekly chg</p>
          <div className={cn(styles.chart_block)}>
            <Chart
              data={props.chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                datasets: { line: { pointRadius: 0 } },
                plugins: {
                  legend: { display: false },
                  tooltip: { enabled: false }
                },
                scales: { x: { display: false }, y: { display: false } },
                layout: { padding: 2 }
              }}
              chartContainerClassName={cn(styles.chart)}
            />
            <p
              style={{
                color: `${props.chartData.datasets[0].borderColor}`
              }}
              className={cn(styles.weekly)}
            >
              {props.weekly_trend}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountryWidget;
