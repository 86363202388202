export const selectionColumnStyles = {
  padding: 0,
  flex: `0 0 auto`
};

export const rowSelectionOptions = {
  default: [
    { label: "Select All", value: "SelectAll" },
    { label: "Deselect All", value: "DeselectAll" }
  ],
  delete: [
    {
      label: "Delete Selected",
      value: "DeleteSelected"
    }
  ],
  download: [
    {
      label: "Download Report",
      value: "DownloadReport"
    }
  ]
};
