import React from "react";

import styles from "./Header.module.scss";

import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({ children }) => {
  return <div className={styles.header}>{children}</div>;
};

export default Header;
