import React from "react";
import Dropdown from "react-dropdown";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";

import styles from "./UiDropdown.module.scss";
import { ReactComponent as Arrow } from "../../../icons/arrow.svg";
import { ReactComponent as Glob } from "../../../icons/white_glogus.svg";

type UiDropdownType = {
  options: { value: string; label: string | any; code?: string | any }[];
  value: { value: string; label: string | any; code?: string | any };
  placeholder?: string;
  className?: string;
  controlClassName?: string;
  menuClassName?: string;
  arrowClassName?: string;
  isDropTop?: boolean;
  isFilter?: boolean;
  isGlob?: boolean;
  [key: string]: any;
};

const UiDropdown: React.FC<UiDropdownType> = ({
  options,

  value,
  className,
  controlClassName,
  menuClassName,
  arrowClassName,
  isDropTop = true,
  onChange,
  onFocus,
  placeholder,
  isGlob,
  isFilter = false,
  isShortValue,
  ...res
}) => {
  const md = useMediaQuery({ maxWidth: 567 });
  const { t } = useTranslation("translation");
  return (
    <Dropdown
      {...res}
      value={
        md && isShortValue
          ? { label: t(`language.currentLanguageShort`), value: value.value }
          : value
      }
      onChange={onChange}
      onFocus={onFocus}
      options={
        isFilter ? options : options.filter(el => el.label !== value.label)
      }
      menuClassName={cn(styles.menu, menuClassName, {
        [styles.dropInDown]: isDropTop
      })}
      placeholder={placeholder}
      className={cn(styles.dropdown, className)}
      baseClassName={styles.baseClassName}
      controlClassName={cn(styles.controlClassName, controlClassName)}
      arrowOpen={
        !isGlob ? (
          <Arrow className={cn(styles.arrowClassName, arrowClassName)} />
        ) : (
          <Glob className={cn(styles.arrowClassName, arrowClassName)} />
        )
      }
      arrowClosed={
        !isGlob ? (
          <Arrow className={cn(styles.arrowClassName, arrowClassName)} />
        ) : (
          <Glob className={cn(styles.arrowClassName, arrowClassName)} />
        )
      }
    />
  );
};

export default UiDropdown;
