const generateYearsArray = (numberOfYears: number) => {
  const currentYear = new Date().getFullYear();

  return Array.from({ length: numberOfYears }, (_, index) =>
    String(currentYear - index)
  );
};

const generateStatementPeriod = {
  freequency: (startDate: string, endDate: string) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const startMonth = startDateObj.getUTCMonth();
    const endMonth = endDateObj.getUTCMonth();

    const difference = endMonth - startMonth;

    return difference === 2 ? "quarterly" : "semiAnnual";
  },
  date: (startDate: string, frequency: string, options: any) => {
    const date = startDate.split("-");
    const year = date[0];
    return options.statementDate(year)[frequency].find((period: any) => {
      return period?.value.startDate === startDate;
    });
  }
};

export default {
  generateYearsArray,
  generateStatementPeriod
};
