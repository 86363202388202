import React from "react";
import cn from "classnames";

import styles from "../../Tabs.module.scss";

import { TabItem } from "../../types";

const Index: React.FC<TabItem> = ({ label, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.tabItem, {
        [styles.active]: active
      })}
    >
      <p>{label}</p>
    </div>
  );
};

export default Index;
