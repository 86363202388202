import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import ReactSelect from "react-select";

import styles from "./SelectField.module.scss";

import { Control, IndicatorsContainer, Menu, Option } from "./components";

type Option = Record<string, string>;

type SelectFieldProps = UseControllerProps & Record<string, unknown>;

const SelectField: React.FC<SelectFieldProps> = props => {
  const { name, control, ...rest } = props;
  const { field } = useController({ name, control });
  return (
    <ReactSelect
      unstyled
      {...field}
      {...rest}
      className={styles.select}
      classNamePrefix={styles.select}
      components={{
        IndicatorSeparator: () => null,
        Control,
        Menu,
        Option,
        IndicatorsContainer
      }}
      options={props.options as Option[]}
    />
  );
};

export default SelectField;
