import { IHistory } from "./index";
import { AdvanceTypes } from "./types";

const SetAdvanceSuccessAction = (
  data: { history: IHistory[]; requestAllowed: boolean } | null,
  loading: boolean | undefined
): any => ({
  type: AdvanceTypes.GET_ADVANCE_SUCCESS,
  payload: { data, loading }
});
const GetAdvanceRequestAction = (userId: string, payeeCode: string): any => ({
  type: AdvanceTypes.GET_ADVANCE_REQUEST,
  payload: { userId, payeeCode }
});
export { GetAdvanceRequestAction, SetAdvanceSuccessAction };
