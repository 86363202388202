import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import ReactSelect, { components, DropdownIndicatorProps } from "react-select";

import styles from "./SelectChekbox.module.scss";
import { ReactComponent as Down } from "../../../icons/iconDown.svg";
import { ReactComponent as Up } from "../../../icons/iconUp.svg";

import { useActions } from "../../../hooks/useActions";
import { TableType } from "../../../page/StatementUpload/types";
import { downloadCSV } from "../../../page/StatementUpload/utils";

export type SelectProps = {
  selectedFlatRows?: any;
  CustomInput: any;
  onChange: any;
  name: string;
  control: any;
  options: { value: any; label: string }[];
  rules?: any;
  placeholder?: string;
  width?: number;
  [key: string]: any;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={styles.iconsContainer}>
        {props.selectProps.menuIsOpen ? <Up /> : <Down />}
      </div>
    </components.DropdownIndicator>
  );
};

const SelectCheckbox: React.FC<SelectProps> = ({
  name,
  onChange,
  control,
  options,
  placeholder,
  CustomInput,
  rules = {},
  selectedFlatRows
}) => {
  const { field } = useController({
    name,
    control,
    rules
  });
  const isArray = true;
  const initialTableType = localStorage.getItem("uploadTableType") as TableType;

  const { handleClickDownload } = downloadCSV;
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [bachIdArray, setBachIdArray] = useState(null);

  const isActionDisabled = (value: string) =>
    (value === "DeleteSelected" || value === "DownloadReport") &&
    selectedFlatRows.length === 0;

  const customStyles = {
    option: (base: any, { data }: any) => {
      const disabled = isActionDisabled(data.value);

      return {
        ...base,
        opacity: disabled ? "0.4" : base.color,
        cursor: disabled ? "not-allowed!important" : base.cursor,
        color:
          data.value === "DeleteSelected"
            ? "#D42B3A!important"
            : data.value === "DownloadReport"
            ? "#YourColorHere!important"
            : base.cursor
      };
    }
  };

  useEffect(() => {
    const updatedOptions = filteredOptions.map(option => ({
      ...option,
      isDisabled: isActionDisabled(option.value)
    }));
    setFilteredOptions(updatedOptions);
  }, [selectedFlatRows, options]);

  useEffect(() => {
    const batchIds = selectedFlatRows.map((el: any) => el.original.id);
    setBachIdArray(batchIds);
  }, [selectedFlatRows]);

  const { SetOpenModalAction } = useActions();
  field.onChange = (option: any) => {
    switch (option.value) {
      case "SelectAll":
        onChange({ target: { checked: true } });
        break;
      case "DeselectAll":
        onChange({ target: { checked: false } });
        break;
      case "DeleteSelected":
        SetOpenModalAction({
          isOpen: true,
          type: "DeleteSelected",
          queryParams: selectedFlatRows
        });
        break;
      case "DownloadReport":
        handleClickDownload(bachIdArray, initialTableType, isArray);
        break;
      default:
        return;
    }
  };

  return (
    <ReactSelect
      unstyled
      className={styles.dropdown}
      classNamePrefix="select"
      components={{
        DropdownIndicator,
        ValueContainer: CustomInput
      }}
      options={filteredOptions}
      placeholder={placeholder}
      {...field}
      styles={customStyles}
    />
  );
};
export default React.memo(SelectCheckbox);
