import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "../CreatePassword.module.scss";
import { ReactComponent as Line } from "../../../../icons/line_icon.svg";

import Button, { ButtonType } from "../../../../components/shared/UiButton";

type SuccessModalType = {
  isCreate: boolean;
};

const SuccessModal: React.FC<SuccessModalType> = ({ isCreate }) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className={styles.success}>
      <div className={styles.iconWrap}>
        <h4>
          {isCreate
            ? t(`resetPasswordPage.successfullySetPassword`)
            : t(`resetPasswordPage.successMessage`)}
        </h4>
        <Line className={styles.decor} />
      </div>
      <Button
        variant={ButtonType.Primary}
        type={"button"}
        className={styles.closeSuccess}
        onClick={() => history.push("/login")}
      >
        {t(`buttons.login`)}
      </Button>
    </div>
  );
};

export default SuccessModal;
