import React from "react";

import "./UploadDescription.module.scss";

const UploadDescription = () => {
  return (
    <ol>
      <li>
        Prepare your PDF for upload by making sure it’s following the file
        naming convention: filename_payeecode.pdf
      </li>
      <li>
        Upload your PDF file, we’ll notify you of any errors along the way.
      </li>
      <li>
        You’ll be able to add and check your uploaded files before publishing.
      </li>
    </ol>
  );
};

export default UploadDescription;
