import {
  AnalyticsTypes,
  getTopState,
  IGetTopIncomeGroupsAction,
  IGetTopIncomeGroupsActionSuccess,
  IGetTopSongsAction,
  IGetTopSongsActionSuccess,
  IGetTopSourcesAction,
  IGetTopSourcesActionSuccess,
  IGetTopTerritoriesAction,
  IGetTopTerritoriesActionSuccess,
  ISetIncomeLoadingAction,
  ISetSongsLoadingAction,
  ISetSourcesLoadingAction,
  ISetTerritoriesLoadingAction,
  top4IncomeResType,
  top4SongsResType,
  top4SourcesResType,
  top4TerritoriesResType
} from "./types";

const getTopSongsAction = ({
  payeeCode,
  data
}: getTopState): IGetTopSongsAction => ({
  type: AnalyticsTypes.GET_TOP_SONGS,
  payload: { payeeCode, data }
});

const getTopSongsActionSuccess = (
  data: top4SongsResType
): IGetTopSongsActionSuccess => ({
  type: AnalyticsTypes.GET_TOP_SONGS_SUCCESS,
  payload: data
});

const getTopSourcesAction = ({
  payeeCode,
  data
}: getTopState): IGetTopSourcesAction => ({
  type: AnalyticsTypes.GET_TOP_SOURCES,
  payload: { payeeCode, data }
});

const getTopSourcesActionSuccess = (
  data: top4SourcesResType
): IGetTopSourcesActionSuccess => ({
  type: AnalyticsTypes.GET_TOP_SOURCES_SUCCESS,
  payload: data
});

const getTopTerritoriesAction = ({
  payeeCode,
  data
}: getTopState): IGetTopTerritoriesAction => ({
  type: AnalyticsTypes.GET_TOP_TERRITORIES,
  payload: { payeeCode, data }
});

const getTopTerritoriesActionSuccess = (
  data: top4TerritoriesResType
): IGetTopTerritoriesActionSuccess => ({
  type: AnalyticsTypes.GET_TOP_TERRITORIES_SUCCESS,
  payload: data
});

const getTopIncomeGroupsAction = ({
  payeeCode,
  data
}: getTopState): IGetTopIncomeGroupsAction => ({
  type: AnalyticsTypes.GET_TOP_INCOME_GROUPS,
  payload: { payeeCode, data }
});

const getTopIncomeGroupsActionSuccess = (
  data: top4IncomeResType
): IGetTopIncomeGroupsActionSuccess => ({
  type: AnalyticsTypes.GET_TOP_INCOME_GROUPS_SUCCESS,
  payload: data
});

const setSongsLoadingAction = (load: boolean): ISetSongsLoadingAction => ({
  type: AnalyticsTypes.SET_SONGS_LOADING,
  payload: load
});

const setSourcesLoadingAction = (load: boolean): ISetSourcesLoadingAction => ({
  type: AnalyticsTypes.SET_SOURCES_LOADING,
  payload: load
});

const setTerritoriesLoadingAction = (
  load: boolean
): ISetTerritoriesLoadingAction => ({
  type: AnalyticsTypes.SET_TERRITORIES_LOADING,
  payload: load
});

const setIncomeLoadingAction = (load: boolean): ISetIncomeLoadingAction => ({
  type: AnalyticsTypes.SET_INCOME_LOADING,
  payload: load
});

export {
  getTopIncomeGroupsAction,
  getTopIncomeGroupsActionSuccess,
  getTopSongsAction,
  getTopSongsActionSuccess,
  getTopSourcesAction,
  getTopSourcesActionSuccess,
  getTopTerritoriesAction,
  getTopTerritoriesActionSuccess,
  setIncomeLoadingAction,
  setSongsLoadingAction,
  setSourcesLoadingAction,
  setTerritoriesLoadingAction
};
