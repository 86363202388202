import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./SaveProgressPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { UploadedFile } from "../../../../page/StatementUpload/types";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Condition from "../../../shared/Condition";
import Icon from "../../../shared/Icon";
import Button from "../../WCMButton";

type SaveProgressPopupProps = {
  saveProgress: () => Promise<any>;
  failedFileList: UploadedFile[];
  uploadedFiles: UploadedFile[];
};
const SaveProgressPopup: React.FC<SaveProgressPopupProps> = ({
  saveProgress,
  failedFileList,
  uploadedFiles
}) => {
  const modal = useSelector(modalSelector);

  const hasFailedFiles = failedFileList.length;

  const currentModalIsActive =
    modal.isOpen &&
    modal.type?.slice(-1)[0] === "SaveProgress" &&
    !hasFailedFiles;

  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { SetOpenModalAction } = useActions();

  const closeModdal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);

  useEffect(() => {
    if (!uploadedFiles.length && currentModalIsActive) {
      closeModdal();
    }
  }, [uploadedFiles.length, currentModalIsActive]);

  const saveProgressHandler = async () => {
    setIsSaving(true);
    try {
      await saveProgress();
      setIsSaved(true);
    } catch (err) {
      console.log("Error saving progress", err);
    } finally {
      setTimeout(() => {
        closeModdal();
      }, 1000);
      setIsSaving(false);
    }
  };

  if (!currentModalIsActive) return null;

  // const documentWord =
  //   failedDocumentList?.length === 1 ? "document" : "documents";

  return (
    <Modal
      ariaHideApp={false}
      isOpen={currentModalIsActive}
      onRequestClose={closeModdal}
      className={styles.modal}
      overlayClassName={styles.overlay}
      onAfterClose={() => setIsSaved(false)}
    >
      <div className={styles.contentWrapper}>
        <Condition
          condition={isSaved}
          Truthy={
            <div className={styles.title}>
              <span>
                <Icon kind="save" />
                Upload saved
              </span>
            </div>
          }
          Falsy={
            <>
              <div className={styles.title}>
                <span>Save upload progress?</span>
              </div>
              <div className={styles.buttonsContainer}>
                <Button variant="text" onClick={closeModdal}>
                  Back
                </Button>

                <Button onClick={saveProgressHandler} isLoading={isSaving}>
                  Save
                </Button>
              </div>
            </>
          }
        />
      </div>
    </Modal>
  );
};

export default SaveProgressPopup;
