import { all } from "redux-saga/effects";

import { watchAdvanceRequest } from "./advance/sagas";
import { watchAnalyticsRequest } from "./analytics/sagas";
import {
  watchAppStaticPayeeCode,
  watchAppStaticRequest
} from "./appStatic/sagas";
import {
  watchArchitectUsersRequest,
  watchMetaDataRequest,
  watchModifiersRequest,
  watchUploaderListRequest
} from "./architectUsersStore/sagas";
import {
  watchClosedPeriodsRequest,
  watchDealsRequest,
  watchLedgersRequest
} from "./dealsStore/sagas";

export default function* rootSaga(): Generator {
  yield all([
    watchUploaderListRequest(),
    watchAppStaticPayeeCode(),
    watchArchitectUsersRequest(),
    watchDealsRequest(),
    watchLedgersRequest(),
    watchClosedPeriodsRequest(),
    watchAppStaticRequest(),
    watchMetaDataRequest(),
    watchAnalyticsRequest(),
    watchModifiersRequest(),
    watchAdvanceRequest()
  ]);
}
