import React from "react";

import styles from "./GridPlaceholderLoader.module.scss";

export interface GridPlaceHolderProps {
  fullWidth?: boolean;
  minWidth?: number;
  height?: string;
  width?: string;
}

const GridPlaceholderLoader = ({
  fullWidth,
  minWidth,
  height,
  width
}: GridPlaceHolderProps) => {
  return (
    <div
      className={`${styles.animatedBackground} ${fullWidth &&
        styles.animatedBackground_fullWidth}`}
      style={{
        minWidth: minWidth ? minWidth : "65",
        height: height ? height : "25px",
        width: width ? width : "60%"
      }}
    />
  );
};
export default GridPlaceholderLoader;
