import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import cn from "classnames";

import styles from "./Textarea.module.scss";

type TextareaProps = UseControllerProps & {
  limit?: number;
};

const Textarea: React.FC<TextareaProps> = ({ limit = 160, ...rest }) => {
  const { field } = useController(rest);

  return (
    <div className={styles.textareaContainer}>
      <textarea
        maxLength={limit}
        {...field}
        placeholder="Enter announcement message"
        className={styles.alertMessage}
      />
      <span
        className={cn(styles.limit, {
          [styles.errorLimit]: field.value.length >= limit
        })}
      >
        {field.value.length || 0}/{limit}
      </span>
    </div>
  );
};

export default Textarea;
