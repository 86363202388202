export enum ArchitectUsersTypes {
  ARCHITECT_USERS_REQUEST = "ARCHITECT_USERS_REQUEST",
  ARCHITECT_USERS_SUCCESS = "ARCHITECT_USERS_SUCCESS",
  ARCHITECT_USERS_LOADING = "ARCHITECT_USERS_LOADING",

  METADATA_LOADING = "METADATA_LOADING",
  METADATA_REQUEST = "METADATA_REQUEST",
  METADATA_SUCCESS = "METADATA_SUCCESS",

  UPLOADER_REQUEST = "UPLOADER_REQUEST",
  UPLOADER_SUCCESS = "UPLOADER_SUCCESS",
  UPLOADER_LOADING = "UPLOADER_LOADING",

  MODIFIERS_REQUEST = "MODIFIERS_REQUEST",
  MODIFIERS_SUCCESS = "MODIFIERS_SUCCESS",
  MODIFIERS_LOADING = "MODIFIERS_LOADING",

  OPEN_MODAL_ADD_USER = "OPEN_MODAL_ADD_USER",
  SET_BODY_ACTION = "SET_BODY_ACTION",

  SET_HISTORY_FILTERS_ACTION = "SET_HISTORY_FILTERS_ACTION",
  SET_HISTORY_GENERATED_FILTERS_ACTION = "SET_HISTORY_GENERATED_FILTERS_ACTION",
  SET_HISTORY_MODAL_FILTERS_ACTION = "SET_HISTORY_MODAL_FILTERS_ACTION",
  SET_HISTORY_DATE_RANGE_ACTION = "SET_HISTORY_DATE_RANGE_ACTION",
  SET_HISTORY_MODAL_DATE_RANGE_ACTION = "SET_HISTORY_MODAL_DATE_RANGE_ACTION",

  SET_DOWNLOAD_FILTERS_ACTION = "SET_DOWNLOAD_FILTERS_ACTION",
  SET_DOWNLOAD_MODAL_FILTERS_ACTION = "SET_DOWNLOAD_MODAL_FILTERS_ACTION",
  SET_DOWNLOAD_DATE_RANGE_ACTION = "SET_DOWNLOAD_DATE_RANGE_ACTION",
  SET_DOWNLOAD_MODAL_DATE_RANGE_ACTION = "SET_DOWNLOAD_MODAL_DATE_RANGE_ACTION",

  SET_SAVED_UPLOADS_FILTERS_ACTION = "SET_SAVED_UPLOADS_FILTERS_ACTION",
  SET_SAVED_UPLOADS_GENERATED_FILTERS_ACTION = "SET_SAVED_UPLOADS_GENERATED_FILTERS_ACTION",
  SET_SAVED_UPLOADS_MODAL_FILTERS_ACTION = "SET_SAVED_UPLOADS_MODAL_FILTERS_ACTION",
  SET_SAVED_UPLOADS_DATE_RANGE_ACTION = "SET_SAVED_UPLOADS_DATE_RANGE_ACTION",
  SET_SAVED_UPLOADS_MODAL_DATE_RANGE_ACTION = "SET_SAVED_UPLOADS_MODAL_DATE_RANGE_ACTION",
  SET_UPLOAD_TABLE_TYPE = "SET_UPLOAD_TABLE_TYPE",

  SET_HISTORY_FILTERS_BODY_ACTION = "SET_HISTORY_FILTERS_BODY_ACTION",

  SET_FILTERS_ACTION = "SET_FILTERS_ACTION",
  SET_MODAL_FILTERS_ACTION = "SET_MODAL_FILTERS_ACTION",
  SET_GENERATED_FILTERS_ACTION = "SET_GENERATED_FILTERS_ACTION",
  SET_DATE_RANGE_ACTION = "SET_DATE_RANGE_ACTION",
  SET_SEARCH_VALUE = "SET_SEARCH_VALUE",

  SET_UMS_SEARCH_TYPE = "SET_UMS_SEARCH_TYPE"
}
