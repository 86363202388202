import i18n from "i18next";
import { DateTime } from "luxon";

import { SelectOption } from "../../types/select";

const getMonthFromDate = (date: string) => {
  return i18n.t(
    `months.${DateTime.fromISO(date)
      .toFormat("LLLL")
      .toLowerCase()}`
  );
};

export const updateSelectedOption = (options: SelectOption[]) => (
  currentOption: SelectOption
) => options.find(option => option.value === currentOption.value) || options[0];

export const getPeriodOrPublishedDate = (
  startDate: string | null,
  endDate: string | null,
  publishedAt: string
): string => {
  if (!startDate || !endDate) {
    const month = getMonthFromDate(publishedAt);
    const day = DateTime.fromISO(publishedAt).toFormat("dd");
    return `${month} ${day}`;
  }

  return `${getMonthFromDate(startDate)} - ${getMonthFromDate(endDate)}`;
};
