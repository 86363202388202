import { useCallback, useRef } from "react";
import axios from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { DocumentType, PaginationParams } from "../types";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  return config;
});

type SortingParams = { id: string; desc: boolean }[];
type ParsedSortingParams = { field: string; order: "desc" | "asc" }[];

const DEFAULT_PAGE_SIZE = 10;

const useHistory = () => {
  const storedSortParams = useRef<ParsedSortingParams>([]);
  const storedPageSize = useRef(DEFAULT_PAGE_SIZE);

  const parseSortingParams = (
    sortingParams?: SortingParams
  ): ParsedSortingParams | null => {
    if (!sortingParams?.length) return null;
    const sortData = sortingParams?.map(sortBy => {
      const { id, desc } = sortBy;
      return {
        field: id,
        order: desc ? "desc" : "asc"
      } as const;
    });
    return sortData;
  };

  const getHistory = useCallback(
    async (
      and: any,
      options: {
        documentType: DocumentType | null;
        paginationParams?: PaginationParams;
        sortingParams?: SortingParams;
      }
    ) => {
      const { documentType, paginationParams, sortingParams } = options;
      const parsedSortingParams = parseSortingParams(sortingParams);

      if (paginationParams?.take)
        storedPageSize.current = paginationParams.take;
      if (parsedSortingParams) {
        storedSortParams.current = parsedSortingParams;
      }
      const sortParams = parsedSortingParams
        ? [...parsedSortingParams]
        : [...storedSortParams.current];

      const { skip = 0, take = storedPageSize.current } =
        paginationParams || {};
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/fetch?docStatus=PUBLISHED`,
        {
          skip,
          take,
          sort: sortParams.length
            ? sortParams
            : [
                {
                  field: "publishedAt",
                  order: "desc"
                }
              ],
          filter: {
            and: [
              ...and,
              {
                operation: "eq",
                name: "fileStatus",
                value: "PUBLISHED"
              }
            ]
          }
        }
      );
      return res?.data;
    },
    [storedSortParams]
  );
  return {
    getHistory: getHistory,
    pageSize: storedPageSize.current
  };
};

export default useHistory;
