import React from "react";
import MuiPopover, {PopoverProps} from "@material-ui/core/Popover";

import styles from "./Popover.module.scss";
// eslint-disable-next-line prettier/prettier
export type {PopoverProps};

const Popover = (props: PopoverProps): JSX.Element => {
  const anchorElWidth =
      props.anchorEl && typeof props.anchorEl === 'object' ? props.anchorEl.clientWidth + 2 : undefined;
  return (
      <MuiPopover
          PaperProps={{
            classes: {root: styles.paperRoot},
            square: true,
            style: {minWidth: anchorElWidth ? anchorElWidth : undefined},
          }}
          {...props}
      />
  );
};

export default Popover;
