import { AccountData, Deals, MenuItem, MenuItems } from "./types";

export const checkMenuItemConditions = (
  menuItem: MenuItem,
  account: AccountData,
  deals?: Deals
) => {
  const { conditions } = menuItem;

  if (!conditions) {
    return true;
  }

  const {
    shouldBeAdmin,
    shouldNotBeAdmin,
    pathShouldInclude,
    pathShouldNotInclude,
    emailTypeShouldInclude,
    emailTypeShouldNotInclude,
    inCountry,
    notInCountry,
    shouldHaveSingleDeal
  } = conditions;

  const { path, emailTypes, country, isAdmin } = account;

  return (
    (!shouldBeAdmin || isAdmin) &&
    (!shouldNotBeAdmin || !isAdmin) &&
    (!inCountry || inCountry.includes(country)) &&
    (!notInCountry || !notInCountry.includes(country)) &&
    (!pathShouldInclude || pathShouldInclude.includes(path)) &&
    (!pathShouldNotInclude || !pathShouldNotInclude.includes(path)) &&
    (!emailTypeShouldInclude ||
      emailTypes?.some(type => emailTypeShouldInclude.includes(type))) &&
    (!emailTypeShouldNotInclude ||
      !emailTypes?.some(type => emailTypeShouldNotInclude.includes(type))) &&
    (!shouldHaveSingleDeal || deals?.deals?.length === 1)
  );
};

export const getMenuItemData = (
  menuItem: MenuItem,
  account: AccountData,
  deals?: Deals
): MenuItem | null => {
  const conditionsMet = checkMenuItemConditions(menuItem, account, deals);

  if (!conditionsMet) return null;

  const menuItemData = { ...menuItem };

  if (menuItem.storedSlugKey) {
    menuItemData.slug =
      localStorage.getItem(menuItem.storedSlugKey) || menuItem.slug;
  }
  return menuItemData;
};

export const validateMenuItems: (
  menuItems: MenuItems,
  accountData: AccountData,
  deals?: Deals
) => MenuItems = (menuItems, accountData, deals) => {
  return menuItems.map(Item => {
    if (Item === null) return null;
    if ("submenu" in Item) {
      const { title, list } = Item.submenu;
      return {
        submenu: {
          title,
          list: validateMenuItems(list, accountData, deals)
        }
      };
    }
    return getMenuItemData(Item, accountData, deals);
  });
};
