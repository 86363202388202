import { DateTime } from "luxon";

import { SelectOption } from "../types/select";

export const getTimeDifferece = (startDate?: Date, endDate?: Date) => {
  if (!startDate || !endDate) return "";

  const luxonStartDate = DateTime.fromJSDate(startDate);
  const luxonEndDate = DateTime.fromJSDate(endDate);

  const diff = luxonStartDate
    .diff(luxonEndDate)
    .shiftTo("days", "hours", "minutes");

  const days = Math.abs(diff.days);
  const hours = Math.abs(diff.hours);
  const minutes = Math.abs(diff.minutes);

  const dayString = days === 0 ? "" : days > 1 ? `${days} days` : `${days} day`;
  const hourString =
    hours < 1 ? "" : hours > 1 ? `${hours} hours` : `${hours} hour`;
  const minuteString = minutes >= 1 ? `${Math.floor(minutes)} minutes` : "";

  return `${dayString} ${hourString} ${minuteString}`;
};

const timeZones: SelectOption[] = (Intl as any)
  .supportedValuesOf("timeZone")
  .map((timeZone: string) => {
    const timeZoneSplit = timeZone.split("/");
    const label = timeZoneSplit[timeZoneSplit.length - 1];
    return {
      label: `${label.replaceAll("_", " ")} ${
        DateTime.local().setZone(timeZone).offsetNameShort
      }`,
      value: timeZone
    };
  })
  .sort((a: any, b: any) => {
    const aOffset = DateTime.local().setZone(a.value).offset || "";
    const bOffset = DateTime.local().setZone(b.value).offset || "";
    return +aOffset - +bOffset;
  });

export const getTimeZones = () => timeZones;

export const roundMintuesTo = (minutes: number, date: DateTime) => {
  const remainder = minutes - (date.minute % minutes);
  return date.plus({ minutes: remainder - 1 });
};
// Converts local time to specifed timeZone time
export const convertUTCToZone = (timeZone: string, date = new Date()) => {
  const t =
    DateTime.fromJSDate(date)
      .setZone(timeZone, { keepLocalTime: false })
      .toISO({
        extendedZone: false,
        includeOffset: false
      }) || date.toUTCString();
  return DateTime.fromISO(t);
};
// Keep date convert time zone
export const changeTimeZone = (
  timeZone: string,
  date = new Date()
): DateTime => {
  return DateTime.fromJSDate(date)
    .setZone(timeZone, {
      keepLocalTime: true
    })
    .setLocale("en-US");
};

export const IANAtoString = (IANA: string) =>
  DateTime.local().setZone(IANA).offsetNameLong;

export const getCurrentTimeZoneOption = () =>
  getTimeZones().find(
    (timeZone: SelectOption) => timeZone.value === DateTime.now().zoneName
  ) || (getTimeZones()[0] as SelectOption);
