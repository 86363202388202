import { twCascade } from "@mariusmarais/tailwind-cascade";

// jc - join & cascade classes helper
export const jc = (...classes: (string | undefined | false)[]): string => {
  // return classes
  //   .reduce((r, className) => {
  //     if (className) {
  //       r.push(className);
  //     }
  //     return r;
  //   }, [] as string[])
  //   .join(' ');

  // twCascade merges and dedup Tailwind classes
  // to workaround cases with e.g. when "text-green-500 text-yellow-500"
  // is not being overwritten with the last class styles
  return twCascade(
    classes.reduce((r, className) => {
      if (className) {
        r.push(className);
      }
      return r;
    }, [] as string[])
  );
};
