import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import styles from "./TerritoriesCard.module.scss";

import Card from "../../../../components/shared/Card";
import CurrencyRateShort from "../../../../components/shared/CurrencyRateShort";
import { textParams } from "../../../../utils";

const TerritoriesCard = ({ data }: any) => {
  const { t } = useTranslation("translation");
  return (
    <>
      <p className={styles.totalCount}>
        {textParams(t(`analytics.top20Territories`))[0] +
          ` ${data?.length} ` +
          textParams(t(`analytics.top20Territories`))[2]}
      </p>
      {data.map((el: any) => (
        <Card key={el.creators}>
          <Card.Content>
            <Card.Items title={false} label={el?.title} />
            <Card.Items
              title={
                <CurrencyRateShort rate={el?.previousAmount} id={uuidv4()} />
              }
              label={`${t("analytics.lastPeriod")}:`}
            />
            <Card.Items
              title={<CurrencyRateShort rate={el?.amount} id={uuidv4()} />}
              label={`${t("analytics.currentPeriod")}:`}
            />
          </Card.Content>
        </Card>
      ))}
    </>
  );
};

export default TerritoriesCard;
