export const country = [
  { code: "USA", name: "United States" },
  { code: "GBR", name: "United Kingdom" },
  { code: "ARG", name: "Argentina" },
  { code: "AUT", name: "Austria" },
  { code: "AUS", name: "Australia" },
  { code: "BEL", name: "Belgium" },
  { code: "BRA", name: "Brazil" },
  { code: "CAN", name: "Canada" },
  { code: "CHL", name: "Chile" },
  { code: "CHN", name: "China" },
  { code: "COL", name: "Colombia" },
  { code: "DNK", name: "Denmark" },
  { code: "FIN", name: "Finland" },
  { code: "FRA", name: "France" },
  { code: "DEU", name: "Germany" },
  { code: "HKG", name: "Hong Kong" },
  { code: "ITA", name: "Italy" },
  { code: "PRK", name: "Korea" },
  { code: "MYS", name: "Malaysia" },
  { code: "MEX", name: "Mexico" },
  { code: "NLD", name: "Netherlands" },
  { code: "NOR", name: "Norway" },
  { code: "PHL", name: "Philippines" },
  { code: "RUS", name: "Russia" },
  { code: "SGP", name: "Singapore" },
  { code: "ESP", name: "Spain" },
  { code: "SWE", name: "Sweden" },
  { code: "CHE", name: "Switzerland" },
  { code: "TWN", name: "Taiwan" },
  { code: "THA", name: "Thailand" },
  { code: "VNM", name: "Vietnam" }
];

export const preferableLanguage = [
  { code: "EN", name: "English" },
  { code: "MS", name: "Malay" },
  { code: "SC", name: "Simplified Chinese" },
  { code: "ZH", name: "Traditional Chinese" },
  { code: "DA", name: "Danish" },
  { code: "NL", name: "Dutch" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "French" },
  { code: "DE", name: "Deutsch" },
  { code: "IT", name: "Italian" },
  { code: "KO", name: "Korean" },
  { code: "NO", name: "Norwegian" },
  { code: "PT", name: "Portuguese" },
  { code: "RU", name: "Russian" },
  { code: "ES", name: "Spanish" },
  { code: "LAS", name: "Latin American Spanish" },
  { code: "SV", name: "Swedish" }
];

export const languages = [
  { code: "EN", name: "English" },
  { code: "MS", name: "Bahasa Malaysia" },
  { code: "SC", name: "简体中文" },
  { code: "ZH", name: "繁體中文" },
  { code: "DA", name: "Dansk" },
  { code: "NL", name: "Nederlands" },
  { code: "FI", name: "Suomi" },
  { code: "FR", name: "Français" },
  { code: "DE", name: "Deutsch" },
  { code: "IT", name: "Italiano" },
  { code: "KO", name: "한국어" },
  { code: "NO", name: "Norsk" },
  { code: "PT", name: "Português" },
  { code: "RU", name: "Русский" },
  { code: "ES", name: "Español" },
  { code: "LAS", name: "Latin American Spanish" },
  { code: "SV", name: "Svenska" }
];

export const contactUsQuestions: any = [
  {
    label: "contactUsPage.topics.troubleLoggingIn",
    postLogin: false,
    preLogin: true,
    value: 1
  },
  {
    label: "contactUsPage.topics.registerAccount",
    preLogin: true,
    postLogin: false,
    value: 2
  },
  {
    label: "contactUsPage.topics.payments",
    preLogin: true,
    postLogin: true,
    value: 3
  },
  {
    label: "contactUsPage.topics.updateAccount",
    preLogin: true,
    postLogin: true,
    value: 4
  },
  {
    label: "contactUsPage.topics.royaltyStatements",
    preLogin: true,
    postLogin: true,
    value: 5
  },
  {
    label: "contactUsPage.topics.other",
    preLogin: true,
    postLogin: true,
    value: 6
  },
  {
    label: "menu.deleteAccount",
    preLogin: true,
    postLogin: true,
    value: 7
  }
];
