import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import { DateRange, DefinedRange } from "materialui-daterange-picker/src/types";

type Props = {
  open: boolean;
  toggle: () => void;
  onChange: (range: any) => void;
  maxDate?: string | Date;
  defineRanges?: DefinedRange[];
  initialDateRange: DateRange;
};

const RangeDatePicker: React.FunctionComponent<Props> = props => {
  return (
    <DateRangePicker
      wrapperClassName="datePicker-modal"
      open={props.open}
      toggle={props.toggle}
      onChange={(range: any) => props.onChange(range)}
      initialDateRange={props.initialDateRange}
      definedRanges={props.defineRanges || []}
    />
  );
};
export default RangeDatePicker;
