import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./SortDropdown.module.scss";
import { ReactComponent as LinkIcon } from "../../../icons/down_icon_dropdown.svg";
export interface SortDropdownProps {
  sortDate?: string | undefined;
  loading?: boolean;
  defaultValue: number;
  sortAZ?: string | undefined;
  onChangeCallback: (data: { id: string; desc: boolean }[]) => void;
}
export interface ItemProps {
  label: string;
  value: number;
  desc: boolean;
  sort: string;
}

const options = (t: any, sortAZ: any, sortDate: any) => {
  const op = [
    { label: t(`common.aToZ`), value: 1, desc: false, sort: sortAZ },
    { label: t(`common.zToA`), value: 2, desc: true, sort: sortAZ }
  ];
  if (sortDate) {
    return [
      ...op,
      { label: t(`common.newest`), value: 3, desc: false, sort: sortDate },
      { label: t(`common.oldest`), value: 4, desc: true, sort: sortDate }
    ];
  }
  return op;
};

const SortDropdown = ({
  loading = false,
  sortDate,
  defaultValue,
  onChangeCallback,
  sortAZ
}: SortDropdownProps) => {
  const { t } = useTranslation("translation");
  const innerOptions = useMemo(() => {
    if (sortDate) {
      return options(t, sortAZ, sortDate);
    }
    return options(t, sortAZ, sortDate);
  }, [sortDate, sortAZ, t]);

  const value = useMemo(() => {
    return innerOptions.find(el => el.value === defaultValue);
  }, [innerOptions, t, defaultValue]);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const handleChange = (el: ItemProps) => {
    onChangeCallback([{ id: el.sort, desc: el.desc }]);
    setMenuIsOpen(false);
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuIsOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  return (
    <div>
      <div
        className={cn(styles.select, { [styles.loading]: loading })}
        onClick={() => {
          if (!loading) {
            setMenuIsOpen(!menuIsOpen);
          }
        }}
      >
        <span className={cn(styles.select_title)}>{t(`common.sortBy`)} </span>
        <span className={cn(styles.select_label)}>{value && value.label}</span>
        <LinkIcon
          className={cn(styles.arrow, menuIsOpen ? styles.arrow_open : "")}
        />
      </div>
      <div ref={wrapperRef}>
        {menuIsOpen ? (
          <div className={cn(styles.container)}>
            <div className={cn(styles.menu)}>
              {innerOptions.map((el, index) => {
                return (
                  <div
                    className={cn(
                      styles.item,
                      value && value.value === el.value
                        ? styles.item_active
                        : ""
                    )}
                    key={index}
                    onClick={() => {
                      handleChange(el);
                    }}
                  >
                    <span>{el.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default SortDropdown;
