import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "../Menu.module.scss";

import Icon from "../../../../shared/Icon";
import { Submenu } from "../types";
import { MenuItem } from "./index";

type MenuGroupProps = {
  data: Submenu;
  setOpenMenu: (newValue: boolean) => void;
};
const MenuGroup: React.FC<MenuGroupProps> = ({ data, setOpenMenu }) => {
  const history = useHistory();
  const { t } = useTranslation("translation");
  const [height, setHeight] = useState<any>(
    history.location.pathname.includes("/accounts") ? "auto" : 0
  );
  return (
    <div className={cn(styles.group)}>
      <div
        className={cn(styles.group_title)}
        aria-expanded={height !== 0}
        aria-controls="example-panel"
        onClick={() => setHeight(height === 0 ? "auto" : 0)}
      >
        <span>{t(data.title)}</span>
        <Icon
          kind="arrow_black"
          className={cn(
            styles.group_arrow,
            height ? styles.group_arrow_rotate : ""
          )}
        />
      </div>
      <AnimateHeight
        id="example-panel"
        duration={500}
        height={height} // see props documentation below
      >
        {data.list.map(
          (menuItem: any, index: number) =>
            menuItem && (
              <MenuItem key={index} item={menuItem} setOpenMenu={setOpenMenu} />
            )
        )}
      </AnimateHeight>
    </div>
  );
};

export default MenuGroup;
