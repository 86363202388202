import i18next from "i18next";

import { SelectOption } from "../../types/select";

export const documentTypes = {
  royaltyStatement: "ROYALTY_STATEMENT",
  remittanceAdvice: "REMITTANCE_ADVICE",
  selfBillingInvoice: "SELF_BILLING_INVOICE",
  statement: "STATEMENT",
  tax: "TAX",
  all: "ALL"
};

export const getYearOptions = (
  t: typeof i18next.t,
  availableYears: number[]
): SelectOption[] => {
  const initial = [
    {
      value: documentTypes.all,
      label: t("statementsPage.documents.filters.allYears")
    }
  ];
  if (!availableYears.length) return initial;

  return [
    ...initial,
    ...availableYears.map(year => {
      return {
        value: String(year),
        label: String(year)
      };
    })
  ];
};

export const getTypeOptions = (
  t: typeof i18next.t,
  availableTypes: string[]
): SelectOption[] => {
  const options = [
    {
      value: documentTypes.royaltyStatement,
      label: t(
        `statementsPage.documents.documentType.${documentTypes.royaltyStatement}`
      )
    },
    {
      value: documentTypes.remittanceAdvice,
      label: t(
        `statementsPage.documents.documentType.${documentTypes.remittanceAdvice}`
      )
    },
    {
      value: documentTypes.selfBillingInvoice,
      label: t(
        `statementsPage.documents.documentType.${documentTypes.selfBillingInvoice}`
      )
    },
    {
      value: documentTypes.statement,
      label: t(
        `statementsPage.documents.documentType.${documentTypes.statement}`
      )
    },
    {
      value: documentTypes.tax,
      label: t(`statementsPage.documents.documentType.${documentTypes.tax}`)
    }
  ];
  const filteredOptions = options.filter(option =>
    availableTypes.includes(option.value)
  );
  return [
    {
      value: documentTypes.all,
      label: t("statementsPage.documents.filters.allDocuments")
    },
    ...filteredOptions
  ];
};

export const downloadRadioOptions = {
  pdf: "pdf",
  csv: "csv",
  all: "pdf,csv"
};

export const ROYALTY_TYPE = "ROYALTY_STATEMENT";

export const filterQueries = {
  year: "selectedYear",
  type: "selectedType"
};
