import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import i18n from "i18next";

import styles from "../Faq.mobile.module.scss";

import { languageSelector } from "../../../../store/appStatic/selectors";
import { getUrlParams, textParams } from "../../../../utils";
declare const window: any;

const PostLoginMobileFaq = () => {
  const { t } = useTranslation("translation");
  const languages = useSelector(languageSelector);
  const location = useLocation();
  const getUrlCode = getUrlParams(location.search).lang?.toUpperCase();
  const languageCode =
    languages.find((e: { value: string }) => e.value === getUrlCode) ||
    languages[0];
  useEffect(() => {
    if (languageCode?.value) {
      i18n.changeLanguage(languageCode?.value || "EN");
    }
  }, [languageCode]);
  return (
    <div className={styles.faq}>
      <div className={styles.headTxt}>
        {t(`faqPage.postLogin.whenReceivePaymentsOrStatementQuestion`)}
      </div>
      <div className={styles.txt}>
        {t(`faqPage.postLogin.whenReceivePaymentsOrStatementAnswer`)}
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.postLogin.howUpdateLanguageQuestion`)}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.postLogin.howUpdateLanguageAnswer`))[0]}{" "}
          <span
            onClick={() =>
              window.ReactNativeWebView.postMessage("ContactUsFromMenu")
            }
            className={styles.link}
          >
            {textParams(t(`faqPage.postLogin.howUpdateLanguageAnswer`))[1]}
          </span>{" "}
          {textParams(t(`faqPage.postLogin.howUpdateLanguageAnswer`))[2]}
        </div>
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.postLogin.howUpdateAddressQuestion`)}{" "}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.postLogin.howUpdateAddressAnswer`))[0]}{" "}
          <span
            onClick={() =>
              window.ReactNativeWebView.postMessage("ContactUsFromMenu")
            }
            className={styles.link}
          >
            {textParams(t(`faqPage.postLogin.howUpdateAddressAnswer`))[1]}
          </span>{" "}
          {textParams(t(`faqPage.postLogin.howUpdateAddressAnswer`))[2]}
        </div>
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.postLogin.whereDownloadTaxQuestion`)}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.postLogin.whereDownloadTaxAnswer`))[0]}{" "}
          <span
            onClick={() =>
              window.ReactNativeWebView.postMessage("ContactUsFromMenu")
            }
            className={styles.link}
          >
            {textParams(t(`faqPage.postLogin.whereDownloadTaxAnswer`))[1]}
          </span>{" "}
          {textParams(t(`faqPage.postLogin.whereDownloadTaxAnswer`))[2]}
        </div>
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.preLogin.otherQueriesQuestion`)}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.otherQueriesAnswer`))[0]}{" "}
          <span
            onClick={() =>
              window.ReactNativeWebView.postMessage("ContactUsFromMenu")
            }
            className={styles.link}
          >
            {textParams(t(`faqPage.otherQueriesAnswer`))[1]}
          </span>{" "}
          {textParams(t(`faqPage.otherQueriesAnswer`))[2]}
        </div>
      </div>
    </div>
  );
};

export default PostLoginMobileFaq;
