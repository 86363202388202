import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./Footer.module.scss";
import { ReactComponent as Logo } from "../../../icons/logo.svg";
import { ReactComponent as ScrollTop } from "../../../icons/scroll_top.svg";

import { cookiePolicyLanguages } from "../../../configs/constants/transaction";
import {
  languageSelector,
  loadingSelector,
  profileSelector
} from "../../../store/appStatic/selectors";
import { replaceText } from "../../../utils";
import UiDropdown from "../../shared/UiDropdown";

const footerLinks = (t: (s: string) => string) => [
  { title: t("menu.aboutWcm"), to: "https://warnerchappell.com/" },
  { title: t("menu.faq"), to: "/faq" },
  { title: t("menu.contactUs"), to: "/contact-us" },
  { title: t("menu.privacyPolicy"), to: "/privacy-policy" },
  { title: t("menu.termOfUse"), to: "/terms-of-use" },
  {
    title: t("menu.cookiesPolicy"),
    to: "https://www.wminewmedia.com/cookies-policy/"
  }
];
export interface FooterProp {
  hide?: boolean;
}
const Footer = ({ hide }: FooterProp) => {
  const { t, i18n } = useTranslation("translation");
  const languages = useSelector(languageSelector);
  const profile = useSelector(profileSelector);
  const loading = useSelector(loadingSelector);
  const [dropdownValue, setDropdownValue] = useState(
    languages.find((el: { value: string }) => el.value === i18n.language) ||
      languages[0]
  );
  const userTermAccepted = profile?.settings?.isTermAccepted;

  useEffect(() => {
    setDropdownValue(
      languages.find((el: { value: string }) => el.value === i18n.language) ||
        languages[0]
    );
  }, [i18n.language]);
  const [top, setTop] = useState(0);
  const defaultLanguage = localStorage.getItem("i18nextLng");
  const onScroll = useCallback(() => {
    if (top === 0 || window.pageYOffset === 0) {
      setTop(window.pageYOffset);
    }
  }, [top]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);
  const checkCookiePolicyLanguage = (lng: string) => {
    return cookiePolicyLanguages[lng];
  };
  return (
    <div className={cn(styles.footer, hide ? styles.footer_hide : "")}>
      <div className={cn(styles.footerWrap, "container")}>
        {!userTermAccepted && !loading && (
          <div className={styles.language}>
            <span>{t(`language.language`)}:</span>
            <UiDropdown
              options={languages}
              className={styles.select}
              isDropTop={false}
              value={dropdownValue}
              onChange={(value: any) => {
                i18n.changeLanguage(value?.value);
                setDropdownValue(value);
              }}
            />
          </div>
        )}
        <div className={styles.logoWrap}>
          <Logo className={styles.logo} />
          <span className={styles.logoTitle}>Client portal</span>
        </div>
        <div className={styles.info}>
          <p>{t("footer.wcm")}</p>
          <p>{replaceText(t(`footer.copyright`), " 2022 - 2024")}</p>
        </div>
        <div className={styles.links}>
          {footerLinks(t).map(el =>
            el.to.includes("https://www.wminewmedia.com/cookies-policy/") ? (
              <a
                key={el.title}
                href={`https://www.wminewmedia.com/cookies-policy/${checkCookiePolicyLanguage(
                  defaultLanguage || "EN"
                )}/`}
                target="_blank"
                rel="noreferrer"
              >
                {el.title}
              </a>
            ) : el.to.includes("https://warnerchappell.com/") ? (
              <a
                key={el.title}
                href={`https://warnerchappell.com/`}
                target="_blank"
                rel="noreferrer"
              >
                {el.title}
              </a>
            ) : (
              <NavLink key={el.title} to={el.to}>
                {el.title}
              </NavLink>
            )
          )}
          <a className="ot-sdk-show-settings" style={{ cursor: "pointer" }}>
            {/*<span>{t("menu.doNotSellMyPersonalInfo")}</span>*/}
            <span>Do Not Sell or Share My Personal Information</span>
          </a>
        </div>
      </div>
      <div className={styles.scrollWrap}>
        <ScrollTop
          className={cn(styles.scrollTop, {
            [styles.disableScroll]: top === 0
          })}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </div>
    </div>
  );
};

export default Footer;
