import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

import styles from "./TopSongs.module.scss";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevrons_down.svg";
import { ReactComponent as ChevronUpIcon } from "../../../icons/chevrons_up.svg";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import CurrencyRateShort from "../../../components/shared/CurrencyRateShort";
import Loader from "../../../components/shared/Loading";
import NoDataAnalytics from "../../../components/shared/NoDataAnalytics";
import Table from "../../../components/shared/Table/Table";
import TopSongsChart from "../../../components/shared/TopSongsChart";
import { getSortedData } from "../../../configs/utils";
import {
  songsLoadingSelector,
  songsTopSelector
} from "../../../store/analytics/selectors";
import {
  detailCellRenderer,
  rowType,
  topWorksType
} from "../../../store/analytics/types";
import { textParams } from "../../../utils";
import TotalEarnings from "../TotalEarnings";
import SongsCard from "./SongsCard";

const TopSongs = () => {
  const { t } = useTranslation("translation");
  const m = useMediaQuery({ maxWidth: 567 });
  const songsTop = useSelector(songsTopSelector);
  const songsLoading = useSelector(songsLoadingSelector);
  const [currentList, setCurrentList] = useState<topWorksType>(
    songsTop?.topWorks || []
  );
  const [sortBy, setSortBy] = useState({
    id: "amount",
    desc: true
  });

  const defaultSortBy = useMemo(() => {
    return [{ id: sortBy.id, desc: sortBy.desc }];
  }, [sortBy.id, sortBy.desc, currentList]);

  const sortedData = useMemo<topWorksType>(() => {
    return getSortedData(currentList, {
      sort: sortBy.id,
      order: sortBy.desc
    });
  }, [currentList, sortBy.id, sortBy.desc]);

  const handleSort = useCallback(
    ({ sortBy: sortByInner }) => {
      if (sortByInner[0]?.id) {
        setSortBy(sortByInner[0]);
      }
    },
    [sortBy.id, sortBy.desc]
  );

  useEffect(() => {
    const topWorks = songsTop?.topWorks.map((el: any) => {
      return {
        ...el,
        isOpen: true
      };
    });
    if (songsTop?.topWorks) {
      setCurrentList(topWorks);
    }
  }, [songsTop?.topWorks]);

  const Collapse = useCallback(
    ({
      row
    }: {
      row: { original: { workTitle: string; isOpen: boolean } };
    }) => {
      return (
        <button
          className={cn(styles.chevron)}
          onClick={() => {
            setCurrentList(
              sortedData.map(el => {
                if (el.workTitle === row.original.workTitle) {
                  return { ...el, isOpen: !row.original.isOpen };
                } else {
                  return el;
                }
              })
            );
          }}
        >
          {!row.original.isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </button>
      );
    },
    [currentList]
  );

  const mb = useMediaQuery({ maxWidth: 767 });

  const columns = useMemo(
    () => [
      {
        accessor: "index",
        withoutSort: true,
        Header: "#",
        maxWidth: 10,
        Cell: ({ row }: rowType) => {
          return (
            <div>
              <p
                className={styles.previousAmount}
              >{`${row.original.index}.`}</p>
            </div>
          );
        }
      },
      {
        accessor: "workTitle",
        Header:
          textParams(t(`analytics.top20Songs`))[0] +
          ` ${sortedData?.length} ` +
          textParams(t(`analytics.top20Songs`))[2],
        Cell: ({ row }: rowType) => {
          return (
            <div className={styles.creatorsWrap}>
              <p className={styles.workTitle}>{row.original.workTitle}</p>
              <p className={styles.creators}>{row.original.creators}</p>
            </div>
          );
        }
      },
      ...(mb
        ? []
        : [
            {
              accessor: "previousAmount",
              Header: t(`analytics.lastPeriod`),
              maxWidth: 80,
              headerClass: "customHeader",
              className: styles.end,
              Cell: ({ row }: rowType) => {
                return (
                  <div>
                    <p className={styles.previousAmount}>
                      <CurrencyRateShort
                        id={`${uuidv4()}`}
                        rate={row.original.previousAmount}
                      />
                    </p>
                  </div>
                );
              }
            }
          ]),
      {
        accessor: "amount",
        Header: t(`analytics.currentPeriod`),
        maxWidth: mb ? 126 : 80,
        headerClass: "customHeader",
        className: styles.end,
        Cell: ({ row }: rowType) => {
          return (
            <div className={styles.tableValue}>
              <p
                className={cn(styles.amount, {
                  [styles.noPercentage]: !row.original.percentage
                })}
              >
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={row.original.amount}
                />
              </p>
              {!!row.original.percentage && (
                <p>
                  {row.original.direction === "INCREMENT" ? (
                    <p className={styles.increment}>
                      {`${row.original.percentage || 0}%`}
                      <UpIcon />
                    </p>
                  ) : (
                    <p className={styles.decrement}>
                      {`(%${row.original.percentage || 0})`}
                      <DownIcon />
                    </p>
                  )}
                </p>
              )}
            </div>
          );
        }
      },
      {
        Header: "",
        id: "collapse",
        minWidth: 40,
        Cell: Collapse,
        withoutSort: true,
        maxWidth: 15
      }
    ],
    [sortedData, sortBy.id, sortBy.desc, mb, t]
  );

  if (songsLoading) {
    return <Loader />;
  }
  if (!songsTop && !songsLoading) {
    return <NoDataAnalytics className={styles.empty} />;
  }

  const DetailCellRenderer = (row: detailCellRenderer) => {
    const data = row.original;
    return (
      <div className={cn(styles.table_detail)}>
        <div className={styles.wrap}>
          <p>{t(`analytics.topCategory`)}</p>
          <p className={styles.name}>{data.topIncomeType.name}</p>
          <p>
            <CurrencyRateShort
              id={`${uuidv4()}`}
              rate={data.topIncomeType.amount}
            />
          </p>
        </div>
        <div className={cn(styles.wrap, styles.wrap_big)}>
          <p>{t(`analytics.topSource`)}</p>
          <p className={styles.name}>{data.topSource.name}</p>
          <p>
            <CurrencyRateShort
              id={`${uuidv4()}`}
              rate={data.topSource.amount}
            />
          </p>
        </div>
        <div className={styles.wrap}>
          <p>{t(`analytics.topTerritory`)}</p>
          <p className={styles.name}>{data.topTerritory.name}</p>
          <p>
            <CurrencyRateShort
              id={`${uuidv4()}`}
              rate={data.topTerritory.amount}
            />
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.topSongs}>
      <div className={styles.earnings}>
        <TotalEarnings
          value={songsTop.total}
          percent={songsTop.percentage}
          isIncrement={songsTop.direction === "INCREMENT"}
          className={styles.earningsWrap}
        />
        {songsTop.total > 0 && (
          <div className={styles.topSongsChartWrap}>
            <TopSongsChart isInnerPage={true} />
          </div>
        )}
      </div>
      <div className={styles.table}>
        {!m ? (
          <Table
            callapsClassName={styles.callapsClassName}
            className={styles.table}
            data={sortedData.map((el, i) => ({ ...el, index: i + 1 }))}
            columns={columns}
            callapsComponent={DetailCellRenderer}
            defaultSortBy={defaultSortBy}
            onSort={handleSort}
          />
        ) : (
          <SongsCard
            data={sortedData.map((el, i) => ({ ...el, index: i + 1 }))}
          />
        )}
      </div>
    </div>
  );
};

export default TopSongs;
