import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import styles from "./LandingPage.module.scss";

import Loader from "../../components/shared/Loading";
import PayeeAccountsModal from "../../components/shared/Modals/PayeeAccountsModal";
import SearchInput from "../../components/shared/SearchInput";
import Table from "../../components/shared/Table/Table";
import { getSortedData } from "../../configs/utils";
import { useActions } from "../../hooks/useActions";
import {
  clientsSelector,
  payeeCodeSelector,
  preselectedPayeeCodeSelector
} from "../../store/appStatic/selectors";
import LandingForMobile from "./LandingForMobile";
import LandingPageCard from "./LandingPageCard";

const LandingPage = () => {
  const { t } = useTranslation("translation");
  const clients = useSelector(clientsSelector);
  const [pageIndex, setPageIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const preslectedPayeeCode = useSelector(preselectedPayeeCodeSelector);
  const [paginateData, setPaginateData] = useState<any>([]);
  const payeeCode = useSelector(payeeCodeSelector);
  const [size, setSize] = useState<any>(10);
  const [sortBy, setSortBy] = useState<any>({ id: "fullName", desc: false });
  const [activeState, setActiveState] = useState<any>(null);
  const md = useMediaQuery({ maxWidth: 768 });
  const mb = useMediaQuery({ maxWidth: 570 });
  const m = useMediaQuery({ maxWidth: 567 });
  const { resetDealsState, setPayeeCodeAction } = useActions();

  useEffect(() => {
    resetDealsState();
    if (window) {
      window.ledgerId = null;
    }
  }, []);

  useEffect(() => {
    if (currentList?.length) {
      const sortedData = getSortedData(currentList, {
        sort: sortBy.id,
        order: sortBy.desc
      });
      setCurrentList(sortedData);
    }
  }, [currentList, sortBy]);

  useEffect(() => {
    setCurrentList(
      clients.map((el: any, index: number) => ({ ...el, id: index + 1 }))
    );
  }, [clients]);

  useEffect(() => {
    if (currentList?.length) {
      setPaginateData(
        currentList
          ?.map((el: any, index: any) => ({ ...el, id: index + 1 }))
          .slice(pageIndex * size, (pageIndex + 1) * size) || []
      );
    } else {
      setPaginateData([]);
    }
  }, [currentList, pageIndex, size, sortBy]);

  const history = useHistory();
  const { SetOpenModal } = useActions();
  const isRedirect = useMemo(() => {
    const list: any = [];
    clients.forEach((el: any) => list.push(...el.payeeAccounts));
    if (list.length === 1) {
      return true;
    }
  }, [clients, payeeCode, preslectedPayeeCode]);
  if (isRedirect) {
    history.push(`/home`);
  }

  const payee: any = mb
    ? []
    : [
        {
          Header: () => {
            return (
              <span
                className="table_heading"
                title={t(`myAccountPage.payeeAccounts`)}
              >
                {t(`myAccountPage.payeeAccounts`)}
              </span>
            );
          },
          headerClass: styles.headerClass,
          accessor: "payeeAccounts",
          maxWidth: 500,
          Cell: ({ row }: any) => {
            const props = row.original.payeeAccounts;
            const [item, setItem] = useState<any>(null);
            const [left, setLeft] = useState(0);
            const isLast = (props.length - 6) * 20 >= -left;
            const count = props.length - 5 + left / 20;

            const handlePayeeAccountClick = (payeeCode: string) => {
              setPayeeCodeAction(payeeCode);
              history.push(`home`);
            };
            row.original.setLeft = setLeft;
            return (
              <p className={styles.wrapper}>
                <header className={styles.value}>
                  <span
                    className={styles.elements}
                    style={{ marginLeft: `${left}%` }}
                  >
                    {!md &&
                      props.slice(0, 5).map((el: any) => (
                        <Tooltip
                          key={el.payeeCode}
                          title={
                            <div className={cn(styles.tool)}>
                              <p>{item?.payeeAccount}</p>
                              <p>{item?.payeeCode}</p>
                            </div>
                          }
                        >
                          <p
                            onMouseEnter={() => setItem(el)}
                            className={styles.code}
                            onClick={() =>
                              handlePayeeAccountClick(el.payeeCode)
                            }
                          >
                            <p className={styles.desc}>{el.payeeAccount}</p>
                          </p>
                        </Tooltip>
                      ))}
                    <span
                      className={cn(styles.countLength, {
                        [styles.active]: md
                      })}
                    >
                      {props.length}
                    </span>
                  </span>
                </header>
                {isLast && !md && (
                  <button
                    className={styles.nextIcon}
                    onClick={() => {
                      setLeft(0);
                      SetOpenModal({
                        type: "payeeCodes",
                        isOpen: true,
                        data: row.original
                      });
                    }}
                  >
                    <span className={styles.count}>{`+${count}`}</span>
                  </button>
                )}
              </p>
            );
          }
        }
      ];

  const columnDefs = useMemo(
    () => [
      {
        Header: "#",
        id: "#",
        maxWidth: 5,
        withoutSort: true,
        Cell: ({ row }: any) => {
          return row.original.id;
        }
      },
      {
        Header: () => {
          return (
            <span
              className="table_heading"
              title={t(`myAccountPage.accountName`)}
            >
              {t(`myAccountPage.accountName`)}
            </span>
          );
        },
        accessor: "fullName",
        maxWidth: 200,
        Cell: ({ row }: any) => {
          return <p className={styles.name}>{row.original.fullName}</p>;
        }
      },
      ...payee
    ],
    [sortBy, md, mb, t]
  );

  const handleRowClick = useCallback(
    (el, e) => {
      const tagName = e.target.tagName;
      if (tagName !== "svg" && tagName !== "path" && tagName !== "BUTTON") {
        setActiveState(el.original);
        if (!mb) {
          SetOpenModal({
            type: "payeeCodes",
            isOpen: true,
            data: el.original
          });
        }
      }
    },
    [SetOpenModal, md]
  );

  const onFilterTextBoxChanged = useCallback(
    e => {
      const filteredData = e.target.value
        ? clients.filter((account: any) =>
            account.fullName
              .toUpperCase()
              .includes(e.target.value.toUpperCase())
          )
        : clients;
      setCurrentList(filteredData);
      if (pageIndex > 0) {
        setPageIndex(0);
      }
      setSearchInputValue(e.target.value ? e.target.value : "");
    },
    [clients, pageIndex]
  );

  const [searchInputValue, setSearchInputValue] = useState<string>("");

  const fetchData = useCallback(
    ({ pageIndex: innerPage }: { pageSize: number; pageIndex: number }) => {
      if (innerPage !== pageIndex) {
        setPageIndex(innerPage);
      }
    },
    [pageIndex]
  );

  const defaultSortBy = useMemo(() => {
    return [{ id: sortBy.id, desc: sortBy.desc }];
  }, [sortBy]);

  const handleSort = useCallback(
    ({ sortBy }) => {
      if (sortBy[0]?.id) {
        setSortBy(sortBy[0]);
      }
    },
    [sortBy]
  );

  if (activeState && mb) {
    return (
      <LandingForMobile
        setActiveState={setActiveState}
        activeState={activeState}
        currentList={currentList}
      />
    );
  }
  return (
    <>
      {clients.length ? (
        <div className="container">
          <div className={cn(styles.container)}>
            <div className={cn(styles.table)}>
              <div className={cn(styles.table_head)}>
                <h4 className={cn(styles.table_title)}>
                  {t(`myAccountPage.title`)}
                </h4>
                <SearchInput
                  placeholder={t(`myAccountPage.search`)}
                  mobilePlaceholder={t(`myAccountPage.search`)}
                  onInput={onFilterTextBoxChanged}
                  onclickClearIcon={onFilterTextBoxChanged}
                  value={searchInputValue}
                />
              </div>
            </div>
          </div>
          {m && (
            <LandingPageCard
              data={paginateData}
              handleRowClick={(el: any) => setActiveState(el)}
            />
          )}
          <Table
            isMobile={m}
            data={paginateData}
            className={styles.tableReact}
            columns={columnDefs}
            totalItems={currentList?.length}
            fetchData={fetchData}
            onRowClick={handleRowClick}
            onSort={handleSort}
            defaultSortBy={defaultSortBy}
            isWhitePagination={true}
            defaultPage={pageIndex}
            defaultPageSize={size}
            onChangeSize={(e: any) => {
              setSize(+e.value);
              setPageIndex(0);
            }}
          />
        </div>
      ) : (
        <Loader />
      )}
      {!mb && <PayeeAccountsModal />}
    </>
  );
};

export default LandingPage;
