export const colors = ["#AD076A", "#E8BE04", "#79A13C", "#007BD9"];

export const readsColor = [
  colors[0],
  "#AA0568",
  "#AC0769",
  "#AC0669",
  "#A20061"
];

export const yellowColors = [
  "#E6BD04",
  "#D4AD00",
  "#E7BD04",
  "#DFB602",
  "#DFB702",
  "#DEB502",
  "#E0B802",
  colors[1]
];

export const mounts = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const options = {
  scales: {
    xAxes: [{ gridLines: { color: "red" } }],
    y: {
      beginAtZero: true,
      suggestedMax: 5,
      min: 0,
      grid: {
        drawBorder: true,
        color: "#646464",
        borderColor: "white",
        borderWidth: 2,
        borderDash: [2]
      },
      ticks: {
        maxTicksLimit: 6,
        fontSize: 9,
        color: "#CBCBCB",
        callback: function(value: number) {
          return `$${value}`;
        }
        // stepSize: 5
      }
    },
    x: {
      beginAtZero: true,
      borderDash: [20],
      grid: {
        drawBorder: true,
        color: "#646464",
        borderColor: "white",
        borderWidth: 2,
        borderDash: [2]
      },
      ticks: {
        maxTicksLimit: 6,
        fontSize: 9,
        color: "#CBCBCB"
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      labels: {
        usePointStyle: true
      },
      display: false
    }
  }
};

export const barOptions = {
  type: "bar",
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: false
    }
  },
  clip: { left: 5, top: true, right: 50, bottom: 50 },
  scales: {
    y: {
      anchor: "end",
      beginAtZero: true,
      suggestedMax: 5,
      min: 0,
      grid: {
        drawTicks: false,
        drawBorder: true,
        color: "#646464",
        borderColor: "black",
        borderWidth: 2,
        borderDash: [2]
      },
      ticks: {
        maxTicksLimit: 6,
        fontSize: 9,
        color: "#CBCBCB"
      }
    },
    x: {
      beginAtZero: true,
      borderDash: [20],
      grid: {
        drawBorder: true,
        color: "#646464",
        drawTicks: false,
        borderColor: "white",
        borderWidth: 2,
        borderDash: [2]
      },
      ticks: {
        maxTicksLimit: 6,
        fontSize: 9,
        color: "#CBCBCB",
        callback: function() {
          return ``;
        }
      }
    }
  }
};

export const circleOptions = {
  plugins: {
    tooltip: {
      enabled: false
    },
    title: {
      display: false
    },
    legend: {
      display: false
    }
  },
  scales: {}
};
export const cookiePolicyLanguages: any = {
  DA: "dk",
  DE: "de",
  EN: "en",
  ES: "es",
  FI: "fi",
  FR: "fr",
  IT: "it",
  KO: "ko",
  LAS: "en",
  MS: "en",
  NL: "nl",
  NO: "no",
  PT: "en",
  RU: "ru",
  SC: "zh",
  SV: "sv",
  ZH: "zh"
};
