import { useCallback } from "react";
import axios from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { DocumentType, PublishableDocument, StoringAction } from "../types";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  return config;
});

const useStoreFiles = () => {
  const storeFiles = useCallback(
    async (
      batchId: string,
      documentType: DocumentType,
      action: StoringAction
    ) => {
      if (!batchId) return;
      const res: any = await axiosInstance.put(
        `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/${batchId}`,
        {
          action
        }
      );
      return res?.data;
    },
    []
  );
  const checkForDuplication = useCallback(
    async (batchId: string, documentType: DocumentType) => {
      if (documentType === "tax-doc") return [];
      if (!batchId) return;
      const res: any = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/${batchId}/check-duplications`
      );
      return res?.data;
    },
    []
  );

  const publishFiles = useCallback(
    async (
      batchId: string,
      documentType: DocumentType,
      body: PublishableDocument[] | Record<string, any> = {}
    ) => {
      if (!batchId) return;
      const res: any = await axiosInstance.put(
        `${
          process.env.REACT_APP_BASE_URI
        }api/cms/${documentType}/batches/${batchId}${
          documentType !== "tax-doc" ? "/publish" : ""
        }`,
        body
      );
      return res?.data;
    },
    []
  );

  return { storeFiles, checkForDuplication, publishFiles };
};

export default useStoreFiles;
