import React from "react";

import styles from "../ManagementHeader.module.scss";
import { ReactComponent as Logo } from "../../../../../icons/logo_black.svg";

interface Title {
  title: string;
}

function PageHeading(props: Title) {
  return (
    <div className={styles.logoWrap}>
      <Logo />
      <h1 className="font-bold text-xl font-black ff-Plaak ml-2">
        {props.title}
      </h1>
    </div>
  );
}

export default PageHeading;
