import React from "react";

import styles from "./ButtonLoader.module.scss";

const ButtonLoader = () => {
  return (
    <svg className={styles.loader} width="13" height="13">
      <circle
        className="progress-ring__circle"
        strokeWidth="1"
        fill="transparent"
        r="6"
        cx="6.5"
        cy="6.5"
      />
    </svg>
  );
};

export default ButtonLoader;
