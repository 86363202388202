import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import cn from "classnames";

import styles from "../UserManagement.module.scss";
import { ReactComponent as ArrowBlack } from "../../../icons/arrow_black.svg";
import { ReactComponent as Accent } from "../../../icons/col_accent.svg";

import GridPlaceholderLoader from "../../../components/shared/GridPlaceholder";
import RowToolTip from "../../../components/userManagement/RowToolTip";
import RowToolTipItem from "../../../components/userManagement/RowToolTip/RowToolTipItem";
import UserPopup from "../../../components/userManagement/UserPopup/UserPopup";
import { useActions } from "../../../hooks/useActions";
import { profileSelector } from "../../../store/appStatic/selectors";
import {
  bodySelector,
  generatedFiltersSelector,
  UMSTypeSearchSelector
  // umsTypeSearchSelector
} from "../../../store/architectUsersStore/selectors";
import {
  activityGrid,
  archiveGrid,
  internalUsersGrid,
  payeeCodeGrid,
  songWriterGrid
} from "../TableConfigs";
import { UMSTypeSearchEnum } from "../types";
import Grid from "./Grid";

const SuperAdminLayout = () => {
  const {
    GetArchitectUsersAction,
    SetBody,
    MetaDataAction,
    ModifiersAction,
    SetOpenModalAction,
    SetSearchedValue
  } = useActions();
  const tableRef = useRef<any>();
  const [modalIsOpen, setModalIsOpen] = useState<any>({
    isOpen: false,
    item: null,
    title: ""
  });
  const modalClose = () => {
    setModalIsOpen({
      isOpen: false,
      item: null
    });
  };

  const history = useHistory();
  const { tab } = useParams<any>();
  const { status } = useParams<any>();
  const [rowData, setRowData] = useState<any>([]);

  const UMSTypeSearch = useSelector(UMSTypeSearchSelector);
  const generatedFilters = useSelector(generatedFiltersSelector);
  const profile = useSelector(profileSelector);
  const body = useSelector(bodySelector);
  const { architectUsers } = useSelector(
    (state: any) => state.architectUsersReducer
  );

  // const umsTypeSearch = useSelector(umsTypeSearchSelector);

  const { total } = useSelector((state: any) => state.architectUsersReducer);
  const { loading } = useSelector((state: any) => state.architectUsersReducer);
  const [searchValue, setSearchValue] = useState<string>();
  const { modifiers } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  const { meta } = useSelector((state: any) => state.architectUsersReducer);

  useEffect(() => {
    if (profile && meta === null) {
      MetaDataAction();
    }
    if (profile && !modifiers.length) {
      ModifiersAction();
    }
  }, [profile]);

  const generateFilterEndVal = useCallback(
    (tabName: string, statusName: string, generatedFilters: any) => {
      let roleAndSongwritersType: any = [];
      let otherFilters: any = [];
      const isRoleAndSongwriterType = () => {
        return !!(
          generatedFilters.find((el: any) => el.name === "role") &&
          generatedFilters.find((el: any) => el.name === "songwriterType")
        );
      };

      if (isRoleAndSongwriterType()) {
        roleAndSongwritersType = generatedFilters.filter(
          (el: any) => el.name === "role" || el.name === "songwriterType"
        );
        otherFilters = generatedFilters.filter(
          (el: any) => el.name !== "role" && el.name !== "songwriterType"
        );
      } else {
        otherFilters = generatedFilters;
      }
      const customFilter = () => {
        return roleAndSongwritersType.length
          ? [
              {
                or: roleAndSongwritersType.map((el: any) => {
                  return el;
                })
              }
            ]
          : [];
      };
      switch (tabName) {
        case "activity":
          if (profile?.isSuperAdmin) {
            if (statusName) {
              const requestBody = [
                {
                  name: "status",
                  operation: "eq",
                  value: `${status.toUpperCase()}`
                }
              ];
              if (status === "invited") {
                requestBody.push({
                  name: "status",
                  operation: "eq",
                  value: "PENDING_REGISTERED"
                });
              }
              return {
                ...(generatedFilters.length
                  ? {
                      and: [
                        { or: requestBody },
                        ...customFilter(),
                        ...otherFilters.map((el: any) => {
                          return el;
                        })
                      ]
                    }
                  : { or: requestBody })
              };
            } else {
              return {
                ...(generatedFilters.length
                  ? {
                      and: [
                        ...customFilter(),
                        ...otherFilters.map((el: any) => {
                          return el;
                        })
                      ]
                    }
                  : { and: [] })
              };
            }
          } else {
            if (statusName) {
              return {
                and: [
                  { name: "type", operation: "eq", value: "EXTERNAL" },
                  {
                    name: "status",
                    operation: "eq",
                    value: status.toUpperCase()
                  },
                  ...customFilter(),
                  ...otherFilters.map((el: any) => {
                    return el;
                  })
                ]
              };
            } else {
              return {
                and: [
                  {
                    name: "type",
                    operation: "eq",
                    value: "EXTERNAL"
                  },
                  ...customFilter(),
                  ...otherFilters.map((el: any) => {
                    return el;
                  })
                ]
              };
            }
          }
        case "clients":
          if (statusName) {
            const requestBody = [];
            if (!status) {
              requestBody.push({
                name: "type",
                operation: "eq",
                value: "EXTERNAL"
              });
            } else {
              if (status === "invited") {
                requestBody.push({
                  name: "status",
                  operation: "eq",
                  value: "PENDING_REGISTERED"
                });
              }
              requestBody.push({
                name: "status",
                operation: "eq",
                value: status.toUpperCase()
              });
            }
            return {
              ...(generatedFilters.length
                ? {
                    and: [
                      { or: requestBody },
                      ...customFilter(),
                      ...otherFilters.map((el: any) => {
                        return el;
                      })
                    ]
                  }
                : { or: requestBody })
            };
          } else {
            return {
              and: [
                { name: "type", operation: "eq", value: "EXTERNAL" },
                ...customFilter(),
                ...otherFilters.map((el: any) => {
                  return el;
                })
              ]
            };
          }
        case "internal-users":
          if (statusName) {
            return {
              and: [
                { name: "type", operation: "eq", value: "INTERNAL" },
                {
                  name: "status",
                  operation: "eq",
                  value: status.toUpperCase()
                },
                ...customFilter(),
                ...otherFilters.map((el: any) => {
                  return el;
                })
              ]
            };
          } else {
            return {
              and: [
                { name: "type", operation: "eq", value: "INTERNAL" },
                ...customFilter(),
                ...otherFilters.map((el: any) => {
                  return el;
                })
              ]
            };
          }
        case "archive":
          if (statusName) {
            return {
              ...(generatedFilters.length
                ? {
                    and: [
                      {
                        or: [
                          {
                            name: "status",
                            operation: "eq",
                            value: status.toUpperCase()
                          }
                        ]
                      },
                      ...customFilter(),
                      ...otherFilters.map((el: any) => {
                        return el;
                      })
                    ]
                  }
                : {
                    or: [
                      {
                        name: "status",
                        operation: "eq",
                        value: status.toUpperCase()
                      }
                    ]
                  })
            };
          } else {
            if (profile?.isSuperAdmin) {
              return {
                ...(generatedFilters.length
                  ? {
                      and: [
                        {
                          or: [
                            {
                              name: "status",
                              operation: "eq",
                              value: "INACTIVE"
                            },
                            {
                              name: "status",
                              operation: "eq",
                              value: "SUSPENDED"
                            }
                          ]
                        },
                        ...customFilter(),
                        ...otherFilters.map((el: any) => {
                          return el;
                        })
                      ]
                    }
                  : {
                      or: [
                        { name: "status", operation: "eq", value: "INACTIVE" },
                        { name: "status", operation: "eq", value: "SUSPENDED" }
                      ]
                    })
              };
            } else {
              return {
                ...(generatedFilters.length
                  ? {
                      and: [
                        {
                          or: [
                            {
                              name: "status",
                              operation: "eq",
                              value: "SUSPENDED"
                            }
                          ]
                        },
                        ...customFilter(),
                        ...otherFilters.map((el: any) => {
                          return el;
                        })
                      ]
                    }
                  : {})
              };
            }
          }
        case "payee-code":
          return {
            ...(generatedFilters.length
              ? {
                  and: [
                    ...otherFilters.map((el: any) => {
                      return el;
                    })
                  ]
                }
              : { and: [] })
          };
        case "":
          return "all";
      }
    },
    [profile?.isSuperAdmin, status]
  );
  const requestBody = useCallback(
    (take: number, skip: number) => {
      return {
        skip: skip,
        take: take,
        sort: [
          {
            field:
              UMSTypeSearch.value === UMSTypeSearchEnum.PAYEE_NAME_CODE
                ? "clientFullName"
                : "modifiedAt",
            order: "desc"
          }
        ],
        ...(generateFilterEndVal(tab, status, generatedFilters) && {
          filter: generateFilterEndVal(tab, status, generatedFilters)
        })
      };
    },
    [generateFilterEndVal, status, tab, generatedFilters]
  );
  // useEffect(() => {
  //   const isUMSTypeSearchPayeeCode =
  //     umsTypeSearch?.value === UMSTypeSearchEnum.PAYEE_NAME_CODE;
  //   const isPathUMSTypeSearchPayeeCode = history.location.pathname.startsWith(
  //     "/user-management/payee-code"
  //   );
  //   const isPathUMSTypeSearchActivity = !isPathUMSTypeSearchPayeeCode;
  //
  //   if (isUMSTypeSearchPayeeCode) {
  //     !isPathUMSTypeSearchPayeeCode &&
  //       history.push("/user-management/payee-code");
  //   } else {
  //     !isPathUMSTypeSearchActivity &&
  //       history.push("/user-management/activity/");
  //   }
  // }, [umsTypeSearch]);

  useEffect(() => {
    SetBody(requestBody(50, 0));
  }, [requestBody]);

  const openDeactivateModal = useCallback(
    (type: string, user: any) => {
      SetOpenModalAction({
        isOpen: true,
        type: type,
        user: user,
        queryParams: requestBody(50, 0)
      });
    },
    [SetOpenModalAction, requestBody]
  );
  const openEditUserModal = useCallback(
    (type: string, user: any) => {
      SetOpenModalAction({
        isOpen: true,
        type: type,
        user: user,
        queryParams: requestBody(50, 0)
      });
    },
    [SetOpenModalAction, requestBody]
  );
  const dwnldBtnClicked = useCallback(
    (type: string, fileName: string) => {
      if (!loading) {
        SetOpenModalAction({
          isOpen: true,
          type: type,
          user: tableRef.current && tableRef.current.api.getDisplayedRowCount(),
          queryParams: requestBody(50, 0),
          fileName: fileName
        });
      }
    },
    [SetOpenModalAction, requestBody, loading]
  );
  const filterIconClicked = useCallback(() => {
    if (!loading) {
      SetOpenModalAction({
        isOpen: true,
        type: "filterModal",
        user: tableRef.current && tableRef.current.api.getDisplayedRowCount(),
        queryParams: requestBody(50, 0)
      });
    }
  }, [SetOpenModalAction, requestBody, loading]);

  const getColAccent = ({ data }: any) => {
    if (Object.keys(data).length) {
      return (
        <div style={{ width: "25px" }}>
          <div className={cn(styles.accent)} data-for={data.id} data-tip="">
            <Accent />
          </div>
          {tab === "internal-users" && (
            <RowToolTip
              tooltipId={data.id}
              offset={{
                left: -15
              }}
              place="left"
              className={cn(styles.accent_ToolTip)}
            >
              <div>
                <RowToolTipItem
                  title="View/Edit Details"
                  onClick={() => openEditUserModal("editUser", data)}
                />
                {data.status !== "INACTIVE" && (
                  <RowToolTipItem
                    onClick={() => {
                      openDeactivateModal("deactivate", data);
                    }}
                    title="Deactivate"
                  />
                )}
              </div>
            </RowToolTip>
          )}
          {tab === "clients" && (
            <RowToolTip
              tooltipId={data.id}
              offset={{
                left: -15
              }}
              place="left"
              className={cn(styles.accent_ToolTip)}
            >
              <div>
                <RowToolTipItem
                  title="View Details"
                  onClick={() => {
                    openEditUserModal("viewUser", data);
                  }}
                />
                <RowToolTipItem
                  title={"Impersonate"}
                  onClick={() => {
                    openEditUserModal("impersonate", data);
                  }}
                />
                {data.status !== "SUSPENDED" && data.status !== "INVITED" && (
                  <RowToolTipItem
                    onClick={() => {
                      openDeactivateModal("suspend", data);
                    }}
                    title="Suspend"
                  />
                )}
                {data.status === "SUSPENDED" && (
                  <RowToolTipItem
                    title="Reactivate"
                    onClick={() => {
                      openDeactivateModal("reactivate", data);
                    }}
                  />
                )}
              </div>
            </RowToolTip>
          )}
        </div>
      );
    } else {
      return <GridPlaceholderLoader />;
    }
  };

  const headerCheckBoxRender = useCallback(
    (step: string) => {
      const { api } = tableRef.current && tableRef.current;
      const tableStatus = tableRef.current && tableRef.current.props.status;
      return (
        <div className="text-right ml-2">
          <RowToolTip
            place="right"
            tooltipId="user-selection"
            offset={{
              left: 30
            }}
            className={cn(styles.checkbox_toolTip)}
          >
            <div>
              <RowToolTipItem
                title="Select All"
                onClick={() => api.selectAll()}
              />
              <RowToolTipItem
                title="Deselect All"
                onClick={() => api.deselectAll()}
              />
              <RowToolTipItem
                onClick={() =>
                  api.getSelectedRows().length &&
                  openDeactivateModal(
                    step === "internal-users"
                      ? "deactivate"
                      : tableStatus === "suspended"
                      ? "reactivate"
                      : "suspend",
                    api.getSelectedRows()
                  )
                }
                title={
                  step === "internal-users"
                    ? "Bulk Deactivate"
                    : tableStatus === "suspended"
                    ? "Bulk Reactivate"
                    : "Bulk Suspend"
                }
              />
            </div>
          </RowToolTip>
          <ArrowBlack
            className={cn(styles.arrow, "cell-arrow")}
            data-for="user-selection"
            data-tip=""
          />
        </div>
      );
    },
    [openDeactivateModal]
  );
  const getGridData = useCallback(
    (
      isScroll = false,
      take = 50,
      skip = 0,
      params = requestBody(take, skip)
    ) => {
      GetArchitectUsersAction(params, isScroll);
    },
    [generatedFilters, requestBody]
  );

  useEffect(() => {
    if (profile) {
      getGridData();
      const { columnApi } = tableRef.current && tableRef.current;
      const { api } = tableRef.current && tableRef.current;
      api && api.setRowData(new Array(6).fill({}));
      api && api.hideOverlay();
      columnApi && columnApi.resetColumnState();
      setSearchValue("");
      if (tab === "internal-users" && !profile?.isSuperAdmin) {
        history.push("/access-denied");
      }
    }
  }, [generatedFilters, profile, status, tab]);

  useEffect(() => {
    setRowData(architectUsers);
  }, [architectUsers]);
  const SortTable = useCallback(
    (e: any) => {
      const { api } = tableRef.current && tableRef.current;
      api && api.setRowData(new Array(6).fill({}));
      api && api.hideOverlay();
      const currentCol = e.api.columnModel.displayedColumns.find(
        (c: any) => c.sort
      )
        ? e.api.columnModel.displayedColumns.find((c: any) => c.sort)
        : null;
      const b = {
        ...body,
        sort: [
          {
            field: currentCol
              ? currentCol.colId
              : UMSTypeSearch.value === UMSTypeSearchEnum.PAYEE_NAME_CODE
              ? "clientFullName"
              : "modifiedAt",
            order: currentCol ? currentCol.sort : "desc"
          }
        ]
      };
      SetBody(b);
      getGridData(false, 50, 0, b);
    },
    [body, SetBody, getGridData]
  );

  const handleActivityRowClick = useCallback(
    user => {
      let type = "";
      if (
        user.role === "SUPER_ADMIN" ||
        user.role === "ADMIN"
        // user.role === "SENIOR_ADMIN"
      ) {
        type = "editUser";
      }
      if (user.role === "EXTERNAL_USER") {
        type = "viewUser";
      }
      openEditUserModal(type, user);
    },
    [openEditUserModal]
  );

  const searchClick = useCallback(() => {
    SetSearchedValue(searchValue);
    const or = {
      or:
        UMSTypeSearch.value === UMSTypeSearchEnum.USER_NAME_EMAIL
          ? [
              {
                operation: "like",
                name: "email",
                value: searchValue
              },
              {
                operation: "like",
                name: "title",
                value: searchValue
              }
            ]
          : [
              // {
              //   operation: "like",
              //   name: "userEmail",
              //   value: searchValue
              // },
              // {
              //   operation: "like",
              //   name: "payeeCode",
              //   value: searchValue
              // },
              // {
              //   operation: "like",
              //   name: "payeeAccount",
              //   value: searchValue
              // },
              // {
              //   operation: "like",
              //   name: "clientFullName",
              //   value: searchValue
              // }
              {
                operation: "like",
                name: "email",
                value: searchValue
              },
              {
                operation: "like",
                name: "title",
                value: searchValue
              }
            ]
    };
    if (searchValue) {
      const a =
        body.filter?.and &&
        body.filter?.and?.filter((obj: any) =>
          obj?.or
            ? obj?.or?.find((ob: any) => ob.operation !== "like")
            : body.filter?.and
        );

      let sBody;
      if (body.filter?.and) {
        sBody = {
          ...body,
          filter: {
            and: [...a, or]
          }
        };
      } else {
        const ors = {
          or: body.filter?.or
        };
        sBody = {
          ...body,
          filter: {
            and: [ors, or]
          }
        };
      }
      SetBody(sBody);
      getGridData(false, 50, 0, sBody);
      const { api } = tableRef.current && tableRef.current;
      api && api.setRowData(new Array(6).fill({}));
      api && api.hideOverlay();
    }
  }, [searchValue, body, getGridData, SetBody]);
  const searchEmpty = useCallback(() => {
    SetSearchedValue(searchValue);
    SetBody(requestBody(50, 0));
    getGridData();
    const { api } = tableRef.current && tableRef.current;
    api && api.setRowData(new Array(6).fill({}));
    api && api.hideOverlay();
  }, [SetBody, getGridData, requestBody]);
  return (
    <>
      {tab === "activity" && (
        <Grid
          loading={loading}
          rowData={rowData}
          metaData={meta}
          tab={tab}
          columnDefs={
            profile?.isSuperAdmin
              ? activityGrid().superAdminColumnDefs
              : activityGrid().adminColumns
          }
          quickFilters={
            profile?.isSuperAdmin
              ? activityGrid().superAdminQuickFilters
              : activityGrid().adminQuickFilters
          }
          status={status}
          getGridData={getGridData}
          setGridRef={tableRef}
          total={total}
          onRowClicked={user => handleActivityRowClick(user.data)}
          onSortChanged={(e: any) => SortTable(e)}
          filterIconClicked={() => filterIconClicked()}
          onChangeSearch={(v: string) => {
            setSearchValue(v);
          }}
          searchClick={searchClick}
          searchEmpty={searchEmpty}
          searchValue={searchValue}
        />
      )}

      {tab === "clients" && (
        <Grid
          loading={loading}
          rowData={rowData}
          metaData={meta}
          tab={tab}
          columnDefs={
            profile?.isSuperAdmin
              ? songWriterGrid(status).superAdminColumnDefs
              : songWriterGrid(status).adminColumns
          }
          quickFilters={songWriterGrid().superAdminQuickFilters}
          status={status}
          getGridData={getGridData}
          frameworkComponents={{
            getColAccent: getColAccent,
            headerCheckBoxRender: () => headerCheckBoxRender("clients")
          }}
          setGridRef={tableRef}
          total={total}
          downloadBtnClicked={() => dwnldBtnClicked("csvDwnld", "clients")}
          onRowClicked={user => openEditUserModal("viewUser", user.data)}
          onSortChanged={(e: any) => SortTable(e)}
          filterIconClicked={() => filterIconClicked()}
          onChangeSearch={(v: string) => setSearchValue(v)}
          searchClick={searchClick}
          searchValue={searchValue}
          searchEmpty={searchEmpty}
        />
      )}
      {tab === "internal-users" && profile?.isSuperAdmin && (
        <Grid
          total={total}
          rowData={rowData}
          metaData={meta}
          tab={tab}
          columnDefs={internalUsersGrid(status && status).superAdminColumnDefs}
          quickFilters={internalUsersGrid().superAdminQuickFilters}
          status={status}
          getGridData={getGridData}
          frameworkComponents={{
            getColAccent: getColAccent,
            // rowCheckBoxRender: rowCheckBoxRender,
            headerCheckBoxRender: () => headerCheckBoxRender("internal-users")
          }}
          setGridRef={tableRef}
          loading={loading}
          downloadBtnClicked={() =>
            dwnldBtnClicked("csvDwnld", "internal-users")
          }
          onRowClicked={user => openEditUserModal("editUser", user.data)}
          onSortChanged={(e: any) => SortTable(e)}
          filterIconClicked={() => filterIconClicked()}
          onChangeSearch={(v: string) => setSearchValue(v)}
          searchClick={searchClick}
          searchValue={searchValue}
          searchEmpty={searchEmpty}
        />
      )}
      {tab === "archive" && (
        <Grid
          loading={loading}
          rowData={rowData}
          metaData={meta}
          tab={tab}
          columnDefs={
            profile?.isSuperAdmin
              ? archiveGrid().superAdminColumnDefs
              : archiveGrid().adminColumns
          }
          quickFilters={
            profile?.isSuperAdmin
              ? archiveGrid().superAdminQuickFilters
              : archiveGrid().adminQuickFilters
          }
          status={status}
          getGridData={getGridData}
          frameworkComponents={{
            getColAccent: getColAccent
          }}
          setGridRef={tableRef}
          total={total}
          downloadBtnClicked={() => dwnldBtnClicked("csvDwnld", "archive")}
          onRowClicked={user => handleActivityRowClick(user.data)}
          onSortChanged={(e: any) => SortTable(e)}
          filterIconClicked={() => filterIconClicked()}
          onChangeSearch={(v: string) => setSearchValue(v)}
          searchClick={searchClick}
          searchValue={searchValue}
          searchEmpty={searchEmpty}
        />
      )}
      {tab === "payee-code" && (
        <Grid
          loading={loading}
          rowData={rowData}
          metaData={meta}
          tab={tab}
          columnDefs={payeeCodeGrid()}
          quickFilters={
            profile?.isSuperAdmin
              ? archiveGrid().superAdminQuickFilters
              : archiveGrid().adminQuickFilters
          }
          status={status}
          getGridData={getGridData}
          // frameworkComponents={{
          //   getColAccent: getColAccent
          // }}
          setGridRef={tableRef}
          total={total}
          // downloadBtnClicked={() => dwnldBtnClicked("csvDwnld", "archive")}
          onRowClicked={() => {
            // handleActivityRowClick(user.data);
          }}
          onSortChanged={(e: any) => SortTable(e)}
          filterIconClicked={() => filterIconClicked()}
          onChangeSearch={(v: string) => setSearchValue(v)}
          searchClick={searchClick}
          searchValue={searchValue}
          searchEmpty={searchEmpty}
        />
      )}
      <UserPopup modalIsOpen={modalIsOpen} modalClose={modalClose} />
    </>
  );
};

export default SuperAdminLayout;
