import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./SetCountryModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

import { useActions } from "../../../../hooks/useActions";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";

const SetCountryModal = (props: any) => {
  const { t } = useTranslation("translation");
  const dealsModal = useSelector(dealsModalSelector);
  const { SetOpenModal } = useActions();
  const [success] = useState(false);
  const [cancel] = useState(false);
  const scrolledRef = useRef<any>();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [shadowPosition, setShadowPosition] = useState<string>("down");
  const toggleModal = () => {
    SetOpenModal({ type: "", isOpen: false });
  };
  const handleNavigation = useCallback(() => {
    const currentScrollTop = scrolledRef?.current?.scrollTop;
    if (currentScrollTop > scrollTop) {
      setShadowPosition("up");
    } else if (currentScrollTop < scrollTop) {
      setShadowPosition("down");
    }
    setScrollTop(currentScrollTop);
  }, [scrollTop]);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={dealsModal.isOpen && dealsModal.type === "countryList"}
      onRequestClose={toggleModal}
      className={styles.contactUsModal}
      overlayClassName={styles.myoverlay}
    >
      <div className={styles.wrapper}>
        {!success && !cancel && (
          <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
        )}
        <div className={styles.container}>
          <div className={styles.body}>
            <h3 className={styles.title}>
              {props.modalTitle ? props.modalTitle : ""}
            </h3>
            <p className={styles.subtitle}>
              {t(`common.administeredTerritories`)}
              {`: `}
              {`${props?.countries?.length}`}
            </p>
            <div
              className={cn(
                shadowPosition === "up"
                  ? styles.wrap_shadowUp
                  : styles.wrap_shadowDown
              )}
            >
              <div className={cn(styles.shadow_top, styles.shadow)} />
              <div
                className={cn(styles.wrap)}
                onScroll={handleNavigation}
                ref={scrolledRef}
              >
                <p>{props.countries?.join(", ")}</p>
              </div>
              <div className={cn(styles.shadow_bottom, styles.shadow)} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SetCountryModal;
