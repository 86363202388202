import React from "react";

import styles from "./ResponseAlert.module.scss";
import { ReactComponent as Success } from "../../../icons/success.svg";
import { ReactComponent as WarningIcon } from "../../../icons/warning.svg";
import { ReactComponent as Close } from "../../../icons/x_2.svg";

import { jc } from "../../../lib/styles";

export enum ResponseAlertType {
  Error,
  Warning,
  Success
}

export interface ResponseAlert extends React.PropsWithChildren<unknown> {
  className?: string;
  hideCloseButton?: boolean;
  onClick: any;
  type: ResponseAlertType;
  children: JSX.Element;
}

const renderAlertClass = (props: ResponseAlert): string => {
  return jc(
    `${styles.responseAlert}`,
    (typeof props.type === "undefined" ||
      props.type === ResponseAlertType.Success) &&
      `${styles.responseAlert_success}`,
    props.type === ResponseAlertType.Error && `${styles.responseAlert_error}`,
    props.type === ResponseAlertType.Warning &&
      `${styles.responseAlert_warning}`,
    props.className
  );
};

const ResponseAlert = (props: ResponseAlert) => {
  const { hideCloseButton, onClick, type, children } = props;
  return (
    <div className={renderAlertClass(props)}>
      {type === ResponseAlertType.Error && (
        <WarningIcon
          className={`${styles.responseAlert_icon} ${styles.responseAlert_icon_error}`}
        />
      )}
      {type === ResponseAlertType.Warning && (
        <WarningIcon
          className={`${styles.responseAlert_icon} ${styles.responseAlert_icon_warning}`}
        />
      )}
      {type === ResponseAlertType.Success && (
        <Success
          className={`${styles.responseAlert_icon} ${styles.responseAlert_icon_success}`}
        />
      )}
      {!hideCloseButton && (
        <Close className={styles.responseAlert_close} onClick={onClick} />
      )}
      <div className={styles.responseAlert_wrapper}>{children}</div>
    </div>
  );
};
export default ResponseAlert;
