import { OktaAuth } from "@okta/okta-auth-js";

export const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  pkce: true,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  scopes: ["offline_access", "openid", "roles"],
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 5 * 60, // To prevent time out of sync problem between client and server
    secure: true,
    // Can't use localStorage as a token storage in SSR. Create a dummy storage to fix console warning.
    storage:
      typeof window === "undefined"
        ? {
            /**
             * Dummy method
             * @returns Undefined
             */
            getItem: () => undefined,
            /**
             * Dummy method
             * @returns Undefined
             */
            setItem: () => undefined
          }
        : window.localStorage
  }
});
