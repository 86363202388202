import React from "react";

import styles from "./UploadProgress.module.scss";

type UploadProgressProps = {
  value?: number | null;
};

const UploadProgress: React.FC<UploadProgressProps> = ({ value }) => {
  return <progress className={styles.progress} value={value || 0} max={100} />;
};

export default UploadProgress;
