import { useCallback, useEffect, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { DocumentItem } from "../../../types/documents";
import { documentTypes, ROYALTY_TYPE } from "../constants";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  config.headers.common["x-email"] = `${
    localStorage.getItem("impersonateEmail")
      ? localStorage.getItem("impersonateEmail")
      : localStorage.getItem("profileEmail")
  }`;
  return config;
});

type Options = {
  year?: string | null;
  type?: string | null;
};

const useFitDocuments = (payeeCode: string, options: Options = {}) => {
  const [result, setResult] = useState<DocumentItem[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableTypes, setAvailableTypes] = useState<string[]>([]);

  const fetchDocuments = useCallback(
    async (options: Options) => {
      try {
        const documentType =
          options?.type && options.type !== documentTypes.all
            ? `documentType=${options.type}&`
            : "";
        const year =
          options?.year && options.year !== documentTypes.all
            ? `year=${options.year}`
            : "";
        setLoading(true);
        const response: AxiosResponse<DocumentItem[]> = await axiosInstance.get(
          `api/cms/payee-code-documents/${payeeCode}?${documentType}${year}`
        );
        const result = response.data.map(document => {
          // If Document type is royalty statement, set the document has no id nor fileName
          if (document.id === null)
            return {
              ...document,
              id: `${document.startDate}-${document.endDate}-${ROYALTY_TYPE}`
            };
          return document;
        });
        if (!availableYears.length || options?.year == documentTypes.all) {
          setAvailableYears(() => {
            return Array.from(new Set(result?.map(doc => doc.year))).sort(
              (a, b) => b - a
            );
          });
        }
        if (!availableTypes.length || options.type == documentTypes.all) {
          setAvailableTypes(() => {
            return Array.from(new Set(result?.map(doc => doc.documentType)));
          });
        }
        setResult(result);
      } catch (e) {
        setError((e as AxiosError)?.message);
      } finally {
        setLoading(false);
      }
    },
    [payeeCode, options]
  );

  const incrementDownloadCount = async (document: DocumentItem) => {
    if (!result?.length) return;
    const documents = [...result];
    const match = documents.find(doc => doc.id === document.id);
    if (!match) return;
    match.downloadCount += 1;
    setResult(documents);
  };

  useEffect(() => {
    void fetchDocuments(options);
  }, [payeeCode]);

  return {
    loading,
    result,
    error,
    availableTypes,
    availableYears,
    incrementDownloadCount,
    refetch: fetchDocuments
  };
};

export default useFitDocuments;
