import React from "react";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import { ReactComponent as Gear } from "../../icons/gear.svg";

import QuickFilterButton from "../../components/shared/QuickFilterButton";
import Button, {
  ButtonIconPosition,
  ButtonType
} from "../../components/shared/UiButton";
import CheckBoxFiled from "../../components/shared/UiCheckBox";
import ResponseAlert from "../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../components/shared/UiResponseAlert/ResponseAlert";
import UiSelect from "../../components/shared/UiSelect";
import TextAreaField from "../../components/shared/UiTextArea";
import TextField from "../../components/shared/UiTextField";

const Components = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: "onChange" });
  const onSubmit = (data: Record<string, unknown>) => console.log(data);
  const onClick = (type: string) => {
    alert(type);
  };
  const onChange = (e: any) => {
    console.log(e);
  };
  return (
    <div style={{ background: "black", padding: "2rem", marginTop: "1.5rem" }}>
      {/*<Loader />*/}
      <h1 style={{ color: "white" }}>Shared Components</h1>
      <div>
        <h4 style={{ color: "white" }}>Select</h4>
        {/*<UiSelect*/}
        {/*  placeholder="Select option..."*/}
        {/*  isClearable*/}
        {/*  options={[*/}
        {/*    { value: "Butter Pecan", label: "Butter Pecan" },*/}
        {/*    { value: "Chocolate", label: "Chocolate" },*/}
        {/*    { value: "Cookie Dough", label: "Cookie Dough" },*/}
        {/*    { value: "Cookies N' Cream", label: "Cookies N' Cream" },*/}
        {/*    { value: "Mint Chocolate Chip", label: "Mint Chocolate Chip" },*/}
        {/*    { value: "Moose Tracks", label: "Moose Tracks" },*/}
        {/*    { value: "Neapolitan", label: "Neapolitan" }*/}
        {/*  ]}*/}
        {/*/>*/}
      </div>
      <br />
      <hr />
      <div>
        <h4 style={{ color: "white" }}>Buttons</h4>
        <QuickFilterButton title={"Approved"} count={50} active />
        <br />
        <QuickFilterButton title={"Rejected "} count={50} />
        <h5>Default Button</h5>
        <div className="d-flex">
          <Button
            variant={ButtonType.Default}
            text="Default Button"
            className="mr-1"
            onClick={() => onClick("Default Button")}
          />
          <Button
            variant={ButtonType.Default}
            text="Default Button Disabled"
            className="mr-1"
            disabled
            loading={true}
          />
        </div>
        <br />
        <br />
        <div className="d-flex">
          <Button
            className="mr-1"
            variant={ButtonType.Default}
            text="Default Button Icon Right"
            iconPosition={ButtonIconPosition.IconRight}
            onClick={() => onClick("Default Button Icon Right")}
          >
            <Gear className="btn_icon" />
          </Button>
          <Button
            className="mr-1"
            variant={ButtonType.Default}
            text="Default Button Icon Left"
            iconPosition={ButtonIconPosition.IconLeft}
            onClick={() => onClick("Default Button Icon Left")}
          >
            <Gear className="btn_icon" />
          </Button>
          <Button
            className="mr-1"
            variant={ButtonType.Default}
            text="Default Button Icon Left"
            iconPosition={ButtonIconPosition.IconLeft}
            disabled
          >
            <Gear className="btn_icon" />
          </Button>
        </div>
        <br />
        <br />
        <h5>Primary Button</h5>
        <div className="d-flex">
          <Button
            variant={ButtonType.Primary}
            text="Primary Button"
            className="mr-1"
            onClick={() => onClick("Primary Button")}
          />
          <Button
            variant={ButtonType.Primary}
            text="Primary Button Disabled"
            className="mr-1"
            disabled
            loading={true}
          />
        </div>
        <br />
        <br />
        <div className="d-flex">
          <Button
            className="mr-1"
            variant={ButtonType.Primary}
            text="Primary Button Icon Right"
            iconPosition={ButtonIconPosition.IconRight}
            onClick={() => onClick("Primary Button Icon Right")}
          >
            <Gear className="btn_icon" />
          </Button>
          <Button
            className="mr-1"
            variant={ButtonType.Primary}
            text="Primary Button Icon Left"
            iconPosition={ButtonIconPosition.IconLeft}
            onClick={() => onClick("Primary Button Icon Left")}
          >
            <Gear className="btn_icon" />
          </Button>
          <Button
            className="mr-1"
            variant={ButtonType.Primary}
            text="Primary Button Icon Left"
            iconPosition={ButtonIconPosition.IconLeft}
            disabled
          >
            <Gear className="btn_icon" />
          </Button>
        </div>
        <br />
        <br />
        <h5>Text Button</h5>
        <div className="d-flex">
          <Button
            variant={ButtonType.Text}
            text="Text Button"
            className="mr-1"
            onClick={() => onClick("Text Button")}
          />
          <Button
            variant={ButtonType.Text}
            text="Text Button Disabled"
            className="mr-1"
            disabled
            loading={true}
          />
        </div>
        <br />
        <br />
        <div className="d-flex">
          <Button
            className="mr-1"
            variant={ButtonType.Text}
            text="Text Button Icon Right"
            iconPosition={ButtonIconPosition.IconRight}
            onClick={() => onClick("Text Button Icon Right")}
          >
            <Calendar className="btn_icon" />
          </Button>
          <Button
            className="mr-1"
            variant={ButtonType.Text}
            text="Text Button Icon Left"
            iconPosition={ButtonIconPosition.IconLeft}
            onClick={() => onClick("Text Button Icon Left")}
          >
            <Calendar className="btn_icon" />
          </Button>
          <Button
            className="mr-1"
            variant={ButtonType.Text}
            text="Text Button Icon Left"
            iconPosition={ButtonIconPosition.IconLeft}
            disabled
          >
            <Calendar className="btn_icon" />
          </Button>
        </div>
      </div>
      <br />
      <hr />
      <div>
        <h4 style={{ color: "white" }}>Inputs</h4>
        <div className="d-flex">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="select"
              control={control}
              render={({ field }) => {
                return (
                  <UiSelect
                    {...field}
                    label="Select"
                    placeholder=""
                    isClearable
                    className="mb-4"
                    errors={errors}
                    ref={field.ref}
                    options={[
                      { value: "Butter Pecan", label: "Butter Pecan" },
                      { value: "Chocolate", label: "Chocolate" },
                      { value: "Cookie Dough", label: "Cookie Dough" },
                      { value: "Cookies N' Cream", label: "Cookies N' Cream" },
                      {
                        value: "Mint Chocolate Chip",
                        label: "Mint Chocolate Chip"
                      },
                      { value: "Moose Tracks", label: "Moose Tracks" },
                      { value: "Neapolitan", label: "Neapolitan" }
                    ]}
                    isReservedErrorSpace
                  />
                );
              }}
              rules={{ required: "Required Select Field" }}
            />
            <TextField
              label="Name"
              type="text"
              {...register("name", { required: "Field is required" })}
              className="mb-4"
              errors={errors}
              isReservedErrorSpace
            />
            <TextField
              label="Email"
              type="email"
              className="mb-4"
              errors={errors}
              {...register("email", {
                required: "Field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address"
                }
              })}
              isReservedErrorSpace
            />
            <CheckBoxFiled
              className="mb-4 d-block"
              errors={errors}
              label="Checkbox label"
              {...register("checkbox", {
                required: "Field is required",
                onChange: (e: any) => onChange(e)
              })}
              isReservedErrorSpace
            />
            <TextAreaField
              errors={errors}
              label="Text Area"
              {...register("textarea", { required: "Field is required" })}
              isReservedErrorSpace
            />
            <Button
              variant={ButtonType.Default}
              text="SUBMIT"
              onClick={() => onSubmit}
            />
          </form>
        </div>
        <br />
        <br />
        <hr />
        <h4 style={{ color: "white" }}>Alerts</h4>
        <div className="d-flex">
          <div style={{ width: "21.5rem" }}>
            <ResponseAlert
              type={ResponseAlertType.Error}
              className="mb-4"
              onClick={() => console.log("clicked")}
            >
              <div>
                Oops, the password you entered is incorrect. We can help you
              </div>
            </ResponseAlert>
            <ResponseAlert
              type={ResponseAlertType.Success}
              className="mb-4"
              onClick={() => console.log("clicked")}
            >
              <div>
                Oops, the password you entered is incorrect. We can help you
              </div>
            </ResponseAlert>
            <ResponseAlert
              type={ResponseAlertType.Warning}
              className="mb-4"
              onClick={() => console.log("clicked")}
            >
              <div>
                Oops, the password you entered is incorrect. We can help you
              </div>
            </ResponseAlert>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Components;
