/**
 * Corrects url in case it's potenial incorrect (passed without protocol)
 * @param url Original url
 * @returns Correct url
 */
export const correctUrl = (url: string): string => {
  if (!url) {
    return "";
  }
  if (url.startsWith("/")) {
    return url; // Relative link, don't change anything.
  }
  return new RegExp(/^(ftp|https?|mailto|tel):/i).test(url)
    ? url
    : `https://${url}`;
};

/**
 * Converts URL address to relative one
 * @param url URL address
 * @returns Relative URL address
 */
export const toRelativeUrl = (url: string): string => {
  if (!url || url.startsWith("/")) {
    return url;
  }
  const urlObj = new URL(url);
  return urlObj.toString().substring(urlObj.origin.length);
};

/**
 * Adds source parameter to URL address and returns relative url.
 * If the source is not passed, there will be a try to resolve it from the window.location.href.
 * @param url URL address
 * @param source Source URL which will be added as a parameter to URL address
 * @returns Relative URL address
 */
export const withSourceParam = (url: string, source?: string): string => {
  const sourceParam =
    source ?? new URL(window.location.href).searchParams.get("source");
  const relativeSource = sourceParam && toRelativeUrl(sourceParam);
  if (!relativeSource) {
    return url;
  }
  const urlObj = new URL(
    url,
    url.startsWith("/") ? window.location.origin : undefined
  );
  urlObj.searchParams.set("source", relativeSource);
  return toRelativeUrl(urlObj.toString());
};
