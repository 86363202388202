import React from "react";
import { useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import styles from "./LoginDebug.module.scss";

import Button from "../../../components/shared/UiButton";
import {
  profileBeSelector,
  profileSelector
} from "../../../store/appStatic/selectors";

const LoginDebug = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const profile = useSelector(profileSelector);
  const profileBe = useSelector(profileBeSelector);

  return (
    <div className={styles.loginDebug}>
      <h3>Logged in user:</h3>
      <pre>{JSON.stringify(profile, undefined, 2)}</pre>
      {profileBe && (
        <>
          {" "}
          <h3>User profile from BE:</h3>
          <pre>{JSON.stringify(profileBe, undefined, 2)}</pre>
        </>
      )}
      <h3>ID Token:</h3>
      <div className={styles.id}>{authState?.idToken?.idToken}</div>
      <h3>Access Token:</h3>
      <div className={styles.id}>{authState?.accessToken?.accessToken}</div>
      <Button onClick={async () => await oktaAuth.signOut()}>LOGOUT</Button>
    </div>
  );
};

export default LoginDebug;
