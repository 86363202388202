import axios from "axios";

import { oktaAuth } from "../lib/oktaAuth";

/**
 * Main axios instance
 */
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});
axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers["X-Email"] =
    localStorage.getItem("impersonateEmail") ??
    localStorage.getItem("profileEmail");
  return config;
});
