import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./CurrentPeriod.module.scss";

import Condition from "../../../../components/shared/Condition";
import FormattedCurrency from "../../../../components/shared/FormattedCurrency";
import PageHeader from "../../../../components/shared/PageHeader";
import { convertToIntlLocale } from "../../../../utils/format";
import { DATE_FORMAT, DATE_PERIOD_FORMAT } from "../../constants/date";
import CurrentPeriodInfoItem from "../CurrentPeriodInfoItem";
import { CurrentPeriodProps } from "./CurrentPeriod.types";

/**
 * Renders current period widget
 * @param props React component properties
 * @returns JSX element
 */
const CurrentPeriod = (props: CurrentPeriodProps): JSX.Element => {
  const { i18n, t } = useTranslation("translation");

  const { ledger } = props;
  const locale = convertToIntlLocale(i18n.language);
  const payment = ledger?.currentPeriodOverview?.royaltiesPayment;

  const periodStart =
    ledger?.currentPeriodOverview?.startDate &&
    DateTime.fromISO(ledger.currentPeriodOverview.startDate)
      .setLocale(locale)
      .toFormat(DATE_PERIOD_FORMAT);

  const periodEnd =
    ledger?.currentPeriodOverview?.endDate &&
    DateTime.fromISO(ledger.currentPeriodOverview.endDate)
      .setLocale(locale)
      .toFormat(DATE_PERIOD_FORMAT);

  const isNextPaymentPassed =
    !!ledger?.currentPeriodOverview?.nextStatementAvailableDate &&
    // Use start of the day to compare dates without hours and minutes
    DateTime.local().startOf("day") >
      DateTime.fromISO(ledger.currentPeriodOverview.nextStatementAvailableDate);

  const nextPayment =
    ledger?.currentPeriodOverview?.nextStatementAvailableDate &&
    DateTime.fromISO(ledger.currentPeriodOverview.nextStatementAvailableDate)
      .setLocale(locale)
      .toFormat(DATE_FORMAT);

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.header}>
        <PageHeader className={styles.h2} variant="h2">
          {t("home.currentPeriod")}
        </PageHeader>
        {periodStart && periodEnd && (
          <div className={styles.period}>{`${periodStart} - ${periodEnd}`}</div>
        )}
      </div>
      <hr className={styles.hr} />
      <div className={styles.row}>
        <CurrentPeriodInfoItem
          label={t("ledger.currentBalance")}
          tooltip={t("home.currentBalanceTooltip")}
          value={
            <FormattedCurrency
              currency={ledger?.currency}
              value={ledger?.currentPeriodOverview?.currentBalance}
            />
          }
          variant="large"
        />
        <div className={styles.details}>
          <CurrentPeriodInfoItem
            label={t("home.advances")}
            tooltip={t("home.advancesTooltip")}
            value={
              <FormattedCurrency
                currency={ledger?.currency}
                value={ledger?.currentPeriodOverview?.advances}
              />
            }
            variant="small"
          />
          <CurrentPeriodInfoItem
            label={t("home.adjustments")}
            tooltip={t("home.adjustmentsTooltip")}
            value={
              <FormattedCurrency
                currency={ledger?.currency}
                value={ledger?.currentPeriodOverview?.adjustments}
              />
            }
            variant="small"
          />
          <CurrentPeriodInfoItem
            label={t("home.netEarnings")}
            tooltip={t("home.netEarningsTooltip")}
            value={
              <FormattedCurrency
                currency={ledger?.currency}
                value={ledger?.currentPeriodOverview?.netEarnings}
              />
            }
            variant="small"
          />
          {!!payment && (
            <CurrentPeriodInfoItem
              label={t("home.royaltiesPayment")}
              tooltip={t("home.royaltiesPaymentTooltip")}
              value={
                <FormattedCurrency
                  currency={ledger?.currency}
                  value={payment}
                />
              }
              variant="small"
            />
          )}
        </div>
      </div>
      <Condition
        condition={
          !!nextPayment &&
          !isNextPaymentPassed &&
          (ledger?.priorPeriodSummary?.closingBalance ?? 0) > 0
        }
        Truthy={
          <div className={styles.paymentScheduled}>
            {t("home.paymentScheduledOn", { DATE: nextPayment })}
          </div>
        }
      />
    </div>
  );
};

export default CurrentPeriod;
