import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./NoDataAnalytics.module.scss";
import { ReactComponent as ChartIcon } from "../../../icons/chart.svg";

const NoDataAnalytics = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.noDataAnalytics}>
        <ChartIcon className={styles.chartIcon} />
        <div className={styles.noDataAnalyticsText}>
          <div>{t("home.noDataToShowYet")}</div>
          <div className={styles.noDataAnalyticsDescription}>
            {t("common.noAnalyticsText")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataAnalytics;
