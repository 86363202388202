import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./Tabs.module.scss";

import TabItem from "./components/TabItem";
import { TabsProps } from "./types";

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const history = useHistory();
  return (
    <div className={styles.tabs}>
      {tabs.map(tab => {
        const key = tab.value + tab.label;
        const { label, slug } = tab;
        const active = history.location.pathname.includes(slug);
        const handleTabClick = (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          history.push(`/user-management/${slug}`);
        };

        return (
          <TabItem
            onClick={handleTabClick}
            label={label}
            active={active}
            key={key}
          />
        );
      })}
    </div>
  );
};

export default Tabs;
