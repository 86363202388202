import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ColDef } from "ag-grid-community";
import cn from "classnames";

import styles from "./Dashboard.module.scss";

import Chart from "../../components/shared/Chart";
import CountryWidget from "../../components/shared/CountryWidget";
import DataTable from "../../components/shared/DataTable";
import GridPlaceholderLoader from "../../components/shared/GridPlaceholder";
import { profileSelector } from "../../store/appStatic/selectors";
import { country, defs } from "./helper";

const Dashboard = () => {
  const profile = useSelector(profileSelector);
  const tableRef = useRef<any>();
  const combineChart = ({ data }: any) => {
    if (data) {
      return (
        <div className="d-flex items-center text-right">
          {data && (
            <>
              <Chart
                data={data.chart}
                chartContainerClassName={cn(styles.chart)}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  datasets: { line: { pointRadius: 0 } },
                  plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false }
                  },
                  scales: { x: { display: false }, y: { display: false } },
                  layout: { padding: 2 }
                }}
              />
              {/*<p*/}
              {/*  className="ml-2"*/}
              {/*  style={{ color: `${data.chart.datasets[0].borderColor}` }}*/}
              {/*>*/}
              {/*  {data.weekly_trend}*/}
              {/*</p>*/}
            </>
          )}
        </div>
      );
    } else {
      return <GridPlaceholderLoader />;
    }
  };
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "song_title", minWidth: 237, headerName: "SONG TITLE" },
    { field: "writer", headerName: "WRITER", minWidth: 250 },
    { field: "source", headerName: "SOURCE", minWidth: 150 },
    {
      field: "weekly_trend",
      headerName: "WEEKLY TREND"
      // cellRenderer: combineChart
    },
    { field: "amount_paid", headerName: "AMOUNT PAID", minWidth: 150 }
  ]);
  console.log(setColumnDefs);

  return (
    profile && (
      <div className={`container`}>
        <div className={styles.welcome}>
          HI, {profile.firstname} {profile.lastname}
        </div>
        <div className={cn(styles.table)}>
          <DataTable
            rowData={defs}
            columnDefs={columnDefs}
            themeClassName={cn(styles.table_dashboard)}
            setGridRef={tableRef}
            getGridData={
              tableRef.current && tableRef.current.api.setRowData(combineChart)
            }
          />
        </div>
        <div>
          <h5 className={cn(styles.widgets_title)}>Top 5 Markets by Revenue</h5>
          <div className={cn(styles.widgets)}>
            {country.map((el, index) => {
              return (
                <CountryWidget
                  country={el.country}
                  val={el.amount_paid}
                  key={index}
                  chartData={el.chart}
                  weekly_trend={el.weekly_trend}
                />
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};
export default Dashboard;
