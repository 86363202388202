import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./TopSourcesChart.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import { colors } from "../../../configs/constants/transaction";
import {
  sourcesLoadingSelector,
  top4SourceSelector
} from "../../../store/analytics/selectors";
import CurrencyRateShort from "../CurrencyRateShort";

const SourcesChart = () => {
  const history = useHistory();
  const top4Sources = useSelector(top4SourceSelector);
  const sourcesLoading = useSelector(sourcesLoadingSelector);

  const [anime, setAnime] = useState([]);

  useEffect(() => {
    if (!sourcesLoading && top4Sources) {
      const x: any = [];
      setTimeout(() => {
        top4Sources.forEach((el: any) => {
          x.push(el?.amount);
        });
        setAnime(x);
      }, 100);
    }
  }, [top4Sources, sourcesLoading]);

  if (!top4Sources) {
    return null;
  }
  const full = top4Sources[0]?.amount;

  return (
    <div>
      {top4Sources.map((el: any, i: any) => {
        return (
          <div key={i} className={styles.lineWrap}>
            <div className={styles.item}>
              <div
                className={cn(styles.info, {
                  [styles.innerPageInfo]: history.location.pathname.includes(
                    "topSources"
                  )
                })}
              >
                <p>{el.name}</p>
              </div>
              <div
                style={{
                  width: `${(el?.amount * 100) / full}%`,
                  backgroundColor: colors[i]
                }}
                className={cn(styles.line, { [styles.active]: anime[i] })}
              />
            </div>
            <div className={styles.wrapCount}>
              <p className={styles.count}>
                <CurrencyRateShort
                  id={`topSources_${i + 1}`}
                  rate={el?.amount}
                />
              </p>
              {!!el.percentage && (
                <p
                  className={cn(styles.percent, {
                    [styles.down]: el.direction !== "INCREMENT"
                  })}
                >
                  {`${el.percentage}%`}
                  {el.direction === "INCREMENT" ? (
                    <UpIcon className={styles.upIcon} />
                  ) : (
                    <DownIcon className={styles.downIcon} />
                  )}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SourcesChart;
