import { DealsTypes } from "./types";

const GetDealsAction = (id: string, history: any): any => ({
  type: DealsTypes.DEALS_REQUEST,
  payload: { id, history }
});
const GetLedgersAction = (payeeCode: string, history: any): any => ({
  type: DealsTypes.LEDGERS_REQUEST,
  payload: { payeeCode, history }
});

const GetClosedPeriodsAction = (payeeCode: string): any => ({
  type: DealsTypes.CLOSED_PERIODS_REQUEST,
  payload: { payeeCode }
});
const SetClosedPeriodsLoadingAction = (isLoading: boolean): any => ({
  type: DealsTypes.CLOSED_PERIODS_LOADING,
  payload: isLoading
});
const SetClosedPeriodsSuccessAction = (closedPeriods: any[]): any => ({
  type: DealsTypes.CLOSED_PERIODS_SUCCESS,
  payload: closedPeriods
});

const SetSelectedClosedPeriod = (selectedClosingPeriod: any): any => ({
  type: DealsTypes.SET_SELECTED_CLOSED_PERIOD,
  payload: selectedClosingPeriod
});

const GetPayeeCodeAction = (id: string): any => ({
  type: DealsTypes.PAYEECODE_REQUEST,
  payload: { id }
});

const GetPayeeCodeSuccessAction = (data: any): any => ({
  type: DealsTypes.PAYEECODE_SUCCESS,
  payload: { data }
});
const GetDealsSuccessAction = ({ data }: any): any => ({
  type: DealsTypes.DEALS_SUCCESS,
  payload: { data }
});

const GetLedgersSuccessAction = ({ ledgers }: any): any => ({
  type: DealsTypes.LEDGERS_SUCCESS,
  payload: { ledgers }
});

const SetDealsLoading = (isLoading: boolean): any => ({
  type: DealsTypes.DEALS_LOADING,
  payload: isLoading
});

const SetLedgersLoading = (isLoading: boolean): any => ({
  type: DealsTypes.LEDGERS_LOADING,
  payload: isLoading
});

const SetPayeeCodeLoading = (isLoading: boolean): any => ({
  type: DealsTypes.PAYEECODE_LOADING,
  payload: isLoading
});

const SetOpenModal = ({
  isOpen,
  type,
  data
}: {
  isOpen: boolean;
  type: string;
  data?: { value: string; label: string };
}): any => ({
  type: DealsTypes.SET_OPEN_MODAL,
  payload: { isOpen, type, data }
});

const SetLedger = ({ data }: any): any => ({
  type: DealsTypes.SET_LEDGER,
  payload: { data }
});

const resetDealsState = (): any => ({
  type: DealsTypes.RESET_DEALS_STATE
});

const setQueryOptionsDeals = (queryOptions: any): any => ({
  type: DealsTypes.SET_QUERY_OPTIONS_DEALS,
  payload: queryOptions
});

const setSongsQueryOptionsDeals = (queryOptions: any): any => ({
  type: DealsTypes.SET_SONGS_QUERY_OPTIONS,
  payload: queryOptions
});

const worksLoading = (load: boolean): any => ({
  type: DealsTypes.GET_WORKS_LOADING,
  payload: load
});

const getWorks = (requestData: any): any => ({
  type: DealsTypes.GET_WORKS,
  payload: requestData
});

const getWorksSuccess = (data: any): any => ({
  type: DealsTypes.GET_WORKS_SUCCESS,
  payload: data
});

const resetWorksList = (): any => ({
  type: DealsTypes.RESET_WORKS_LIST
});

const setWorksError = (error: any): any => ({
  type: DealsTypes.SET_WORKS_ERROR,
  payload: error
});

export {
  GetClosedPeriodsAction,
  GetDealsAction,
  GetDealsSuccessAction,
  GetLedgersAction,
  GetLedgersSuccessAction,
  GetPayeeCodeAction,
  GetPayeeCodeSuccessAction,
  getWorks,
  getWorksSuccess,
  resetDealsState,
  resetWorksList,
  SetClosedPeriodsLoadingAction,
  SetClosedPeriodsSuccessAction,
  SetDealsLoading,
  SetLedger,
  SetLedgersLoading,
  SetOpenModal,
  SetPayeeCodeLoading,
  setQueryOptionsDeals,
  SetSelectedClosedPeriod,
  setSongsQueryOptionsDeals,
  setWorksError,
  worksLoading
};
