import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";

import styles from "./Pagination.module.scss";
import { ReactComponent as Arrow } from "../../../icons/arrow.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";

import Condition from "../../shared/Condition";
import UiDropdown from "../UiDropdown";

type PaginationNumbersType = {
  changePageRequest: any;
  onChangeSize?: any;
  totalAmount: any;
  currentPage: any;
  className?: string;
  isWhite?: boolean;
  showSizeSelect?: boolean;
  size?: number;
};

const PaginationNumbers: React.FC<PaginationNumbersType> = ({
  changePageRequest,
  totalAmount,
  onChangeSize,
  size = 10,
  currentPage,
  showSizeSelect = true,
  isWhite = false,
  className
}) => {
  const md = useMediaQuery({ maxWidth: 567 });

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [value, setValue] = useState<any>({
    value: "10",
    code: <p className={styles.value}>{`10 / Page`}</p>,
    label: (
      <p className={styles.checkIconWrap}>
        <p className={styles.check}>
          <CheckIcon />
        </p>

        <p className={styles.value}>{`10 / Page`}</p>
      </p>
    )
  });
  const changePage = ({ currentTarget: { name: newPage } }: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    changePageRequest(+newPage);
  };
  const numbersWithJumps = () => (
    <>
      {currentPage > 1 && (
        <button
          type="button"
          name={"0"}
          onClick={changePage}
          className={styles.pageNumbers}
        >
          1
        </button>
      )}
      {currentPage > 3 && dimensions.width > 750 && (
        <button
          type="button"
          name={`${currentPage - 3}`}
          onClick={changePage}
          className={cn(styles.pageNumbers, styles.pageNumbers_dotes)}
        >
          . . .
        </button>
      )}
      {currentPage >= 3 && dimensions.width > 750 && (
        <button
          type="button"
          name={`${currentPage - 2}`}
          onClick={changePage}
          className={styles.pageNumbers}
        >
          {currentPage - 1}
        </button>
      )}
      <button
        type="button"
        name={`${currentPage - 1}`}
        onClick={changePage}
        className={cn(styles.pageNumbers, styles.selectedPage)}
      >
        {currentPage}
      </button>
      {totalAmount - currentPage >= 1 && dimensions.width > 750 && (
        <button
          type="button"
          name={currentPage}
          onClick={changePage}
          className={styles.pageNumbers}
        >
          {currentPage + 1}
        </button>
      )}
      {totalAmount - currentPage > 2 && (
        <button
          type="button"
          name={currentPage + 1}
          onClick={changePage}
          className={cn(styles.pageNumbers, styles.pageNumbers_dotes)}
        >
          . . .
        </button>
      )}
      {currentPage < totalAmount - 1 && (
        <button
          type="button"
          name={`${totalAmount - 1}`}
          onClick={changePage}
          className={styles.pageNumbers}
        >
          {totalAmount}
        </button>
      )}
    </>
  );

  const paginationButtons = () => {
    if (totalAmount >= 7 || (md && totalAmount >= 4)) {
      return numbersWithJumps();
    }
    const newPages = [];

    for (let i = 1; i <= totalAmount; ++i) {
      newPages.push(i);
    }
    return newPages.map(pageNumber => (
      <button
        type="button"
        name={`${pageNumber - 1}`}
        onClick={changePage}
        className={cn(styles.pageNumbers, {
          [styles.selectedPage]: pageNumber === currentPage
        })}
        key={pageNumber}
      >
        {pageNumber}
      </button>
    ));
  };

  const options = useMemo(() => {
    return [
      {
        label: (
          <p className={styles.checkIconWrap}>
            <p className={styles.check}>
              {value.value === "10" && <CheckIcon />}
            </p>

            <p className={styles.value}>{`10 / Page`}</p>
          </p>
        ),
        value: "10",
        code: <p className={styles.value}>{`10 / Page`}</p>
      },
      {
        label: (
          <p className={styles.checkIconWrap}>
            <p className={styles.check}>
              {value.value === "20" && <CheckIcon />}
            </p>

            <p className={styles.value}>{`20 / Page`}</p>
          </p>
        ),
        value: "20",
        code: <p className={styles.value}>{`20 / Page`}</p>
      },
      {
        label: (
          <p className={styles.checkIconWrap}>
            <p className={styles.check}>
              {value.value === "50" && <CheckIcon />}
            </p>

            <p className={styles.value}>{`50 / Page`}</p>
          </p>
        ),
        value: "50",
        code: <p className={styles.value}>{`50 / Page`}</p>
      }
    ];
  }, [value, localStorage?.getItem("i18nextLng")]);

  useEffect(() => {
    if (size) {
      setValue(options.find(el => +el.value === size));
    }
  }, [size, localStorage?.getItem("i18nextLng")]);

  return (
    <>
      <Condition
        condition={totalAmount}
        Truthy={
          <div
            className={cn(styles.paginationWrap, className, {
              [styles.white]: isWhite
            })}
          >
            <button
              type="button"
              className={cn(styles.arrows, {
                [styles.disabled]: currentPage <= 1
              })}
              name={`${currentPage - 2}`}
              disabled={currentPage <= 1}
              onClick={changePage}
            >
              <Arrow
                className={cn(styles.arrawLeft, {
                  [styles.whiteArrow]: isWhite
                })}
              />
            </button>
            {paginationButtons()}
            <button
              type="button"
              className={cn(styles.arrows, {
                [styles.disabled]: totalAmount === currentPage
              })}
              name={`${currentPage}`}
              disabled={totalAmount === currentPage}
              onClick={changePage}
            >
              <Arrow
                className={cn(styles.arraw, { [styles.whiteArrow]: isWhite })}
              />
            </button>
            {showSizeSelect && (
              <div>
                <UiDropdown
                  isFilter
                  options={options}
                  className={styles.select}
                  isDropTop={false}
                  value={value}
                  onChange={(el: any) => {
                    onChangeSize(el);
                    setValue(el);
                  }}
                  menuClassName={styles.paginationMenu}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default PaginationNumbers;
