import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import axios from "axios";
import cn from "classnames";

import styles from "./Registration.module.scss";
import { ReactComponent as GlobeIcon } from "../../../icons/globus.svg";
import { ReactComponent as LabelIcon } from "../../../icons/label.svg";

import Button, { ButtonType } from "../../../components/shared/UiButton";
import ResponseAlert from "../../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../../components/shared/UiResponseAlert/ResponseAlert";
import UiSelect from "../../../components/shared/UiSelect";
import TextField from "../../../components/shared/UiTextField";
import { countrySelector } from "../../../store/appStatic/selectors";
import { textParams } from "../../../utils";
import { getErrorDetails } from "../../../utils/error";
import { trimObject } from "../../../utils/objects";
import {
  emailConfirmValidaton,
  emailValidaton,
  requiredValidaton
} from "../../../utils/validation";
import SuccessModal from "./SuccessModal";

const Registration = () => {
  const { t } = useTranslation("translation");
  const { control, handleSubmit } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    phoneNumber?: string;
    countryCode: { label: string; value: string };
  }>({ mode: "all" });
  const [error, setError] = useState<null | string>(null);
  const [isCreated, setIsCreated] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const history = useHistory();
  const countries = useSelector(countrySelector);

  const email = useWatch({ control, name: "email" });
  const emailExistsError = t(`registrationPage.errorEmailAlreadyExist`);

  const validateEmail = emailValidaton(t("inputs.invalidEmail"));
  const validateEmailConfirmation = emailConfirmValidaton(
    t(`registrationPage.errorEmailNotMatching`),
    email
  );
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    phoneNumber?: string;
    countryCode: { label: string; value: string };
  }): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}api/users/external`,
        trimObject({
          countryCode: data?.countryCode?.value,
          firstName: data.firstName,
          email: data.email,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          language: localStorage.getItem("i18nextLng")
        })
      );
      if (res.status) {
        setIsCreated(true);
      }
    } catch (e) {
      const { status } = getErrorDetails(e);
      status === 412
        ? setError(emailExistsError)
        : setError(t(`resetPasswordPage.errorMessageUnknown`));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(mobile);
  }, [isMobile]);

  return isCreated || (error && error !== emailExistsError) ? (
    <SuccessModal error={error} />
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.registration}>
        <h3>{t(`registrationPage.createNewAccount`)}</h3>
        {error && (
          <ResponseAlert
            type={ResponseAlertType.Error}
            onClick={() => setError(null)}
            className={styles.error}
          >
            <div className={styles.errorsMessage}>
              <p>{textParams(error)[0]}</p>
              <p className={styles.links}>
                <NavLink to={"/forgot-password"} className={styles.account}>
                  {textParams(error)[1]}
                </NavLink>{" "}
                {textParams(error)[2]}
                <NavLink to={"/login"} className={styles.account}>
                  {textParams(error)[3]}
                </NavLink>{" "}
                {textParams(error)[4]}
              </p>
            </div>
          </ResponseAlert>
        )}
        <div className={styles.inputsWrap}>
          <Controller
            control={control}
            name="firstName"
            render={({ field, formState }) => (
              <TextField
                {...field}
                className={styles.input}
                errors={formState.errors}
                label={`${t("inputs.firstName")}*`}
                type="text"
                isReservedErrorSpace
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field, formState }) => (
              <TextField
                {...field}
                className={styles.input}
                errors={formState.errors}
                label={`${t("inputs.lastName")}*`}
                type="text"
                isReservedErrorSpace
              />
            )}
            rules={{ validate: validateRequired }}
          />
        </div>
        <div className={styles.inputsWrap}>
          <Controller
            name={"countryCode"}
            control={control}
            render={({ field, formState }) => (
              <UiSelect
                {...field}
                options={countries}
                label={`${t("inputs.dealSigningTerritories")}*`}
                labelIcon={
                  <Tooltip title={t(`registrationPage.locationTooltip`)}>
                    <span className={styles.labelIcon_wrap}>
                      <LabelIcon className={styles.labelIcon} />
                    </span>
                  </Tooltip>
                }
                placeholder={`--${t("inputs.selectorPlaceholder")}--`}
                errors={formState.errors}
                className={styles.input}
                rightIcon={<GlobeIcon />}
                isReservedErrorSpace
              />
            )}
            rules={{ validate: validateRequired }}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value } }) => (
              <label htmlFor="phoneNumber">
                <div className={cn(styles.phoneInput_head)}>
                  <div className={cn(styles.phoneInput_label)}>
                    {t(`inputs.phoneNumber`)}
                  </div>
                </div>
                <div className={cn(styles.phoneInput_wrapper)}>
                  <PhoneInput
                    className={cn(styles.phoneInput)}
                    id="phoneNumber"
                    onChange={onChange}
                    value={value}
                  />
                </div>
              </label>
            )}
          />
        </div>
        <div className={styles.inputsWrap}>
          <Controller
            control={control}
            name="email"
            render={({ field, formState }) => (
              <TextField
                {...field}
                className={styles.input}
                errors={formState.errors}
                label={`${t("inputs.email")}*`}
                placeholder={"user@example.com"}
                type="text"
                isReservedErrorSpace
              />
            )}
            rules={{ validate: v => validateRequired(v) ?? validateEmail(v) }}
          />
          <Controller
            control={control}
            name="confirmEmail"
            render={({ field, formState }) => (
              <TextField
                {...field}
                className={styles.input}
                errors={formState.errors}
                label={`${t("inputs.confirmEmail")}*`}
                placeholder={"user@example.com"}
                type="text"
                isReservedErrorSpace
              />
            )}
            rules={{
              validate: v =>
                validateRequired(v) ??
                validateEmail(v) ??
                validateEmailConfirmation(v)
            }}
          />
        </div>
        <div className={styles.buttonsWrap}>
          <span className={styles.link}>
            {t(`registrationPage.alreadyHaveAnAccount`)}{" "}
            <NavLink to={"/login"} className={styles.account}>
              {t(`registrationPage.loginLink`)}
            </NavLink>
          </span>
          <div className={styles.buttons}>
            <Button
              variant={ButtonType.Text}
              type={"button"}
              onClick={() => {
                if (history.length > 2) {
                  history.goBack();
                } else {
                  history.push("/login");
                }
              }}
            >
              {t(`buttons.cancel`)}
            </Button>
            <Button
              variant={ButtonType.Primary}
              type={"submit"}
              disabled={isLoading}
              loading={isLoading}
            >
              {t(`buttons.create`)}
            </Button>
          </div>
        </div>
        <div className={styles.accountLink}>
          {textParams(t(`registrationPage.info`))[0]}
          <NavLink to={"/terms-of-use"} className={styles.account}>
            {textParams(t(`registrationPage.info`))[1]}
          </NavLink>{" "}
          {textParams(t(`registrationPage.info`))[2]}
          <NavLink to={"/privacy-policy"} className={styles.account}>
            {textParams(t(`registrationPage.info`))[3]}
          </NavLink>
        </div>
      </form>
    </>
  );
};

export default Registration;
