import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import cn from "classnames";

import styles from "./PayeeAccountsModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as Cursor } from "../../../../icons/dark_cursor.svg";

import { getSortedData } from "../../../../configs/utils";
import { useActions } from "../../../../hooks/useActions";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";
import HeaderComponent from "../../HeaderComponent/HeaderComponent";
import PaginationNumbers from "../../Pagination";
import SearchInput from "../../SearchInput";

const PayeeAccountsModal = () => {
  const { t } = useTranslation("translation");
  const md = useMediaQuery({ maxWidth: 1024 });
  const mb = useMediaQuery({ maxWidth: 570 });
  const history = useHistory();
  const tableRef = useRef<any>();
  const modal = useSelector(dealsModalSelector);
  const [currentList, setCurrentList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [paginateData, setPaginateData] = useState<any>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const { SetOpenModal, setPayeeCodeAction } = useActions();
  const [sortBy, setSortBy] = useState<any>({
    sort: "payeeAccount",
    order: false
  });

  useEffect(() => {
    if (currentList?.length) {
      const sortedData = getSortedData(currentList, sortBy);
      setCurrentList(sortedData);
    }
  }, [currentList, sortBy]);
  useEffect(() => {
    setCurrentList(
      modal?.data?.payeeAccounts?.map((el: any, index: number) => ({
        ...el,
        id: index + 1
      }))
    );
  }, [modal?.data?.payeeAccounts]);

  const toggleModal = useCallback(() => {
    // setCurrentList(modal?.data?.payeeAccounts);
    setPageIndex(0);
    SetOpenModal({ isOpen: false, type: "" });
  }, [SetOpenModal]);

  const handleRowClick = useCallback(
    e => {
      setPayeeCodeAction(e.data.payeeCode);
      toggleModal();
      history.push(`home`);
    },
    [history, toggleModal]
  );

  const codes: any = md
    ? []
    : [
        {
          field: "payeeCode",
          headerName: t(`myAccountPage.payeeCode`),
          headerComponent: () => (
            <span
              className="ag-header-cell-text"
              title={t(`myAccountPage.payeeCode`)}
            >
              {t(`myAccountPage.payeeCode`)}
            </span>
          ),
          sortable: false,
          flex: 1,
          suppressMovable: true,
          cellRenderer: (props: ICellRendererParams) => {
            if (props?.value !== undefined) {
              return <div className={styles.value}>{props.value}</div>;
            }
          }
        }
      ];
  const mobile: any = mb
    ? []
    : [
        {
          field: "test",
          headerName: t(`common.statementFrequency`),
          sortable: false,
          flex: 1,
          suppressMovable: true,
          headerComponent: () => (
            <span
              className="ag-header-cell-text"
              title={t(`common.statementFrequency`)}
            >
              {t(`common.statementFrequency`)}
            </span>
          ),
          cellRenderer: (props: ICellRendererParams) => {
            let name = "-";
            if (props.data.accountingPeriod === 3) {
              name = t(`ledger.quarter`);
            }
            if (props.data.accountingPeriod === 6) {
              name = t(`ledger.semiAnnual`);
            }
            return <div className={styles.value}>{name}</div>;
          }
        },
        {
          field: "settlementPeriod",
          headerName: t(`common.settlementPeriod`),
          sortable: false,
          flex: 0.8,
          suppressMovable: true,
          headerComponent: () => (
            <span
              className="ag-header-cell-text"
              title={t(`common.settlementPeriod`)}
            >
              {t(`common.settlementPeriod`)}
            </span>
          ),
          cellRenderer: (props: ICellRendererParams) => {
            if (props.value !== undefined) {
              return (
                <div className={styles.value}>
                  {props.value + t(`downloadStatement.days`)}
                </div>
              );
            }
          }
        },
        {
          field: "",
          headerName: "",
          flex: 1,
          width: 50,
          maxWidth: 43,
          suppressMovable: true,
          cellRenderer: () => {
            return (
              <div className={styles.value}>
                {<Cursor className={styles.cursor} />}
              </div>
            );
          }
        }
      ];

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: "#",
        unSortIcon: false,
        flex: 0.1,
        filter: false,
        suppressMovable: true,
        headerComponent: () => <div>#</div>,
        cellRenderer: (props: ICellRendererParams) => {
          if (props?.data?.id) {
            return <div className={styles.index}>{props?.data?.id}</div>;
          }
          return "";
        }
      },
      {
        field: "payeeAccount",
        flex: 1.2,
        filter: true,
        suppressMovable: true,
        headerComponent: () => (
          <HeaderComponent
            headerName={t(`myAccountPage.accountName`)}
            field={"payeeAccount"}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        ),
        cellRenderer: (props: ICellRendererParams) => {
          if (props?.value !== undefined) {
            return md ? (
              <div className={styles.value}>
                <p className={styles.accountTitle}>{props.value}</p>
                <p>{props.data.payeeCode}</p>
              </div>
            ) : (
              <div className={styles.nameTitle}>{props.value}</div>
            );
          }
        }
      },
      ...codes,
      ...mobile
    ],
    [sortBy, md, mb, t]
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      sortable: true,
      suppressMenu: true
    }),
    []
  );

  useEffect(() => {
    if (currentList?.length) {
      setPaginateData(
        currentList
          ?.map((el: any, index: any) => ({ ...el, id: index + 1 }))
          .slice(pageIndex * 5, (pageIndex + 1) * 5) || []
      );
    } else {
      setPaginateData([]);
    }
  }, [currentList, pageIndex, sortBy]);

  const onFilterTextBoxChanged = useCallback(
    e => {
      const filteredData = e.target.value
        ? modal?.data?.payeeAccounts?.filter(
            (account: any) =>
              account.payeeAccount
                .toUpperCase()
                .includes(e.target.value.toUpperCase()) ||
              account.payeeCode
                .toUpperCase()
                .includes(e.target.value.toUpperCase())
          )
        : modal?.data?.payeeAccounts;
      setCurrentList(filteredData);
      if (pageIndex > 0) {
        setPageIndex(0);
      }
      setSearchInputValue(e.target.value ? e.target.value : "");
    },
    [modal?.data?.payeeAccounts, pageIndex]
  );

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "payeeCodes"}
      onRequestClose={toggleModal}
      className={cn(styles.mymodal)}
      overlayClassName={styles.myoverlay}
    >
      <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
      <h2 className={styles.fullName}>{modal?.data?.fullName}</h2>
      {modal?.data?.payeeAccounts?.length > 5 && (
        <div className={cn(styles.table_filter_wrapper)}>
          <SearchInput
            theme="light"
            onInput={onFilterTextBoxChanged}
            placeholder={t(`myAccountPage.search`)}
            mobilePlaceholder={t(`myAccountPage.search`)}
            value={searchInputValue}
            onclickClearIcon={onFilterTextBoxChanged}
          />
        </div>
      )}
      <div className={cn(paginateData.length === 0 ? styles.table_nodata : "")}>
        <AgGridReact
          suppressCellSelection={true}
          suppressRowHoverHighlight={true}
          suppressContextMenu={true}
          rowClass={cn(styles.table_row)}
          headerHeight={46}
          ref={tableRef}
          masterDetail={true}
          className={cn("ag-theme-alpine", styles.header)}
          animateRows={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection={true}
          // rowData={modal?.data?.payeeAccounts}
          rowData={paginateData}
          rowSelection="multiple"
          overlayNoRowsTemplate={t(`common.noResult`)}
          suppressRowClickSelection={true}
          // detailCellRenderer={detailCellRenderer}
          rowHeight={46}
          detailRowHeight={46}
          domLayout="autoHeight"
          onRowClicked={el => handleRowClick(el)}
        />
      </div>
      <PaginationNumbers
        showSizeSelect={false}
        changePageRequest={(index: number) => {
          setPageIndex(index);
        }}
        totalAmount={Math.ceil(currentList?.length / 5)}
        currentPage={pageIndex + 1}
      />
    </Modal>
  );
};

export default PayeeAccountsModal;
