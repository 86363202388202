import React, { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import getSymbolFromCurrency from "currency-symbol-map";
import { v4 as uuidv4 } from "uuid";

import styles from "./TerritoriesBar.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import {
  barOptions,
  colors,
  readsColor,
  yellowColors
} from "../../../configs/constants/transaction";
import { setCurrency } from "../../../configs/utils";
import {
  territoriesLoadingSelector,
  territoriesTopSelector,
  top4TerritoriesSelector
} from "../../../store/analytics/selectors";
import ChartCard from "../ChartCard";
import CurrencyRateShort from "../CurrencyRateShort";

type TerritoriesBarType = {
  isInnerPage?: boolean;
};

const TerritoriesBar: React.FC<TerritoriesBarType> = ({
  isInnerPage = false
}) => {
  const { t } = useTranslation();
  const md = useMediaQuery({ maxWidth: 1023 });
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  const top4Territories = useSelector(top4TerritoriesSelector);
  const territoriesLoading = useSelector(territoriesLoadingSelector);
  const territoriesTop = useSelector(territoriesTopSelector);
  const [step, setStep] = useState({
    title: "",
    backgroundColor: ""
  });

  const handleHover = (e: any, v: any) => {
    if (v.length) {
      const backColor = v[0].element.options.backgroundColor;
      const isRed = readsColor.includes(backColor);
      const isYellow = yellowColors.includes(backColor);
      const isGrin = backColor === colors[2];
      const isBlue = backColor === colors[3];
      if (
        step.backgroundColor !== v[0].element.options.backgroundColor &&
        (colors.indexOf(v[0].element.options.backgroundColor) !== -1 ||
          isRed ||
          isYellow ||
          isGrin ||
          isBlue)
      ) {
        if (isYellow) {
          setStep({
            title: top4Territories[1].title,
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
        if (isRed) {
          setStep({
            title: top4Territories[0].title,
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
        if (isGrin) {
          setStep({
            title: top4Territories[2].title,
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
        if (isBlue) {
          setStep({
            title: top4Territories[3].title,
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
      }
    } else {
      if (step.backgroundColor) {
        setStep({ title: "", backgroundColor: "" });
      }
    }
  };

  const borderColor = useMemo(() => {
    const color = ["black", "black", "black", "black"];
    if (step.title) {
      const titles = top4Territories?.map((el: any) => el.title);
      const index = titles.indexOf(step.title);
      color[index] = "white";
    }
    return color;
  }, [step]);
  const data = {
    labels: [0, 1, 2, 3].map((e: any) =>
      top4Territories
        ? top4Territories[e]
          ? top4Territories[e].title
          : ""
        : null
    ),
    datasets: [
      {
        backgroundColor: colors,
        borderColor: borderColor,
        hoverBorderColor: ["white", "white", "white", "white"],
        borderWidth: 2,
        hoverBorderWidth: 2,
        barPercentage: 0.6,
        data: top4Territories?.map((o: any) => o.amount)
      }
    ]
  };

  if (isInnerPage) {
    return (
      <div className={styles.innerWrap}>
        <div className={styles.innerPageBar}>
          <Bar
            data={data}
            options={{
              ...(barOptions as any),
              scales: {
                ...barOptions.scales,
                y: {
                  ...barOptions.scales.y,
                  ticks: {
                    ...barOptions.scales.y.ticks,
                    callback: function(value) {
                      return setCurrency(
                        ledgers?.currency
                          ? getSymbolFromCurrency(ledgers?.currency)
                          : "",
                        value,
                        false
                      );
                    }
                  }
                }
              },
              onHover: handleHover
            }}
            height={md ? 200 : 300}
            className={styles.innerBar}
          />
        </div>
        <div>
          {top4Territories.map((el: any, i: number) => (
            <div
              key={el.amount}
              className={styles.info}
              onMouseEnter={() =>
                setStep({
                  title: el.title,
                  backgroundColor: colors[i]
                })
              }
              onMouseLeave={() =>
                setStep({
                  title: "",
                  backgroundColor: ""
                })
              }
              style={{
                border: `4px solid ${colors[i]}`,
                backgroundColor:
                  el.title === step.title ? step.backgroundColor : "inherit"
              }}
            >
              <div className={styles.index}>
                <p>{i + 1}.</p>
                <span>{el.title}</span>
              </div>
              <div className={styles.wrap}>
                <p
                  className={cn(styles.amount, {
                    [styles.noPercentage]: !el.percentage
                  })}
                >
                  <CurrencyRateShort id={`${uuidv4()}`} rate={el.amount} />
                </p>
                {!!el.percentage && (
                  <p
                    className={cn(styles.percent, {
                      [styles.isIncrement]: el.direction !== "INCREMENT",
                      [styles.hovered]: el.title === step.title
                    })}
                  >
                    {`${el.percentage}%`}
                    {el.direction === "INCREMENT" ? (
                      <UpIcon className={styles.upIcon} />
                    ) : (
                      <DownIcon className={styles.upIcon} />
                    )}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <ChartCard
      info={top4Territories?.length ? top4Territories : null}
      title={t(`analytics.topTerritories`)}
      totalTitle={t(`analytics.allTerritories`)}
      to={"territoriesBar"}
      isLoading={territoriesLoading}
      totals={territoriesTop}
      name="territoriesBar"
    >
      {!territoriesLoading && top4Territories?.length ? (
        <Bar
          data={data}
          options={{
            ...(barOptions as any),
            scales: {
              ...barOptions.scales,
              y: {
                ...barOptions.scales.y,
                ticks: {
                  ...barOptions.scales.y.ticks,
                  callback: function(value) {
                    return setCurrency(
                      getSymbolFromCurrency(ledgers?.currency),
                      value,
                      false
                    );
                  }
                }
              }
            },
            onHover: handleHover
          }}
          height={300}
          className={styles.barWrap}
        />
      ) : (
        ""
      )}
    </ChartCard>
  );
};

export default TerritoriesBar;
