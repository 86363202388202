import React, { useCallback } from "react";

import styles from "./ActivityHistory.module.scss";
import { ReactComponent as AdminIcon } from "../../../../../icons/admin_history.svg";
import { ReactComponent as UpdateIcon } from "../../../../../icons/globus.svg";
import { ReactComponent as LogIn } from "../../../../../icons/log_in.svg";
import { ReactComponent as UserInvitedIcon } from "../../../../../icons/user_invited.svg";
import { ReactComponent as UserDeactivatedIcon } from "../../../../../icons/user_lock.svg";
import { ReactComponent as UserAddedIcon } from "../../../../../icons/user_plus.svg";
import { ReactComponent as UserReactivatedIcon } from "../../../../../icons/user_reactivated.svg";
import { ReactComponent as UserRevokedIcon } from "../../../../../icons/user_revoked.svg";
import { ReactComponent as UserRoleIcon } from "../../../../../icons/user_role.svg";

import { getDateByUTCFormat } from "../../../../../utils";

type ActivityHistoryType = {
  activities: any[];
  createdAt: string;
};

const ActivityHistory: React.FC<ActivityHistoryType> = ({
  activities,
  createdAt
}) => {
  const getIconByAction = useCallback(history => {
    switch (history.action) {
      case "USER_LAST_LOGIN": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <LogIn className={styles.icons} />
            </span>

            <span className={styles.info}>{`User Login on ${getDateByUTCFormat(
              history.modifiedAt
            )}`}</span>
          </div>
        );
      }
      case "INTERNAL_USER_LOCATION_UPDATED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UpdateIcon className={styles.icons} />
            </span>

            <span
              className={styles.info}
            >{`Location update on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "INTERNAL_USER_ROLE_UPDATED_ADMIN": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <AdminIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Role: Admin on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "INTERNAL_USER_ROLE_UPDATED_SUPER_ADMIN": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserRoleIcon className={styles.iconsBig} />
            </span>
            <span
              className={styles.info}
            >{`Role: Super Admin on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "INTERNAL_USER_ADDED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserAddedIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Registered on ${getDateByUTCFormat(
              history.modifiedAt
            )}  by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "EXTERNAL_USER_REGISTERED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserAddedIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Registered on ${getDateByUTCFormat(
              history.modifiedAt
            )}  by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "INTERNAL_USER_DEACTIVATED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserDeactivatedIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Deactivated on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "EXTERNAL_USER_SUSPENDED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserDeactivatedIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Suspended on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "EXTERNAL_USER_REVOKED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserRevokedIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Revoked on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      case "EXTERNAL_USER_REACTIVATED": {
        return (
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserReactivatedIcon className={styles.iconsBig} />
            </span>
            <span className={styles.info}>{`Reactivated on ${getDateByUTCFormat(
              history.modifiedAt
            )} by ${history.modifiedByTitle}`}</span>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }, []);

  return (
    <div>
      <span className={styles.activityTitle}>User Activity</span>
      <div className={styles.rowWrap}>
        {activities?.map(history => {
          return (
            <div key={history.modifiedAt} className={styles.row}>
              {getIconByAction(history)}
            </div>
          );
        })}
        <div className={styles.row}>
          <div className={styles.historyWrap}>
            <span className={styles.iconsWrap}>
              <UserInvitedIcon className={styles.icons} />
            </span>
            <span className={styles.info}>{`Invited on ${getDateByUTCFormat(
              createdAt
            )} 
            `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityHistory;
