import React from "react";

import styles from "./Status.module.scss";

type StatusProps = {
  status: string;
};

const Status: React.FC<StatusProps> = ({ status }) => {
  if (status === "ManuallyEnded" || status === "Expired") {
    return (
      <>
        <p className={styles.statusBadge}>
          <span className={styles[status]}>
            {status === "ManuallyEnded" ? "Manually Ended" : status}
          </span>
        </p>
        <p className={styles.deletes}>Deletes in 24hrs</p>
      </>
    );
  }
  return (
    <p className={styles.statusBadge}>
      <span className={styles[status]}>{status}</span>
    </p>
  );
};

export default Status;
