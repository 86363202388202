import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

import { cleanupLocalStorage } from "../../../utils/localStorage";

const Logout = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    (async () => {
      cleanupLocalStorage();
      await oktaAuth.signOut();
    })();
  }, []);

  // Logout conformation could be implemented here if needed in the future.
  return null;
};

export default Logout;
