import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./Settings.module.scss";
import { ReactComponent as Leaf } from "../../icons/leaf.svg";

import BackButton from "../../components/shared/BackButton";
import Loader from "../../components/shared/Loading/Loader";
import LanguageModal from "../../components/shared/Modals/SetLanguageModal";
import Notification from "../../components/shared/Notification";
import PageHeader from "../../components/shared/PageHeader";
import UiSelect from "../../components/shared/UiSelect";
import { useActions } from "../../hooks/useActions";
import { axiosInstance } from "../../services/axios";
import {
  languageSelector,
  singleClientSelector
} from "../../store/appStatic/selectors";
import InfoItem from "./components/InfoItem";
import NotificationSettings from "./components/NotificationSettings";
import { SettingsItem } from "./Settings.types";

/**
 * Renders user settings page
 * @returns JSX element
 */
const Settings = (): JSX.Element => {
  const { i18n, t } = useTranslation("translation");

  const { SetOpenModal } = useActions();

  const { data } = useSelector(singleClientSelector);
  const languages = useSelector(languageSelector);

  const [
    isNotificationSettingsLoading,
    setIsNotificationSettingsLoading
  ] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [settings, setSettings] = useState<SettingsItem | null>(null);

  const language = useMemo(
    () =>
      languages?.find(l => l.value === settings?.preferableLanguage) ??
      languages?.[0],
    [languages, settings?.preferableLanguage]
  );

  /**
   * Handles language settings changes
   * @param newLanguage New language option
   */
  const onLanguageChange = async (newLanguage: {
    label: string;
    value: string;
  }): Promise<void> => {
    try {
      const response = await axiosInstance.put<SettingsItem>(
        `${process.env.REACT_APP_BASE_URI}api/users/${data?.profile?.id}/settings`,
        { preferableLanguage: newLanguage.value }
      );
      const newLanguageCode = response.data.preferableLanguage ?? "EN";
      if (localStorage.getItem("impersonateId")) {
        localStorage.setItem("impersonateLn", newLanguageCode);
      }
      await i18n.changeLanguage(newLanguageCode);
      SetOpenModal({ data: newLanguage, isOpen: true, type: "successLang" });
      setSettings(response.data);
    } catch {
      setNotification(t("common.unknownError"));
    }
  };

  /**
   * Handles notification settings changes
   * @param isChecked New state
   */
  const onNotificationSettingsChange = async (
    isChecked: boolean
  ): Promise<void> => {
    setIsNotificationSettingsLoading(true);
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_BASE_URI}api/users/${data?.profile?.id}/settings`,
        { emailNotification: isChecked }
      );
      setSettings(response.data);
    } catch {
      setNotification(t("common.unknownError"));
    } finally {
      setIsNotificationSettingsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.profile?.id) {
      axiosInstance
        .get<SettingsItem>(
          `${process.env.REACT_APP_BASE_URI}api/users/${data.profile.id}/settings`
        )
        .then(response => setSettings(response.data))
        .catch(() => setNotification(t("common.unknownError")));
    }
  }, [data?.profile?.id]);

  if (!data?.profile?.id || !settings) {
    return <Loader />;
  }

  return (
    <div className={classNames(styles.container, "container")}>
      <BackButton />
      <PageHeader variant="h1">{t("settings.title")}</PageHeader>
      <NotificationSettings
        isChecked={!!settings.emailNotification}
        isLoading={isNotificationSettingsLoading}
        onChange={isChecked => void onNotificationSettingsChange(isChecked)}
      />
      <InfoItem
        label={
          <div className={styles.languageContainer}>
            {t("settings.appLanguage")}
            <UiSelect
              className={styles.languageSelect}
              hasGlob
              onChange={option =>
                SetOpenModal({
                  // Assertion because of issues in UiSelect typings
                  data: (option as unknown) as { label: string; value: string },
                  isOpen: true,
                  type: "langModal"
                })
              }
              options={languages}
              value={language}
            />
          </div>
        }
      />
      <InfoItem
        label={t("settings.paperlessStatements")}
        value={
          <div className={styles.paperlessStatementsValue}>
            <Leaf />
            {t("settings.paperlessStatementsText")}
          </div>
        }
      />
      <LanguageModal onSubmit={onLanguageChange} />
      <Notification
        isOpen={!!notification}
        onClose={() => setNotification(null)}
      >
        {notification}
      </Notification>
    </div>
  );
};

export default Settings;
