import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./PriorPeriod.module.scss";
import { ReactComponent as InfoIcon } from "../../../../icons/info.svg";
import { ReactComponent as ChevronDown } from "../,,/../../../../icons/chevron_down_2.svg";

import FormattedCurrency from "../../../../components/shared/FormattedCurrency";
import PageHeader from "../../../../components/shared/PageHeader";
import Button, { ButtonType } from "../../../../components/shared/UiButton";
import { convertToIntlLocale } from "../../../../utils/format";
import { DATE_FORMAT, DATE_PERIOD_FORMAT } from "../../constants/date";
import PriorPeriodInfoItem from "../PriorPeriodInfoItem";
import { PriorPeriodProps } from "./PriorPeriod.types";

/**
 * Renders prior period widget
 * @param props React component properties
 * @returns JSX element or null of there is no prior period
 */
const PriorPeriod = (props: PriorPeriodProps): JSX.Element | null => {
  const { i18n, t } = useTranslation("translation");

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const locale = convertToIntlLocale(i18n.language);
  const { ledger } = props;

  const periodStart =
    ledger?.priorPeriodSummary?.startDate &&
    DateTime.fromISO(ledger.priorPeriodSummary?.startDate)
      .setLocale(locale)
      .toFormat(DATE_PERIOD_FORMAT);
  const periodEnd =
    ledger?.priorPeriodSummary?.endDate &&
    DateTime.fromISO(ledger.priorPeriodSummary.endDate)
      .setLocale(locale)
      .toFormat(DATE_PERIOD_FORMAT);

  const isSettlement = ledger?.priorPeriodSummary?.pending;
  const isSettled = !isSettlement;
  const settlementEnd =
    ledger?.currentPeriodOverview?.nextStatementAvailableDate &&
    DateTime.fromISO(ledger.currentPeriodOverview.nextStatementAvailableDate)
      .setLocale(locale)
      .toFormat(DATE_FORMAT);

  const statement = useMemo(() => {
    if (!ledger?.priorPeriodSummary) {
      return undefined;
    }
    const balanceStartTimestamp = new Date(
      ledger.priorPeriodSummary.startDate
    ).getTime();
    const balanceEndTimestamp = new Date(
      ledger.priorPeriodSummary.endDate
    ).getTime();
    // Sometimes there is a small difference between start and end dates, so use the middle one.
    const balanceMiddleTimestamp = new Date(
      balanceStartTimestamp +
        Math.floor((balanceEndTimestamp - balanceStartTimestamp) / 2)
    ).getTime();
    return props.statements.find(
      s =>
        new Date(s.startDate).getTime() < balanceMiddleTimestamp &&
        balanceMiddleTimestamp < new Date(s.endDate).getTime()
    );
  }, [ledger?.priorPeriodSummary, props.statements]);

  if (!ledger?.priorPeriodSummary) {
    return (
      <div className={classNames(styles.container, props.className)}>
        <div className={styles.header}>
          <div>
            <PageHeader className={styles.h2} variant="h2">
              {t("home.priorPeriod")}
            </PageHeader>
            <div className={styles.noDataPeriod} />
          </div>
          <div>
            <div className={styles.noDataState} />
          </div>
        </div>
        <hr className={styles.hr} />
        <div className={styles.noData}>{t("home.noDataPriorPeriod")}</div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.header}>
        <div>
          <PageHeader className={styles.h2} variant="h2">
            {t("home.priorPeriod")}
          </PageHeader>
          <div className={styles.period}>{`${periodStart} - ${periodEnd}`}</div>
          {settlementEnd && !isSettled && !statement && (
            <div className={styles.payment}>
              {t("home.statementAvailable", { DATE: settlementEnd })}
            </div>
          )}
        </div>
        {isSettlement && (
          <div>
            <div className={classNames(styles.state, styles.inSettlement)}>
              {t("home.inSettlement")}
            </div>
          </div>
        )}
        {isSettled && (
          <div>
            <div className={classNames(styles.state, styles.settled)}>
              {t("home.settled")}
            </div>
          </div>
        )}
      </div>
      <hr className={styles.hr} />
      <div className={styles.infoItems}>
        <PriorPeriodInfoItem
          className={styles.totalInfoItem}
          label={
            <>
              <div>
                <span className={styles.closingBalanceLabelClassName}>
                  {t("ledger.closingBalance")}
                </span>
                <Tooltip title={t("home.closingBalanceDescription")}>
                  <InfoIcon className={styles.tooltipIcon} />
                </Tooltip>
              </div>
              <button
                className={styles.detailsButton}
                onClick={() => setIsDetailsVisible(!isDetailsVisible)}
              >
                {isDetailsVisible
                  ? t("home.hideDetails")
                  : t("home.showDetails")}
                <ChevronDown
                  className={classNames(
                    styles.detailsButtonIcon,
                    isDetailsVisible && styles.rotate180
                  )}
                />
              </button>
            </>
          }
          value={
            <FormattedCurrency
              className={styles.total}
              currency={ledger.currency}
              value={ledger.priorPeriodSummary.closingBalance}
            />
          }
        />
        {isDetailsVisible && (
          <>
            <PriorPeriodInfoItem
              label={t("home.openingBalance")}
              tooltip={t("home.openingBalanceTooltip")}
              value={
                <FormattedCurrency
                  currency={ledger.currency}
                  value={ledger.priorPeriodSummary.openingBalance}
                />
              }
            />
            <PriorPeriodInfoItem
              hidden={!ledger.priorPeriodSummary.royaltiesPayment}
              label={t("home.royaltiesPayment")}
              tooltip={t("home.royaltiesPaymentTooltip")}
              value={
                <FormattedCurrency
                  currency={ledger.currency}
                  value={ledger.priorPeriodSummary.royaltiesPayment}
                />
              }
            />
            <PriorPeriodInfoItem
              label={t("home.netEarnings")}
              tooltip={t("home.netEarningsTooltip")}
              value={
                <FormattedCurrency
                  currency={ledger.currency}
                  value={ledger.priorPeriodSummary.netEarnings}
                />
              }
            />
            <PriorPeriodInfoItem
              label={t("home.advances")}
              tooltip={t("home.advancesTooltip")}
              value={
                <FormattedCurrency
                  currency={ledger.currency}
                  value={ledger.priorPeriodSummary.advances}
                />
              }
            />
            <PriorPeriodInfoItem
              label={t("home.adjustments")}
              tooltip={t("home.adjustmentsTooltip")}
              value={
                <FormattedCurrency
                  currency={ledger.currency}
                  value={ledger.priorPeriodSummary.adjustments}
                />
              }
            />
          </>
        )}
      </div>
      {statement && (
        <div className={styles.downloadButtonContainer}>
          <Button
            menuItems={[
              {
                onClick: () => props.onDownloadClick(statement, ["csv", "pdf"]),
                text: t("home.allFormats")
              },
              {
                onClick: () => props.onDownloadClick(statement, ["pdf"]),
                text: "PDF"
              },
              {
                onClick: () => props.onDownloadClick(statement, ["csv"]),
                text: "CSV"
              }
            ]}
            variant={ButtonType.Primary}
          >
            {t("buttons.downloadStatement")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PriorPeriod;
