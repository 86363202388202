import React, { useCallback, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import cn from "classnames";

import styles from "./RowToolTip.module.scss";

type Props = React.PropsWithChildren<{
  place: any;
  multiline?: boolean;
  html?: boolean;
  backgroundColor?: string;
  offset?: any;
  content?: JSX.Element;
  event?: string;
  eventOff?: string;
  className?: string;
  tooltipId: string;
  children?: JSX.Element;
}>;
const RowToolTip: React.FunctionComponent<Props> = ({
  tooltipId,
  offset,
  children,
  place = "place",
  className
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const _afterShow = () => {
    const rows = document.querySelectorAll(".ag-row");
    const toolTips = document.querySelectorAll(".__react_component_tooltip");
    const child: any = document.getElementById(tooltipId);
    const parentWithClass: any = child && child.closest(".ag-row");
    rows.forEach((row: any) => {
      row.style.zIndex = 0;
    });
    toolTips.forEach((toolTip: any) => {
      toolTip.classList.remove("show");
    });
    if (parentWithClass) {
      parentWithClass.style.zIndex = 1;
    }
    child.classList.add("show");
  };
  const _afterHide = useCallback(() => {
    const rows = document.querySelectorAll(".ag-row");
    rows.forEach((row: any) => {
      row.style.zIndex = row.getAttribute("row-index");
    });
    const toolTips = document.querySelectorAll(".__react_component_tooltip");
    const child: any = document.getElementById(tooltipId);
    toolTips.forEach((toolTip: any) => {
      toolTip.classList.remove("show");
    });
    child.classList.remove("show");
  }, [tooltipId]);
  return (
    <ReactTooltip
      event="click"
      effect="solid"
      id={tooltipId}
      globalEventOff="click"
      place={place}
      offset={offset}
      className={cn(styles.container, className ? className : "")}
      clickable
      scrollHide
      afterShow={_afterShow}
      afterHide={_afterHide}
    >
      <div className={cn(styles.wrapper)}>{children}</div>
    </ReactTooltip>
  );
};
export default RowToolTip;
