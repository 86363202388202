import "rc-collapse/assets/index.css";

import React, { FC, useState } from "react";
import cn from "classnames";
import Collapse from "rc-collapse";

import styles from "./FailedFileValidationPopup.module.scss";

import { groupByValue } from "../../../../configs/utils";
import { UploadedFile } from "../../../../page/StatementUpload/types";
import Icon from "../../../shared/Icon";

interface DocumentsOverviewProps {
  failedDocuments: UploadedFile[];
}
const FailedDocumentsOverviewDetailed: FC<DocumentsOverviewProps> = ({
  failedDocuments
}) => {
  const groupErrors = groupByValue(failedDocuments, "invalidationReason");
  const messages = [];
  const [activeKey, setActiveKey] = useState<string>("");

  const Panel = Collapse.Panel;
  const expandIcon = () => {
    return <Icon kind="chevron_down" fillColor="transparent" />;
  };
  for (const errorName in groupErrors) {
    messages.push(
      <Collapse
        accordion={true}
        className={cn(styles.collapse)}
        activeKey={activeKey}
        onChange={() => {
          if (activeKey === errorName) {
            setActiveKey("");
          } else {
            setActiveKey(errorName);
          }
        }}
        expandIcon={expandIcon}
      >
        <Panel
          key={errorName}
          header={`${groupErrors[errorName].length} ${errorName}`}
          headerClass={cn(styles.collapse_header)}
        >
          {groupErrors[errorName].map((el: UploadedFile) => {
            return (
              <div key={el.fileName} className={cn(styles.collapse_item)}>
                {el.fileName}
              </div>
            );
          })}
        </Panel>
      </Collapse>
    );
  }
  return <>{messages}</>;
};
export default FailedDocumentsOverviewDetailed;
