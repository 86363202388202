import React, { memo, useEffect, useState } from "react";
import {
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import cx from "classnames";
import cn from "classnames";

import styles from "./AnouncementsTable.module.scss";

import Condition from "../../../../components/shared/Condition";
import Icon from "../../../../components/userManagement/Icon";
import PaginationNumbers from "../../../../components/userManagement/Pagination";

export interface Props {
  className?: string;
  defaultPage?: number;
  defaultPageSize?: number;
  size?: "sm" | "md" | "lg";
  columns: any[];
  data: any[];
  onSort?: (props: { sortBy: any }) => void;
  fetchData?: (paginationParams: { take?: number; skip?: number }) => void;
  totalItems?: any;
  onRowClick?: (row: unknown, e: any, id: number) => void;
  hidePagination?: boolean;
  onChangeSize?: any;
  loading?: boolean;
  defaultSortBy?: { id: string; desc: boolean }[];
}

const Table: React.FC<Props> = ({
  loading,
  className,
  data,
  columns,
  size,
  onSort,
  defaultPageSize = 10,
  fetchData,
  defaultPage,
  onRowClick = () => ({}),
  onChangeSize = () => ({}),
  totalItems,
  hidePagination = true,
  defaultSortBy
}) => {
  hidePagination;
  const [pageCount, setPageCount] = useState(0);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30
    }),
    []
  );
  const Skeleton = () => {
    return <div className={cn(styles.skeleton)} />;
  };
  const tableData = React.useMemo(() => {
    return loading ? Array(5).fill({}) : data;
  }, [loading, data]);
  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map(column => ({
            ...column,
            Cell: column.Header ? <Skeleton /> : ""
          }))
        : columns,
    [loading, columns]
  );
  const tableProps = useTable(
    {
      columns: tableColumns,
      data: tableData,
      pageCount,
      defaultColumn,
      initialState: {
        pageIndex: defaultPage ?? 0,
        sortBy: defaultSortBy ?? []
      },
      manualPagination: true,
      manualSortBy: !!onSort,
      autoResetPage: false
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy }
  } = tableProps;

  useEffect(() => {
    if (defaultPage === 0) {
      gotoPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    gotoPage(defaultPage ?? 0);
    setPageCount(Math.ceil(totalItems / defaultPageSize));
  }, [totalItems, pageSize, defaultPageSize]);

  useEffect(() => {
    gotoPage(defaultPage ?? 0);
    onSort && onSort({ sortBy });
  }, [sortBy]);

  const handleRowClick = (
    event: React.MouseEvent,
    id: number,
    row: unknown
  ) => {
    const selection = window?.getSelection()?.toString() ?? "";
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(row, event, id);
    }
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <table
          {...getTableProps()}
          className={cx(styles.table, styles[size as string], className)}
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps(
                      !column?.withoutSort && column.getSortByToggleProps()
                    )}
                    className={column?.headerClass}
                  >
                    {column.render("Header")}
                    {!column?.disableSortBy && (
                      <button title="">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon kind="sort_down" />
                          ) : (
                            <Icon kind="sort_up" />
                          )
                        ) : (
                          <Icon kind="sort" />
                        )}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  className={styles.row}
                  key={row.original.id}
                  {...row.getRowProps()}
                  onClick={event => handleRowClick(event, row.original.id, row)}
                  onMouseLeave={() => {
                    if (row?.original?.setLeft) {
                      row.original.setLeft(0);
                    }
                  }}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={cell.id}
                        className={cell.column.className}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {data && !data.length && !loading && (
              <tr>
                <td className={styles.noResult}>No Announcements</td>
              </tr>
            )}
          </tbody>
        </table>

        <Condition
          condition={!hidePagination && !!pageCount}
          Truthy={
            <div className={styles.paginationWrap}>
              <PaginationNumbers
                changePageRequest={(page: any) => {
                  gotoPage(page);
                  if (fetchData) {
                    fetchData({
                      skip: page * defaultPageSize,
                      take: defaultPageSize
                    });
                  }
                }}
                totalAmount={pageCount}
                currentPage={pageIndex + 1}
                onChangeSize={(e: any) => {
                  gotoPage(defaultPage ?? 0);
                  onChangeSize(e);
                }}
                size={defaultPageSize}
              />
            </div>
          }
        />
      </div>
    </>
  );
};

export default memo(Table);
