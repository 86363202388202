import Card from "./Card";
import Content from "./Content";
import ExtendableContent from "./ExtendableContent";
import Items from "./Items";

Card.Content = Content;
Card.ExtendableContent = ExtendableContent;
Card.Items = Items;

export default Card;
