import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "../PrivacyPolicy/PrivacyPolicy.module.scss";
import { ReactComponent as ChevronDownGreyIcon } from "../../icons/chevrons_down_grey.svg";
import { ReactComponent as ChevronUpGreyIcon } from "../../icons/chevrons_up_grey.svg";

const InfoPrivacy = ({ isMobileRoute }: { isMobileRoute?: boolean }) => {
  const [openedRows, toggleRows] = useState<any>([]);
  const { t } = useTranslation("translation");
  const toggleRowHandler = (row: any) => {
    let updatedRows = [...openedRows];
    if (openedRows.includes(row)) {
      updatedRows = openedRows.filter((el: any) => el !== row);
    } else {
      updatedRows.push(row);
    }
    toggleRows(updatedRows);
  };

  return (
    <div className="container">
      <div className={styles.privacy}>
        {!isMobileRoute && (
          <div className={styles.header}>{t(`infoPrivacy.title`)}</div>
        )}
        <div className={styles.content}>
          <div className={styles.txt}>
            <Trans
              i18nKey="infoPrivacy.block1"
              components={{
                controllers: (
                  <a
                    className={styles.underline}
                    href="#section-1"
                    onClick={() => toggleRowHandler(1)}
                  />
                )
              }}
            />
          </div>
          <div className={`${styles.toggleRows} ${styles.txt}`}>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-1"}
                onClick={() => toggleRowHandler(1)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>1</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(1) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section1`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(1) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(1) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section1Text`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(2)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>2</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(2) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section2`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(2) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(2) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section2Text_1`)}:
                  <br />
                  <br />
                  {t(`infoPrivacy.section2Text_2`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section2Text_3`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section2Text_4`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section2Text_5`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section2Text_6`)}
                  <br />
                  <br />
                  <Trans
                    i18nKey="infoPrivacy.section2Text_7"
                    components={{
                      linkTo: (
                        <a
                          className={styles.underline}
                          href="mailto:datenschutz@warnerchappell.com"
                        />
                      )
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(3)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>3</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(3) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section3`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(3) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(3) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section3Text_1`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section3Text_2`)}:
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_3`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_4`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_5`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_6`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_7`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_8`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_9`)}</div>
                  </div>
                  <br />
                  <br />
                  {t(`infoPrivacy.section3Text_10`)}:
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_11`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section3Text_12`)}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(4)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>4</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(4) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section4`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(4) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(4) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section4Text_1`)}:
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section4Text_2`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section4Text_3`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section4Text_4`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section4Text_5`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section4Text_6`)}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(5)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>5</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(5) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section5`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(5) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(5) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section5Text`)}
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>5.1</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section5_1Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section5_1Text_2`)}:
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_1Text_3`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_1Text_4`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_1Text_5`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_1Text_6`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_1Text_7`)}</div>
                      </div>
                      <br />
                      <br />
                      {t(`infoPrivacy.section5_1Text_8`)}
                    </div>
                  </div>
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>5.2</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section5_2Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section5_2Text_2`)}:
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_2Text_3`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_2Text_4`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_2Text_5`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_2Text_6`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_2Text_7`)}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>5.3</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section5_3Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section5_3Text_2`)}:
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_3Text_3`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_3Text_4`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_3Text_5`)}</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>5.4</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section5_4Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section5_4Text_2`)}
                      <br />
                      <br />
                      {t(`infoPrivacy.section5_4Text_3`)}:
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_4Text_4`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_4Text_5`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_4Text_6`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div> {t(`infoPrivacy.section5_4Text_7`)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(6)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>6</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(6) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section6`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(6) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(6) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section6Text_1`)}
                  <br />
                  <br />
                  <span className={styles.underline}>
                    {t(`infoPrivacy.section6Text_2`)}:
                  </span>
                  <br />
                  <br />
                  <Trans
                    i18nKey="infoPrivacy.section6Text_3"
                    components={{
                      linkTo: (
                        <a
                          className={styles.underline}
                          target="_blank"
                          href="https://wminewmedia.com/affiliates/"
                          rel="noreferrer"
                        />
                      )
                    }}
                  />
                  <br />
                  <br />
                  <span className={styles.underline}>
                    {t(`infoPrivacy.section6Text_4`)}:
                  </span>
                  <br />
                  {t(`infoPrivacy.section6Text_5`)}:
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_6`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_7`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_8`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_9`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_10`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_11`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_12`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_13`)}</div>
                  </div>
                  <br />
                  <br />
                  <span className={styles.underline}>
                    {t(`infoPrivacy.section6Text_14`)}:
                  </span>
                  <br />
                  {t(`infoPrivacy.section6Text_15`)}
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_16`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_17`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section6Text_18`)}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(7)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>7</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(7) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section7`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(7) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(7) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section7Text_1`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section7Text_2`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(8)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>8</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(8) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section8`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(8) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(8) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section8Text_1`)}
                  <br />
                  <br />
                  {t(`infoPrivacy.section8Text_2`)}:
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section8Text_3`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section8Text_4`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`infoPrivacy.section8Text_5`)}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(9)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>9</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(9) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section9`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(9) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(9) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section9Text`)}
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>9.1</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section9_1Text_1`)}
                      </span>
                      <br />
                      <br />
                      <div className={styles.border}>
                        <div style={{ display: "flex" }}>
                          <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                          <div
                            className={cn(styles.headTxt, styles.marginTop0)}
                          >
                            {" "}
                            {t(`infoPrivacy.section9_1Text_2`)}
                          </div>
                        </div>
                        <br />
                        <div style={{ display: "flex" }}>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          <div> {t(`infoPrivacy.section9_1Text_3`)}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          <div> {t(`infoPrivacy.section9_1Text_4`)}</div>
                        </div>
                        <br />
                        <div
                          style={{ display: "flex" }}
                          className={cn(styles.headTxt, styles.marginTop0)}
                        >
                          <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                          <div> {t(`infoPrivacy.section9_1Text_5`)}</div>
                        </div>
                        <br />
                        <div style={{ display: "flex" }}>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          <div> {t(`infoPrivacy.section9_1Text_6`)}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          <div> {t(`infoPrivacy.section9_1Text_7`)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>9.2</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section9_2Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section9_2Text_2`)}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>9.3</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section9_3Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section9_3Text_2`)}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>9.4</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section9_4Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section9_4Text_2`)}
                      <br />
                      <br />
                      {t(`infoPrivacy.section9_4Text_3`)}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>9.5</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`infoPrivacy.section9_4Text_1`)}
                      </span>
                      <br />
                      <br />
                      {t(`infoPrivacy.section9_4Text_2`)}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-2"}
                onClick={() => toggleRowHandler(10)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>10</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(10) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section10`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(10) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(10) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section10Text`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-3"}
                onClick={() => toggleRowHandler(11)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>11</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(11) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section11`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(11) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(11) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section11Text`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-3"}
                onClick={() => toggleRowHandler(12)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>12</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(12) ? styles.opened : ""
                    }`}
                  >
                    {t(`infoPrivacy.section12`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(12) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(12) && (
                <div className={styles.rowContent}>
                  {t(`infoPrivacy.section12Text`)}
                </div>
              )}
            </div>
          </div>
          <br />
          <br />
          <div className={styles.txt}>{t(`infoPrivacy.bottomText`)}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoPrivacy;
