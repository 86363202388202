import React from "react";
import cn from "classnames";

import styles from "./HeaderComponent.module.scss";

import Icon from "../../shared/Icon";

type HeaderComponentType = {
  headerName: string;
  field: string;
  sortBy: { sort: string; order: boolean };
  setSortBy: any;
  titleClass?: string;
};

const HeaderComponent: React.FC<HeaderComponentType> = ({
  headerName,
  sortBy,
  setSortBy,
  titleClass,
  field
}) => {
  const position = sortBy?.order ? "sort_down" : "sort_up";
  return (
    <div
      onClick={() =>
        setSortBy({
          sort: field,
          order: sortBy.sort !== field ? true : !sortBy.order
        })
      }
      className={cn(styles.headerComponent, titleClass)}
    >
      <p className={cn(styles.title)} title={headerName}>
        {headerName}
      </p>
      <Icon kind={sortBy.sort !== field ? "sort" : position} />
    </div>
  );
};

export default HeaderComponent;
