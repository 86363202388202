import { useEffect, useMemo, useState } from "react";
import axios from "axios";

/**
 * Delay between maintainance check fetches. In milliseconds.
 */
const FETCH_INTERVAL = 3 * 60 * 1000;

/**
 * Checks maintenance status
 * @returns Maintenance status
 */
export const useMaintenance = (): {
  isMaintenance: boolean;
  isMaintenanceLoading: boolean;
} => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isMaintenanceLoading, setIsMaintenanceLoading] = useState(true);

  useEffect(() => {
    const source = axios.CancelToken.source();
    /**
     * Fetches maintenance status from BE
     */
    const fetchMaintenance = async (): Promise<void> => {
      try {
        const { data } = await axios.get<string>(
          `/.maintenance?${Date.now()}`, // Add timestamp to workaround cache
          { cancelToken: source.token }
        );
        // It returns index.html in case of 404 error, so check that no HTML is returned.
        const newIsMaintenance = !data.startsWith("<!doctype html>");
        isMaintenance && !newIsMaintenance
          ? window.location.reload() // Reload the page when switching from maintenance to normal mode
          : setIsMaintenance(newIsMaintenance);
      } catch {
        if (isMaintenance) {
          window.location.reload(); // Reload the page when switching from maintenance to normal mode
        }
      }
      setIsMaintenanceLoading(false);
    };

    void fetchMaintenance();
    const intervalId = setInterval(() => {
      void fetchMaintenance();
    }, FETCH_INTERVAL);

    return () => {
      clearInterval(intervalId);
      source.cancel("Request cancelled by user");
    };
  }, [isMaintenance]);

  return useMemo(() => ({ isMaintenance, isMaintenanceLoading }), [
    isMaintenance,
    isMaintenanceLoading
  ]);
};
