import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./Maintenance.module.scss";
import { ReactComponent as Logo } from "../../icons/logo.svg";

/**
 * Renders maintenance screen
 * @returns React component
 */
const Maintenance: FC = () => {
  const { t } = useTranslation("translation");
  return (
    <div className={classNames("container", styles.container)}>
      <header className={styles.header}>
        <Logo />
        <span className={styles.siteName}>Client Portal</span>
      </header>
      <main className={styles.main}>
        <div className={styles.content}>
          <h1 className={styles.title}>{t("maintenance.title")}</h1>
          <p className={styles.description}>{t("maintenance.description")}</p>
          <p className={styles.description}>{t("maintenance.hint")}</p>
        </div>
      </main>
    </div>
  );
};

export default Maintenance;
