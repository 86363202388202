import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Skeleton, SkeletonProps } from "@material-ui/lab";

import styles from "./AlertPreview.module.scss";

import Loader from "../../components/shared/Loading";
import { profileSelector } from "../../store/appStatic/selectors";

/**
 * Renders alert preview page
 * @returns React component
 */
const AlertPreview: FC = () => {
  const profile = useSelector(profileSelector);

  const skeletonClasses: SkeletonProps["classes"] = {
    root: styles.skeletonRoot
  };

  useEffect(() => {
    // Make all elements on the page not clickable
    const styleEl = document.createElement("style");
    styleEl.innerHTML = "*{pointer-events:none}";
    document.head.appendChild(styleEl);
    return () => {
      styleEl.remove();
    };
  }, []);

  return (
    <div className="container">
      {!profile && <Loader />}
      {profile && ( // Wait for header loading
        <>
          <Skeleton
            animation="wave"
            classes={skeletonClasses}
            height={30}
            variant="rect"
            width={270}
          />
          <Skeleton
            animation={false}
            classes={skeletonClasses}
            height={100}
            variant="rect"
          />
          <Skeleton
            animation={false}
            classes={skeletonClasses}
            height={30}
            variant="rect"
            width={100}
          />
          <Skeleton
            animation={false}
            classes={skeletonClasses}
            height={150}
            variant="rect"
          />
        </>
      )}
    </div>
  );
};

export default AlertPreview;
