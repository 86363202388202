import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import styles from "../ForgotPassword.module.scss";
import { ReactComponent as Line } from "../../../../icons/line_icon.svg";

import Button, { ButtonType } from "../../../../components/shared/UiButton";
import { textParams } from "../../../../utils";

const SuccessModal = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className={styles.success}>
      <div className={styles.iconWrap}>
        <h4>{t(`troubleToLoginPage.successPopup.header`)}</h4>
        <Line className={styles.decor} />
      </div>
      <p className={styles.description}>
        {t(`troubleToLoginPage.successPopup.text`)}
      </p>
      <Button
        variant={ButtonType.Primary}
        type={"button"}
        className={styles.closeSuccess}
        onClick={() => history.push("/login")}
      >
        {t(`buttons.close`)}
      </Button>
      <p className={styles.links}>
        {`${t("common.moreQuestions.text1")} 
        ${textParams(t("common.moreQuestions.text2"))[0]}`}{" "}
        <NavLink to="/faq" className={styles.successLink}>
          {textParams(t("common.moreQuestions.text2"))[1]}
        </NavLink>{" "}
        {textParams(t("common.moreQuestions.text2"))[2]}{" "}
        <NavLink className={styles.successLink} to="/contact-us">
          {textParams(t("common.moreQuestions.text2"))[3]}
        </NavLink>
      </p>
    </div>
  );
};

export default SuccessModal;
