function Icons() {
  return {
    icons: {
      sortAscending:
        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">\n' +
        '<path d="M8.28711 6.9375H1.78711C1.05078 6.9375 0.695312 7.82617 1.20312 8.33398L4.42773 11.584C4.75781 11.9141 5.26562 11.9141 5.5957 11.584L8.82031 8.33398C9.37891 7.82617 9.02344 6.9375 8.28711 6.9375ZM5.0625 11L1.8125 7.75H8.28711L5.0625 11ZM1.8125 5.3125H8.28711C9.02344 5.3125 9.37891 4.44922 8.87109 3.94141L5.64648 0.691406C5.31641 0.361328 4.80859 0.361328 4.50391 0.691406L1.22852 3.94141C0.720703 4.44922 1.07617 5.3125 1.8125 5.3125ZM5.0625 1.25L8.28711 4.5H1.78711L5.0625 1.25Z" fill="#3F3F3F"/>\n' +
        '<path d="M1.68555 5.3125H8.41406C9.02344 5.3125 9.35352 4.57617 8.89648 4.11914L5.54492 0.666016C5.41797 0.513672 5.24023 0.4375 5.0625 0.4375C4.88477 0.4375 4.68164 0.513672 4.55469 0.666016L1.20312 4.11914C0.746094 4.57617 1.07617 5.3125 1.68555 5.3125Z" fill="#3F3F3F"/>\n' +
        "</svg>",
      sortDescending:
        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">\n' +
        '<path d="M8.28711 6.9375H1.78711C1.05078 6.9375 0.695312 7.82617 1.20312 8.33398L4.42773 11.584C4.75781 11.9141 5.26562 11.9141 5.5957 11.584L8.82031 8.33398C9.37891 7.82617 9.02344 6.9375 8.28711 6.9375ZM5.0625 11L1.8125 7.75H8.28711L5.0625 11ZM1.8125 5.3125H8.28711C9.02344 5.3125 9.37891 4.44922 8.87109 3.94141L5.64648 0.691406C5.31641 0.361328 4.80859 0.361328 4.50391 0.691406L1.22852 3.94141C0.720703 4.44922 1.07617 5.3125 1.8125 5.3125ZM5.0625 1.25L8.28711 4.5H1.78711L5.0625 1.25Z" fill="#3F3F3F"/>\n' +
        '<path d="M8.89648 8.15625L5.54492 11.6094C5.41797 11.7617 5.24023 11.8125 5.0625 11.8125C4.85938 11.8125 4.68164 11.7617 4.55469 11.6094L1.20312 8.15625C0.746094 7.69922 1.07617 6.9375 1.68555 6.9375H8.41406C9.02344 6.9375 9.35352 7.69922 8.89648 8.15625Z" fill="#3F3F3F"/>\n' +
        "</svg>",
      sortUnSort:
        '<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M8.28711 6.9375H1.78711C1.05078 6.9375 0.695312 7.82617 1.20312 8.33398L4.42773 11.584C4.75781 11.9141 5.26562 11.9141 5.5957 11.584L8.82031 8.33398C9.37891 7.82617 9.02344 6.9375 8.28711 6.9375ZM5.0625 11L1.8125 7.75H8.28711L5.0625 11ZM1.8125 5.3125H8.28711C9.02344 5.3125 9.37891 4.44922 8.87109 3.94141L5.64648 0.691406C5.31641 0.361328 4.80859 0.361328 4.50391 0.691406L1.22852 3.94141C0.720703 4.44922 1.07617 5.3125 1.8125 5.3125ZM5.0625 1.25L8.28711 4.5H1.78711L5.0625 1.25Z" fill="#3F3F3F"/>\n' +
        "</svg>\n"
    }
  };
}

export default Icons;
