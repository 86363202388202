import React from "react";
import cn from "classnames";

import styles from "./Icon.module.scss";

import Icons from "../../../icons";
import { TIcons } from "../../../types/icons";

type IconProps = {
  kind: TIcons;
  className?: string;
};
const Icon: React.FC<IconProps> = props => {
  const { kind, className } = props;

  const SpecificIcon = Icons[kind];

  return (
    <i className={cn(styles.icon, className)}>
      <SpecificIcon />
    </i>
  );
};

export default Icon;
