import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./Loader.module.scss";
import { ReactComponent as LoaderIconBlack } from "../../../icons/wcm_monogram_black.svg";
import { ReactComponent as LoadingIconGold } from "../../../icons/wcm_monogram_gold.svg";
interface loaderType {
  isInner?: boolean;
}
function Loader(props: loaderType) {
  const { t } = useTranslation("translation");
  return (
    <div
      className={cn(styles.container, props.isInner && styles.container_inner)}
    >
      <div className={styles.wrapper}>
        <div className={styles.icons_parent}>
          <div className={styles.back_icon_wrapper}>
            <LoaderIconBlack className={styles.loader_icon_back} />
          </div>
          <div className={styles.front_icon_wrapper}>
            <LoadingIconGold className={styles.loader_icon_front} />
          </div>
        </div>
        {!props.isInner && (
          <div className={styles.text}>{t(`common.launching`)}</div>
        )}
      </div>
    </div>
  );
}

export default Loader;
