import React from "react";
import cn from "classnames";

import styles from "./PayeeAccount.module.scss";

// import DataTable from "../../components/shared/DataTable";

const PayeeAccount = () => {
  // const columnDefs = [
  //   { field: "index", maxWidth: 50, headerName: "#" },
  //   { field: "name", headerName: "Name" },
  //   { field: "payeeCode", headerName: "Payee Code" },
  //   { field: "accountingPeriod", headerName: "Accounting Period" },
  //   { field: "settlementPeriod", headerName: "Settlement Period" }
  // ];
  // const defs = [
  //   {
  //     index: "1",
  //     name: "J MALOY ROACH EST",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "2",
  //     name: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "3",
  //     name: "Lorem ipsum dolor sit amet",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "4",
  //     name: "DIGNISSIM",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "5",
  //     name: "J MALOY ROACH EST",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "6",
  //     name: "J MALOY ROACH EST",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "7",
  //     name: "J MALOY ROACH EST",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   },
  //   {
  //     index: "8",
  //     name: "J MALOY ROACH EST",
  //     payeeCode: "US 100043398 000",
  //     accountingPeriod: "3 Months",
  //     settlementPeriod: "60 Days"
  //   }
  // ];
  return (
    <div className={cn(styles.impersonationSection, "container")}>
      <p className={styles.title}>Your payee codes</p>
      <div className={styles.table}>
        {/*<DataTable*/}
        {/*  rowData={defs}*/}
        {/*  columnDefs={columnDefs}*/}
        {/*  themeClassName={cn(styles.table_theme)}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default PayeeAccount;
