import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import axios from "axios";
import cn from "classnames";

import styles from "./SetEditUserModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as EditIcon } from "../../../../icons/edit.svg";
import { ReactComponent as GlobeIcon } from "../../../../icons/globus.svg";
import { ReactComponent as ProfileIcon } from "../../../../icons/profile_icon.svg";
import { ReactComponent as UserLock } from "../../../../icons/user_lock.svg";
import { ReactComponent as UserReactivateIcon } from "../../../../icons/user_reactivated.svg";
import { ReactComponent as UserSlash } from "../../../../icons/user_slash.svg";

import { userTypes } from "../../../../configs/constants/innerRole";
import { useActions } from "../../../../hooks/useActions";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { oktaAuth } from "../../../../lib/oktaAuth";
import { jc } from "../../../../lib/styles";
import { preferableLanguage } from "../../../../store/appStatic/constants";
import { countrySelector } from "../../../../store/appStatic/selectors";
import {
  bodySelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import { UserRole } from "../../../userManagement/api/users";
import LoadingCircle from "../../LoadingCircle";
import Button from "../../UiButton";
import UiSelect from "../../UiSelect";
import ActionsModal from "./ActionsModal";
import ActivityHistory from "./ActivityHistory";

const generateTagClassName = (status: string) => {
  return jc(
    styles.status,
    (typeof status === "undefined" || status === "") && `${styles.status}`,
    status === "INVITED" && `${styles.status_invited}`,
    status === "PENDING_REGISTERED" && `${styles.status_invited}`,
    status === "REGISTERED" && `${styles.status_registered}`,
    status === "ACTIVE" && `${styles.status_active}`,
    status === "INACTIVE" && `${styles.status_inactive}`,
    status === "SUSPENDED" && `${styles.status_suspended}`
  );
};
const SetEditUserModal = () => {
  const body = useSelector(bodySelector);
  const countries = useSelector(countrySelector);
  const { isOpen, type, user } = useSelector(modalSelector);
  const { register, control, watch, reset } = useForm<any>({
    defaultValues: { weather: user?.role }
  });
  const country = countries.find((el: any) => el.label === user?.location);
  useEffect(() => {
    reset({
      weather: user?.role,
      country
    });
  }, [user?.role, reset, countries, user?.location, country]);

  const modalRef = useRef(null);

  const [actionModal, setActionModal] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [emailType, setEmailType] = useState([]);
  const [preferableLanguageData, setPreferableLanguageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [payeeCodesList, setPayeeCodesList] = useState<any>([]);
  const { SetOpenModalAction, GetArchitectUsersAction } = useActions();
  const isFormChanged =
    user?.role !== watch("weather") ||
    (watch("country")?.label !== user?.location && !!watch("country")?.label);
  useEffect(() => {
    if (user?.id) {
      axios
        .get(`/api/users/${user?.id}/clients`, {
          headers: {
            authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        })
        .then(res => {
          const payeeCodes: any = [];
          res.data.clients.forEach((el: any) => {
            payeeCodes.push(...el.payeeAccounts);
          });
          setPreferableLanguageData(res.data?.preferableLanguage);
          setEmailType(res.data.emailType);
          setPayeeCodesList(payeeCodes);
        })
        .catch(() => {
          setPayeeCodesList(null);
        });
    }
  }, [user?.id]);
  const handleDeactivate = () => {
    SetOpenModalAction({ isOpen: true, type: "deactivate", user });
  };
  const handleImpersonate = () => {
    SetOpenModalAction({
      isOpen: true,
      type: "impersonate",
      user: { ...user, payeeCode: payeeCodesList[0].payeeCode }
    });
  };
  const handleSuspend = () => {
    SetOpenModalAction({ isOpen: true, type: "suspend", user });
  };
  const handleReactivate = () => {
    SetOpenModalAction({ isOpen: true, type: "reactivate", user });
  };
  const getRoleName = (role: string): string => {
    switch (role) {
      case "ADMIN":
        return "Admin";
      case "SUPER_ADMIN":
        return "Super Admin";
      case "EXTERNAL_USER":
        return "Songwriter";
      default:
        return "";
    }
  };

  const handleClick = useCallback(
    type => {
      let newData = {};
      if (type === "save") {
        newData = {
          title: "Save Changes",
          description: "Do you want to save changes to this profile?",
          type
        };
      }
      if (type === "discard") {
        newData = {
          title: "Discard Changes",
          description: "Are you sure you want to discard your changes?",
          type
        };
      }
      if (type === "unsaved") {
        newData = {
          title: "Unsaved Changes",
          description: "Would you like to save your changes before exiting?",
          type
        };
      }
      setActionModal(newData);
    },
    [setActionModal]
  );

  const toggleModal = useCallback(() => {
    if (isFormChanged) {
      handleClick("unsaved");
    } else {
      reset();
      setPayeeCodesList([]);
      setActionModal(null);
      setIsEdit(false);
      setError("");
      setEmailType([]);
      setPreferableLanguageData([]);
      SetOpenModalAction({ isOpen: false, type: "" });
    }
  }, [SetOpenModalAction, reset, isFormChanged, handleClick]);

  const handleSaveChanges = useCallback(() => {
    setIsLoading(true);
    axios
      .put(
        `/api/users/internal`,
        {
          userId: user?.id,
          role: watch("weather"),
          countryCode: watch("country")?.value
        },
        {
          headers: {
            authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        }
      )
      .then(({ data }) => {
        setIsSuccess(true);
        setIsEdit(false);
        setActionModal(null);
        GetArchitectUsersAction(body, false);
        SetOpenModalAction({ isOpen: true, type: "editUser", user: data });
      })
      .catch(() => setError("Unable to save changes"))
      .finally(() => setIsLoading(false));
  }, [
    watch,
    oktaAuth,
    user?.id,
    GetArchitectUsersAction,
    SetOpenModalAction,
    body
  ]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  useOutsideClick(modalRef, (e: any) => {
    if (
      e.target.tagName !== "path" &&
      e.target.tagName !== "svg" &&
      !e?.target?.className.includes("ReactModal__Overlay")
    ) {
      setActionModal(null);
    }
    setError("");
  });

  const preferableLanguageArray = preferableLanguageData.map(
    (element: string) => {
      return preferableLanguage.filter(item => item.code === element)[0]?.name;
    }
  );

  return (
    <div className={styles.setEditUserModal}>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen && (type === "editUser" || type === "viewUser")}
        onRequestClose={toggleModal}
        overlayClassName={styles.myoverlay}
        className={cn(styles.mymodal, {
          [styles.viewUser]: type === "viewUser"
        })}
      >
        {isSuccess && (
          <div className={styles.success}>
            <ProfileIcon />
            <p>Changes Saved</p>
          </div>
        )}
        {actionModal && (
          <ActionsModal
            handleSaveChanges={handleSaveChanges}
            isLoading={isLoading}
            error={error}
            modalRef={modalRef}
            handleClose={() => {
              setActionModal(false);
              setError("");
            }}
            handleDiscard={() => {
              setIsEdit(false);
              setActionModal(false);
              setError("");
              reset({ weather: user?.role, country });
            }}
            title={actionModal?.title}
            description={actionModal?.description}
            type={actionModal?.type}
          />
        )}
        <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
        <div className={styles.fullName}>
          {user?.title ? user?.title : user?.email}
        </div>
        <div className={styles.actionWrap}>
          <div
            className={cn(styles.status, generateTagClassName(user?.status))}
          >
            {user?.status === "PENDING_REGISTERED"
              ? "Pending Registered"
              : user?.status}
          </div>
          {user?.status !== "INACTIVE" && (
            <div className={styles.action}>
              {type === "viewUser" ? (
                <>
                  {!!payeeCodesList?.length && (
                    <div
                      className={styles.iconsWrap}
                      onClick={() => handleImpersonate()}
                    >
                      <span>Impersonate</span>
                    </div>
                  )}
                  {user.status !== "INVITED" && (
                    <div
                      className={styles.iconsWrap}
                      onClick={() => {
                        if (user.status === "REGISTERED") {
                          handleSuspend();
                        }
                        if (user.status === "SUSPENDED") {
                          handleReactivate();
                        }
                      }}
                    >
                      {user.status === "REGISTERED" && (
                        <UserLock className={styles.slashIcon} />
                      )}
                      {user.status === "SUSPENDED" && (
                        <UserReactivateIcon className={styles.slashIcon} />
                      )}
                      {user.status === "SUSPENDED" && <span>Reactivate</span>}
                      {user.status === "REGISTERED" && <span>Suspend</span>}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className={styles.iconsWrap}
                    onClick={() => setIsEdit(true)}
                  >
                    <EditIcon className={styles.editIcon} />
                    <span>Edit</span>
                  </div>
                  <div
                    className={styles.iconsWrap}
                    onClick={() => handleDeactivate()}
                  >
                    <UserSlash className={styles.slashIcon} />
                    <span>Deactivate</span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.role}>{getRoleName(user?.role)}</div>
        {isEdit ? (
          <div className={styles.radio}>
            <form
              className={cn({
                [styles.content]: user?.role === "EXTERNAL_USER"
              })}
            >
              <div className={styles.radioButtons}>
                <p className={styles.radioTitle}>Role</p>
                <div className={styles.radioWrap}>
                  <label>Admin</label>
                  <input
                    {...register("weather")}
                    type="radio"
                    name="weather"
                    value={UserRole.ADMIN}
                    id="field-admin"
                  />
                </div>
                {/*<div className={styles.radioWrap}>*/}
                {/*  <label>Senior Admin</label>*/}
                {/*  <input*/}
                {/*    {...register("weather")}*/}
                {/*    type="radio"*/}
                {/*    name="weather"*/}
                {/*    value={UserRole.SENIOR_ADMIN}*/}
                {/*    id="field-superAdmin"*/}
                {/*  />*/}
                {/*</div>*/}
                <div className={styles.radioWrap}>
                  <label>Super Admin</label>
                  <input
                    {...register("weather")}
                    type="radio"
                    name="weather"
                    value={UserRole.SUPER_ADMIN}
                    id="field-superAdmin"
                  />
                </div>
              </div>
              <div className={styles.emailWrap}>
                <span className={styles.editLabel}>Email:</span>
                <span className={styles.editEmail}>{user?.email}</span>
              </div>
              <Controller
                name={"country"}
                control={control}
                render={({ ...field }) => (
                  <UiSelect
                    {...field.field}
                    isWhite
                    options={countries}
                    label={"Location"}
                    placeholder={"--Please select one--"}
                    className={styles.input}
                    rightIcon={<GlobeIcon className={styles.globe} />}
                  />
                )}
                rules={{ required: "field is mandatory" }}
              />
              <div className={styles.buttonsWrap}>
                <Button
                  type={"button"}
                  onClick={() => {
                    if (isFormChanged) {
                      handleClick("discard");
                    } else {
                      setIsEdit(false);
                    }
                  }}
                  className={styles.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  type={"button"}
                  className={styles.saveButton}
                  disabled={!watch("country")?.label}
                  onClick={() => handleClick("save")}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.wraper}>
              <div className={styles.emailWrap}>
                <span className={styles.userEmailLabel}>Email:</span>
                <span className={styles.userEmail}>{user?.email}</span>
              </div>
              {!!user?.location && (
                <div className={styles.emailWrap}>
                  <span className={styles.userEmailLabel}>Location:</span>
                  <span className={styles.userEmail}>{user?.location}</span>
                </div>
              )}
              {type === "viewUser" && !!emailType.length && (
                <div className={styles.emailWrap}>
                  <span className={styles.userEmailLabel}>Type:</span>
                  <span className={styles.userEmail}>
                    {emailType.map((el, index) => (
                      <span key={el}>{`${userTypes[el]}${
                        emailType?.length == index + 1 ? "" : ", "
                      }`}</span>
                    ))}
                  </span>
                </div>
              )}
              {type === "viewUser" && !!preferableLanguageArray.length && (
                <div className={styles.emailWrap}>
                  <span className={styles.userEmailLabel}>
                    Preferable Language:
                  </span>
                  <span className={styles.userEmail}>
                    {preferableLanguageArray.map((el, index) => (
                      <span key={el}>{`${el}${
                        preferableLanguageArray?.length == index + 1 ? "" : ", "
                      }`}</span>
                    ))}
                  </span>
                </div>
              )}
            </div>
            {type === "viewUser" && (
              <div>
                <p className={styles.userEmailLabel}>Payee Codes</p>
                <div className={styles.payeeWrap}>
                  {!!payeeCodesList?.length &&
                    payeeCodesList?.map((el: { payeeCode: string }) => (
                      <p className={styles.code} key={el.payeeCode}>
                        {el.payeeCode}
                      </p>
                    ))}
                  {!payeeCodesList?.length && payeeCodesList !== null ? (
                    <LoadingCircle className={styles.circle} isBig />
                  ) : null}
                  {payeeCodesList === null && (
                    <p className={styles.notFound}>No payee code found</p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <ActivityHistory
          activities={user?.activities}
          createdAt={user?.createdAt}
        />
      </Modal>
    </div>
  );
};

export default SetEditUserModal;
