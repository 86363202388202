import React, { useEffect } from "react";
import cn from "classnames";

import styles from "./SearchInput.module.scss";
import { ReactComponent as ClearIcon } from "../../../icons/clear_icon.svg";
import { ReactComponent as ClearIconHovered } from "../../../icons/clear_icon.svg";
import { ReactComponent as ClearIconHoveredLight } from "../../../icons/clear_icon_hovered_light.svg";
import { ReactComponent as ClearIconLight } from "../../../icons/clear_icon_light.svg";
import { ReactComponent as SearchIcon } from "../../../icons/filter_search.svg";

export interface SearchInputProps extends React.PropsWithChildren<unknown> {
  placeholder: string;
  mobilePlaceholder?: string;
  className?: string;
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  value?: string;
  onclickClearIcon?: (event: React.MouseEvent<HTMLElement>) => void;
  theme?: string;

  [key: string]: any;
}

const SearchInput: React.FC<SearchInputProps> = React.forwardRef(
  function SearchInput(props, ref: any) {
    const {
      placeholder = "",
      mobilePlaceholder = "",
      className,
      onInput,
      id,
      value,
      onclickClearIcon,
      theme = "dark",
      ...res
    } = props;
    const [dimensions, setDimensions] = React.useState({
      height: window.innerHeight,
      width: window.innerWidth
    });
    useEffect(() => {
      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        });
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });
    return (
      <div className={cn(styles.wrapper, "searchInputWrapper")}>
        <input
          ref={ref || null}
          {...res}
          type="text"
          className={cn(
            styles.field,
            theme === "light" && styles.field_light,
            value ? styles.field_hasValue : styles.field,
            className && className
          )}
          onInput={onInput}
          id={id}
          placeholder={
            dimensions?.width > 990 ? placeholder : mobilePlaceholder
          }
          value={value}
        />
        <SearchIcon
          className={cn(
            styles.icon,
            styles.icon_search,
            value && styles.icon_search_notempty
          )}
        />
        {value && (
          <div
            className={cn(styles.icon, styles.icon_clear)}
            onClick={onclickClearIcon}
          >
            {theme === "dark" && (
              <>
                <ClearIcon className={cn(styles.icon, "notHoveredIcon")} />
                <ClearIconHovered className={cn(styles.icon, "hoveredIcon")} />
              </>
            )}
            {theme === "light" && (
              <>
                <ClearIconLight className={cn(styles.icon, "notHoveredIcon")} />
                <ClearIconHoveredLight
                  className={cn(styles.icon, "hoveredIcon")}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default SearchInput;
