import React from "react";
import cn from "classnames";

import styles from "./Content.module.scss";

type ContentTypes = {
  children: any;
  className?: string;
};

const Content: React.FC<ContentTypes> = ({ children, className }) => {
  return <div className={cn(styles.content, className)}>{children}</div>;
};

export default Content;
