import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  RowClickedEvent
} from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import cn from "classnames";

import styles from "./DataTable.module.scss";

import { bodySelector } from "../../../store/architectUsersStore/selectors";
import GridPlaceholderLoader from "../GridPlaceholder/GridPlaceholderLoader";

export interface DataTableProps {
  columnDefs: ColDef[];
  rowData?: any;
  themeClassName: string;
  rowClass?: string;
  rowHeight?: number;
  headerHeight?: number;
  frameworkComponents?: any;
  getGridData?: any;
  rowModelType?:
    | "infinite"
    | "clientSide"
    | "viewport"
    | "serverSide"
    | undefined;
  getGridHeight?: any;
  rowTooltip?: boolean;
  showToolTip?: any;
  userIdList?: any;
  selectedRows?: any;
  setGridRef?: any;
  tab?: any;
  status?: any;
  total?: number;
  loading?: boolean;
  onRowClicked?: (e: RowClickedEvent) => void;
  onSortChanged?: (e: any) => void;

  [key: string]: any;
}

const DataTable = (props: DataTableProps) => {
  const {
    tab,
    status,
    rowData,
    rowHeight = 86,
    frameworkComponents,
    headerHeight = 76,
    rowModelType,
    getGridData,
    total,
    setGridRef,
    onRowClicked,
    onSortChanged,
    loading,
    ...res
  } = props;
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>();
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);
  const [x, setX] = useState(1);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // flex: 1,
      // minWidth: 100,
      // resizable: true
    };
  }, []);
  const onGridReady = useCallback((ref: GridReadyEvent) => {
    ref.api.setRowData(new Array(6).fill({}));
  }, []);

  useEffect(() => {
    setGridRef.current &&
      setGridRef.current.api &&
      setGridRef.current.api.setRowData(rowData);
  }, [rowData, setGridRef, setGridRef.api]);
  useEffect(() => {
    const gridApi = setGridRef.current.api;
    x !== 2 &&
      selectedRowIds.forEach(id => {
        gridApi?.getRowNode(id)?.setSelected(true);
      });
  }, [selectedRowIds, setGridRef, x]);
  useEffect(() => {
    setX(1);
  }, [status, tab]);
  const colDefs = props.columnDefs.map(el => {
    if (!el.cellRenderer && el.colId !== "checkbox") {
      return {
        ...el,
        cellRenderer: (props: ICellRendererParams) => {
          if (props.value !== undefined) {
            return <div className={cn(styles.cell)}>{props.value}</div>;
          } else {
            return <GridPlaceholderLoader />;
          }
        }
      };
    } else {
      return {
        ...el
      };
    }
  });
  useEffect(() => {
    if (typeof window === "undefined" || !props.getGridHeight) return;
    const onWindowResize = () => {
      if (containerRef.current) {
        const top = containerRef.current.getBoundingClientRect().top;
        props.getGridHeight && setHeight(props.getGridHeight(top));
      }
    };
    onWindowResize();
    window.addEventListener("resize", onWindowResize, { passive: true });
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [props, props.getGridHeight]);
  const lastScrollTop = 0;
  const body = useSelector(bodySelector);
  const onBodyScroll = useCallback(
    (e: any) => {
      const _bottom = e.api.getVerticalPixelRange().bottom;
      const st = _bottom;
      const _grid_container_height =
        e.api.getDisplayedRowCount() *
        e.api.getSizesForCurrentTheme().rowHeight;
      if (st > lastScrollTop) {
        setX(1);
        if (_grid_container_height && _bottom >= _grid_container_height - 330) {
          setX(x + 1);
          const b = {
            ...body,
            skip: e.api.getDisplayedRowCount()
          };
          if (x == 2) {
            if (total !== e.api.getDisplayedRowCount() && !loading) {
              getGridData(true, 50, e.api.getDisplayedRowCount(), b);
              e.api.setRowData([...rowData, ...new Array(3).fill({})]);
            }
          }
        }
      }
    },
    [x, body, total, loading, getGridData, rowData]
  );
  const getRowStyle = (params: any) => {
    return { zIndex: params.node.rowIndex };
  };
  const noMachOverlay = () => {
    return `
      <div>
        <h2 class="noRowsTitle">No matches found</h2>
        <p>Try adjusting your search to find what you’re looking for</p>
      </div>`;
  };
  return (
    <div style={containerStyle} className={cn(styles.grid)}>
      <div
        style={{
          height: height
        }}
        ref={containerRef}
        className={cn(props.themeClassName, styles.grid_theme)}
      >
        <AgGridReact
          {...{
            ...((props as unknown) as AgGridReactProps)
          }}
          getRowStyle={getRowStyle}
          ref={setGridRef}
          {...res}
          rowData={rowData}
          frameworkComponents={frameworkComponents}
          animateRows={true}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowBuffer={6}
          suppressRowHoverHighlight={true}
          suppressCellSelection={true}
          suppressContextMenu={true}
          rowModelType={rowModelType}
          cacheBlockSize={50}
          infiniteInitialRowCount={10}
          maxBlocksInCache={10000}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={noMachOverlay()}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          rowClass={cn(styles.row, props.rowClass)}
          rowSelection="multiple"
          onBodyScroll={onBodyScroll}
          suppressScrollOnNewData
          suppressRowClickSelection={true}
          onSortChanged={(e: any) => onSortChanged && onSortChanged(e)}
          onSelectionChanged={e => {
            const selectedNodes = e.api.getSelectedNodes();
            if (selectedNodes.length === 0) return;

            const selectedRowIds = selectedNodes
              .map(n => n.id)
              .filter(Boolean) as string[];
            setSelectedRowIds(selectedRowIds);
          }}
          onRowClicked={(e: any) => {
            e &&
              e.event &&
              !e.event.target.classList.contains("table-toolTip-item") &&
              onRowClicked &&
              onRowClicked(e);
          }}
        />
      </div>
      <div className={cn(styles.shadow)} />
    </div>
  );
};
export default DataTable;
