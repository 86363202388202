import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./ConfirmationPopup.module.scss";

import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Condition from "../../../shared/Condition";
import Button from "../../WCMButton";

type ConfirmationPopupProps = {
  type: string;
  title: string;
  subtitle?: string;
  approveMessage: string | React.ReactNode;
  disapproveMessage: string | React.ReactNode;
  approveAction?: () => void;
  disapproveAction?: () => void;
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  type,
  approveMessage,
  disapproveMessage,
  approveAction,
  disapproveAction,
  title,
  subtitle
}) => {
  const modal = useSelector(modalSelector);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type.includes(type)}
      onRequestClose={disapproveAction}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          <Condition
            condition={!!subtitle}
            Truthy={<span className={styles.subtitle}>{subtitle}</span>}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button variant="text" onClick={disapproveAction}>
            {disapproveMessage}
          </Button>
          <Condition
            condition={typeof approveMessage === "string"}
            Truthy={<Button onClick={approveAction}>{approveMessage}</Button>}
            Falsy={approveMessage}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationPopup;
