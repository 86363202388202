import React from "react";
import { components, OptionProps } from "react-select";

import styles from "../SelectField.module.scss";

const Option: React.FC<OptionProps> = ({ children, ...rest }) => {
  return (
    <components.Option {...rest}>
      <div className={styles.option}>{children}</div>
    </components.Option>
  );
};

export default Option;
