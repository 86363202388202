import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import styles from "./AnnouncementModal.module.scss";

import Condition from "../../../../components/shared/Condition";
import { ConfirmationPopup } from "../../../../components/userManagement/Modals/ConfirmationPopup";
import Button from "../../../../components/userManagement/WCMButton";
import { useActions } from "../../../../hooks/useActions";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import {
  changeTimeZone,
  convertUTCToZone,
  getCurrentTimeZoneOption
} from "../../../../utils/time";
import {
  Announcement,
  AnnouncementFormValues,
  CreateAnnouncement,
  DeleteAnnouncement,
  EndAnnouncement,
  UpdateAnnouncement
} from "../../Announcements.types";
import { modalTypes } from "../../constants";
import { mapResponseToAnnouncement } from "../../utils";
import AnnouncementForm from "./AnnouncementForm";
import AnnouncementReview from "./AnnouncementReview";

type AnnouncementModalProps = {
  clearCurrentAnnouncement: () => void;
  createAnnouncement: CreateAnnouncement;
  currentAnnouncement: Announcement | null;
  endAnnouncement: EndAnnouncement;
  updateAnnouncement: UpdateAnnouncement;
  deleteAnnouncement: DeleteAnnouncement;
};

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
  currentAnnouncement,
  clearCurrentAnnouncement,
  endAnnouncement,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement
}) => {
  const initialTimeZone = getCurrentTimeZoneOption();

  const defaultValues = {
    startDate: DateTime.now()
      .startOf("minute") // Round to minutes
      .toJSDate(),
    endDate: DateTime.now()
      .startOf("minute") // Round to minutes
      .plus({ minutes: 30 })
      .toJSDate(),
    timeZone: initialTimeZone,
    message: "",
    href: "",
    link: "",
    withLink: false
  };
  const currentAnnouncementValues = mapResponseToAnnouncement(
    currentAnnouncement
  );

  const methods = useForm<AnnouncementFormValues>({
    defaultValues: currentAnnouncementValues || defaultValues
  });

  const { control, resetField, handleSubmit, formState, reset } = methods;

  const [isReview, setIsReview] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isEndLoading, setIsEndLoading] = useState(false);
  const modal = useSelector(modalSelector);
  const { SetOpenModalAction } = useActions();

  const timeZoneField = useWatch({ control, name: "timeZone" });
  const startDate = useWatch({ control, name: "startDate" });
  const endDate = useWatch({ control, name: "endDate" });
  useEffect(() => {
    const time = convertUTCToZone(
      timeZoneField.value,
      DateTime.now().toJSDate()
    );
    resetField("startDate", {
      defaultValue: time.toJSDate()
    });
    resetField("endDate", {
      defaultValue: time.plus({ minutes: 30 }).toJSDate()
    });
  }, [timeZoneField.value]);
  useEffect(() => {
    const startDateTime = DateTime.fromJSDate(startDate);
    const endDateTime = DateTime.fromJSDate(endDate);
    if (startDateTime >= endDateTime) {
      resetField("endDate", {
        defaultValue: startDateTime.plus({ minutes: 30 }).toJSDate()
      });
    }
  }, [startDate, endDate]);

  const announcementType =
    Array.isArray(modal.type) &&
    modal.type?.find((type: string) => modalTypes.includes(type));

  const isEditing = announcementType === "editAnnouncement";
  const isCreating = announcementType === "createAnnouncement";
  const isEnding = announcementType === "endAnnouncement";
  const closeModal = useCallback(() => {
    setIsReview(false);
    reset(defaultValues);
    clearCurrentAnnouncement();
    const indexOfAnnouncementType = modal.type.indexOf(announcementType);
    const indexOfCancelAnnouncement = modal.type.indexOf("cancelAnnouncement");
    const type = [...modal.type];
    type.splice(indexOfCancelAnnouncement, 1);
    type.splice(indexOfAnnouncementType, 1);
    SetOpenModalAction({ isOpen: type.length ? modal.isOpen : false, type });
  }, [SetOpenModalAction, modal]);
  const openCancelConfirmationPopup = useCallback(() => {
    const type = [...modal.type, "cancelAnnouncement"];
    SetOpenModalAction({ isOpen: true, type });
  }, [SetOpenModalAction, modal]);
  const closeConfirmationPopup = useCallback(
    (key: string) => {
      const index = modal.type.indexOf(key);
      const type = [...modal.type];
      type.splice(index, 1);
      SetOpenModalAction({ ...modal, type });
    },
    [SetOpenModalAction, clearCurrentAnnouncement, reset, modal, setIsReview]
  );

  const handlePublish = async (data: any) => {
    setIsPublishLoading(true);
    try {
      const refetch = await createAnnouncement({
        type: "SYSTEM",
        message: data.message,
        startDate: changeTimeZone(data.timeZone.value, data.startDate)
          .toUTC()
          .toISO() as string,
        endDate: changeTimeZone(data.timeZone.value, data.endDate)
          .toUTC()
          .toISO() as string,
        timeZone: data.timeZone.value,
        href: data.href,
        link: data.link
      });
      await refetch();
    } finally {
      setIsPublishLoading(false);
      closeModal();
    }
  };

  const handleUpdate = async (data: any) => {
    setIsPublishLoading(true);
    try {
      const refetch = await updateAnnouncement({
        id: currentAnnouncement?.id as string,
        type: "SYSTEM",
        message: data.message,
        startDate: changeTimeZone(data.timeZone.value, data.startDate)
          .toUTC()
          .toISO() as string,
        endDate: changeTimeZone(data.timeZone.value, data.endDate)
          .toUTC()
          .toISO() as string,
        timeZone: data.timeZone.value,
        href: data.href,
        link: data.link
      });
      await refetch();
    } finally {
      setIsPublishLoading(false);
      closeModal();
    }
  };

  const handleDeleteAnnouncement = async (announcementId?: string) => {
    if (!announcementId)
      throw new Error("Announcement id is required in order to delete it");
    setIsDeleteLoading(true);
    try {
      const refetch = await deleteAnnouncement(announcementId);
      await refetch();
    } finally {
      setIsDeleteLoading(false);
      clearCurrentAnnouncement();
      closeModal();
    }
  };

  const handleEndAnnouncement = async (announcementId?: string) => {
    if (!announcementId)
      throw new Error("Announcement id is required in order to end it");
    setIsEndLoading(true);
    try {
      const refetch = await endAnnouncement(announcementId);
      await refetch();
    } finally {
      clearCurrentAnnouncement();
      setIsEndLoading(false);
      closeModal();
    }
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        className={styles.modal}
        isOpen={
          modal.isOpen &&
          Array.isArray(modal.type) &&
          modal.type.some((type: string) => modalTypes.includes(type))
        }
        onRequestClose={openCancelConfirmationPopup}
        overlayClassName={styles.overlay}
      >
        <div>
          <FormProvider {...methods}>
            <Condition
              condition={isCreating || isEditing}
              Truthy={
                <Condition
                  condition={isReview}
                  Falsy={<AnnouncementForm isEditing={isEditing} />}
                  Truthy={
                    <AnnouncementReview
                      setIsReview={setIsReview}
                      isEditing={isEditing}
                    />
                  }
                />
              }
              Falsy={
                <AnnouncementReview
                  setIsReview={setIsReview}
                  isEnding={isEnding}
                  isEditing={isEditing}
                />
              }
            />
          </FormProvider>
        </div>

        <div className={styles.buttonGroup}>
          <Button onClick={openCancelConfirmationPopup} variant="text">
            Cancel
          </Button>

          <Condition
            condition={isReview}
            Truthy={
              <Condition
                condition={isEditing}
                Truthy={
                  <Button
                    isLoading={isPublishLoading}
                    onClick={handleSubmit(handleUpdate)}
                  >
                    Confirm Changes
                  </Button>
                }
                Falsy={
                  <Button
                    isLoading={isPublishLoading}
                    onClick={handleSubmit(handlePublish)}
                  >
                    Publish
                  </Button>
                }
              />
            }
            Falsy={
              <Condition
                condition={isEnding}
                Truthy={
                  <Button
                    preIcon={{ kind: "x_octagon" }}
                    kind="warning"
                    variant="outlined"
                    onClick={() => {
                      SetOpenModalAction({
                        isOpen: true,
                        type: [...modal.type, "confirmEndAnnouncement"]
                      });
                    }}
                  >
                    End
                  </Button>
                }
                Falsy={
                  <Button
                    disabled={!formState.isValid}
                    onClick={() => setIsReview(true)}
                  >
                    {isEditing ? "Update" : "Next"}
                  </Button>
                }
              />
            }
          />
        </div>
      </Modal>
      <ConfirmationPopup
        type="cancelAnnouncement"
        approveAction={closeModal}
        approveMessage="Confirm"
        disapproveAction={() => closeConfirmationPopup("cancelAnnouncement")}
        disapproveMessage="Go Back"
        title="Cancel Announcement"
        subtitle="Are you sure you want to cancel creating a new announcement? All progres will be lost."
      />
      <ConfirmationPopup
        type="confirmEndAnnouncement"
        approveMessage={
          <Button
            kind="warning"
            onClick={() => handleEndAnnouncement(currentAnnouncement?.id)}
            variant="outlined"
            preIcon={{ kind: "x_octagon" }}
            isLoading={isEndLoading}
          >
            End Announcement
          </Button>
        }
        disapproveAction={() =>
          closeConfirmationPopup("confirmEndAnnouncement")
        }
        disapproveMessage="Go Back"
        title="End Announcement?"
        subtitle="Are you sure you want to end this announcement?"
      />
      <ConfirmationPopup
        type={"deleteAnnouncement"}
        title={"Delete Announcement?"}
        approveMessage={
          <Button
            kind="warning"
            onClick={() => handleDeleteAnnouncement(currentAnnouncement?.id)}
            variant="outlined"
            preIcon={{ kind: "bin" }}
            isLoading={isDeleteLoading}
          >
            Delete
          </Button>
        }
        disapproveAction={() => closeConfirmationPopup("deleteAnnouncement")}
        disapproveMessage="Go Back"
      />
    </>
  );
};

export default AnnouncementModal;
