import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import cn from "classnames";

import styles from "./RequestAdvanceModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as MailIcon } from "../../../../icons/email_white.svg";
import { ReactComponent as GlobIcon } from "../../../../icons/globus_white.svg";
import { ReactComponent as ProfileIcon } from "../../../../icons/profile_icon.svg";

import { useActions } from "../../../../hooks/useActions";
import { oktaAuth } from "../../../../lib/oktaAuth";
import {
  payeeCodeSelector,
  profileSelector,
  singleClientSelector
} from "../../../../store/appStatic/selectors";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import { textParams } from "../../../../utils";
import Button, { ButtonType } from "../../UiButton";
import TextField from "../../UiTextField";

const RequestAdvanceModal = () => {
  const [requestBody, setRequestBody] = useState<{
    amount: string;
    message: string;
  }>({ amount: "", message: "" });
  const [confirm, setConfirm] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [requested, setRequested] = useState<boolean>(false);
  const scrolledRef = useRef<any>();
  const payeeCode = useSelector(payeeCodeSelector);
  const singleClient = useSelector(singleClientSelector);
  const { data } = singleClient;
  const { t } = useTranslation("translation");
  const modal = useSelector(modalSelector);
  const profile = useSelector(profileSelector);
  const { SetOpenModalAction, GetAdvanceRequestAction } = useActions();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [shadowPosition, setShadowPosition] = useState<string>("down");
  const isUserImpersonated = Boolean(localStorage.getItem("impersonateId"));
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  const userId = isUserImpersonated
    ? localStorage.getItem("impersonateId")
    : profile.id;
  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: `/api/users/${userId}/request-advance/${payeeCode}`
  });
  const toggleModal = useCallback(() => {
    setRequestBody({ amount: "", message: "" });
    setConfirm(false);
    SetOpenModalAction({ isOpen: false, type: "" });
    if (requested) {
      GetAdvanceRequestAction(userId, payeeCode);
    }
  }, [SetOpenModalAction, requested]);
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replaceAll(",", "").replaceAll(".", "");
    const isNumber = val
      ? !isNaN(Number(e.target.value.replaceAll(",", "").replaceAll(".", "")))
      : "";
    setRequestBody({
      ...requestBody,
      amount: isNumber ? e.target.value : ""
    });
  };
  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRequestBody({ ...requestBody, message: e.target.value });
  };
  const confirmRequest = () => {
    setConfirm(true);
  };
  const handleNavigation = useCallback(() => {
    const currentScrollTop = scrolledRef?.current?.scrollTop;
    if (currentScrollTop > scrollTop) {
      setShadowPosition("up");
    } else if (currentScrollTop < scrollTop) {
      setShadowPosition("down");
    }
    setScrollTop(currentScrollTop);
  }, [scrollTop]);
  const AdvanceServiceRequest = ({
    url,
    body = {},
    method = "GET",
    headers = {},
    params = {}
  }: any) => {
    const config: any = {
      method,
      url,
      data: body,
      params
    };

    config.headers = {
      ...{
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`
      },
      ...headers
    };
    return axiosInstance(config);
  };
  const requestAdvance = async () => {
    setLoading(true);
    const amount = Number(
      requestBody.amount.replaceAll(",", "").replaceAll(".", "")
    );
    await AdvanceServiceRequest({
      method: "POST",
      body: {
        amount: amount,
        message: requestBody.message,
        amountFormatted: new Intl.NumberFormat(navigator.language, {
          currency: ledgers?.currency,
          style: "currency",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(amount)
      }
    })
      .then(() => {
        setRequested(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };
  return (
    <div className={styles.wrapper}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "requestModal"}
        onRequestClose={toggleModal}
        overlayClassName={styles.overlay}
        className={cn(
          styles.popup,
          error
            ? styles.popup_medium
            : !requested
            ? styles.popup_big
            : styles.popup_small
        )}
      >
        {error ? (
          <div>
            <h2 className={cn(styles.title)}>
              {t(`advancesPage.errorPopup.title`)}
            </h2>
            <p>{t(`advancesPage.errorPopup.msg`)}</p>
            <div className={cn(styles.buttons)}>
              <Button
                variant={ButtonType.Text}
                onClick={() => {
                  setConfirm(false);
                  setError(false);
                  toggleModal();
                }}
              >
                {t(`buttons.cancel`)}
              </Button>
              <Button
                variant={ButtonType.Primary}
                onClick={() => {
                  setConfirm(false);
                  setError(false);
                }}
                loading={loading}
                disabled={loading}
              >
                {t(`buttons.tryAgain`)}
              </Button>
            </div>
          </div>
        ) : !requested ? (
          <div>
            <h2 className={cn(styles.title)}>
              {t(`advancesPage.title`)}
              <CloseIcon
                className={cn(styles.icon, styles.closeIcon)}
                onClick={() => toggleModal()}
              />
            </h2>
            <div className={cn(styles.info)}>
              <ProfileIcon className={cn(styles.icon)} />
              <p>
                <b>{data?.fullName}</b>
                <br />
                <span>{payeeCode}</span>
              </p>
            </div>
            <div className={cn(styles.info)}>
              <MailIcon className={cn(styles.icon)} />
              <span>
                <b>{data?.profile?.email}</b>
              </span>
            </div>
            <div className={cn(styles.info)}>
              <GlobIcon className={cn(styles.icon)} />
              <span>
                <b>{data?.profile?.location}</b>
              </span>
            </div>
            <div className={cn(styles.input)}>
              {confirm ? (
                <span>
                  <b>
                    {t(`advancesPage.requestPopup.requestAdvanceAmount`)}{" "}
                    {requestBody.amount
                      ? new Intl.NumberFormat("us-US", {
                          currency: ledgers?.currency,
                          style: "currency",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(
                          Number(
                            requestBody.amount
                              .replaceAll(",", "")
                              .replaceAll(".", "")
                          )
                        )
                      : ""}
                  </b>
                </span>
              ) : (
                <div className={cn(styles.input_field)}>
                  <TextField
                    icon={
                      <span className={cn(styles.input_currency)}>
                        {Intl.NumberFormat(navigator.language, {
                          currency: ledgers?.currency,
                          style: "currency",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                          .format(0)
                          .replace(/[\d., ]/g, "")}
                      </span>
                    }
                    className={cn(styles.input_withCurrency)}
                    value={
                      requestBody.amount
                        ? new Intl.NumberFormat("us-US").format(
                            Number(
                              requestBody.amount
                                .replaceAll(",", "")
                                .replaceAll(".", "")
                            )
                          )
                        : ""
                    }
                    onChange={handleChangeInput}
                    name="amount"
                    type="text"
                    label={`${t(
                      "advancesPage.requestPopup.inputAdvanceAmount"
                    )}*`}
                  />
                </div>
              )}
            </div>
            <div className={cn(styles.textarea)}>
              {confirm ? (
                <>
                  {requestBody.message.length ? (
                    <label htmlFor="textarea">
                      <b>
                        {t(
                          `advancesPage.requestPopup.textAreaAdditionalInformation`
                        )}
                      </b>
                    </label>
                  ) : null}
                  <div
                    className={cn(
                      shadowPosition === "up"
                        ? styles.wrap_shadowUp
                        : styles.wrap_shadowDown
                    )}
                  >
                    <div className={cn(styles.shadow_top, styles.shadow)} />
                    <div
                      className={cn(styles.information)}
                      ref={scrolledRef}
                      onScroll={handleNavigation}
                    >
                      {requestBody.message}
                    </div>
                    <div className={cn(styles.shadow_bottom, styles.shadow)} />
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="textarea">
                    <b>
                      {t(
                        `advancesPage.requestPopup.textAreaAdditionalInformation`
                      )}
                    </b>
                  </label>
                  <textarea
                    id="textarea"
                    value={requestBody.message || ""}
                    name="message"
                    onChange={handleChangeTextArea}
                    placeholder={t(
                      `advancesPage.requestPopup.textAreaAdditionalInformation2`
                    )}
                  />
                </>
              )}
            </div>
            <div className={cn(styles.buttons)}>
              {confirm ? (
                <>
                  <Button
                    variant={ButtonType.Text}
                    onClick={() => setConfirm(false)}
                  >
                    {t(`buttons.back`)}
                  </Button>
                  <Button
                    variant={ButtonType.Primary}
                    onClick={requestAdvance}
                    loading={loading}
                    disabled={loading}
                  >
                    {t(`buttons.requestAdvance`)}
                  </Button>
                </>
              ) : (
                <Button
                  variant={ButtonType.Primary}
                  disabled={!requestBody.amount}
                  onClick={confirmRequest}
                >
                  {t(`buttons.next`)}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            <h2 className={cn(styles.title_small)}>
              {t(`advancesPage.successPopup.advanceRequested`)}
            </h2>
            <p className={cn(styles.success_text)}>
              {t(`advancesPage.successPopup.successInformation`)}
            </p>
            <div className={cn(styles.questions)}>
              <p>
                {textParams(t(`advancesPage.successPopup.haveAnyQuestions`))[0]}{" "}
                <Link to="/faq" onClick={() => toggleModal()}>
                  <span>
                    {
                      textParams(
                        t(`advancesPage.successPopup.haveAnyQuestions`)
                      )[1]
                    }
                  </span>
                </Link>
                {textParams(t(`advancesPage.successPopup.haveAnyQuestions`))[2]}{" "}
                <Link to="/contact-us">
                  <span onClick={() => toggleModal()}>
                    {
                      textParams(
                        t(`advancesPage.successPopup.haveAnyQuestions`)
                      )[3]
                    }
                  </span>
                </Link>
                {textParams(t(`advancesPage.successPopup.haveAnyQuestions`))[4]}{" "}
              </p>
              <Button
                variant={ButtonType.Primary}
                disabled={!requestBody.amount}
                onClick={() => {
                  toggleModal();
                }}
              >
                {t(`buttons.close`)}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};
export default RequestAdvanceModal;
