import React from "react";
import { useLocation } from "react-router";

import { getUrlParams } from "../../../utils";
import PostLoginMobileFaq from "./post-login-mobile/postLoginMobileFaq";
import PreLoginMobileFaq from "./pre-login-mobile/preLoginMobileFaq";

const FaqMobile = () => {
  const params = getUrlParams(useLocation().search);

  if (params.postLogin === "true") {
    return <PostLoginMobileFaq />;
  } else {
    return <PreLoginMobileFaq />;
  }
};

export default FaqMobile;
