import { Reducer } from "redux";

import { contactUsQuestions, country, languages } from "./constants";
import { AppStaticTypes, IStaticActions, IStaticState } from "./types";

const countryCode = localStorage.getItem("countryCode") || "";

/**
 * Gets payee code from the current URL address or local storage
 * @returns Payee code or empty string
 */
const getPayeeCode = (): string => {
  const queryParam = new URL(window.location.href).searchParams.get(
    "payee-code"
  );
  if (queryParam) {
    localStorage.setItem("payeeCode", queryParam);
  }
  return localStorage.getItem("payeeCode") || "";
};

export const appStaticInitialState: IStaticState = {
  preselectedPayeeCode: "",
  countryCode: countryCode,
  payeeCode: getPayeeCode(),
  country: country,
  errors: [],
  error: "",
  languages: languages,
  clients: [],
  clientEmailType: [],
  profile: null,
  loading: false,
  impersonateLn: "",
  singleClient: { data: null },
  contactUsQuestions: contactUsQuestions
};

export const appStaticReducer: Reducer<IStaticState, IStaticActions> = (
  state = appStaticInitialState,
  action
) => {
  switch (action.type) {
    case AppStaticTypes.GET_LANGUAGE_SUCCESS_ACTION: {
      return {
        ...state,
        impersonateLn: action.payload
      };
    }
    case AppStaticTypes.APP_STATIC_ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case AppStaticTypes.CHANGE_LANGUAGE_SUCCESS_ACTION: {
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: { ...state.profile.settings, ...action.payload.data }
        }
      };
    }
    case AppStaticTypes.CHANGE_IS_TERM_ACCEPTED_ACTION: {
      return {
        ...state,
        profile: { ...state.profile, settings: action.payload.data }
      };
    }
    case AppStaticTypes.CHANGE_EMAIL_NOTIFICATION_SETTING_ACTION: {
      return {
        ...state,
        profile: { ...state.profile, settings: action.payload.data }
      };
    }
    case AppStaticTypes.GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload
      };
    }
    case AppStaticTypes.APP_STATIC_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case AppStaticTypes.RESET_PROFILE_STATE: {
      return {
        ...state,
        profile: null
      };
    }
    case AppStaticTypes.CLEAR_CLIENT: {
      return {
        ...state,
        clients: []
      };
    }
    case AppStaticTypes.PROFILE_ACTION_SUCCESS: {
      const isSuperAdmin = action?.payload?.data?.role === "SUPER_ADMIN";
      const clients = action?.payload.clients.map((el: any, index: number) => {
        return {
          ...el,
          preferableLanguage: action?.payload.preferableLanguage,
          slug: `CLIENT_${index + 1}`,
          emailType: action?.payload?.emailType,
          email: action?.payload.email,
          profile: action.payload.profile
        };
      });
      return {
        ...state,
        profile: { ...action?.payload?.data, isSuperAdmin },
        clients: clients
      };
    }
    case AppStaticTypes.SET_SINGLE_CLIENT: {
      return {
        ...state,
        singleClient: action.payload
      };
    }
    case AppStaticTypes.SET_COUNTRY_CODE: {
      return {
        ...state,
        countryCode: action.payload
      };
    }
    case AppStaticTypes.SET_PAYEE_CODE: {
      return {
        ...state,
        payeeCode: action.payload
      };
    }
    case AppStaticTypes.SET_PRESELECTED_PAYEE_CODE: {
      return {
        ...state,
        preselectedPayeeCode: action.payload
      };
    }
    default:
      return state;
  }
};
