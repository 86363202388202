import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationDA from "./translations/DA.json";
import translationDE from "./translations/DE.json";
import translationEN from "./translations/EN.json";
import translationES from "./translations/ES.json";
import translationFI from "./translations/FI.json";
import translationFR from "./translations/FR.json";
import translationIT from "./translations/IT.json";
import translationKO from "./translations/KO.json";
import translationLAS from "./translations/LAS.json";
import translationMS from "./translations/MS.json";
import translationNL from "./translations/NL.json";
import translationNO from "./translations/NO.json";
import translationPT from "./translations/PT.json";
import translationRU from "./translations/RU.json";
import translationSC from "./translations/SC.json";
import translationSV from "./translations/SV.json";
import translationZH from "./translations/ZH.json";
import { getUserLocalLanguage } from "./utils";

const resources = {
  EN: {
    translation: translationEN
  },
  FR: {
    translation: translationFR
  },
  DA: {
    translation: translationDA
  },
  DE: {
    translation: translationDE
  },
  ES: {
    translation: translationES
  },
  FI: {
    translation: translationFI
  },
  IT: {
    translation: translationIT
  },
  KO: {
    translation: translationKO
  },
  LAS: {
    translation: translationLAS
  },
  MS: {
    translation: translationMS
  },
  NL: {
    translation: translationNL
  },
  NO: {
    translation: translationNO
  },
  PT: {
    translation: translationPT
  },
  RU: {
    translation: translationRU
  },
  SC: {
    translation: translationSC
  },
  SV: {
    translation: translationSV
  },
  ZH: {
    translation: translationZH
  }
};

const userLang = getUserLocalLanguage();

const fallbackLng = localStorage.getItem("i18nextLng") || userLang;

const languageDetector = new LanguageDetector();

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    debug: true,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ["localStorage"],
      lookupFromPathIndex: 0,
      cashes: [localStorage]
    },
    fallbackLng,
    resources
  });

export default i18n;
