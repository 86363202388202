import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { Route, Switch, useHistory } from "react-router-dom";

import styles from "./StatementUpload.module.scss";

import { sharedModalTypes } from "../../components/shared/Modals/Modals.constants";
import Tabs from "../../components/userManagement/ReusableTabs/Tabs";
import Button from "../../components/userManagement/WCMButton";
import { useActions } from "../../hooks/useActions";
import { Header, History, Saved, Upload } from "./components";
import { tabs } from "./constants";
const StatementUpload = () => {
  const history = useHistory();
  const location = useLocation();
  const { SetOpenModalAction } = useActions();
  const urlHistory = location.pathname.includes("/history");

  const openDownloadReportPopup = useCallback(() => {
    SetOpenModalAction({
      isOpen: true,
      type: [sharedModalTypes.DOWNLOAD_REPORT_MODAL]
    });
  }, [SetOpenModalAction]);
  return (
    <>
      <div className={styles.wrapper}>
        <Header>
          <Tabs tabs={tabs} />
          <div className={styles.buttonsContainer}>
            {urlHistory && (
              <Button
                size="small"
                onClick={openDownloadReportPopup}
                preIcon={{ kind: "file_dnld_icon", iconColor: "#000000" }}
              >
                Download Report
              </Button>
            )}

            <Button
              size="small"
              onClick={() => {
                history.push("/user-management");
              }}
              preIcon={{ kind: "exit" }}
            >
              Exit Uploader
            </Button>
          </div>
        </Header>

        <Switch>
          <Route
            path={["/user-management/upload/history"]}
            component={History}
            exact
          />
          <Route
            path={["/user-management/upload/saved"]}
            component={Saved}
            exact
          />
          <Route
            path={[
              "/user-management/upload/uploader/",
              "/user-management/upload/uploader/:savedDocumentType/:savedBatchId"
            ]}
            component={Upload}
            exact
          />
        </Switch>
      </div>
    </>
  );
};

export default StatementUpload;
