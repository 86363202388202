import { Tooltip } from "@material-ui/core";
import classNames from "classnames";

import styles from "./PriorPeriodInfoItem.module.scss";
import { ReactComponent as InfoIcon } from "../../../../icons/info.svg";

import { PriorPeriodInfoItemProps } from "./PriorPeriodInfoItem.types";

/**
 * Renders info row for prior period
 * @param props React component properties
 * @returns JSX element
 */
const PriorPeriodInfoItem = (
  props: PriorPeriodInfoItemProps
): JSX.Element | null => {
  if (props.hidden) return null;

  return (
    <div className={classNames(styles.container, props.className)}>
      <div>
        <div className={styles.label}>{props.label}</div>
        {props.tooltip && (
          <Tooltip title={props.tooltip}>
            <InfoIcon className={styles.icon} />
          </Tooltip>
        )}
      </div>
      <div className={styles.value}>{props.value}</div>
    </div>
  );
};

export default PriorPeriodInfoItem;
