import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import styles from "./LandingMobileCard.module.scss";

import Card from "../../../../components/shared/Card";
import { useActions } from "../../../../hooks/useActions";

const LandingMobileCard = ({ data }: any) => {
  const history = useHistory();
  const { setPayeeCodeAction } = useActions();
  const handleClick = useCallback(el => {
    setPayeeCodeAction(el.payeeCode);
    history.push(`home`);
  }, []);
  return (
    <>
      {data?.map((el: any) => (
        <Card
          key={el?.payeeAccount}
          handleCardClick={() => handleClick(el)}
          className={styles.card}
        >
          <Card.Content>
            <Card.Items
              label={
                <div>
                  <p>{el?.payeeAccount}</p>
                  <p className={styles.dealId}>{el?.payeeCode}</p>
                </div>
              }
              title={" "}
            />
          </Card.Content>
        </Card>
      ))}
    </>
  );
};

export default LandingMobileCard;
