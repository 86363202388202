/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-key */
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import cx from "classnames";

import styles from "./Table.module.scss";

import Icon from "../Icon";
import LoadingCircle from "../LoadingCircle";
import PaginationNumbers from "../Pagination";

export interface Props {
  className?: string;
  isWhitePagination?: boolean;
  defaultPage?: number;
  defaultPageSize?: number;
  size?: "sm" | "md" | "lg";
  columns: any[];
  data: any[];
  defaultSortBy?: { id: string; desc: boolean }[];
  onSort?: (props: { pageSize: number; sortBy: any }) => void;
  fetchData?: (props: { pageSize: number; pageIndex: number }) => void;
  totalItems?: number;
  onRowClick?: (row: unknown, e: any, id: number) => void;
  hidePagination?: boolean;
  hiddenColumns?: string[];
  callapsComponent?: any;
  callapsClassName?: any;
  onChangeSize?: any;
  loading?: any;
  isMobile?: boolean;
}

const Table: React.FC<Props> = ({
  className,
  callapsClassName,
  data,
  columns,
  isMobile = false,
  size,
  onSort,
  defaultSortBy,
  defaultPageSize = 10,
  fetchData,
  loading,
  defaultPage,
  onRowClick = () => ({}),
  onChangeSize = () => ({}),
  totalItems = 0,
  hidePagination = false,
  isWhitePagination = false,
  hiddenColumns = [],
  callapsComponent = null
}) => {
  const { t } = useTranslation("translation");
  const [pageCount, setPageCount] = useState(0);
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 300
    }),
    []
  );

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSortBy ?? [],
        pageIndex: defaultPage ?? 0,
        pageSize: defaultPageSize,
        hiddenColumns
      },
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      pageCount,
      defaultColumn
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy }
  } = tableProps;

  useEffect(() => {
    if (defaultPage === 0) {
      gotoPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / defaultPageSize));
  }, [totalItems, pageSize, defaultPageSize]);

  useEffect(() => {
    if (!isMobile) {
      onSort?.({ sortBy, pageSize });
    }
  }, [onSort, sortBy, pageSize, isMobile]);

  const handleRowClick = (
    event: React.MouseEvent,
    id: number,
    row: unknown
  ) => {
    const selection = window?.getSelection()?.toString() ?? "";
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(row, event, id);
    }
  };

  return (
    <div>
      {!isMobile && (
        <table
          {...getTableProps()}
          className={cx(styles.table, styles[size as string], className)}
        >
          <>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: "" })
                      )}
                      className={column?.headerClass}
                    >
                      {column.render("Header")}
                      {!column?.withoutSort && (
                        <button title="">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon kind="sort_down" />
                            ) : (
                              <Icon kind="sort_up" />
                            )
                          ) : (
                            <Icon kind="sort" />
                          )}
                        </button>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <div className={styles.row} key={row.original.id}>
                    <tr
                      {...row.getRowProps()}
                      onClick={event =>
                        handleRowClick(event, row.original.id, row)
                      }
                      onMouseLeave={() => {
                        if (row?.original?.setLeft) {
                          row.original.setLeft(0);
                        }
                      }}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            className={cell.column.className}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    <div
                      className={cx(styles.isOpen, {
                        [styles.open]: row.original.isOpen,
                        [callapsClassName]: row.original.isOpen
                      })}
                    >
                      {callapsComponent && callapsComponent(row)}
                    </div>
                  </div>
                );
              })}
              {!data.length && (
                <div className={styles.noResult}>{t(`common.noResult`)}</div>
              )}
              <p className={cx({ [styles.loading]: loading })}>
                {loading ? <LoadingCircle isL /> : ""}
              </p>
            </tbody>
          </>
        </table>
      )}
      {!hidePagination && (
        <div className={styles.paginationWrap}>
          {pageCount > 1 && (
            <PaginationNumbers
              changePageRequest={(page: any) => {
                gotoPage(page);
                if (fetchData) {
                  fetchData({ pageIndex: page, pageSize });
                }
              }}
              totalAmount={pageCount}
              currentPage={pageIndex + 1}
              isWhite={isWhitePagination}
              onChangeSize={onChangeSize}
              size={defaultPageSize}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Table);
