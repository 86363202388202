import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./PrivacyPolicy.module.scss";
import { ReactComponent as ChevronDownGreyIcon } from "../../icons/chevrons_down_grey.svg";
import { ReactComponent as ChevronUpGreyIcon } from "../../icons/chevrons_up_grey.svg";

const PrivacyPolicy = () => {
  const [openedRows, toggleRows] = useState<any>([]);
  const { t } = useTranslation("translation");
  const toggleRowHandler = (row: any) => {
    let updatedRows = [...openedRows];
    if (openedRows.includes(row)) {
      updatedRows = openedRows.filter((el: any) => el !== row);
    } else {
      updatedRows.push(row);
    }
    toggleRows(updatedRows);
  };

  return (
    <div className="container">
      <div className={styles.privacy}>
        <div className={styles.header}>{t(`privacyPolicy.title`)}</div>
        <div className={styles.content}>
          <div className={styles.updateTxt}>
            {t(`privacyPolicy.lastUpdated`)}2022.03.01
          </div>
          <br />

          <div className={styles.txt}>
            <Trans
              i18nKey="privacyPolicy.block1"
              components={{
                controllers: (
                  <a
                    className={styles.underline}
                    href="#section-1"
                    onClick={() => toggleRowHandler(1)}
                  />
                )
              }}
            />
            <br />
            <br />
            <Trans i18nKey="privacyPolicy.block2" />
            <br />
            <br />
            <Trans
              i18nKey="privacyPolicy.block3"
              components={{
                countrySpecific: (
                  <a
                    className={styles.underline}
                    href="#section-2"
                    onClick={() => toggleRowHandler(10)}
                  />
                ),
                privacy: (
                  <a
                    className={styles.underline}
                    href="#section-3"
                    onClick={() => toggleRowHandler(11)}
                  />
                )
              }}
            />
            <br />
            <br />
            {t(`privacyPolicy.block4`)}
            <br />
            <br />
            <br />
            <br />
            <span className={styles.headTxt2}>
              {" "}
              {t(`privacyPolicy.quickGuide`)}
            </span>
          </div>
          <div className={`${styles.toggleRows} ${styles.txt}`}>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-1"}
                onClick={() => toggleRowHandler(1)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>1</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(1) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section1`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(1) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(1) && (
                <div className={styles.rowContent}>
                  <div style={{ display: "flex" }}>
                    <div>1.1</div>
                    <div className={styles.subContent}>
                      <Trans i18nKey="privacyPolicy.section1_1" />
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>1.1.1</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section1_1_1" /> U.S.A.
                          <br />
                          <br />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>1.1.2</div>
                        <div className={styles.subContent}>
                          <Trans
                            i18nKey="privacyPolicy.section1_1_2"
                            components={{
                              ToAffiliates: (
                                <a
                                  className={styles.underline}
                                  href={
                                    "https://www.wminewmedia.com/affiliates/"
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                />
                              )
                            }}
                          />
                          <br />
                          <br />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>1.1.3</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section1_1_3" /> UK.
                          <br />
                          <br />
                        </div>
                      </div>
                      {t(`privacyPolicy.section1_1Text`)}
                      <br />
                      <br />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>1.2</div>
                    <div className={styles.subContent}>
                      <Trans
                        i18nKey="privacyPolicy.section1_2"
                        components={{
                          linkTo: (
                            <a
                              className={styles.underline}
                              href="mailto:privacypolicy@wmg.com"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(2)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>2</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(2) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section2`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(2) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(2) && (
                <div className={styles.rowContent}>
                  {t(`privacyPolicy.section2Text1`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section2Text2`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section2Text3`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section2Text4`)}
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>2.1</div>
                    <div className={styles.subContent}>
                      <Trans i18nKey="privacyPolicy.section2_1" />
                      <br />
                      <br />
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section2_1Title1`)}
                          </span>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section2_1Title2`)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {" "}
                            {t(`privacyPolicy.section2_1Title1_1`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text1_1`)}
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text1_2`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_1Title2_1`)}
                          </span>
                          <br />
                          <br />
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_1Text2_1_1`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_1Text2_1_2`)}</div>
                          </div>
                          <br />
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_1Text2_1_3`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text2_1_4`)}
                          <br />
                          <br />
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_1Title2_3`)}{" "}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text2_3`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_1Title2_4`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text2_4`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_1Title2_5`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text2_5`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_1Title2_6`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_1Text2_6`)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }} className={styles.marginTop}>
                    <div>2.2</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section2_2`)}
                      </span>
                      <br />
                      <br />
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section2_2Title1`)}
                          </span>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section2_2Title2`)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_2Title3`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_2Text1_1`)}
                          <br />
                          <br />
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text1_1_1`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text1_1_2`)}</div>
                          </div>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_2Title4`)}
                          </span>
                          <br />
                          <br />
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text1_2_1`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div>
                              {" "}
                              {t(`privacyPolicy.section2_2Text1_2_2`)}.
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text1_2_3`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text1_2_4`)}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_2Title2_1_1`)}{" "}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_2Text2_1_1`)}
                          <br />
                          <br />
                          <span className={styles.headTxt}>
                            {t(`privacyPolicy.section2_2Text2_1_2`)}
                          </span>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_2Title2_1_2`)}
                          </span>
                          <br />
                          <br />
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_3`)}</div>
                          </div>
                          <br />
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_2Title2_1_3`)}
                          </span>
                          <br />
                          <br />
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_4`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_5`)}</div>
                          </div>
                          <br />
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_2Title2_1_4`)}
                          </span>
                          <br />
                          <br />
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_6`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_7`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_8`)}</div>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                            <div> {t(`privacyPolicy.section2_2Text2_1_9`)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }} className={styles.marginTop}>
                    <div>2.3</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section2_3`)}
                      </span>
                      <br />
                      <br />
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section2_3Title1`)}
                          </span>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section2_3Title2`)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_1`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_1`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_2`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_2`)}
                          <br />
                          <br />
                          <br />
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_3`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_3`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_4`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_4`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_2`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_5`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_6`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_6`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_2`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_7`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_8`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_8`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.italicTxt}>
                            {t(`privacyPolicy.section2_3Title1_9`)}
                          </span>
                          <br />
                          <br />
                          {t(`privacyPolicy.section2_3Text1_9`)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(3)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>3</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(3) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section3`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(3) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(3) && (
                <div className={styles.rowContent}>
                  {t(`privacyPolicy.section3Title`)}
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div>
                      <Trans
                        i18nKey="privacyPolicy.section3Text1"
                        components={{
                          ToAffiliates: (
                            <a
                              className={styles.underline}
                              href={"https://www.wminewmedia.com/affiliates/"}
                              target="_blank"
                              rel="noreferrer"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section3Text2`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section3Text3`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section3Text4`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section3Text5`)}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(4)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>4</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(4) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section4`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(4) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(4) && (
                <div className={styles.rowContent}>
                  {t(`privacyPolicy.section4Text1`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section4Text2`)}
                  <br />
                  <br />

                  <span className={styles.orangeTxt}>
                    <Trans
                      i18nKey="privacyPolicy.section4Text3"
                      components={{
                        countrySpecific: (
                          <a
                            className={styles.underline}
                            href="#section-2"
                            onClick={() => toggleRowHandler(10)}
                          />
                        )
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(5)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>5</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(5) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section5`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(5) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(5) && (
                <div className={styles.rowContent}>
                  {t(`privacyPolicy.section5Title1`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section5Title2`)}
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section5Text1`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section5Text2`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section5Text3`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                    <div> {t(`privacyPolicy.section5Text4`)}</div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(6)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>6</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(6) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section6`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(6) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(6) && (
                <div className={styles.rowContent}>
                  {t(`privacyPolicy.section6Text`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(7)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>7</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(7) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section7`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(7) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(7) && (
                <div className={styles.rowContent}>
                  <Trans
                    i18nKey="privacyPolicy.section7Text_1"
                    components={{
                      linkTo: (
                        <a
                          className={styles.underline}
                          href="mailto:privacypolicy@wmg.com"
                        />
                      )
                    }}
                  />
                  <br />
                  <br />
                  {t(`privacyPolicy.section7Text_2`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section7Text_3`)}
                  <br />
                  <br />
                  <span className={styles.orangeTxt}>
                    <Trans
                      i18nKey="privacyPolicy.section7Text_4"
                      components={{
                        countrySpecific: (
                          <a
                            className={styles.underline}
                            href="#section-2"
                            onClick={() => toggleRowHandler(10)}
                          />
                        ),
                        privacy: (
                          <a
                            className={styles.underline}
                            href="#section-3"
                            onClick={() => toggleRowHandler(11)}
                          />
                        ),
                        underline: <u />
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(8)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>8</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(8) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section8`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(8) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(8) && (
                <div className={styles.rowContent}>
                  {t(`privacyPolicy.section8Text`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div className={styles.row} onClick={() => toggleRowHandler(9)}>
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>9</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(9) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section9`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(9) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(9) && (
                <div className={styles.rowContent}>
                  <Trans
                    i18nKey="privacyPolicy.section8Text_1"
                    components={{
                      linkTo: (
                        <a
                          className={styles.underline}
                          href="mailto:privacypolicy@wmg.com"
                        />
                      )
                    }}
                  />
                  .
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_2`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_3`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_4`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_5`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_6`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_7`)}
                  <br />
                  <br />
                  <div>UK</div>
                  <br />
                  {t(`privacyPolicy.section8Text_8`)}
                  <br />
                  <br />
                  {t(`privacyPolicy.section8Text_9`)}
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-2"}
                onClick={() => toggleRowHandler(10)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>10</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(10) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section10`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(10) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(10) && (
                <div className={styles.rowContent}>
                  <div style={{ display: "flex" }}>
                    <div>10.1</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_1Title_1`)}
                      </span>
                      <br />
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_1Text_1`)}
                      </span>
                      <br />
                      <br />
                      <Trans
                        i18nKey="privacyPolicy.section10_1Text_2"
                        components={{
                          linkTo: (
                            <a
                              className={styles.underline}
                              href={"mailto:privacypolicy@wmg.com"}
                            />
                          ),
                          available: (
                            <a
                              className={styles.underline}
                              target="_blank"
                              href={
                                "https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
                              }
                              rel="noreferrer"
                            />
                          ),
                          consult: (
                            <a
                              className={styles.underline}
                              target="_blank"
                              href={
                                "https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                              }
                              rel="noreferrer"
                            />
                          )
                        }}
                      />
                      <br />
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_1Text_3`)}
                      </span>
                      <br />
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_1Text_4`)}
                      </span>
                      <br />
                      <br />
                      <Trans
                        i18nKey="privacyPolicy.section10_1Text_5"
                        components={{
                          linkTo: (
                            <a
                              className={styles.underline}
                              href="mailto:privacypolicy@wmg.com"
                            />
                          )
                        }}
                      />
                      <br />
                      <br />
                      <span className={styles.orangeTxt}>
                        {t(`privacyPolicy.section10_1Text_6`)}
                      </span>
                      <br />
                      <br />
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section10_1Title_2`)}
                          </span>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section10_1Title_3`)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_7`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_8`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_9`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_10`)}
                          <br />
                          <br />
                          {t(`privacyPolicy.section10_1Text_11`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_12`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_13`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_14`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_15`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_16`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_17`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_18`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_19`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_20`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_21`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_22`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_23`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_24`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <Trans
                            i18nKey="privacyPolicy.section10_1Text_25"
                            components={{
                              regulators: (
                                <a
                                  className={styles.underline}
                                  target="_blank"
                                  href={
                                    "https://commission.europa.eu/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en"
                                  }
                                  rel="noreferrer"
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_26`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_27`)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }} className={styles.marginTop}>
                    <div>10.2</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_2Title_1`)}
                      </span>
                      <br />
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_1Text_1`)}
                      </span>
                      <br />
                      <br />
                      <Trans
                        i18nKey="privacyPolicy.section10_2Text_2"
                        components={{
                          available: (
                            <a
                              className={styles.underline}
                              target="_blank"
                              href={
                                "https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
                              }
                              rel="noreferrer"
                            />
                          )
                        }}
                      />
                      <br />
                      <br />
                      <Trans
                        i18nKey="privacyPolicy.section10_2Text_3"
                        components={{
                          linkTo: (
                            <a
                              className={styles.underline}
                              href="mailto:privacypolicy@wmg.com"
                            />
                          ),
                          consult: (
                            <a
                              className={styles.underline}
                              target="_blank"
                              href={
                                "https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
                              }
                              rel="noreferrer"
                            />
                          )
                        }}
                      />
                      <br />
                      <br />
                      <span>{t(`privacyPolicy.section10_1Text_3`)}</span>
                      <br />
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section10_2Text_4`)}
                      </span>
                      <br />
                      <br />
                      <Trans
                        i18nKey="privacyPolicy.section10_1Text_5_2"
                        components={{
                          linkTo: (
                            <a
                              className={styles.underline}
                              href="mailto:privacypolicy@wmg.com"
                            />
                          )
                        }}
                      />
                      <br />
                      <br />
                      <span className={styles.orangeTxt}>
                        {t(`privacyPolicy.section10_1Text_6`)}
                      </span>
                      <br />
                      <br />
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section10_1Title_2`)}
                          </span>
                        </div>
                        <div className={styles.subSubContent}>
                          <span className={styles.smallBoldTxt}>
                            {t(`privacyPolicy.section10_1Title_3`)}
                          </span>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_7`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_8`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_9`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_10`)}
                          <br />
                          <br />
                          {t(`privacyPolicy.section10_1Text_11`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_12`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_13`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_14`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_15`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_16`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_17_2`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_20`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_21`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_22`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_23`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_18`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_19`)}
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_24`)}
                        </div>
                        <div className={styles.subSubContent}>
                          <Trans
                            i18nKey="privacyPolicy.section10_2Text_5"
                            components={{ underline: <u /> }}
                          />
                          <br />
                          <br />
                          <Trans
                            i18nKey="privacyPolicy.section10_2Text_6"
                            components={{ underline: <u /> }}
                          />
                          <br />
                          <br />
                          <Trans
                            i18nKey="privacyPolicy.section10_2Text_7"
                            components={{ underline: <u /> }}
                          />
                          <br />
                          <br />
                          <Trans
                            i18nKey="privacyPolicy.section10_2Text_8"
                            components={{ underline: <u /> }}
                          />{" "}
                          <a
                            className={styles.txtUnderline}
                            target={"_blank"}
                            href={"https://ico.org.uk/make-a-complaint/"}
                            rel="noreferrer"
                          >
                            https://ico.org.uk/make-a-complaint/
                          </a>
                        </div>
                      </div>
                      <div className={styles.subsSubContentWrapper}>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_26`)}
                        </div>
                        <div className={styles.subSubContent}>
                          {t(`privacyPolicy.section10_1Text_27`)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              )}
            </div>
            <div className={styles.rowWrapper}>
              <div
                className={styles.row}
                id={"section-3"}
                onClick={() => toggleRowHandler(11)}
              >
                <div className={styles.firstPart}>
                  <div className={styles.numTxt}>11</div>
                  <div
                    className={`${styles.nameTxt} ${
                      openedRows.includes(11) ? styles.opened : ""
                    }`}
                  >
                    {t(`privacyPolicy.section11`)}
                  </div>
                </div>
                <div>
                  {openedRows.includes(11) ? (
                    <ChevronUpGreyIcon />
                  ) : (
                    <ChevronDownGreyIcon />
                  )}
                </div>
              </div>
              {openedRows.includes(11) && (
                <div className={styles.rowContent}>
                  <Trans
                    i18nKey="privacyPolicy.section11Text"
                    components={{ underline: <u /> }}
                  />

                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.1</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_1Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_1Text_1`)}
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>A</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section11_1Text_2" />
                        </div>
                      </div>
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>B</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section11_1Text_3" />
                        </div>
                      </div>
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>C</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section11_1Text_4" />
                        </div>
                      </div>
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>D</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section11_1Text_5" />
                        </div>
                      </div>
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>E</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section11_1Text_6" />
                        </div>
                      </div>
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>F</div>
                        <div className={styles.subContent}>
                          <Trans i18nKey="privacyPolicy.section11_1Text_7" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.2</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_2Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_2Text`)}
                      <br />
                      <br />
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.3</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_3Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_3Text`)}
                      <br />
                      <br />
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.4</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_4Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_4Text_1`)}
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>
                          <Trans i18nKey="privacyPolicy.section11_4Text_2" />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>
                          <Trans i18nKey="privacyPolicy.section11_4Text_3" />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>
                          <Trans i18nKey="privacyPolicy.section11_4Text_4" />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>
                          <Trans i18nKey="privacyPolicy.section11_4Text_5" />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>
                          <Trans i18nKey="privacyPolicy.section11_4Text_6" />
                        </div>
                      </div>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_4Text_7`)}
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.5</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_5`)}
                      </span>
                      <br />
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_5_1Title1`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_5_1Title2`)}
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_1Text_1`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_1Text_2`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_1Text_3`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_1Text_4`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_1Text_5`)}</div>
                      </div>
                      <br />
                      {t(`privacyPolicy.section11_5_2Title`)}
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_5_2Text`)}
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_5_3Title`)}
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_3Text_1`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_3Text_2`)}</div>
                      </div>
                      <br />
                      {t(`privacyPolicy.section11_5_4Title`)}
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_4Text_1`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_4Text_2`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_4Text_3`)}</div>
                      </div>
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_5_5Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_5_5Text_1`)}
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_5_5Text_2`)}
                      <br />
                      <br />
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_5Text_3`)}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</div>
                        <div>{t(`privacyPolicy.section11_5_5Text_4`)}</div>
                      </div>
                      <br />
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_5_6Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_5_6Text`)}
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.6</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_6Title`)}
                      </span>
                      <br />
                      <br />
                      {t(`privacyPolicy.section11_6Text`)}
                      <br />
                      <br />
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div>11.7</div>
                    <div className={styles.subContent}>
                      <span className={styles.headTxt}>
                        {t(`privacyPolicy.section11_7Title`)}
                      </span>
                      <br />
                      <br />
                      <Trans
                        i18nKey="privacyPolicy.section11_7Text"
                        components={{
                          linkTo: (
                            <a
                              className={styles.underline}
                              href={"mailto:privacypolicy@wmg.com"}
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
