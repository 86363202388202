import React, { useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import cn from "classnames";

import styles from "./IncomeGroups.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import {
  circleOptions,
  colors,
  readsColor,
  yellowColors
} from "../../../configs/constants/transaction";
import {
  incomesLoadingSelector,
  incomeTypesTopSelector,
  top4IncomeTypesSelector
} from "../../../store/analytics/selectors";
import ChartCard from "../ChartCard";
import CurrencyRateShort from "../CurrencyRateShort";
ChartJS.register(ArcElement, Tooltip, Legend);

type IncomeGroupsType = {
  isInnerPage?: boolean;
};

const IncomeGroups: React.FC<IncomeGroupsType> = ({ isInnerPage }) => {
  const { t } = useTranslation();
  const top4IncomeTypes = useSelector(top4IncomeTypesSelector);
  const incomeTypesTop = useSelector(incomeTypesTopSelector);
  const incomesLoading = useSelector(incomesLoadingSelector);
  const mb = useMediaQuery({ maxWidth: 570 });
  const isDesc = useMediaQuery({ maxWidth: 1270 });
  const [step, setStep] = useState({
    title: "",
    percent: 0,
    backgroundColor: ""
  });
  const total = top4IncomeTypes?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.amount,
    0
  );

  const percents = useMemo(() => {
    const res: any = [];
    if (total && top4IncomeTypes) {
      top4IncomeTypes.forEach((el: any) => {
        res.push(((el.amount * 100) / total).toFixed(2));
      });
    }
    return res;
  }, [total, top4IncomeTypes]);

  const borderColor = useMemo(() => {
    const color = ["black", "black", "black", "black"];
    if (step.percent) {
      const index = percents.indexOf(step.percent);
      color[index] = "white";
    }
    return color;
  }, [step]);

  const handleHover = (e: any, v: any) => {
    if (v.length) {
      const backColor = v[0].element.options.backgroundColor;
      const isRed = readsColor.includes(backColor);
      const isYellow = yellowColors.includes(backColor);
      const isGrin = backColor === colors[2];
      const isBlue = backColor === colors[3];
      if (
        step.backgroundColor !== v[0].element.options.backgroundColor &&
        (colors.indexOf(v[0].element.options.backgroundColor) !== -1 ||
          isRed ||
          isYellow ||
          isGrin ||
          isBlue)
      ) {
        if (isYellow) {
          setStep({
            title: top4IncomeTypes[1].name,
            percent: percents[1],
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
        if (isRed) {
          setStep({
            title: top4IncomeTypes[0].name,
            percent: percents[0],
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
        if (isGrin) {
          setStep({
            title: top4IncomeTypes[2].name,
            percent: percents[2],
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
        if (isBlue) {
          setStep({
            title: top4IncomeTypes[3].name,
            percent: percents[3],
            backgroundColor: v[0].element.options.backgroundColor
          });
        }
      }
    } else {
      if (step.backgroundColor) {
        setStep({ title: "", percent: 0, backgroundColor: "" });
      }
    }
  };

  const data = {
    labels: colors,
    datasets: [
      {
        data: percents,
        backgroundColor: colors,
        borderColor: borderColor,
        hoverBorderColor: ["white", "white", "white", "white"],
        title: ["1", "2", "3", "4"],
        borderWidth: 2,
        hoverBorderWidth: 2
      }
    ]
  };

  if (isInnerPage) {
    return (
      <div className={styles.card}>
        <div
          className={styles.isInnerPageWrap}
          onMouseLeave={() =>
            setStep({ title: "", percent: 0, backgroundColor: "" })
          }
        >
          <Doughnut
            data={data}
            className={styles.innerCircle}
            options={{
              ...circleOptions,
              onHover: handleHover,
              onClick: handleHover,
              cutout: isDesc ? 60 : 85
            }}
          />
          <div className={styles.title}>
            <p>{step.title}</p>
            <p>{step.percent ? `${step.percent}%` : ""}</p>
          </div>
        </div>
        <div className={styles.top}>
          {top4IncomeTypes.map((el: any, i: number) => (
            <div
              key={el.amount}
              className={styles.info}
              onMouseEnter={() =>
                setStep({
                  title: el.name,
                  percent: percents[i],
                  backgroundColor: colors[i]
                })
              }
              onMouseLeave={() =>
                setStep({
                  title: "",
                  percent: 0,
                  backgroundColor: ""
                })
              }
              style={{
                border: `4px solid ${colors[i]}`,
                backgroundColor:
                  el.name === step.title ? step.backgroundColor : "inherit"
              }}
            >
              <div className={styles.index}>
                <p>{i + 1}.</p>
                <span>{el.name}</span>
              </div>
              <div className={styles.wrap}>
                <p className={styles.amount}>
                  <CurrencyRateShort
                    rate={el.amount}
                    id={`top4IncomeTypes_${i}`}
                  />
                </p>
                <p
                  className={cn(styles.percent, {
                    [styles.isIncrement]: el.direction !== "INCREMENT",
                    [styles.hovered]: el.name === step.title
                  })}
                >
                  {`${el.percentage}%`}
                  {el.direction === "INCREMENT" ? (
                    <UpIcon className={styles.upIcon} />
                  ) : (
                    <DownIcon className={styles.upIcon} />
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <ChartCard
      info={top4IncomeTypes?.length ? top4IncomeTypes : null}
      totals={incomeTypesTop}
      title={t(`analytics.topIncomeGroups`)}
      totalTitle={t(`analytics.allIncomeTypes`)}
      to={"incomeGroups"}
      isLoading={incomesLoading}
      name="incomeGroups"
    >
      {!incomesLoading && !!top4IncomeTypes?.length && (
        <div
          className={styles.wrap}
          onMouseLeave={() =>
            setStep({ title: "", percent: 0, backgroundColor: "" })
          }
        >
          <Doughnut
            data={data}
            options={{
              ...circleOptions,
              onHover: handleHover,
              cutout: mb ? 50 : 70
            }}
          />
          <div className={styles.title}>
            <p>{step.title}</p>
            <p>{step.percent ? `${step.percent}%` : ""}</p>
          </div>
        </div>
      )}
    </ChartCard>
  );
};

export default IncomeGroups;
