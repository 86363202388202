import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { profileSelector } from "../../store/appStatic/selectors";
import Loader from "../shared/Loading";

type SecurityContainerProps = {
  roles: string[];
  component: ReactElement;
};

const SecurityContainer: React.FC<SecurityContainerProps> = ({
  component,
  roles
}) => {
  const history = useHistory();
  const profile = useSelector(profileSelector);

  if (!roles.includes(profile?.role) && profile?.role) {
    history.push("/access-denied");
  }

  return (
    <>
      {!profile && !history.location.pathname.includes("user-management") && (
        <Loader />
      )}
      <>{component}</>
    </>
  );
};

export default SecurityContainer;
