import "swiper/css";

import { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./AlertCarousel.module.scss";

import Icon from "../../../../components/shared/Icon";
import { AlertItem } from "../../types/alertItem";
import AlertSlide from "../AlertSlide";
import { ITEMS_REMOVING_DELAY } from "./AlertCarousel.constants";
import { AlertCarouselProps } from "./AlertCarousel.types";

/**
 * Renders alert carousel component
 * @param props React component properties
 * @returns React component
 */
const AlertCarousel: FC<AlertCarouselProps> = props => {
  const swiperRef = useRef<SwiperCore>();

  const [activeIndex, setActiveIndex] = useState(0);
  // Separate items state is necessary to apply animation
  const [alertItems, setAlertItems] = useState<AlertItem[]>([]);
  const [isInit, setIsInit] = useState(true);

  /**
   * Adds reference to swiper core
   * @param swiper Swiper core
   */
  const onBeforeInit = (swiper: SwiperCore): void => {
    swiperRef.current = swiper;
  };

  /**
   * Switching to the next slide
   * @param e Mouse event
   */
  const onNextClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    swiperRef.current?.slideNext();
  };

  /**
   * Switching to the previous slide
   * @param e Mouse event
   */
  const onPrevClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    swiperRef.current?.slidePrev();
  };

  /**
   * Handles slide change event
   * @param swiper Swiper core
   */
  const onSlideChange = (swiper: SwiperCore): void => {
    setActiveIndex(swiper.activeIndex);
    props.onSlideChange?.();
  };

  useEffect(() => {
    setIsInit(!props.items.length);
    if (props.items.length > 0) {
      setAlertItems(props.items);
      return;
    }
    const timeoutId = setTimeout(() => {
      setAlertItems(props.items);
    }, ITEMS_REMOVING_DELAY);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.items]);

  return (
    <div
      className={classNames(styles.container, {
        [props.className ?? ""]: !isInit,
        [styles.containerInit]: isInit
      })}
    >
      <Swiper
        className={styles.swiper}
        onBeforeInit={onBeforeInit}
        onSlideChange={onSlideChange}
      >
        {alertItems.map(item => (
          <SwiperSlide key={item.id}>
            <AlertSlide
              hasFullWidthText={props.hasFullWidthText}
              hasNav={props.items.length > 1}
              item={item}
            />
          </SwiperSlide>
        ))}
        {props.items.length > 1 && (
          <div className={styles.nav}>
            <div
              className={classNames(styles.navContainer, {
                container: !props.hasFullWidthText,
                [styles.fullWidth]: props.hasFullWidthText
              })}
            >
              <div className={styles.navControlsContainer}>
                <div className={styles.navControls}>
                  <button
                    className={classNames(
                      styles.navButton,
                      styles.navPrevButton
                    )}
                    onClick={onPrevClick}
                  >
                    <Icon fillColor="currentColor" kind="chevron_right" />
                  </button>
                  <div className={styles.navText}>
                    {activeIndex + 1}/{props.items.length}
                  </div>
                  <button className={styles.navButton} onClick={onNextClick}>
                    <Icon fillColor="currentColor" kind="chevron_right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Swiper>
    </div>
  );
};

export default AlertCarousel;
