import { call, put, takeLatest } from "redux-saga/effects";

import { request } from "../../services";
import {
  getTopIncomeGroupsActionSuccess,
  getTopSongsActionSuccess,
  getTopSourcesActionSuccess,
  getTopTerritoriesActionSuccess,
  setIncomeLoadingAction,
  setSongsLoadingAction,
  setSourcesLoadingAction,
  setTerritoriesLoadingAction
} from "./actions";
import {
  AnalyticsTypes,
  IGetTopIncomeGroupsAction,
  IGetTopSongsAction,
  IGetTopSourcesAction,
  IGetTopTerritoriesAction,
  topIncomeType,
  topSourcesType,
  topWorksType
} from "./types";

const { REACT_APP_BASE_URI } = process.env;

export function* getTopSongsAsync({ payload }: IGetTopSongsAction): unknown {
  try {
    yield put(setSongsLoadingAction(true));
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/royalty/${payload.payeeCode}/${payload.data.startDate}/${payload.data.endDate}/per-top-works`
    );
    const res = {
      ...data,
      topWorks: data.topWorks.map((el: topWorksType) => {
        return {
          ...el,
          isOpen: true
        };
      })
    };
    yield put(
      getTopSongsActionSuccess({
        res: res,
        top4Songs: res.topWorks.slice(0, 4)
      })
    );
  } catch {
    yield put(
      getTopSongsActionSuccess({
        res: null,
        top4Songs: null
      })
    );
  } finally {
    yield put(setSongsLoadingAction(false));
  }
}

export function* getTopSourcesAsync({
  payload
}: IGetTopSourcesAction): unknown {
  try {
    yield put(setSourcesLoadingAction(true));
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/royalty/${payload.payeeCode}/${payload.data.startDate}/${payload.data.endDate}/per-top-sources`
    );
    const res = {
      ...data,
      topSources: data.topSources.map((el: topSourcesType) => {
        return {
          ...el,
          isOpen: true
        };
      })
    };
    yield put(
      getTopSourcesActionSuccess({
        res: res,
        top4Sources: res.topSources.slice(0, 4)
      })
    );
  } catch {
    yield put(
      getTopSourcesActionSuccess({
        res: null,
        top4Sources: null
      })
    );
  } finally {
    yield put(setSourcesLoadingAction(false));
  }
}

export function* getTopTerritoriesAsync({
  payload
}: IGetTopTerritoriesAction): unknown {
  try {
    yield put(setTerritoriesLoadingAction(true));
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/royalty/${payload.payeeCode}/${payload.data.startDate}/${payload.data.endDate}/per-territories`
    );
    const res = {
      ...data,
      topTerritories: data.territories
    };
    yield put(
      getTopTerritoriesActionSuccess({
        res: res,
        top4Territories: res.topTerritories.slice(0, 4)
      })
    );
  } catch {
    yield put(
      getTopTerritoriesActionSuccess({
        res: null,
        top4Territories: null
      })
    );
  } finally {
    yield put(setTerritoriesLoadingAction(false));
  }
}

export function* getIncomeGroupsAsync({
  payload
}: IGetTopIncomeGroupsAction): unknown {
  try {
    yield put(setIncomeLoadingAction(true));
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/royalty/${payload.payeeCode}/${payload.data.startDate}/${payload.data.endDate}/per-income-types`
    );
    const res = {
      ...data,
      incomeTypes: data?.incomeTypes.map((el: topIncomeType) => {
        return {
          ...el,
          isOpen: true
        };
      })
    };
    yield put(
      getTopIncomeGroupsActionSuccess({
        res: res,
        top4IncomeTypes: res.incomeTypes.slice(0, 4)
      })
    );
  } catch {
    yield put(
      getTopIncomeGroupsActionSuccess({
        res: null,
        top4IncomeTypes: null
      })
    );
  } finally {
    yield put(setIncomeLoadingAction(false));
  }
}

function* watchAnalyticsRequest(): Generator {
  yield takeLatest(AnalyticsTypes.GET_TOP_SONGS, getTopSongsAsync);
  yield takeLatest(AnalyticsTypes.GET_TOP_SOURCES, getTopSourcesAsync);
  yield takeLatest(AnalyticsTypes.GET_TOP_TERRITORIES, getTopTerritoriesAsync);
  yield takeLatest(AnalyticsTypes.GET_TOP_INCOME_GROUPS, getIncomeGroupsAsync);
}

export { watchAnalyticsRequest };
