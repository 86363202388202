import React from "react";
import { components, IndicatorsContainerProps } from "react-select";

import styles from "../SelectField.module.scss";

import Icons from "../../Icon";

const IndicatorsContainer: React.FC<IndicatorsContainerProps> = ({
  ...props
}) => (
  <components.IndicatorsContainer
    {...props}
    className={styles.indicatorsContainer}
  >
    <Icons kind="globus" />
  </components.IndicatorsContainer>
);

export default IndicatorsContainer;
