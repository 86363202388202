import React from "react";
import { useSelector } from "react-redux";

import { profileSelector } from "../../store/appStatic/selectors";
import PostLoginFaq from "./post-login/postLoginFaq";
import PreLoginFaq from "./pre-login/preLoginFaq";

const Faq = () => {
  const profile = useSelector(profileSelector);

  if (profile) {
    return <PostLoginFaq />;
  } else {
    return <PreLoginFaq />;
  }
};

export default Faq;
