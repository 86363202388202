import { Tooltip } from "@material-ui/core";
import classNames from "classnames";

import styles from "./CurrentPeriodInfoItem.module.scss";
import { ReactComponent as InfoIcon } from "../../../../icons/info.svg";

import { CurrentPeriodInfoItemProps } from "./CurrentPeriodInfoItem.types";

/**
 * Renders info row for current period
 * @param props React component properties
 * @returns JSX element
 */
const CurrentPeriodInfoItem = (
  props: CurrentPeriodInfoItemProps
): JSX.Element => {
  const className = classNames(
    styles.container,
    props.variant === "large" && styles.largeContainer,
    props.variant === "small" && styles.smallContainer,
    props.className
  );
  const iconClassName = classNames(
    styles.icon,
    props.variant === "large" && styles.largeIcon,
    props.variant === "small" && styles.smallIcon
  );
  const labelClassName = classNames(
    props.variant === "small" && styles.smallLabel
  );
  const valueClassName = classNames(
    styles.value,
    props.variant === "large" && styles.largeValue
  );

  return (
    <div className={className}>
      <div className={labelClassName}>
        <span className={styles.labelText}>{props.label}</span>
        {props.tooltip && (
          <Tooltip title={props.tooltip}>
            <InfoIcon className={iconClassName} />
          </Tooltip>
        )}
      </div>
      <div className={valueClassName}>{props.value}</div>
    </div>
  );
};

export default CurrentPeriodInfoItem;
