export type TrackEventFunction = (
  linkObject: boolean | HTMLElement,
  linkType: "d" | "e" | "o",
  linkName: string
) => void;

/**
 * Sends a tracking call to Adobe
 * @param linkObject Link object
 * @param linkType "d" - download link, "e" - exit link, "o" - custom link.
 * @param linkName Link name
 */
export const trackEvent: TrackEventFunction = (
  linkObject,
  linkType,
  linkName
) => {
  const adobeWindow: Window & { s?: { tl?: TrackEventFunction } } = window;
  adobeWindow.s?.tl?.(linkObject, linkType, linkName);
};
