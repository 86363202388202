export interface FilterBarType {
  id: number;
  title: string | undefined;
  count: number | string | undefined;
  active?: boolean;
}

export enum MetaDataObject {
  activeCount = "activeCount",
  inactiveCount = "inactiveCount",
  invitedCount = "invitedCount",
  registeredCount = "registeredCount",
  suspendedCount = "suspendedCount"
}

export interface MetaDataType {
  activeCount?: number;
  inactiveCount?: number;
  registeredCount?: number;
  invitedCount?: number;
  suspendedCount?: number;
}

export interface quickFiltersType {
  [index: number]: string;

  map(param: (el: string, id: number) => void): void;
}

const getQuickFilterCount = (name: string, metaData: MetaDataType) => {
  switch (name) {
    case MetaDataObject.activeCount:
      return metaData[name];
    case MetaDataObject.inactiveCount:
      return metaData[name];
    case MetaDataObject.registeredCount:
      return metaData[name];
    case MetaDataObject.invitedCount:
      return metaData[name];
    case MetaDataObject.suspendedCount:
      return metaData[name];
    case "":
      return "all";
  }
};
const getQuickFilterName = (name: string) => {
  switch (name) {
    case MetaDataObject.activeCount:
      return "Active";
    case MetaDataObject.inactiveCount:
      return "Inactive";
    case MetaDataObject.registeredCount:
      return "Registered";
    case MetaDataObject.invitedCount:
      return "Invited";
    case MetaDataObject.suspendedCount:
      return "Suspended";
    case "":
      return "all";
  }
};

export const QuickFiltersData = (
  metaData: MetaDataType | undefined,
  quickFilters: quickFiltersType,
  status?: string
) => {
  return (
    metaData &&
    quickFilters.map(
      (el: string, id: number): FilterBarType => {
        return {
          id: id + 1,
          title: getQuickFilterName(el) as string,
          count: getQuickFilterCount(el, metaData) as number,
          active: status === getQuickFilterName(el)?.toLowerCase()
          // active: id === 0
        };
      }
    )
  );
};
export default { QuickFiltersData };
