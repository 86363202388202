import { useMemo } from "react";

import { UploadedFile } from "../types";

const useFileStatuses = (uploadedFiles: UploadedFile[]) => {
  const allFilesValidated = useMemo(() => {
    return uploadedFiles.every(file => file.validationStatus !== null);
  }, [uploadedFiles]);

  const hasValidFiles = useMemo(() => {
    return uploadedFiles.some(file => file.validationStatus === "VALID");
  }, [uploadedFiles]);

  const allFilesSaved = useMemo(() => {
    if (!uploadedFiles.length) return true;
    return !uploadedFiles?.some(file => file?.fileStatus === "DRAFT");
  }, [uploadedFiles]);

  return { allFilesValidated, allFilesSaved, hasValidFiles };
};

export default useFileStatuses;
