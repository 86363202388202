import React, { useState } from "react";
import cx from "classnames";

import styles from "./Card.module.scss";
import { ReactComponent as LinkIcon } from "../../../icons/arrow_black.svg";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevrons_down.svg";
import { ReactComponent as ChevronUpIcon } from "../../../icons/chevrons_up.svg";

import Content from "./Content";
import ExtendableContent from "./ExtendableContent";

const Card: any = ({ children, className, handleCardClick }: any) => {
  const [isOpen, setIsOpen] = useState(true);
  let content = null;
  let extendableContent = null;
  React.Children.forEach(children, child => {
    if (child?.type === Content) {
      content = child;
    }
    if (child?.type === ExtendableContent) {
      extendableContent = child;
    }
  });
  return (
    <div
      className={cx(styles.card, className, {
        [styles.withLink]: !!handleCardClick
      })}
      onClick={handleCardClick}
    >
      <div className={styles.child}>
        <div className={styles.el}>{content}</div>
        {!!handleCardClick && (
          <div className={styles.linkIcon}>
            <LinkIcon className={styles.rotate} />
          </div>
        )}
      </div>

      <div
        className={cx({
          [styles.isOpen]: isOpen,
          [styles.extendableContent]: !!extendableContent
        })}
      >
        {extendableContent}
      </div>
      {extendableContent && (
        <div
          onClick={() => setIsOpen(!isOpen)}
          role="button"
          tabIndex={0}
          className={styles.arrow}
        >
          {/*<div name={isOpen ? "arrow-up" : "arrow-down"} size={14} />*/}
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      )}
    </div>
  );
};

export default Card;
