import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import styles from "./SourcesCard.module.scss";

import Card from "../../../../components/shared/Card";
import CurrencyRateShort from "../../../../components/shared/CurrencyRateShort";
import { textParams } from "../../../../utils";

const SourcesCard = ({ data }: any) => {
  const { t } = useTranslation("translation");

  return (
    <>
      <p className={styles.totalCount}>
        {textParams(t(`analytics.top20Sources`))[0] +
          ` ${data?.length} ` +
          textParams(t(`analytics.top20Sources`))[2]}
      </p>
      {data.map((el: any) => (
        <Card key={el.creators}>
          <Card.Content>
            <Card.Items title={false} label={el?.name || "-"} />
            <Card.Items
              title={
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={el?.previousAmount}
                />
              }
              label={`${t("analytics.lastPeriod")}:`}
            />
            <Card.Items
              title={<CurrencyRateShort id={`${uuidv4()}`} rate={el?.amount} />}
              label={`${t("analytics.currentPeriod")}:`}
            />
          </Card.Content>
          <Card.ExtendableContent>
            <Card.Items
              title={
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={el?.topWork?.amount}
                />
              }
              desc={el?.topWork?.workTitle}
              label={`${t("analytics.topSong")}:`}
            />
            <Card.Items
              title={
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={el.topIncomeType.amount}
                />
              }
              label={`${t("analytics.topCategory")}:`}
              desc={el?.topIncomeType?.name}
            />
            <Card.Items
              title={
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={el?.topTerritory?.amount}
                />
              }
              desc={el?.topTerritory?.name}
              label={`${t("analytics.topTerritory")}:`}
            />
          </Card.ExtendableContent>
        </Card>
      ))}
    </>
  );
};

export default SourcesCard;
