const reactSelectOverWriteStyles = (
  value: any,
  isWhite: boolean,
  isInvalid: boolean | undefined
) => {
  const validateSelect = () => {
    if (value === undefined && isInvalid === false) {
      return "#CBCBCB";
    }
    if (
      (!isInvalid && value === null) ||
      (isInvalid && (value === null || value === undefined))
    ) {
      return "rgba(215,46,61,1)";
    }
  };
  return {
    option: (provided: any, state: any): any => ({
      ...provided,
      color: isWhite ? "#383838" : "#fff",
      padding: "0.6rem 1.5rem",
      backgroundColor: state.isSelected
        ? isWhite
          ? "#F4F4F4"
          : "rgba(217, 168, 27, 1)"
        : isWhite
        ? "white"
        : "black",
      borderLeft: state.isSelected
        ? "2px solid rgba(217, 168, 27, 1)"
        : isWhite
        ? "2px solid white"
        : "2px solid black",
      "&:hover": {
        backgroundColor: state.isSelected
          ? isWhite
            ? "#F4F4F4"
            : "rgba(217, 168, 27, 1)"
          : isWhite
          ? "#F4F4F4"
          : "rgba(56, 56, 56, 1)",
        color: isWhite ? "#383838" : "#ffffff",
        borderLeft: isWhite
          ? "2px solid black"
          : "2px solid rgba(217, 168, 27, 1)"
      },
      cursor: "pointer"
    }),
    valueContainer: (p: any): any => ({
      ...p,
      padding: ".125rem .5rem"
    }),
    input: (p: any): any => ({
      ...p,
      color: isWhite ? "#000" : "white",
      fontSize: ".8125rem"
    }),
    control: (provided: any, state: any): any => {
      return {
        ...provided,
        backgroundColor: isWhite ? "white" : "#000",
        borderBottomColor:
          state.menuIsOpen && isWhite ? "black" : validateSelect(),
        borderColor: "#CBCBCB",
        borderStyle: "solid",
        borderWidth: "1px",
        minHeight: undefined,
        padding: "0 .5rem",
        outline: undefined,
        boxShadow: "none",
        height: "2.1875rem",
        borderRadius: "0",
        "&:hover": {
          // borderColor: "#CBCBCB",
          // borderBottomColor: isInvalid
          //   ? "rgba(215,46,61,1)"
          //   : "rgba(0,134,66,1)"
        }
      };
    },
    singleValue: (provided: any, state: any): any => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = isWhite ? "#000000" : "white";
      const fontSize = ".8125rem";
      return { ...provided, opacity, transition, color, fontSize };
    },
    menu: (provided: any) => ({
      ...provided,
      padding: 0,
      marginTop: isWhite ? "5px" : 0,
      borderLeft: "1px solid #CBCBCB",
      borderRight: "1px solid #CBCBCB",
      borderBottom: "1px solid #CBCBCB",
      borderRadius: "0",
      boxShadow: !isWhite ? "6px 6px 12px rgba(0, 0, 0, 0.2)" : "none",
      maxHeight: "326px",
      overflow: "hidden",
      background: "#000000"
    }),
    menuList: (provided: any) => ({
      ...provided,
      marginTop: "0",
      paddingTop: "0",
      paddingBottom: "0",
      "::-webkit-scrollbar": {
        width: "8px",
        height: "0px",
        background: "transparent"
      },
      "::-webkit-scrollbar-track": {
        background: "transparent"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#CBCBCB",
        borderRadius: "5px",
        backgroundClip: "padding-box"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#CBCBCB"
      }
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: "1rem",
      fontWeight: 400,
      color: "rgba(146, 146, 146, 1)",
      fontStyle: "italic"
    }),
    dropdownIndicator: (p: any) => ({
      ...p
      // ":hover": {
      //   color: "rgba(217, 168, 27, 1)"
      // }
    }),
    clearIndicator: (prevStyle: any) => {
      return {
        ...prevStyle,
        background: "red"
        // ":hover": {
        //   color: "rgba(217, 168, 27, 1)"
        // }
      };
    }
  };
};

export default reactSelectOverWriteStyles;
