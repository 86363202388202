import { call, put, takeEvery } from "redux-saga/effects";

import { request } from "../../services";
import { SetAdvanceSuccessAction } from "./actions";
import { AdvanceTypes } from "./types";

const { REACT_APP_BASE_URI } = process.env;

export function* GetAdvancesHistoryAsync({
  payload
}:
  | {
      userId: string;
      payeeCode: string;
    }
  | any): Generator<any, any, any> {
  try {
    const { data } = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/users/${payload.userId}/request-advance/${payload.payeeCode}`
    );
    yield put(SetAdvanceSuccessAction(data, false));
  } catch {
    yield put(
      SetAdvanceSuccessAction({ history: [], requestAllowed: false }, undefined)
    );
  }
}

function* watchAdvanceRequest(): Generator {
  yield takeEvery(AdvanceTypes.GET_ADVANCE_REQUEST, GetAdvancesHistoryAsync);
}

export { watchAdvanceRequest };
