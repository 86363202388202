import React from "react";
import { useController } from "react-hook-form";
import ReactSelect, { components, DropdownIndicatorProps } from "react-select";
import cn from "classnames";

import styles from "./Select.module.scss";
import { ReactComponent as Down } from "../../../icons/iconDown.svg";
import { ReactComponent as Up } from "../../../icons/iconUp.svg";

export type SelectProps = {
  name: string;
  control: any;
  options: { value: any; label: string }[];
  rules?: any;
  className?: string;
  placeholder?: string;
  width?: number;
  disabled?: boolean;
  [key: string]: any;
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <Up /> : <Down />}
    </components.DropdownIndicator>
  );
};

const Select: React.FC<SelectProps> = ({
  name,
  control,
  options,
  placeholder,
  rules = {},
  disabled,
  width = 200,
  defaultValue,
  className
}) => {
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue
  });
  return (
    <ReactSelect
      styles={{
        container: baseStyles => ({
          ...baseStyles,
          maxWidth: `${width}px`,
          // Note: Table header z-index is 20
          zIndex: 30
        })
      }}
      isDisabled={disabled}
      unstyled
      className={cn(styles.dropdown, className)}
      classNamePrefix="select"
      components={{
        DropdownIndicator
      }}
      options={options}
      placeholder={placeholder}
      {...field}
      value={defaultValue || field.value}
    />
  );
};
export default React.memo(Select);
