import React, { useCallback } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./ExitWarningPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Button from "../../WCMButton";

const ExitWariningPopup = () => {
  const modal = useSelector(modalSelector);

  const { SetOpenModalAction } = useActions();
  const history = useHistory();

  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);

  const { location } = modal?.queryParams || {};

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "ExitWarning"}
      onRequestClose={toggleModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          <span>Are you sure you wish to cancel the upload and exit?</span>
          <span>Any progress will be lost.</span>
        </div>
        <div className={styles.buttonsContainer}>
          <Button variant="text" onClick={toggleModal}>
            Cancel
          </Button>

          <Button
            onClick={() => {
              modal.queryParams?.unblockExit();
              history.push(location.pathname);
              toggleModal();
            }}
          >
            Exit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExitWariningPopup;
