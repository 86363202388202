import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import styles from "./IncomeCard.module.scss";

import Card from "../../../../components/shared/Card";
import CurrencyRateShort from "../../../../components/shared/CurrencyRateShort";

const IncomeCard = ({ data }: any) => {
  const { t } = useTranslation("translation");

  return (
    <>
      <p className={styles.totalCount}>
        {t(`analytics.topIncomeGroupsLowercase`)}
      </p>
      {data.map((el: any) => (
        <Card key={el?.creators}>
          <Card.Content>
            <Card.Items title={false} label={el?.name} />
            <Card.Items
              title={
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={el?.previousAmount}
                />
              }
              label={`${t("analytics.lastPeriod")}:`}
            />
            <Card.Items
              title={<CurrencyRateShort id={`${uuidv4()}`} rate={el?.amount} />}
              label={`${t("analytics.currentPeriod")}:`}
            />
          </Card.Content>
          <Card.ExtendableContent>
            {el?.incomeTypes?.map((type: any) => (
              <React.Fragment key={type?.name}>
                <Card.Items title={false} label={type?.name} />
                <Card.Items
                  title={
                    <CurrencyRateShort
                      id={`${uuidv4()}`}
                      rate={type?.previousAmount}
                    />
                  }
                  label={`${t("analytics.lastPeriod")}:`}
                />
                <Card.Items
                  title={
                    <CurrencyRateShort id={`${uuidv4()}`} rate={type?.amount} />
                  }
                  label={`${t("analytics.currentPeriod")}:`}
                />
              </React.Fragment>
            ))}
          </Card.ExtendableContent>
        </Card>
      ))}
    </>
  );
};

export default IncomeCard;
