import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import styles from "../Faq.module.scss";

import { textParams } from "../../../utils";

const PreLoginFaq = () => {
  const { t } = useTranslation("translation");
  return (
    <div className="container">
      <div className={styles.faq}>
        <div className={styles.header}>{t(`menu.faq`)}</div>
        <div className={styles.content}>
          <div className={styles.headTxt}>
            {t(`faqPage.preLogin.howRegisterQuestion`)}
          </div>
          <div className={styles.txt}>
            {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[0]}
            <NavLink to={"registration"} className={styles.link}>
              {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[1]}
            </NavLink>
            {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[2]}{" "}
            <a
              href={"https://www.mywarnerchappell.com/"}
              className={styles.link}
            >
              {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[3]}{" "}
            </a>{" "}
            {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[4]}{" "}
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.preLogin.contLoginQuestion`)}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.preLogin.contLoginAnswer`))[0]}
              <NavLink to={"forgot-password"} className={styles.link}>
                {textParams(t(`faqPage.preLogin.contLoginAnswer`))[1]}
              </NavLink>
              {textParams(t(`faqPage.preLogin.contLoginAnswer`))[2]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.preLogin.contLoginAnswer`))[3]}{" "}
              </NavLink>
            </div>
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.preLogin.troubleLoggingQuestion`)}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.preLogin.troubleLoggingAnswer`))[0]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.preLogin.troubleLoggingAnswer`))[1]}{" "}
              </NavLink>{" "}
            </div>
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.preLogin.otherQueriesQuestion`)}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.otherQueriesAnswer`))[0]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.otherQueriesAnswer`))[1]}{" "}
              </NavLink>{" "}
              {textParams(t(`faqPage.otherQueriesAnswer`))[2]}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreLoginFaq;
