import cn from "classnames";

import styles from "./ManagementHeader.module.scss";

import AlertBanner from "../../../../containers/AlertBanner";
import ManagementNavBar from "./ManagementNavBar";
import PageHeading from "./PageHeading/PageHeading";

const ManagementHeader = () => {
  return (
    <div className={styles.managementHeaderContainer}>
      <div className={cn(styles.managementHeader)}>
        <div>
          <PageHeading title="User management" />
        </div>
        <ManagementNavBar />
      </div>
      <AlertBanner hasFullWidthText />
    </div>
  );
};

export default ManagementHeader;
