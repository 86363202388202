import React from "react";
import cn from "classnames";

import styles from "./Icon.module.scss";

import Icons from "../../../icons";
import { TIcons } from "../../../types/icons";

type IconProps = {
  style?: {
    [key: string]: string;
  };
  kind: TIcons;
  className?: string;
  fillColor?: string;
} & Record<string, unknown>;
const Icon: React.FC<IconProps> = props => {
  const { kind, className, style, fillColor, ...rest } = props;

  const SpecificIcon = Icons[kind];

  return (
    <i {...rest} className={cn(styles.icon, className)}>
      <SpecificIcon style={style} fill={fillColor} />
    </i>
  );
};

export default Icon;
