import React, { useCallback, useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./FilterModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

import { userTypes } from "../../../../configs/constants/innerRole";
import { GroupingFilters } from "../../../../configs/utils";
import { useActions } from "../../../../hooks/useActions";
import { UMSTypeSearchEnum } from "../../../../page/UserManagement/types";
import {
  countrySelector,
  profileSelector
} from "../../../../store/appStatic/selectors";
import {
  dateRangeSelector,
  filtersSelector,
  modalFiltersSelector,
  modalSelector,
  UMSTypeSearchSelector
} from "../../../../store/architectUsersStore/selectors";
import { IChip } from "../../../userManagement/Filter/chip/Chip";
import SelectField from "../../../userManagement/Filter/SelectField";
import Condition from "../../Condition";

export interface ISelectItem {
  value: string;
  label: string;
}

const FilterModal = () => {
  const { meta, body } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  // const [modalFilter, setModalFilter] = useState<any>([]);
  const {
    SetFilters,
    GenerateFilter,
    setModalFilter,
    SetSearchedValue
  } = useActions();
  const filters = useSelector(filtersSelector);
  const modalFilter = useSelector(modalFiltersSelector);
  const countries = useSelector(countrySelector);
  const profile = useSelector(profileSelector);
  const dateRange = useSelector(dateRangeSelector);
  const countriesList = countries.map(
    (el: { label: string; value: string }) => {
      return {
        label: el.label,
        value: el.value,
        name: "location",
        operation: "in"
      };
    }
  );

  const handleChange = useCallback(
    (items: ISelectItem[], type: string, typeName: string) => {
      const filterList = items.map((itm: ISelectItem) => {
        return {
          ...itm,
          type: type,
          typeName: typeName,
          itemValue: `${type}_${itm.value}`
        };
      });
      setModalFilter([
        ...modalFilter.filter((el: IChip) => el.type !== type),
        ...filterList
      ]);
    },
    [filters, body, modalFilter]
  );
  const ApplyFilter = useCallback(() => {
    SetSearchedValue("");
    SetFilters(modalFilter);
  }, [modalFilter]);
  useEffect(() => {
    SetSearchedValue("");
    GenerateFilter(
      GroupingFilters(filters).concat(dateRange?.filterDateRange || [])
    );
  }, [filters, dateRange]);
  const groupedOptionsSuperAdmin = [
    {
      label: "Clients",
      options: meta?.songwriterTypes?.map((el: string) => {
        return {
          label: userTypes[el],
          value: el,
          name: "songwriterType",
          operation: "in"
        };
      })
    },
    {
      label: "Internal Users",
      options: [
        { label: "Admin", value: "ADMIN", operation: "in", name: "role" },
        // {
        //   label: "Senior Admin",
        //   value: "SENIOR_ADMIN",
        //   operation: "in",
        //   name: "role"
        // },
        {
          label: "Super Admin",
          value: "SUPER_ADMIN",
          operation: "in",
          name: "role"
        }
      ]
    }
  ];

  const groupedOptionsAdmin = [
    {
      label: "Clients",
      options: meta?.songwriterTypes?.map((el: string) => {
        return {
          label: userTypes[el],
          value: el,
          name: "songwriterType",
          operation: "in"
        };
      })
    }
  ];

  const modal = useSelector(modalSelector);
  const { modifiers } = useSelector(
    (state: any) => state.architectUsersReducer
  );
  const UMSTypeSearch = useSelector(UMSTypeSearchSelector);
  const modifiersList = modifiers.map((el: string) => {
    return {
      value: el,
      label: el,
      name: "modifiedBy",
      operation: "in"
    };
  });
  const accountingPeriodList = [
    {
      label: "Quarterly",
      value: "3",
      operation: "in",
      name: "accountingPeriod"
    },
    {
      label: "Semi-Annual",
      value: "6",
      operation: "in",
      name: "accountingPeriod"
    }
  ];
  const statementsPeriodList = [
    {
      label: "30 Days",
      value: "30",
      operation: "eq",
      name: "settlementPeriod"
    },
    {
      label: "40 Days",
      value: "40",
      operation: "eq",
      name: "settlementPeriod"
    },
    {
      label: "45 Days",
      value: "45",
      operation: "eq",
      name: "settlementPeriod"
    },
    {
      label: "60 Days",
      value: "60",
      operation: "eq",
      name: "settlementPeriod"
    },
    { label: "90 Days", value: "90", operation: "eq", name: "settlementPeriod" }
  ];
  const { SetOpenModalAction } = useActions();
  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);
  useEffect(() => {
    if (modal.isOpen && modal.type === "filterModal") {
      setModalFilter(filters);
    }
  }, [modal.isOpen, modal.type]);
  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "filterModal"}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal)}
        overlayClassName={styles.myoverlay}
      >
        <>
          <h1 className={cn(styles.title)}>Filters</h1>
          <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
          <Condition
            condition={
              UMSTypeSearch?.value === UMSTypeSearchEnum.USER_NAME_EMAIL
            }
            Truthy={
              <>
                <div className={cn(styles.filterModal_field)}>
                  <p className={cn(styles.filterModal_field_label)}>
                    User Type
                  </p>
                  <SelectField
                    defaultValue={modalFilter.filter(
                      (el: IChip) => el.type === "songwriterType"
                    )}
                    value={modalFilter.filter(
                      (el: IChip) => el.type === "songwriterType"
                    )}
                    options={
                      profile?.isSuperAdmin
                        ? groupedOptionsSuperAdmin
                        : groupedOptionsAdmin
                    }
                    placeholder="--Select user types--"
                    onChange={(item: ISelectItem[]) => {
                      handleChange(item, "songwriterType", "User Type");
                    }}
                  />
                </div>
                <div className={cn(styles.filterModal_field)}>
                  <p className={cn(styles.filterModal_field_label)}>Location</p>
                  <SelectField
                    defaultValue={modalFilter.filter(
                      (el: IChip) => el.type === "location"
                    )}
                    value={modalFilter.filter(
                      (el: IChip) => el.type === "location"
                    )}
                    hasGlob
                    options={countriesList}
                    placeholder="--Select locations--"
                    onChange={(item: ISelectItem[]) =>
                      handleChange(item, "location", "Location")
                    }
                  />
                </div>
                <div className={cn(styles.filterModal_field)}>
                  <p className={cn(styles.filterModal_field_label)}>
                    Modified By
                  </p>
                  <SelectField
                    options={modifiersList}
                    placeholder="--Select users--"
                    onChange={(item: ISelectItem[]) =>
                      handleChange(item, "modifiedBy", "Modified By")
                    }
                    defaultValue={modalFilter.filter(
                      (el: IChip) => el.type === "modifiedBy"
                    )}
                    value={modalFilter.filter(
                      (el: IChip) => el.type === "modifiedBy"
                    )}
                  />
                </div>
              </>
            }
            Falsy={
              <>
                <div className={cn(styles.filterModal_field)}>
                  <p className={cn(styles.filterModal_field_label)}>
                    Accounting Period
                  </p>
                  <SelectField
                    options={accountingPeriodList}
                    placeholder="--Select Accounting Period--"
                    onChange={(item: ISelectItem[]) =>
                      handleChange(
                        item,
                        "accountingPeriod",
                        "Accounting Period"
                      )
                    }
                    defaultValue={modalFilter.filter(
                      (el: IChip) => el.type === "accountingPeriod"
                    )}
                    value={modalFilter.filter(
                      (el: IChip) => el.type === "accountingPeriod"
                    )}
                  />
                </div>
                <div className={cn(styles.filterModal_field)}>
                  <p className={cn(styles.filterModal_field_label)}>
                    Statement Period
                  </p>
                  <SelectField
                    options={statementsPeriodList}
                    placeholder="--Select Settlement Period--"
                    onChange={(item: ISelectItem[]) =>
                      handleChange(
                        item,
                        "settlementPeriod",
                        "Sattlement Period"
                      )
                    }
                    defaultValue={modalFilter.filter(
                      (el: IChip) => el.type === "settlementPeriod"
                    )}
                    value={modalFilter.filter(
                      (el: IChip) => el.type === "settlementPeriod"
                    )}
                  />
                </div>
              </>
            }
          />
          <div className={styles.buttonWrap}>
            <button
              className={cn(styles.buttonText)}
              onClick={() => {
                setModalFilter([]);
              }}
            >
              Clear
            </button>
            <button
              className={cn(styles.buttonSubmit)}
              onClick={() => {
                toggleModal();
                ApplyFilter();
              }}
            >
              Apply
            </button>
          </div>
        </>
      </Modal>
    </div>
  );
};
export default FilterModal;
