import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cn from "classnames";

import styles from "./LoginPage/Login/Login.module.scss";

import Loader from "../components/shared/Loading";
import Button, { ButtonType } from "../components/shared/UiButton";
import CheckBoxFiled from "../components/shared/UiCheckBox";
import { innerRole } from "../configs/constants/innerRole";
import { useActions } from "../hooks/useActions";
import { oktaAuth } from "../lib/oktaAuth";
import { errorsSelector, profileSelector } from "../store/appStatic/selectors";
import TermsOfUse from "./TermsOfUse";

const RedirectFromOkta = () => {
  const history = useHistory();
  const profile = useSelector(profileSelector);
  const errors = useSelector(errorsSelector);
  const scrolledRef = useRef<any>();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [shadowPosition, setShadowPosition] = useState<string>("down");
  const [isActiveAgree, setIsActiveAgree] = useState<boolean>(false);
  const [checkAgree, setCheckAgree] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<null | boolean>(false);
  const { REACT_APP_BASE_URI } = process.env;
  const { register } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const { changeIsTermAcceptedAction } = useActions();

  useEffect(() => {
    if (profile?.role && profile?.settings?.isTermAccepted) {
      const source = new URL(window.location.href).searchParams.get("source");
      if (source && source !== "/") {
        return history.push(source);
      }
      if (
        profile?.role === innerRole.Admin ||
        profile?.role === innerRole.SUPER_ADMIN
        // profile?.role === innerRole.SENIOR_ADMIN
      ) {
        history.push("/user-management/activity");
      }
      if (profile?.role === innerRole.ExternalUser) {
        history.push("/accounts");
      }
      if (
        profile?.role !== innerRole.Admin &&
        profile?.role !== innerRole.SUPER_ADMIN &&
        // profile?.role !== innerRole.SENIOR_ADMIN &&
        profile?.role !== innerRole.ExternalUser
      ) {
        history.push("/access-denied");
      }
    }
    if (errors?.filter((el: any) => el).length) {
      history.push("/access-denied");
    }
  }, [profile, profile?.settings?.isTermAccepted]);

  const agreeTermsOfUse = useCallback(async () => {
    setIsLoading(true);
    axios
      .put(
        `${REACT_APP_BASE_URI}api/users/${profile.id}/settings`,
        { isTermAccepted: true },
        {
          headers: {
            Authorization: `Bearer ${oktaAuth.getAccessToken()}`
          }
        }
      )
      .then((settings: any) => {
        changeIsTermAcceptedAction(settings.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [profile]);
  const handleNavigation = useCallback(() => {
    const currentScrollTop = scrolledRef?.current?.scrollTop;
    if (
      currentScrollTop >
      scrolledRef?.current?.scrollHeight -
        scrolledRef?.current?.clientHeight -
        10
    ) {
      setIsActiveAgree(true);
    }
    if (currentScrollTop > scrollTop) {
      setShadowPosition("up");
    } else if (currentScrollTop < scrollTop) {
      setShadowPosition("down");
    }
    setScrollTop(currentScrollTop);
  }, [scrollTop, isActiveAgree]);

  if (profile && !profile?.settings?.isTermAccepted) {
    return (
      <div className="container">
        <div className={cn(styles.terms)}>
          <TermsOfUse
            showDate={false}
            shadowPosition={shadowPosition}
            handleNavigation={handleNavigation}
            scrolledRef={scrolledRef}
          />
          <div className={cn(styles.terms_agreeRow)}>
            <div
              className={cn(
                styles.terms_agreeRow_checkbox,
                isActiveAgree && styles.terms_agreeRow_checkbox_active
              )}
            >
              <CheckBoxFiled
                className={cn(styles.terms_checkbox)}
                label="I have read and agree to the Terms of Use."
                {...register("agreeTermsCheckbox")}
                onChange={e => setCheckAgree(e.target.checked)}
              />
            </div>
            <div className={cn(styles.terms_agreeRow_btn)}>
              <Button
                variant={ButtonType.Text}
                text="Cancel"
                onClick={() => {
                  setIsLoading(false);
                  history.push("/logout");
                }}
              />
              <Button
                onClick={async () => {
                  agreeTermsOfUse();
                }}
                disabled={!checkAgree || isLoading}
                loading={isLoading}
                variant={ButtonType.Primary}
                text="Agree"
                className={cn(styles.terms_agreeBtn)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Loader />;
};

export default RedirectFromOkta;
