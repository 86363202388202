export enum AnalyticsTypes {
  GET_TOP_SONGS = "analytics/GET_TOP_SONGS",
  GET_TOP_SONGS_SUCCESS = "analytics/GET_TOP_SONGS_SUCCESS",
  GET_TOP_SOURCES = "analytics/GET_TOP_SOURCES",
  GET_TOP_SOURCES_SUCCESS = "analytics/GET_TOP_SOURCES_SUCCESS",
  GET_TOP_TERRITORIES = "analytics/GET_TOP_TERRITORIES",
  GET_TOP_TERRITORIES_SUCCESS = "analytics/GET_TOP_TERRITORIES_SUCCESS",
  GET_TOP_INCOME_GROUPS = "analytics/GET_TOP_INCOME_GROUPS",
  GET_TOP_INCOME_GROUPS_SUCCESS = "analytics/GET_TOP_INCOME_GROUPS_SUCCESS",
  SET_SONGS_LOADING = "analytics/SET_SONGS_LOADING",
  SET_SOURCES_LOADING = "analytics/SET_SOURCES_LOADING",
  SET_TERRITORIES_LOADING = "analytics/SET_TERRITORIES_LOADING",
  SET_INCOME_LOADING = "analytics/SET_INCOME_LOADING"
}

export type rowType = { row: { original: { [key: string]: string } } };

export type detailCellRenderer = {
  original: {
    incomeTypes?: {
      name: string;
      amount: string;
      direction: string;
      previousAmount: string;
      percentage: string;
    }[];
    topSource: {
      name: string;
      amount: string;
    };
    topWork?: {
      workTitle: string;
      amount: string;
    };
    topIncomeType: {
      name: string;
      amount: string;
    };
    topTerritory: {
      name: string;
      amount: string;
    };
  };
};

export type topWorksType = {
  workCode: string;
  workTitle: string;
  creators: string;
  amount: number;
  previousAmount: number;
  direction: string;
  percentage: number;
  topIncomeType: { name: string; amount: number };
  topSource: { name: string; amount: number };
  topTerritory: { name: string; amount: number };
}[];

export type topSourcesType = {
  name: string;
  amount: number;
  previousAmount: number;
  direction: string;
  percentage: number;
  topIncomeType: { name: string; amount: number };
  topWork: {
    workCode: string;
    workTitle: string;
    creators: null | string;
    amount: number;
  };
  topTerritory: { name: string; amount: number };
}[];

export interface songsTopTypes {
  payeeAccountCode: string;
  closePeriodStartDate: string;
  closePeriodEndDate: string;
  total: string;
  previousTotal: number;
  direction: string;
  percentage: number;
  topWorks: topWorksType;
}

export type territoriesTopType = {
  title: string;
  code: string;
  amount: number;
  previousAmount: number;
  direction: string;
  percentage: number;
}[];

export type topIncomeType = {
  name: string;
  code: string;
  amount: number;
  previousAmount: number;
  direction: string;
  percentage: number;
}[];

export interface territoriesTypes {
  payeeAccountCode: string;
  closePeriodStartDate: string;
  closePeriodEndDate: string;
  total: number;
  previousTotal: number;
  direction: string;
  percentage: number;
  territories: territoriesTopType;
}

export interface sourcesTopTypes {
  payeeAccountCode: string;
  closePeriodStartDate: string;
  closePeriodEndDate: string;
  total: number;
  previousTotal: number;
  direction: string;
  percentage: number;
  topSources: topSourcesType;
}

export interface incomeTopTypes {
  payeeAccountCode: string;
  closePeriodStartDate: string;
  closePeriodEndDate: string;
  total: number;
  previousTotal: number;
  direction: string;
  percentage: number;
  incomeTypes: topIncomeType;
}

export interface IAnalyticsState {
  readonly songsTop: songsTopTypes | null;
  readonly top4Songs: topWorksType | null;
  readonly sourcesTop: sourcesTopTypes | null;
  readonly top4Sources: topSourcesType | null;
  readonly territoriesTop: territoriesTypes | null;
  readonly top4Territories: territoriesTopType | null;
  readonly incomeTypesTop: incomeTopTypes | null;
  readonly top4IncomeTypes: topIncomeType | null;
  readonly songsLoading: boolean;
  readonly sourcesLoading: boolean;
  readonly incomesLoading: boolean;
  readonly territoriesLoading: boolean;
}

export type dataType = { startDate: string; endData: string };
export type top4SongsResType = {
  res: songsTopTypes | null;
  top4Songs: topWorksType | null;
};
export type top4SourcesResType = {
  res: sourcesTopTypes | null;
  top4Sources: topSourcesType | null;
};
export type top4TerritoriesResType = {
  res: territoriesTypes | null;
  top4Territories: territoriesTopType | null;
};
export type top4IncomeResType = {
  res: incomeTopTypes | null;
  top4IncomeTypes: topIncomeType | null;
};

export type getTopState = {
  payeeCode: string;
  data: { startDate: string; endDate: string };
};

export interface IGetTopSongsAction {
  type: AnalyticsTypes.GET_TOP_SONGS;
  payload: getTopState;
}

export interface IGetTopSongsActionSuccess {
  type: AnalyticsTypes.GET_TOP_SONGS_SUCCESS;
  payload: top4SongsResType;
}

export interface IGetTopSourcesAction {
  type: AnalyticsTypes.GET_TOP_SOURCES;
  payload: getTopState;
}

export interface IGetTopSourcesActionSuccess {
  type: AnalyticsTypes.GET_TOP_SOURCES_SUCCESS;
  payload: top4SourcesResType;
}

export interface IGetTopTerritoriesAction {
  type: AnalyticsTypes.GET_TOP_TERRITORIES;
  payload: getTopState;
}

export interface IGetTopTerritoriesActionSuccess {
  type: AnalyticsTypes.GET_TOP_TERRITORIES_SUCCESS;
  payload: top4TerritoriesResType;
}

export interface IGetTopIncomeGroupsAction {
  type: AnalyticsTypes.GET_TOP_INCOME_GROUPS;
  payload: getTopState;
}

export interface IGetTopIncomeGroupsActionSuccess {
  type: AnalyticsTypes.GET_TOP_INCOME_GROUPS_SUCCESS;
  payload: top4IncomeResType;
}

export interface ISetSongsLoadingAction {
  type: AnalyticsTypes.SET_SONGS_LOADING;
  payload: boolean;
}

export interface ISetSourcesLoadingAction {
  type: AnalyticsTypes.SET_SOURCES_LOADING;
  payload: boolean;
}

export interface ISetTerritoriesLoadingAction {
  type: AnalyticsTypes.SET_TERRITORIES_LOADING;
  payload: boolean;
}

export interface ISetIncomeLoadingAction {
  type: AnalyticsTypes.SET_INCOME_LOADING;
  payload: boolean;
}

export type IAnalyticsActions =
  | IGetTopSongsAction
  | IGetTopSongsActionSuccess
  | IGetTopSourcesAction
  | IGetTopSourcesActionSuccess
  | IGetTopTerritoriesAction
  | IGetTopTerritoriesActionSuccess
  | IGetTopIncomeGroupsAction
  | IGetTopIncomeGroupsActionSuccess
  | ISetIncomeLoadingAction
  | ISetTerritoriesLoadingAction
  | ISetSourcesLoadingAction
  | ISetSongsLoadingAction;
