export const defs = [
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    writer: "Kerr, Michael James / Thatcher Ben / Non-Warner, Writer",
    song_title: "Trouble’s Coming, Royal Blood",
    source: "Spotify, Apple",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  }
];
export const country = [
  {
    country: "Canada",
    weekly_trend: "-5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    country: "Germany",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    country: "Jamaica",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    country: "Canada",
    weekly_trend: "+7",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(178, 48, 48, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  },
  {
    country: "Jamaica",
    weekly_trend: "+5",
    amount_paid: "$12,459.00",
    chart: {
      labels: [65, 0, 80, 81, 86],
      datasets: [
        {
          fill: false,
          lineTension: 0.3,
          borderColor: "rgba(48, 178, 84, 1)",
          borderWidth: 2,
          data: [65, 0, 80, 81, 86]
        }
      ]
    }
  }
];
export default {
  defs,
  country
};
