import React from "react";
import Modal from "react-modal";

import Button, { ButtonType } from "../Button";

const UserPopup = (props: any) => {
  const { modalIsOpen } = props;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: 0,
      padding: " 1rem",
      boxShadow: "0 4px 10px rgb(0 0 0 / 25%)"
    }
  };
  return (
    <Modal
      style={customStyles}
      isOpen={props.modalIsOpen.isOpen}
      ariaHideApp={false}
    >
      {modalIsOpen.item && (
        <>
          <h2 className="font-semibold text-base text-wcm-dark">
            {modalIsOpen.item.firstName} {modalIsOpen.item.lastName}
          </h2>
          <div className="text-sm text-wcm-gray">{modalIsOpen.item.email}</div>
        </>
      )}

      <hr className="border-gray-lighter my-10" />
      <div className="mb-4 text-center">
        <div className="font-semibold">{modalIsOpen && modalIsOpen.title}</div>
        <div className="mt-3 text-13">{modalIsOpen && modalIsOpen.desc}</div>
        <div className="modal-btn-group p-6">
          <Button
            disabled={false}
            type={ButtonType.Text}
            onClick={props.modalClose}
          >
            Cancel
          </Button>
          <Button
            disabled={false}
            type={ButtonType.Primary}
            onClick={props.modalClose}
          >
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default UserPopup;
