import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import ReactDatepicker from "react-datepicker";
import { useController, UseControllerProps } from "react-hook-form";
import { DateTime } from "luxon";

import styles from "./Datepicker.module.scss";

import Icons from "../../shared/Icon";
import { commonPickerProps } from "./constants";
import Header from "./Header";
import Input from "./Input";

type DatepickerProps = UseControllerProps & {
  minDate?: Date;
  commonPeriods?: number[];
  handlePeriodClick?: (period: number) => void;
  selectedPeriod?: number;
};
const Datepicker: React.FC<DatepickerProps> = ({
  // Round to minutes
  minDate = new Date(new Date().setSeconds(0, 0)),
  commonPeriods,
  handlePeriodClick,
  selectedPeriod,
  ...rest
}) => {
  const { field } = useController(rest);
  const { ref, ...fieldWithoutRef } = field;

  return (
    <ReactDatepicker
      {...fieldWithoutRef}
      {...commonPickerProps}
      adjustDateOnChange={true}
      onChange={date => {
        const dateValue = date
          ? DateTime.fromJSDate(date)
          : DateTime.now().startOf("minute");
        dateValue < DateTime.fromJSDate(minDate)
          ? field.onChange(minDate)
          : field.onChange(dateValue.toJSDate());
      }}
      calendarClassName={styles.calendarWrapper}
      calendarIconClassname={styles.icon}
      customInput={<Input ref={ref} />}
      dateFormat="MMM d, yyyy"
      dayClassName={() => styles.day}
      icon={<Icons kind="announcement_calendar" />}
      minDate={minDate}
      popperModifiers={[
        {
          name: "offset",
          fn: data => {
            data.y = data.rects.reference.y + data.rects.reference.height + 3;
            return data;
          }
        }
      ]}
      renderCustomHeader={props => (
        <Header
          {...props}
          commonPeriods={commonPeriods}
          handlePeriodClick={handlePeriodClick}
          selectedPeriod={selectedPeriod}
        />
      )}
      selected={field.value}
      weekDayClassName={() => styles.weekDay}
      wrapperClassName={styles.datepickerWrapper}
    />
  );
};

export default Datepicker;
