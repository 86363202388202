import React from "react";
import { useHistory } from "react-router";
import cn from "classnames";

import styles from "./Tabs.module.scss";

export interface TabItemProps {
  value: string;
  metaData?: any;
  label: string;
  status?: string;
  active?: boolean;
}

const TabItem = ({ active, value, label }: TabItemProps) => {
  const history = useHistory();
  return (
    <li
      className={`${cn(styles.item, active ? styles.active : "")}`}
      onClick={() => history.push(`/user-management/${value}`)}
    >
      <span>{label}</span>
    </li>
  );
};
export default TabItem;
