import { Reducer } from "redux";

import { ArchitectUsersTypes } from "./types";

const filters = JSON.parse(localStorage.getItem("filters") || "[]");
const modalFilter = JSON.parse(localStorage.getItem("modalFilter") || "[]");
const generatedFilters = JSON.parse(
  localStorage.getItem("generatedFilters") || "[]"
);
const dateRange = JSON.parse(localStorage.getItem("dateRange") || "null");

const generatedHistoryFilters = JSON.parse(
  localStorage.getItem("generatedHistoryFilters") || "[]"
);
const historyDateRange = JSON.parse(
  localStorage.getItem("historyDateRange") || "null"
);
const historyDateRangeModal = JSON.parse(
  localStorage.getItem("historyDateRangeModal") || "null"
);
const historyFilters = JSON.parse(
  localStorage.getItem("historyFilters") || "[]"
);
const historyFilterModal = JSON.parse(
  localStorage.getItem("historyFilterModal") || "[]"
);

const downloadDateRange = JSON.parse(
  localStorage.getItem("downloadDateRange") || "null"
);
const downloadDateRangeModal = JSON.parse(
  localStorage.getItem("downloadDateRangeModal") || "null"
);
const downloadFilters = JSON.parse(
  localStorage.getItem("downloadFilters") || "[]"
);
const downloadFilterModal = JSON.parse(
  localStorage.getItem("downloadFilterModal") || "[]"
);

const generatedSavedUploadsFilters = JSON.parse(
  localStorage.getItem("generatedSavedUploadsFilters") || "[]"
);
const savedUploadsDateRange = JSON.parse(
  localStorage.getItem("savedUploadsDateRange") || "null"
);
const savedUploadsDateRangeModal = JSON.parse(
  localStorage.getItem("savedUploadsDateRangeModal") || "null"
);
const savedUploadsFilters = JSON.parse(
  localStorage.getItem("savedUploadsFilters") || "[]"
);
const savedUploadsFilterModal = JSON.parse(
  localStorage.getItem("savedUploadsFilterModal") || "[]"
);

const localUploadTableType =
  localStorage.getItem("uploadTableType") || "statement-doc";

const UMSTypeSearch = JSON.parse(
  localStorage.getItem("UMSTypeSearch") || "null"
);

export const architectUsersInitialState: any = {
  modifiers: [],
  uploaderList: [],
  //HISTORY FILTER
  historyFilters: historyFilters || [],
  historyFilterModal: historyFilterModal || [],
  historyDateRange: historyDateRange || null,
  historyDateRangeModal: historyDateRangeModal || null,
  generatedHistoryFilters: generatedHistoryFilters || [],
  //HISTORY FILTER

  // DOWNLOAD FILTER
  downloadFilters: downloadFilters || [],
  downloadFilterModal: downloadFilterModal || [],
  downloadDateRange: downloadDateRange || null,
  downloadDateRangeModal: downloadDateRangeModal || null,
  //DOWNLOAD FILTER

  //SAVED UPLOADS
  savedUploadsFilters: savedUploadsFilters || [],
  savedUploadsFilterModal: savedUploadsFilterModal || [],
  savedUploadsDateRange: savedUploadsDateRange || null,
  savedUploadsDateRangeModal: savedUploadsDateRangeModal || null,
  generatedSavedUploadsFilters: generatedSavedUploadsFilters || [],
  uploadTableType: localUploadTableType,

  //SAVED UPLOADS

  filters: filters || [],
  modalFilter: modalFilter || [],
  generatedFilters: generatedFilters || [],
  dateRange: dateRange || null,

  architectUsers: [],
  meta: null,
  metaDataLoading: false,
  body: null,
  loading: false,
  total: null,
  modal: {
    isOpen: false,
    type: "",
    user: null,
    queryParams: null,
    fileName: ""
  },
  searchedValue: "",
  UMSTypeSearch: UMSTypeSearch || {
    label: "USER NAME / EMAIL",
    value: "USER NAME/EMAIL"
  }
};

export const architectUsersReducer: Reducer<any, any> = (
  state = architectUsersInitialState,
  action
) => {
  switch (action.type) {
    case ArchitectUsersTypes.ARCHITECT_USERS_SUCCESS: {
      const initialArchitectUsers = action.payload.isScroll
        ? [...state.architectUsers, ...action.payload.data.content]
        : action.payload.data.content;
      return {
        ...state,
        architectUsers: initialArchitectUsers,
        total: action.payload.data.total
      };
    }

    case ArchitectUsersTypes.METADATA_SUCCESS: {
      return {
        ...state,
        meta: action.payload.data
      };
    }
    case ArchitectUsersTypes.MODIFIERS_SUCCESS: {
      return {
        ...state,
        modifiers: action.payload.data
      };
    }
    case ArchitectUsersTypes.UPLOADER_SUCCESS: {
      return {
        ...state,
        uploaderList: action.payload.data
      };
    }
    case ArchitectUsersTypes.ARCHITECT_USERS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case ArchitectUsersTypes.METADATA_LOADING: {
      return {
        ...state,
        metaDataLoading: action.payload
      };
    }
    case ArchitectUsersTypes.OPEN_MODAL_ADD_USER: {
      return {
        ...state,
        modal: action.payload
      };
    }
    case ArchitectUsersTypes.SET_UMS_SEARCH_TYPE: {
      return {
        ...state,
        UMSTypeSearch: action.payload
      };
    }
    case ArchitectUsersTypes.SET_MODAL_FILTERS_ACTION: {
      return {
        ...state,
        modalFilter: action.payload
      };
    }

    //HISTORY FILTERS
    case ArchitectUsersTypes.SET_HISTORY_FILTERS_ACTION: {
      return {
        ...state,
        historyFilters: action.payload
      };
    }
    case ArchitectUsersTypes.SET_HISTORY_MODAL_FILTERS_ACTION: {
      return {
        ...state,
        historyFilterModal: action.payload
      };
    }
    case ArchitectUsersTypes.SET_HISTORY_DATE_RANGE_ACTION: {
      return {
        ...state,
        historyDateRange: action.payload
      };
    }
    case ArchitectUsersTypes.SET_HISTORY_MODAL_DATE_RANGE_ACTION: {
      return {
        ...state,
        historyDateRangeModal: action.payload
      };
    }
    case ArchitectUsersTypes.SET_HISTORY_GENERATED_FILTERS_ACTION: {
      return {
        ...state,
        generatedHistoryFilters: action.payload
      };
    }
    //HISTORY FILTERS

    // DOWNLOAD FILTERS
    case ArchitectUsersTypes.SET_DOWNLOAD_FILTERS_ACTION: {
      return {
        ...state,
        downloadFilters: action.payload
      };
    }
    case ArchitectUsersTypes.SET_DOWNLOAD_MODAL_FILTERS_ACTION: {
      return {
        ...state,
        downloadFilterModal: action.payload
      };
    }
    case ArchitectUsersTypes.SET_DOWNLOAD_DATE_RANGE_ACTION: {
      return {
        ...state,
        downloadDateRange: action.payload
      };
    }
    case ArchitectUsersTypes.SET_DOWNLOAD_MODAL_DATE_RANGE_ACTION: {
      return {
        ...state,
        downloadDateRangeModal: action.payload
      };
    }
    //DOWNLOAD FILTERS

    //SAVED UPLOADS
    case ArchitectUsersTypes.SET_SAVED_UPLOADS_FILTERS_ACTION: {
      return {
        ...state,
        savedUploadsFilters: action.payload
      };
    }
    case ArchitectUsersTypes.SET_SAVED_UPLOADS_MODAL_FILTERS_ACTION: {
      return {
        ...state,
        savedUploadsFilterModal: action.payload
      };
    }
    case ArchitectUsersTypes.SET_SAVED_UPLOADS_DATE_RANGE_ACTION: {
      return {
        ...state,
        savedUploadsDateRange: action.payload
      };
    }
    case ArchitectUsersTypes.SET_SAVED_UPLOADS_MODAL_DATE_RANGE_ACTION: {
      return {
        ...state,
        savedUploadsDateRangeModal: action.payload
      };
    }
    case ArchitectUsersTypes.SET_SAVED_UPLOADS_GENERATED_FILTERS_ACTION: {
      return {
        ...state,
        generatedSavedUploadsFilters: action.payload
      };
    }

    case ArchitectUsersTypes.SET_UPLOAD_TABLE_TYPE: {
      return {
        ...state,
        uploadTableType: action.payload
      };
    }
    //SAVED UPLOADS

    case ArchitectUsersTypes.SET_BODY_ACTION: {
      return {
        ...state,
        body: action.payload
      };
    }
    case ArchitectUsersTypes.SET_FILTERS_ACTION: {
      return {
        ...state,
        filters: action.payload
      };
    }

    case ArchitectUsersTypes.SET_GENERATED_FILTERS_ACTION: {
      return {
        ...state,
        generatedFilters: action.payload
      };
    }
    case ArchitectUsersTypes.SET_DATE_RANGE_ACTION: {
      return {
        ...state,
        dateRange: action.payload
      };
    }
    case ArchitectUsersTypes.SET_SEARCH_VALUE: {
      return {
        ...state,
        searchedValue: action.payload
      };
    }
    default:
      return state;
  }
};
