import React, { useCallback, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";
import moment from "moment";

import styles from "./DuplicateFileValidationPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import {
  TableType,
  UploadedFile
} from "../../../../page/StatementUpload/types";
import { fileUtils } from "../../../../page/StatementUpload/utils";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Icon from "../../../shared/Icon";
import Button from "../../WCMButton";

type FormValues = Record<string, OverrideLabel>;

enum OverrideLabel {
  replaceExistingFile = "Replace Existing File",
  ignoreNewFile = "Ignore New File"
}

const overrideMapping = {
  [OverrideLabel.replaceExistingFile]: true,
  [OverrideLabel.ignoreNewFile]: false
};

const radioOptions = [
  { value: OverrideLabel.replaceExistingFile, id: "field-overWrite" },
  { value: OverrideLabel.ignoreNewFile, id: "field-ignoreMyFile" }
];

const DuplicateFileValidationPopup: React.FC<{ handlePublish: any }> = ({
  handlePublish
}) => {
  const { getSignedURL } = fileUtils;
  const tableType = localStorage.getItem("uploadTableType") as TableType;

  const [isInPreview, setIsInPreview] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, isDirty }
  } = useForm<FormValues>({
    mode: "all"
  });
  const formValues = useWatch({ control });
  const modal = useSelector(modalSelector);
  const { SetOpenModalAction } = useActions();

  const onSubmit = async (overrideList: Record<string, OverrideLabel>) => {
    if (!isInPreview) {
      return setIsInPreview(true);
    }
    setIsLoading(true);

    if (!overrideList) return;
    const result: Record<string, boolean> = {};
    Object.keys(overrideList).forEach((overrideId: string) => {
      result[overrideId] = overrideMapping[overrideList[overrideId]];
    });

    try {
      await handlePublish(result);
    } finally {
      setIsInPreview(false);
      setIsLoading(false);
      SetOpenModalAction({ isOpen: false, type: "" });
    }
  };

  const handleBackClick = useCallback(() => {
    if (isInPreview) {
      setIsInPreview(false);
    } else {
      SetOpenModalAction({ isOpen: false, type: "" });
    }
  }, [SetOpenModalAction, isInPreview, setIsInPreview]);

  const duplicates: Array<Record<string, UploadedFile>> =
    modal.queryParams?.duplicates;

  const fileRows = useMemo(() => {
    return duplicates?.map(({ newDocument, oldDocument }) => {
      const isUnchecked = !formValues[newDocument.id];
      return (
        <div className={styles.duplicateRow} key={newDocument.fileName}>
          <div key={newDocument.fileName} className={styles.newFileColumn}>
            <Icon kind={isUnchecked ? "question" : "file_check"} />
            <Tooltip title={`Download - ${newDocument.fileName}`}>
              <p
                className={styles.fileName}
                onClick={() => {
                  getSignedURL({
                    documentId: newDocument.id,
                    tableType,
                    name: newDocument.fileName
                  });
                }}
              >
                {newDocument.fileName}
              </p>
            </Tooltip>
          </div>
          <div key={oldDocument.fileName} className={styles.existingFileColumn}>
            <Tooltip title={`Download - ${oldDocument.fileName}`}>
              <p
                onClick={() => {
                  getSignedURL({
                    documentId: oldDocument.id,
                    tableType,
                    name: oldDocument.fileName
                  });
                }}
                className={styles.fileName}
              >
                {oldDocument.fileName}
              </p>
            </Tooltip>
            <p className={styles.fileDescription}>
              <span>{`${moment(oldDocument.uploadedAt).format("ll")} by ${
                oldDocument.uploaderUserTitle
              }`}</span>
            </p>
          </div>
          <div className={styles.actionColumn}>
            {isInPreview ? (
              <div
                className={cn(styles.actionOverview, {
                  [styles.overwriteWarning]:
                    formValues[newDocument.id] === radioOptions[1].value
                })}
              >
                {formValues[newDocument.id]}
              </div>
            ) : (
              <div className={styles.actionOptions}>
                {radioOptions.map(option => (
                  <label className={styles.option} key={option.value}>
                    <input
                      {...register(newDocument.id, {
                        required: true
                      })}
                      type="radio"
                      value={option.value}
                      className={styles.input}
                    />
                    <span className={styles.radioLabel}>{option.value}</span>
                    <span className={styles.radioButton} />
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [duplicates, formValues, isInPreview, register]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "DuplicateFileValidation"}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <header>
            <h3 className={cn(styles.title)}>DUPLICATE FILES FOUND</h3>
            <h5 className={cn(styles.subtitle)}>
              <Icon
                kind="warning"
                fillColor="#D42B3A"
                className={cn(styles.icon)}
              />
              <span>{`${duplicates?.length} duplicate files found from previously published uploads.`}</span>
            </h5>
            <ol className={styles.description}>
              <li>Preview files by clicking on the file name</li>
              <li>
                For each duplicate found, select if you would like to replace
                the published existing file or delete your uploaded file.
              </li>
              <li>Confirm your choice.</li>
              <li>Publish your files.</li>
            </ol>
          </header>
          <main>
            <div className={styles.duplicateHeader}>
              <div className={styles.newFileHeader}>New Files</div>
              <div className={styles.existingFileHeader}>
                Published Existing Files
              </div>
              <div className={styles.actionHeader}>Action</div>
            </div>
            <div className={styles.duplicateList}>{[fileRows]}</div>
          </main>
          <footer>
            <div className={styles.buttonsContainer}>
              <Button
                onClick={handleBackClick}
                variant="contained"
                size="medium"
              >
                Back
              </Button>
              <Button
                variant="outlined"
                disabled={!isDirty || !isValid}
                type="submit"
                isLoading={isLoading}
                size="medium"
              >
                {isInPreview ? "Confirm Selection & Publish" : "Next"}
              </Button>
            </div>
          </footer>
        </form>
      </div>
    </Modal>
  );
};

export default DuplicateFileValidationPopup;
