import React, { useState } from "react";
import cn from "classnames";

import styles from "./Button.module.scss";

import { TIcons } from "../../../types/icons";
import ButtonLoader from "../../shared/ButtonLoader";
import Condition from "../../shared/Condition";
import Icons from "../../shared/Icon";

type Icon = {
  kind: TIcons;
  hoverKind?: TIcons;
  iconColor?: string;
};
type ButtonProps = {
  size?: "small" | "medium" | "large";
  onClick?: any;
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained";
  preIcon?: Icon;
  postIcon?: Icon;
  isLoading?: boolean;
  className?: string;
  kind?: "primary" | "secondary" | "warning";
};

const Button: React.FC<ButtonProps> = ({
  size = "medium",
  onClick,
  children,
  disabled,
  variant = "outlined",
  preIcon,
  postIcon,
  className,
  isLoading = false,
  type = "button",
  kind = "primary"
}) => {
  const [ishovered, setIshovered] = useState(false);
  return (
    <button
      onMouseOver={() => setIshovered(true)}
      onMouseLeave={() => setIshovered(false)}
      type={type}
      disabled={disabled || isLoading}
      onClick={e => onClick && onClick(e)}
      className={cn(
        styles.button,
        styles[size],
        styles[variant],
        styles[kind],
        className
      )}
    >
      {preIcon?.kind ? (
        <Icons
          kind={ishovered ? preIcon?.hoverKind || preIcon?.kind : preIcon?.kind}
          fillColor={preIcon.iconColor}
        />
      ) : null}
      {children}
      {postIcon?.kind ? (
        <Icons
          kind={
            ishovered ? postIcon?.hoverKind || postIcon?.kind : postIcon?.kind
          }
          fillColor={postIcon.iconColor}
        />
      ) : null}

      <Condition condition={isLoading} Truthy={<ButtonLoader />} />
    </button>
  );
};

export default Button;
