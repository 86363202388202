import React from "react";

import classNames from "./UploadFileZone.module.scss";
import { ReactComponent as Icon } from "../../../icons/upload-icon.svg";

import ButtonLoader from "../../shared/ButtonLoader";
import Condition from "../../shared/Condition";
import { FileUploaderProps } from "./types";

const FileUploader: React.FC<FileUploaderProps> = ({
  isLoading = false,
  setFileList,
  documentType,
  onUpload = () => null
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    onUpload();
    setFileList([...event.target.files]);
    event.target.value = "";
    event.target.files = null;
  };

  if (!documentType) return null;

  const documentTypeName =
    documentType === "statement-doc"
      ? "statement summary "
      : documentType === "tax-doc"
      ? "tax document"
      : documentType.replaceAll("-", " ");

  return (
    <label htmlFor="upload" className={classNames.inputWrap}>
      <input
        className={classNames.uploadInput}
        onChange={handleFileChange}
        type="file"
        id="upload"
        accept=".pdf"
        multiple
      />
      <div className={classNames.descriptionWrap}>
        <Icon className={classNames.iconUploader} />
        <i className={`${classNames.iconUploader} icon-cloud-upload`} />
        <div>{`Drag and drop PDF ${documentTypeName} to upload.`}</div>
        <div className={classNames.uploadTitle}>
          <span>Browse Files</span>
          <Condition condition={isLoading} Truthy={<ButtonLoader />} />
        </div>
      </div>
    </label>
  );
};

export default FileUploader;
