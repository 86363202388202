import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./ManagementNavBar.module.scss";
import { ReactComponent as UserIcon } from "../../../../../icons/add_user.svg";
import { ReactComponent as AnnouncementIcon } from "../../../../../icons/announcement.svg";
import { ReactComponent as Burger } from "../../../../../icons/burger.svg";
import { ReactComponent as LogOutIcon } from "../../../../../icons/logout.svg";
import { ReactComponent as UploadIcon } from "../../../../../icons/upload.svg";

import { useActions } from "../../../../../hooks/useActions";
import { profileSelector } from "../../../../../store/appStatic/selectors";
import { modalSelector } from "../../../../../store/architectUsersStore/selectors";
import SetAddUserModal from "../../../../shared/Modals/SetAddUserModal";
import RowToolTip from "../../../../userManagement/RowToolTip";
import RowToolTipItem from "../../../../userManagement/RowToolTip/RowToolTipItem";

const ManagementNavBar = () => {
  const history = useHistory();
  const modal = useSelector(modalSelector);
  const profile = useSelector(profileSelector);
  const { SetOpenModalAction } = useActions();

  const handleOpenAddUserModal = useCallback(() => {
    SetOpenModalAction({ isOpen: true, type: "addUser" });
  }, [SetOpenModalAction]);

  return (
    <div className={styles.managementNavBar}>
      {profile?.isSuperAdmin || profile?.isAdmin}
      {
        <>
          <div
            className={cn(styles.navBarItem, {
              [styles.active]: history.location.pathname.includes("/upload")
            })}
            onClick={() => history.push("/user-management/upload/uploader")}
          >
            <UploadIcon className={styles.icon} />
            <p className={styles.title}>Upload</p>
          </div>
        </>
      }
      {profile?.isSuperAdmin && (
        <div
          className={cn(styles.navBarItem, {
            [styles.open]: modal.isOpen && modal.type === "addUser"
          })}
          onClick={handleOpenAddUserModal}
        >
          <UserIcon className={styles.icon} />
          <p className={styles.title}>Add new user</p>
        </div>
      )}
      {profile && (
        <RowToolTip
          tooltipId="user-menu"
          place="left"
          className={cn(styles.tooltip)}
          offset={{
            top: 5,
            left: 120
          }}
        >
          <div className={cn(styles.tooltip_wrapper)}>
            <div className={cn(styles.user_info)}>
              <b className={cn(styles.user_info_title)}>
                {profile.firstName} {profile.lastName}
              </b>
              <br />
              <span className={cn(styles.user_info_role)}>
                {profile?.isSuperAdmin ? "Super Admin" : "Admin"}
              </span>
            </div>
            <div className={styles.itemContainer}>
              {profile?.isSuperAdmin && (
                <RowToolTipItem
                  title="Client Portal Announcements"
                  className={cn(styles.user_logout)}
                  onClick={() => history.push("/user-management/announcements")}
                >
                  <AnnouncementIcon />
                </RowToolTipItem>
              )}
              <RowToolTipItem
                title="Logout"
                className={cn(styles.user_logout)}
                onClick={() => history.push("/logout")}
              >
                <LogOutIcon />
              </RowToolTipItem>
            </div>
          </div>
        </RowToolTip>
      )}
      <div
        data-for="user-menu"
        data-tip=""
        className={cn(styles.burgerMenu, "burgerMenu")}
      >
        <Burger className={styles.burgerIcon} />
      </div>
      <SetAddUserModal />
    </div>
  );
};

export default ManagementNavBar;
