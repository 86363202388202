import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";

import styles from "./DocumentTable.module.scss";

import { Table } from "../../../../components/shared/TableV8";
import CheckBox from "../../../../components/shared/UiCheckBox";
import { DocumentItem } from "../../../../types/documents";
import { getPeriodOrPublishedDate } from "../../utils";
import { DocumentTableProps } from "./DocumentTable.types";

const DocumentTable: React.FC<DocumentTableProps> = ({
  setSelected,
  selected,
  page,
  pageSize,
  loading,
  data
}) => {
  const columnHelper = createColumnHelper<DocumentItem>();
  const { t } = useTranslation();
  const md = useMediaQuery({ maxWidth: 1024 });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "actions",
        header: ({ table, column }) => (
          <CheckBox
            isDark={false}
            name={column.id}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
            checked={table.getIsAllPageRowsSelected()}
          />
        ),
        cell: ({ row }) => (
          <CheckBox
            isDark={false}
            name={row.id}
            onChange={row.getToggleSelectedHandler()}
            checked={row.getIsSelected()}
          />
        ),
        size: 5
      }),
      columnHelper.accessor("year", {
        header: () => t("statementsPage.documents.table.headers.year"),
        cell: ({ renderValue }) => renderValue(),
        enableSorting: true,
        size: md ? 150 : 300
      }),
      columnHelper.accessor("startDate", {
        header: () => t("statementsPage.documents.table.headers.period"),
        cell: ({
          row: {
            original: { startDate, endDate, publishedAt }
          }
        }) => getPeriodOrPublishedDate(startDate, endDate, publishedAt),
        enableSorting: false,
        size: md ? 200 : 400
      }),
      columnHelper.accessor("documentType", {
        header: () => t("statementsPage.documents.table.headers.documentType"),
        cell: ({ getValue, row }) => (
          <div
            className={classNames({
              [styles.newDocument]: !row.original.downloadCount
            })}
          >
            {t(`statementsPage.documents.documentType.${getValue()}`)}
          </div>
        ),
        enableSorting: true
      })
    ],
    [md]
  ) as ColumnDef<DocumentItem, unknown>[];

  return (
    <Table
      loading={loading}
      columns={columns}
      data={data}
      getRowId={row => row.id}
      onRowSelectionChange={setSelected}
      state={{
        rowSelection: selected,
        pagination: {
          pageSize,
          pageIndex: page
        }
      }}
    />
  );
};

export default DocumentTable;
