import { useRef } from "react";
import axios from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { convertDocumentPeriodToString } from "../../../utils";
import { DocumentType, PaginationParams, SavedBatch } from "../types";

type SortingParams = { id: string; desc: boolean }[];
type ParsedSortingParams = { field: string; order: "desc" | "asc" }[];

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  return config;
});

const convertSavedDate = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const dateObj = new Date(date);
  const day = dateObj.getUTCDate();
  const month = months[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

const groupSavedBatch = (batches: any) => {
  if (!batches?.length) return [];
  return batches.map((batch: any) => {
    const firstDocument = batch.documents[0];
    const res = {
      numberOfDocuments: batch.documents.length,
      startDate:
        firstDocument.startDate && firstDocument.endDate
          ? convertDocumentPeriodToString(
              firstDocument.startDate,
              firstDocument.endDate
            )
          : "N/A",
      userTitle: firstDocument.uploaderUserTitle,
      id: batch.id,
      modifiedAt: convertSavedDate(batch.modifiedAt),
      documents: batch.documents,
      savedAt: batch.savedAt
    };
    return res as SavedBatch;
  });
};

const DEFAULT_PAGE_SIZE = 10;

const useSaved = () => {
  const storedSortParams = useRef<ParsedSortingParams>([]);
  const storedPageSize = useRef(DEFAULT_PAGE_SIZE);

  const parseSortingParams = (
    sortingParams?: SortingParams
  ): ParsedSortingParams | null => {
    if (!sortingParams?.length) return null;
    return sortingParams?.map(sortBy => {
      const { id, desc } = sortBy;
      return {
        field: id,
        order: desc ? "desc" : "asc"
      } as const;
    });
  };

  const getSavedBatches = async (
    and: any,
    options: {
      documentType: DocumentType | null;
      paginationParams: PaginationParams;
      sortingParams?: any;
    }
  ) => {
    const { documentType, paginationParams, sortingParams } = options;
    const parsedSortingParams = parseSortingParams(sortingParams);

    if (paginationParams?.take) storedPageSize.current = paginationParams.take;
    if (parsedSortingParams) {
      storedSortParams.current = parsedSortingParams;
    }

    const sortParams = parsedSortingParams
      ? [...parsedSortingParams]
      : [...storedSortParams.current];

    const { skip = 0, take = storedPageSize.current } = paginationParams || {};

    const filter = {
      and: [
        ...and,
        {
          operation: "eq",
          name: "fileStatus",
          value: "SAVED"
        }
      ]
    };
    const res: any = await axiosInstance.post(
      `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/fetch?docStatus=SAVED`,
      {
        skip,
        take,
        filter,
        sort: sortParams.length
          ? sortParams
          : [
              {
                field: "savedAt",
                order: "desc"
              }
            ]
      }
    );
    const batches = groupSavedBatch(res.data.result);
    return {
      ...res.data,
      batches
    };
  };
  return { getSavedBatches };
};

export default useSaved;
