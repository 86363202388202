import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import cn from "classnames";

import styles from "./Chart.module.scss";

ChartJS.register(...registerables);

export interface ChartProps extends React.PropsWithChildren<unknown> {
  data?: any;
  options?: any;
  chartContainerClassName?: string;

  [key: string]: any;
}

const Chart: React.FC<ChartProps> = React.forwardRef(function TextField(
  props,
  ref: any
) {
  const { data, options, chartContainerClassName, ...res } = props;
  return (
    <div className={cn(styles.container, chartContainerClassName)}>
      <Line data={data} ref={ref || null} {...res} options={options} />
    </div>
  );
});
export default Chart;
