import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import styles from "./AccessDenied.module.scss";
import { ReactComponent as Line } from "../../icons/line_icon.svg";

import Button, { ButtonType } from "../../components/shared/UiButton";
import { textParams } from "../../utils";

const AccessDenied = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className={"container"}>
      <div className={styles.accessDenied}>
        <div className={styles.iconWrap}>
          <h4>{t(`accessDenied.title`)}</h4>
          <Line className={styles.decor} />
        </div>
        <div className={styles.description}>{t(`accessDenied.text`)}</div>
        <Button
          variant={ButtonType.Primary}
          className={styles.button}
          onClick={() => history.push("/logout")}
        >
          {t(`buttons.close`)}
        </Button>
        <div>
          {textParams(t(`accessDenied.bottomText`))[0]}{" "}
          <NavLink className={styles.link} to={"/registration"}>
            {textParams(t(`accessDenied.bottomText`))[1]}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
