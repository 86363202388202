import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import cn from "classnames";

import styles from "./ContactUs.module.scss";
import { ReactComponent as GlobeIcon } from "../../icons/globus.svg";
import { ReactComponent as Line } from "../../icons/line_icon.svg";

import Button, { ButtonType } from "../../components/shared/UiButton";
import ResponseAlert from "../../components/shared/UiResponseAlert";
import { ResponseAlertType } from "../../components/shared/UiResponseAlert/ResponseAlert";
import UiSelect from "../../components/shared/UiSelect";
import TextAreaField from "../../components/shared/UiTextArea";
import TextField from "../../components/shared/UiTextField";
import { useActions } from "../../hooks/useActions";
import {
  contactUsQuestionsSelector,
  countrySelector,
  profileSelector
} from "../../store/appStatic/selectors";
import { dealsModalSelector } from "../../store/dealsStore/selectors";
import { getUrlParams, textParams } from "../../utils";

type ContactUsTypes = {
  isModal?: boolean;
  className?: string;
  setSuccess?: (success: boolean) => void;
};

const ContactUs: React.FC<ContactUsTypes> = ({
  isModal = false,
  className,
  setSuccess = () => ({})
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid }
  } = useForm({ mode: "all" });

  const impersonateUser = localStorage.getItem("impersonateUser")
    ? JSON.parse(localStorage.getItem("impersonateUser") || "")
    : null;

  const { t, i18n } = useTranslation("translation");
  const history = useHistory();
  const { REACT_APP_BASE_URI } = process.env;
  const [isSuccess, setIsSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const countries = useSelector(countrySelector);
  const contactUsQuestions = useSelector(contactUsQuestionsSelector);
  const profile = useSelector(profileSelector);
  const dealsModal = useSelector(dealsModalSelector);
  const queryParams = getUrlParams(history.location.search);
  const { SetOpenModal } = useActions();
  const questionList = () => {
    if (localStorage.getItem("impersonateId") && profile) {
      return contactUsQuestions.filter(
        (el: any) => el.label !== "menu.deleteAccount" && el.postLogin
      );
    }
    if (profile) {
      return contactUsQuestions.filter((el: any) => el.postLogin);
    } else if (!profile) {
      return contactUsQuestions.filter(
        (el: any) => el.preLogin && el.label !== "menu.deleteAccount"
      );
    }
  };
  useEffect(() => {
    if (profile) {
      setDisabled(true);
      if (impersonateUser?.email) {
        const country = countries.find(
          (el: any) => el.label === impersonateUser?.location
        );
        reset({
          email: impersonateUser?.email || "",
          firstName: impersonateUser?.firstName || "",
          lastName: impersonateUser?.lastName || "",
          isMember: { label: t(`common.yes`), value: true },
          ...(country && {
            country: {
              label: country?.label || "",
              value: country?.value || ""
            }
          }),
          ...(queryParams?.state === "delete" && {
            questionType: {
              label: t("menu.deleteAccount"),
              preLogin: true,
              postLogin: true,
              value: 7
            }
          })
        });
      } else {
        const country = countries.find(
          (el: any) => el.label === profile?.location
        );
        reset({
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          isMember: { label: t(`common.yes`), value: true },
          ...(country && {
            country: {
              label: country?.label || "",
              value: country?.value || ""
            }
          }),
          ...(queryParams?.state === "delete" && {
            questionType: {
              label: t("menu.deleteAccount"),
              preLogin: true,
              postLogin: true,
              value: 7
            }
          })
        });
      }
    }
  }, [profile, reset, t, countries, queryParams?.state]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setError(null);
    const { questionType, isMember, ...result } = data;
    try {
      const res = await axios.post(
        `${REACT_APP_BASE_URI}api/contact-us`,
        {
          ...result,
          country: result.country.value,
          languageCode: i18n.language,
          member: isMember.value ? t(`common.yes`) : t(`common.no`),
          question: t(questionType.label),
          isOther: questionType.label === "contactUsPage.topics.other"
        },
        { headers: { "Accept-Language": "en" } }
      );
      if (res) {
        setIsSuccess(true);
        setSuccess(true);
      }
    } catch ({ response }) {
      const errors: any = response;
      const newError = errors?.data?.message || errors?.data?.debug;
      setError(newError || t(`resetPasswordPage.errorMessageUnknown`));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn(styles.contactUs, className)}>
      {isSuccess ? (
        isModal ? (
          <div className={styles.successModal}>
            <p className={styles.header}>
              {t(`contactUsPage.successPopup.header`)}!
            </p>
            <p className={styles.description}>
              {t(`contactUsPage.successPopup.text`)}
            </p>
            <div className={styles.actions}>
              <p>
                {`${t("common.moreQuestions.text1")} ${
                  textParams(t("common.moreQuestions.text2"))[0]
                }`}
                <br />{" "}
                <NavLink to="/faq" className={styles.whiteLink}>
                  {textParams(t("common.moreQuestions.text2"))[1]}
                </NavLink>{" "}
                {textParams(t("common.moreQuestions.text2"))[2]}{" "}
                <NavLink to="/contact-us" className={styles.whiteLink}>
                  {textParams(t("common.moreQuestions.text2"))[3]}
                </NavLink>
              </p>
              <Button
                type={"button"}
                variant={ButtonType.Primary}
                onClick={() => {
                  SetOpenModal({ type: "", isOpen: false });
                  if (!dealsModal.isOpen) {
                    history.goBack();
                  }
                }}
              >
                {t(`buttons.close`)}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.wrap}>
            <div className={styles.iconWrap}>
              <h4>{t(`contactUsPage.successPopup.header`)}!</h4>
              <Line className={styles.decor} />
            </div>
            <p>{t(`contactUsPage.successPopup.text`)}</p>
            <Button
              type={"button"}
              variant={ButtonType.Primary}
              onClick={() => {
                SetOpenModal({ type: "", isOpen: false });
                if (!dealsModal.isOpen) {
                  history.goBack();
                }
              }}
            >
              {t(`buttons.close`)}
            </Button>
            <div className={styles.infoLinks}>
              {`${t("common.moreQuestions.text1")} ${
                textParams(t("common.moreQuestions.text2"))[0]
              }`}{" "}
              <NavLink to="/faq" className={styles.navLink}>
                {textParams(t("common.moreQuestions.text2"))[1]}
              </NavLink>{" "}
              {textParams(t("common.moreQuestions.text2"))[2]}{" "}
              <NavLink
                to="/contact-us"
                className={styles.navLink}
                onClick={() => {
                  reset();
                  setIsSuccess(false);
                }}
              >
                {" "}
                {textParams(t("common.moreQuestions.text2"))[3]}
              </NavLink>
            </div>
          </div>
        )
      ) : (
        <form
          className={cn(styles.content, { [styles.popup]: isModal })}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formWrap}>
            <div
              className={cn(styles.iconWrap, {
                [styles.iconWrapModal]: isModal
              })}
            >
              <h3
                className={cn(styles.title, { [styles.titleModal]: isModal })}
              >
                {t(`contactUsPage.title`)}
              </h3>
              {!isModal && <Line className={styles.decor} />}
            </div>
            {error && (
              <ResponseAlert
                type={ResponseAlertType.Error}
                onClick={() => setError(null)}
                className={styles.alert}
              >
                <div>{error}</div>
              </ResponseAlert>
            )}
            <div className={styles.inputsWrap}>
              <TextField
                className={cn(styles.input)}
                readOnly={
                  impersonateUser
                    ? disabled && !!impersonateUser?.firstName
                    : disabled && !!profile?.firstName
                }
                type={"text"}
                label={`${t("inputs.firstName")}*`}
                {...register("firstName", {
                  required: "field is mandatory"
                })}
              />
              <TextField
                className={styles.input}
                type={"text"}
                label={`${t("inputs.lastName")}*`}
                readOnly={
                  impersonateUser
                    ? disabled && !!impersonateUser?.lastName
                    : disabled && !!profile?.lastName
                }
                {...register("lastName", { required: "field is mandatory" })}
              />
            </div>
            <div className={styles.inputsWrap}>
              <TextField
                className={styles.input}
                type={"text"}
                label={`${t("inputs.email")}*`}
                readOnly={disabled}
                placeholder={"user@example.com"}
                {...register("email", {
                  required: "field is mandatory",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
              />
              <Controller
                name={"country"}
                control={control}
                render={({ ...field }) => (
                  <UiSelect
                    {...field.field}
                    options={countries}
                    label={`${t("inputs.location")}*`}
                    placeholder={`--${t("inputs.selectorPlaceholder")}--`}
                    className={styles.input}
                    disabled={
                      impersonateUser
                        ? disabled &&
                          !!countries.find(
                            (el: any) => el.label === impersonateUser?.location
                          )
                        : disabled
                    }
                    rightIcon={<GlobeIcon />}
                  />
                )}
                rules={{ required: "field is mandatory" }}
              />
            </div>
            <div className={styles.inputsWrap}>
              <Controller
                name={"questionType"}
                control={control}
                render={({ ...field }) => (
                  <UiSelect
                    {...field.field}
                    options={questionList()}
                    getOptionLabel={(e: any) => t(e.label)}
                    label={`${t("inputs.chooseTopic")}*`}
                    placeholder={t(`inputs.chooseTopicPlaceholder`)}
                    className={styles.input}
                  />
                )}
                rules={{ required: "field is mandatory" }}
              />
              <Controller
                name={"isMember"}
                control={control}
                render={({ ...field }) => {
                  return (
                    <UiSelect
                      {...field.field}
                      value={
                        field?.field?.value
                          ? {
                              label: field?.field?.value?.value
                                ? t(`common.yes`)
                                : t(`common.no`),
                              value: field?.field?.value?.value
                            }
                          : undefined
                      }
                      options={[
                        { label: t(`common.yes`), value: true },
                        { label: t(`common.no`), value: false }
                      ]}
                      disabled={disabled}
                      label={`${t("inputs.isMember")}*`}
                      placeholder={`--${t("inputs.selectorPlaceholder")}--`}
                      className={styles.input}
                    />
                  );
                }}
                rules={{ required: "field is mandatory" }}
              />
            </div>
            <Controller
              name={"comment"}
              control={control}
              render={({ ...field }) => (
                <TextAreaField
                  {...field.field}
                  label={t(`inputs.message`)}
                  errors={{}}
                  className={styles.textarea}
                />
              )}
            />

            <div className={styles.buttonsWrap}>
              {!isModal && (
                <Button
                  variant={ButtonType.Text}
                  type={"button"}
                  onClick={() => {
                    SetOpenModal({ type: "", isOpen: false });
                    if (!dealsModal.isOpen) {
                      if (history.length > 2) {
                        history.goBack();
                      } else {
                        history.push("/login");
                      }
                    }
                  }}
                >
                  {t(`buttons.cancel`)}
                </Button>
              )}
              <Button
                variant={ButtonType.Primary}
                type={"submit"}
                disabled={!isValid || isLoading}
                loading={isLoading}
              >
                {t(`buttons.send`)}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactUs;
