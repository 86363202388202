import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

import styles from "./TopIncomeGroups.module.scss";
import { ReactComponent as ChevronDownIcon } from "../../../icons/chevrons_down.svg";
import { ReactComponent as ChevronUpIcon } from "../../../icons/chevrons_up.svg";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import CurrencyRateShort from "../../../components/shared/CurrencyRateShort";
import IncomeGroups from "../../../components/shared/IncomeGroups";
import Loader from "../../../components/shared/Loading";
import NoDataAnalytics from "../../../components/shared/NoDataAnalytics";
import Table from "../../../components/shared/Table/Table";
import { getSortedData } from "../../../configs/utils";
import {
  incomesLoadingSelector,
  incomeTypesTopSelector
} from "../../../store/analytics/selectors";
import {
  detailCellRenderer,
  rowType,
  topIncomeType
} from "../../../store/analytics/types";
import TotalEarnings from "../TotalEarnings";
import IncomeCard from "./IncomeCard";

const TopIncomeGroups = () => {
  const m = useMediaQuery({ maxWidth: 567 });
  const { t } = useTranslation("translation");
  const incomeGroupsTop = useSelector(incomeTypesTopSelector);
  const incomesLoading = useSelector(incomesLoadingSelector);
  const md = useMediaQuery({ maxWidth: 767 });
  const [currentList, setCurrentList] = useState<topIncomeType>(
    incomeGroupsTop?.incomeTypes || []
  );
  const [sortBy, setSortBy] = useState({
    id: "amount",
    desc: true
  });

  const defaultSortBy = useMemo(() => {
    return [{ id: sortBy.id, desc: sortBy.desc }];
  }, [sortBy.id, sortBy.desc, currentList]);

  const sortedData = useMemo<topIncomeType>(() => {
    return getSortedData(currentList, {
      sort: sortBy.id,
      order: sortBy.desc
    });
  }, [currentList, sortBy.id, sortBy.desc]);

  const handleSort = useCallback(
    ({ sortBy: sortByInner }) => {
      if (sortByInner[0]?.id) {
        setSortBy(sortByInner[0]);
      }
    },
    [sortBy.id, sortBy.desc]
  );
  useEffect(() => {
    if (incomeGroupsTop?.incomeTypes) {
      setCurrentList(incomeGroupsTop?.incomeTypes);
    }
  }, [incomeGroupsTop?.incomeTypes]);

  const Collapse = useCallback(
    ({ row }: rowType) => {
      return (
        <button
          className={cn(styles.chevron)}
          onClick={() => {
            setCurrentList(
              sortedData.map(el => {
                if (el.name === row.original.name) {
                  return { ...el, isOpen: !row.original.isOpen };
                } else {
                  return el;
                }
              })
            );
          }}
        >
          {!row.original.isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </button>
      );
    },
    [currentList]
  );

  const columns = useMemo(
    () => [
      {
        accessor: "index",
        withoutSort: true,
        Header: "#",
        maxWidth: 10,
        Cell: ({ row }: rowType) => {
          return (
            <div>
              <p
                className={styles.previousAmount}
              >{`${row.original.index}.`}</p>
            </div>
          );
        }
      },
      {
        accessor: "name",
        Header: md
          ? t(`analytics.incomeGroupsLowercase`)
          : t(`analytics.topIncomeGroupsLowercase`),
        maxWidth: md ? 50 : 150,
        Cell: ({ row }: rowType) => {
          return (
            <div>
              <p className={styles.previousAmount}>{row.original.name}</p>
            </div>
          );
        }
      },
      ...(md
        ? []
        : [
            {
              accessor: "previousAmount",
              Header: t("analytics.lastPeriod"),
              maxWidth: 80,
              headerClass: "customHeader",
              className: styles.end,
              Cell: ({ row }: rowType) => {
                return (
                  <div>
                    <p className={styles.previousAmount}>
                      <CurrencyRateShort
                        id={`analytics.lastPeriod_${row.original.index}`}
                        rate={row.original.previousAmount}
                      />
                    </p>
                  </div>
                );
              }
            }
          ]),
      {
        accessor: "amount",
        Header: t("analytics.currentPeriod"),
        maxWidth: md ? 40 : 80,
        headerClass: "customHeader",
        className: styles.end,
        Cell: ({ row }: rowType) => {
          return (
            <div className={styles.tableValue}>
              <p
                className={cn(styles.amount, {
                  [styles.noPercentage]: !row.original.percentage
                })}
              >
                <CurrencyRateShort
                  id={`analytics.currentPeriod_${row.original.index}`}
                  rate={row.original.amount}
                />
              </p>
              {!!row.original?.percentage && (
                <p>
                  {row.original.direction === "INCREMENT" ? (
                    <p className={styles.increment}>
                      {`${row.original?.percentage || 0}%`}
                      <UpIcon />
                    </p>
                  ) : (
                    <p className={styles.decrement}>
                      {`(%${row.original.percentage || 0})`}
                      <DownIcon />
                    </p>
                  )}
                </p>
              )}
            </div>
          );
        }
      },
      {
        Header: "",
        id: "collapse",
        Cell: Collapse,
        withoutSort: true,
        minWidth: 40,
        maxWidth: 15
      }
    ],
    [sortedData, sortBy.id, sortBy.desc, md, t]
  );

  if (incomesLoading) {
    return <Loader />;
  }

  if (!incomeGroupsTop && !incomesLoading) {
    return <NoDataAnalytics className={styles.empty} />;
  }

  const DetailCellRenderer = (row: detailCellRenderer) => {
    const data = row.original?.incomeTypes;
    return (
      <div className={cn(styles.table_detail)}>
        {data?.map((el: any) => {
          return (
            <div key={el.amount} className={styles.row}>
              <p className={styles.nameWrap}>{el.name}</p>

              <div className={styles.infoWrap}>
                <p className={styles.prev}>
                  {" "}
                  <CurrencyRateShort
                    id={`TopIncomeGroups_previousAmount_${uuidv4()}`}
                    rate={el.previousAmount}
                  />
                </p>
                <div className={styles.pers}>
                  <p>
                    <CurrencyRateShort
                      id={`TopIncomeGroups_amount_${uuidv4()}`}
                      rate={el.amount}
                    />
                  </p>
                  {!!el.percentage && (
                    <>
                      {el.direction === "INCREMENT" ? (
                        <p className={styles.increment}>
                          {`${el.percentage || 0}%`}
                          <UpIcon />
                        </p>
                      ) : (
                        <p className={styles.decrement}>
                          {`(%${el.percentage || 0})`}
                          <DownIcon />
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.topIncomeGroups}>
      <div className={styles.earnings}>
        <TotalEarnings
          value={incomeGroupsTop.total}
          percent={incomeGroupsTop.percentage}
          isIncrement={incomeGroupsTop.direction === "INCREMENT"}
          className={styles.earningsWrap}
        />
        {incomeGroupsTop.total > 0 && (
          <div className={styles.topIncomeGroupsWrap}>
            <IncomeGroups isInnerPage={true} />
          </div>
        )}
      </div>
      <div className={styles.table}>
        {!m ? (
          <Table
            callapsClassName={styles.callapsClassName}
            className={styles.table}
            data={sortedData.map((el, i) => ({ ...el, index: i + 1 }))}
            columns={columns}
            callapsComponent={DetailCellRenderer}
            defaultSortBy={defaultSortBy}
            onSort={handleSort}
          />
        ) : (
          <IncomeCard
            data={sortedData.map((el, i) => ({ ...el, index: i + 1 }))}
          />
        )}
      </div>
    </div>
  );
};

export default TopIncomeGroups;
