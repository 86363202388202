import { Reducer } from "redux";

import { AnalyticsTypes, IAnalyticsActions, IAnalyticsState } from "./types";

export const analyticsInitialState: IAnalyticsState = {
  songsTop: null,
  top4Songs: null,
  sourcesTop: null,
  top4Sources: null,
  territoriesTop: null,
  top4Territories: null,
  incomeTypesTop: null,
  top4IncomeTypes: null,
  songsLoading: false,
  sourcesLoading: false,
  incomesLoading: false,
  territoriesLoading: false
};

export const analyticsReducer: Reducer<IAnalyticsState, IAnalyticsActions> = (
  state = analyticsInitialState,
  action
) => {
  switch (action.type) {
    case AnalyticsTypes.GET_TOP_SONGS_SUCCESS: {
      return {
        ...state,
        songsTop: action.payload.res,
        top4Songs: action.payload.top4Songs
      };
    }
    case AnalyticsTypes.GET_TOP_SOURCES_SUCCESS: {
      return {
        ...state,
        sourcesTop: action.payload.res,
        top4Sources: action.payload.top4Sources
      };
    }
    case AnalyticsTypes.GET_TOP_TERRITORIES_SUCCESS: {
      return {
        ...state,
        territoriesTop: action.payload.res,
        top4Territories: action.payload.top4Territories
      };
    }
    case AnalyticsTypes.GET_TOP_INCOME_GROUPS_SUCCESS: {
      return {
        ...state,
        incomeTypesTop: action.payload.res,
        top4IncomeTypes: action.payload.top4IncomeTypes
      };
    }
    case AnalyticsTypes.SET_SONGS_LOADING: {
      return {
        ...state,
        songsLoading: action.payload
      };
    }
    case AnalyticsTypes.SET_INCOME_LOADING: {
      return {
        ...state,
        incomesLoading: action.payload
      };
    }
    case AnalyticsTypes.SET_TERRITORIES_LOADING: {
      return {
        ...state,
        territoriesLoading: action.payload
      };
    }
    case AnalyticsTypes.SET_SOURCES_LOADING: {
      return {
        ...state,
        sourcesLoading: action.payload
      };
    }
    default:
      return state;
  }
};
