import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

import styles from "./TopTerritories.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import CurrencyRateShort from "../../../components/shared/CurrencyRateShort";
import Loader from "../../../components/shared/Loading";
import NoDataAnalytics from "../../../components/shared/NoDataAnalytics";
import Table from "../../../components/shared/Table/Table";
import TerritoriesBar from "../../../components/shared/TerritoriesBar";
import { getSortedData } from "../../../configs/utils";
import {
  territoriesLoadingSelector,
  territoriesTopSelector
} from "../../../store/analytics/selectors";
import { rowType, territoriesTopType } from "../../../store/analytics/types";
import { textParams } from "../../../utils";
import TotalEarnings from "../TotalEarnings";
import TerritoriesCard from "./TerritoriesCard";

const TopTerritories = () => {
  const m = useMediaQuery({ maxWidth: 567 });
  const { t } = useTranslation("translation");
  const md = useMediaQuery({ maxWidth: 1270 });
  const mb = useMediaQuery({ maxWidth: 568 });
  const territoriesTop = useSelector(territoriesTopSelector);
  const territoriesLoading = useSelector(territoriesLoadingSelector);
  const [currentList, setCurrentList] = useState<territoriesTopType>(
    territoriesTop?.topTerritories || []
  );
  const [sortBy, setSortBy] = useState({
    id: "amount",
    desc: true
  });
  const defaultSortBy = useMemo(() => {
    return [{ id: sortBy.id, desc: sortBy.desc }];
  }, [sortBy.id, sortBy.desc, currentList]);

  const sortedData = useMemo<territoriesTopType>(() => {
    return getSortedData(currentList, {
      sort: sortBy.id,
      order: sortBy.desc
    });
  }, [currentList, sortBy.id, sortBy.desc]);

  const handleSort = useCallback(
    ({ sortBy: sortByInner }) => {
      if (sortByInner[0]?.id) {
        setSortBy(sortByInner[0]);
      }
    },
    [sortBy.id, sortBy.desc]
  );

  useEffect(() => {
    if (territoriesTop?.topTerritories) {
      setCurrentList(territoriesTop?.topTerritories);
    }
  }, [territoriesTop?.topTerritories]);

  const columns = useMemo(
    () => [
      {
        accessor: "index",
        withoutSort: true,
        Header: "#",
        maxWidth: 10,
        Cell: ({ row }: rowType) => {
          return (
            <div>
              <p
                className={styles.previousAmount}
              >{`${row.original.index}.`}</p>
            </div>
          );
        }
      },
      {
        accessor: "title",
        Header:
          textParams(t(`analytics.top20Territories`))[0] +
          ` ${sortedData?.length} ` +
          textParams(t(`analytics.top20Territories`))[2],
        maxWidth: md ? 90 : 200,
        Cell: ({ row }: rowType) => {
          return (
            <div>
              <p className={styles.previousAmount}>{row.original.title}</p>
            </div>
          );
        }
      },
      ...(mb
        ? []
        : [
            {
              accessor: "previousAmount",
              Header: t(`analytics.lastPeriod`),
              maxWidth: 80,
              headerClass: "customHeader",
              className: styles.end,
              Cell: ({ row }: rowType) => {
                return (
                  <div>
                    <p className={styles.previousAmount}>
                      <CurrencyRateShort
                        id={`${uuidv4()}`}
                        rate={row.original.previousAmount}
                      />
                    </p>
                  </div>
                );
              }
            }
          ]),
      {
        accessor: "amount",
        Header: t(`analytics.currentPeriod`),
        maxWidth: 80,
        headerClass: "customHeader",
        className: styles.end,
        Cell: ({ row }: rowType) => {
          return (
            <div className={styles.tableValue}>
              <p
                className={cn(styles.amount, {
                  [styles.noPercentage]: !row.original.percentage
                })}
              >
                <CurrencyRateShort
                  id={`${uuidv4()}`}
                  rate={row.original.amount}
                />
              </p>
              {!!row.original.percentage && (
                <p>
                  {row.original.direction === "INCREMENT" ? (
                    <p className={styles.increment}>
                      {`${row.original.percentage || 0}%`}
                      <UpIcon />
                    </p>
                  ) : (
                    <p className={styles.decrement}>
                      {`(%${row.original.percentage || 0})`}
                      <DownIcon />
                    </p>
                  )}
                </p>
              )}
            </div>
          );
        }
      }
    ],
    [sortedData, sortBy.id, sortBy.desc, md, mb, t]
  );

  if (territoriesLoading) {
    return <Loader />;
  }

  if (!territoriesTop && !territoriesLoading) {
    return <NoDataAnalytics className={styles.empty} />;
  }
  return (
    <div className={styles.topTerritories}>
      <div className={styles.earnings}>
        <TotalEarnings
          value={territoriesTop.total}
          percent={territoriesTop.percentage}
          isIncrement={territoriesTop.direction === "INCREMENT"}
          className={styles.earningsWrap}
        />
        {territoriesTop.total > 0 && (
          <div className={styles.topTerritoriesWrap}>
            <TerritoriesBar isInnerPage={true} />
          </div>
        )}
      </div>
      <div className={styles.table}>
        {!m ? (
          <Table
            callapsClassName={styles.callapsClassName}
            className={styles.table}
            data={sortedData.map((el, i) => ({ ...el, index: i + 1 }))}
            columns={columns}
            defaultSortBy={defaultSortBy}
            onSort={handleSort}
          />
        ) : (
          <TerritoriesCard
            data={sortedData.map((el, i) => ({ ...el, index: i + 1 }))}
          />
        )}
      </div>
    </div>
  );
};

export default TopTerritories;
