import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./TopSourcesChart.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import {
  sourcesLoadingSelector,
  sourcesTopSelector
} from "../../../store/analytics/selectors";
import ChartCard from "../ChartCard";
import CurrencyRateShort from "../CurrencyRateShort";
import SourcesChart from "./SourcesChart";

type TopSourcesChartType = {
  isInnerPage?: boolean;
};

const TopSourcesChart: React.FC<TopSourcesChartType> = ({ isInnerPage }) => {
  const { t } = useTranslation();
  const sourcesTop = useSelector(sourcesTopSelector);
  const sourcesLoading = useSelector(sourcesLoadingSelector);

  if (isInnerPage) {
    return <SourcesChart />;
  }
  return (
    <ChartCard
      title={t(`analytics.topSources`)}
      isList
      to={"topSources"}
      isLoading={sourcesLoading}
      info={sourcesTop?.topSources}
      name="topSources"
    >
      {sourcesTop?.topSources?.length && !sourcesLoading ? (
        <div>
          <SourcesChart />
          <div className={styles.total}>
            <p>{t(`analytics.allSources`)}</p>
            <div className={styles.wrapCount}>
              <p className={styles.count}>
                <CurrencyRateShort
                  id={`topSources_total`}
                  rate={sourcesTop?.total}
                />
              </p>
              {!!sourcesTop.percentage && (
                <p
                  className={cn(styles.percent, {
                    [styles.down]: sourcesTop.direction !== "INCREMENT"
                  })}
                >
                  {`${sourcesTop.percentage}%`}
                  {sourcesTop.direction === "INCREMENT" ? (
                    <UpIcon className={styles.upIcon} />
                  ) : (
                    <DownIcon className={styles.upIcon} />
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </ChartCard>
  );
};

export default TopSourcesChart;
