import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import {
  GetAdvanceRequestAction,
  SetAdvanceSuccessAction
} from "../store/advance/actions";
import * as analyticsActions from "../store/analytics/actions";
import * as appStaticActions from "../store/appStatic/actions";
import {
  ClearFilters,
  ClearModalFilters,
  GenerateFilter,
  GenerateHistoryFilter,
  GenerateSavedUploadsFilter,
  GetArchitectUsersAction,
  GetModifiersSuccessAction,
  GetUploaderListSuccessAction,
  MetaDataAction,
  ModifiersAction,
  SetBody,
  SetDateRange,
  SetDownloadDateRange,
  SetDownloadDateRangeModal,
  SetDownloadFilterModal,
  SetDownloadFilters,
  SetFilters,
  SetHistoryDateRange,
  SetHistoryDateRangeModal,
  SetHistoryFilterModal,
  SetHistoryFilters,
  setModalFilter,
  SetModifiersLoading,
  SetOpenModalAction,
  SetSavedUploadsDateRange,
  SetSavedUploadsDateRangeModal,
  SetSavedUploadsFilterModal,
  SetSavedUploadsFilters,
  SetSearchedValue,
  SetUMSTypeSearch,
  SetUploadTableType,
  UploaderListAction
} from "../store/architectUsersStore/actions";
import {
  GetClosedPeriodsAction,
  GetDealsAction,
  GetLedgersAction,
  GetPayeeCodeAction,
  getWorks,
  resetDealsState,
  resetWorksList,
  SetLedger,
  SetOpenModal,
  setQueryOptionsDeals,
  SetSelectedClosedPeriod,
  setSongsQueryOptionsDeals
} from "../store/dealsStore/actions";

export const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      bindActionCreators(
        {
          ClearFilters,
          ClearModalFilters,
          SetUMSTypeSearch,
          SetSavedUploadsFilters,
          SetSavedUploadsFilterModal,
          SetSavedUploadsDateRange,
          SetSavedUploadsDateRangeModal,
          GenerateSavedUploadsFilter,
          SetHistoryFilters,
          SetDownloadFilters,
          SetDownloadFilterModal,
          SetDownloadDateRange,
          SetDownloadDateRangeModal,
          SetHistoryFilterModal,
          SetHistoryDateRange,
          SetHistoryDateRangeModal,
          GenerateHistoryFilter,
          SetSearchedValue,
          resetWorksList,
          getWorks,
          setSongsQueryOptionsDeals,
          GetArchitectUsersAction,
          resetDealsState,
          SetBody,
          SetFilters,
          setModalFilter,
          GenerateFilter,
          SetModifiersLoading,
          GetModifiersSuccessAction,
          GetUploaderListSuccessAction,
          SetDateRange,
          SetSelectedClosedPeriod,
          setQueryOptionsDeals,
          SetOpenModal,
          SetLedger,
          SetOpenModalAction,
          UploaderListAction,
          MetaDataAction,
          GetDealsAction,
          GetLedgersAction,
          GetClosedPeriodsAction,
          GetPayeeCodeAction,
          ModifiersAction,
          SetAdvanceSuccessAction,
          GetAdvanceRequestAction,
          SetUploadTableType,
          ...appStaticActions,
          ...analyticsActions
        },
        dispatch
      ),
    [dispatch]
  );
};
