import { useCallback } from "react";
import axios from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { DocumentList, DocumentType } from "../types";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  return config;
});

type Payload = {
  documentType: DocumentType;
  startDate?: string;
  endDate?: string;
};

const useSignedUrls = () => {
  const getSignedUrls = useCallback(
    async (newFilesList: File[], payload: Payload, batchId?: string) => {
      if (!newFilesList.length) return;
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URI}api/cms/${payload.documentType}/signed-urls`,
        {
          fileNames: newFilesList.map(file => file.name),
          ...payload,
          batchId
        }
      );
      return res?.data as DocumentList;
    },
    []
  );
  return { getSignedUrls };
};

export default useSignedUrls;
