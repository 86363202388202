import React from "react";

import styles from "../SetEditUserModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../../icons/close.svg";
import { ReactComponent as WarningIcon } from "../../../../../icons/warning.svg";

import LoadingCircle from "../../../LoadingCircle";
import Button from "../../../UiButton";

type ActionsModalType = {
  isLoading: boolean;
  modalRef: any;
  error: string;
  handleClose: () => void;
  handleSaveChanges: any;
  handleDiscard: any;
  type: string;
  title?: any;
  description?: any;
};

const ActionsModal: React.FC<ActionsModalType> = ({
  isLoading,
  modalRef,
  error,
  handleClose,
  handleSaveChanges,
  handleDiscard,
  type,
  title,
  description
}) => {
  return (
    <div className={styles.saveModal} ref={modalRef}>
      <CloseIcon className={styles.closeIcon} onClick={() => handleClose()} />
      <p className={styles.fullName}>{title}</p>
      <p className={styles.saveModalDescription}>{description}</p>
      <div className={styles.buttonsWrap}>
        <div className={styles.errorWrap}>
          {isLoading && <LoadingCircle className={styles.circle} />}
          {error && !isLoading && (
            <p className={styles.errorMessage}>
              <WarningIcon className={styles.warning} />
              <span>{error}</span>
            </p>
          )}
        </div>
        {type === "unsaved" && (
          <Button
            type={"button"}
            className={styles.discardButton}
            onClick={() => handleDiscard()}
            disabled={isLoading}
          >
            Discard
          </Button>
        )}
        <Button
          type={"button"}
          className={styles.button}
          onClick={() => {
            if (type === "discard") {
              handleDiscard();
            } else {
              handleSaveChanges();
            }
          }}
          disabled={isLoading}
        >
          {type === "discard" ? "Discard" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default ActionsModal;
