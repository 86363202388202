import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import cn from "classnames";

import styles from "./Filter.module.scss";
import { ReactComponent as FilterIcon } from "../../../icons/filter.svg";
import { ReactComponent as SearchIcon } from "../../../icons/search_thin.svg";

import Select from "../../../components/userManagement/Select";
import { useActions } from "../../../hooks/useActions";
import {
  modalSelector,
  UMSTypeSearchSelector
} from "../../../store/architectUsersStore/selectors";
import { GridType } from "../api/Grid";
import Button, { ButtonType } from "../Button";

export interface FilterBar {
  hasSearchInput?: boolean;
  hasSelect?: boolean;
  onClick?: () => void;
  isOpen?: boolean | undefined;
  value?: GridType;
  closeFilterModal?: () => void;
  onChangeSearch?: (e: any) => void;
  searchClick?: (e: any) => void;
  searchEmpty?: (e: any) => void;
  searchValue?: string;
  placeholder?: string;

  [key: string]: any;
}

function FilterBar(props: FilterBar) {
  const { SetUMSTypeSearch, ClearModalFilters, ClearFilters } = useActions();
  const { hasSelect = false } = props;
  const UMSTypeSearch = useSelector(UMSTypeSearchSelector);
  const [option, setOption] = useState<{ value: string; label: string }>(
    UMSTypeSearch
  );
  const modal = useSelector(modalSelector);
  const { control, register } = useForm({
    defaultValues: {
      name: option
    }
  });
  const placeholder = useCallback(() => {
    if (option?.value === "USER NAME/EMAIL") {
      return "Search client name or email";
    }
    if (option?.value === "PAYEE NAME/CODE") {
      return "Search payee name or code";
    }
  }, [option]);
  const changeSearchType = (option: { label: string; value: string }) => {
    SetUMSTypeSearch(option);
    ClearModalFilters();
    ClearFilters();
  };
  return (
    <>
      <div className={cn(styles.bar, "d-flex justify-end h-12 items-center")}>
        {props.hasSearchInput ? (
          <>
            <div className={cn(styles.dropdown)}>
              {hasSelect ? (
                <Select
                  {...register("name", {
                    onChange: event => {
                      setOption(event.target.value);
                      changeSearchType(event.target.value);
                    }
                  })}
                  control={control}
                  placeholder="Select Search Type"
                  options={[
                    { label: "USER NAME / EMAIL", value: "USER NAME/EMAIL" },
                    { label: "PAYEE NAME / CODE", value: "PAYEE NAME/CODE" }
                  ]}
                  defaultValue={option}
                />
              ) : null}
            </div>
            <TextField
              {...props.res}
              value={props.searchValue}
              onChange={e => {
                if (e.target.value === "") {
                  props.searchEmpty && props.searchEmpty(e);
                }
                props.onChangeSearch && props.onChangeSearch(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  props.searchClick && props.searchClick(e);
                }
              }}
              id={`user-search`}
              variant="standard"
              placeholder={props.placeholder || placeholder()}
              className={cn(styles.input, "items-center find-input")}
              InputProps={{
                endAdornment: (
                  <Button type={ButtonType.Text} onClick={props.searchClick}>
                    <SearchIcon className={styles.icon} />
                  </Button>
                )
              }}
            />
          </>
        ) : null}
        <div
          data-testid="filter-icon"
          onClick={props.onClick}
          className={cn(
            styles.icon_wrapper,
            modal.isOpen &&
              (modal.type === "filterModal" ||
                modal.type.includes("HistoryFilterModal") ||
                modal.type.includes("SavedUploadsFilterModal"))
              ? styles.icon_wrapper_active
              : "",
            "cursor-pointer"
          )}
        >
          <FilterIcon className={cn(styles.icon)} />
        </div>
      </div>
    </>
  );
}

export default FilterBar;
