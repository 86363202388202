import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import styles from "../Faq.module.scss";

import { textParams } from "../../../utils";

const PostLoginFaq = () => {
  const { t } = useTranslation("translation");
  return (
    <div className="container">
      <div className={styles.faq}>
        <div className={styles.header}> {t(`menu.faq`)}</div>
        <div className={styles.content}>
          <div className={styles.headTxt}>
            {t(`faqPage.postLogin.whenReceivePaymentsOrStatementQuestion`)}
          </div>
          <div className={styles.txt}>
            {t(`faqPage.postLogin.whenReceivePaymentsOrStatementAnswer`)}
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.postLogin.howUpdateLanguageQuestion`)}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.postLogin.howUpdateLanguageAnswer`))[0]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.postLogin.howUpdateLanguageAnswer`))[1]}
              </NavLink>{" "}
              {textParams(t(`faqPage.postLogin.howUpdateLanguageAnswer`))[2]}
            </div>
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.postLogin.howUpdateAddressQuestion`)}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.postLogin.howUpdateAddressAnswer`))[0]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.postLogin.howUpdateAddressAnswer`))[1]}
              </NavLink>{" "}
              {textParams(t(`faqPage.postLogin.howUpdateAddressAnswer`))[2]}
            </div>
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.postLogin.whereDownloadTaxQuestion`)}{" "}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.postLogin.whereDownloadTaxAnswer`))[0]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.postLogin.whereDownloadTaxAnswer`))[1]}{" "}
              </NavLink>{" "}
              {textParams(t(`faqPage.postLogin.whereDownloadTaxAnswer`))[2]}{" "}
            </div>
          </div>
          <div>
            <div className={styles.headTxt}>
              {t(`faqPage.preLogin.otherQueriesQuestion`)}
            </div>
            <div className={styles.txt}>
              {textParams(t(`faqPage.otherQueriesAnswer`))[0]}{" "}
              <NavLink to={"contact-us"} className={styles.link}>
                {textParams(t(`faqPage.otherQueriesAnswer`))[1]}{" "}
              </NavLink>{" "}
              {textParams(t(`faqPage.otherQueriesAnswer`))[2]}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLoginFaq;
