import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";
import { Tooltip } from "@material-ui/core";
import { DateTime } from "luxon";

import styles from "./Announcements.module.scss";

import Condition from "../../components/shared/Condition";
import Icons from "../../components/shared/Icon";
import Button from "../../components/userManagement/WCMButton";
import { useActions } from "../../hooks/useActions";
import { modalSelector } from "../../store/architectUsersStore/selectors";
import { IANAtoString } from "../../utils/time";
import { correctUrl } from "../../utils/url";
import { Announcement } from "./Announcements.types";
import { AnnouncementModal, AnnouncementsTable, Header } from "./components";
import Status from "./components/Status";
import { dateFormat } from "./constants";
import useAnnouncements from "./hooks/useAnnouncements";
import { deriveStatus, parceAnnouncmentDatesToTimeZone } from "./utils";

const Announcements = () => {
  const history = useHistory();
  const [
    currentAnnouncement,
    setCurrentAnnouncement
  ] = useState<Announcement | null>(null);

  const modal = useSelector(modalSelector);

  const { SetOpenModalAction } = useActions();
  const {
    isAnnouncementsLoading,
    getAnnouncements,
    createAnnouncement,
    announcements,
    endAnnouncement,
    deleteAnnouncement,
    updateAnnouncement
  } = useAnnouncements();

  useEffect(() => {
    getAnnouncements();
  }, [getAnnouncements]);

  const openAnnouncemntModal = () => {
    SetOpenModalAction({
      isOpen: true,
      type: ["createAnnouncement"],
      queryParams: null
    });
  };
  const columns: Column<Announcement>[] = [
    {
      Header: "Announcement Dates",
      width: 130,
      Cell: ({ row }: CellProps<Announcement>) => {
        const { startDate, endDate } = row.original;
        if (!startDate || !endDate) return null;
        const start = DateTime.fromISO(startDate)
          .setZone(row.original.timeZone)
          .toFormat(dateFormat);
        const end = DateTime.fromISO(endDate)
          .setZone(row.original.timeZone)
          .toFormat(dateFormat);
        return (
          <div className={styles.rangeContainer}>
            <p>{start}</p>
            <Icons className={styles.arrow} kind="arrow_right_big" />
            <p>{end}</p>
          </div>
        );
      }
    },
    {
      accessor: "timeZone",
      Header: "Time Zone",
      Cell: ({ row }: CellProps<Announcement>) => {
        const { timeZone } = row.original;
        if (!timeZone) return null;
        const timeZoneLabel = IANAtoString(timeZone);
        return <p>{timeZoneLabel}</p>;
      },
      width: 70
    },
    {
      accessor: "message",
      Header: "Announcement Banner Message",
      Cell: ({ row }: CellProps<Announcement>) => {
        const { message } = row.original;
        if (!message) return null;
        const link =
          row.original.href && row.original.link ? (
            <a
              className={styles.messageLink}
              href={correctUrl(row.original.href)}
              target="_blank"
              rel="noreferrer"
            >
              {row.original.link}
            </a>
          ) : null;
        return (
          <p>
            {message} {link}
          </p>
        );
      },
      width: 300
    },
    {
      id: "status",
      Header: "Status",
      width: 60,
      sortType: (rowA: Row<Announcement>, rowB: Row<Announcement>) => {
        const { startDate: startDateA, endDate: endDateA } = rowA.original;
        const { startDate: startDateB, endDate: endDateB } = rowB.original;
        if (!startDateA || !endDateA || !startDateB || !endDateB) return 0;
        const statusA = deriveStatus(startDateA, endDateA);
        const statusB = deriveStatus(startDateB, endDateB);
        return statusA.localeCompare(statusB);
      },
      Cell: ({ row }: CellProps<Announcement>) => {
        const { startDate, endDate } = row.original;
        if (!startDate || !endDate) return null;
        const status = row.original.ended
          ? "ManuallyEnded"
          : deriveStatus(startDate, endDate);
        return (
          <div className={styles.statusContainer}>
            <Status status={status} />
          </div>
        );
      }
    },
    {
      id: "tooltip",
      disableSortBy: true,
      width: 30,
      Cell: ({ row }: CellProps<Announcement>) => {
        const { startDate, endDate } = row.original;
        if (!startDate || !endDate) return null;
        const status = row.original.ended
          ? "ManuallyEnded"
          : deriveStatus(startDate, endDate);
        const isOutdated = ["Expired", "ManuallyEnded"].includes(status);

        return (
          <div className={styles.tooltipContainer}>
            <Condition
              condition={!isOutdated}
              Truthy={
                <>
                  <Tooltip
                    classes={{ tooltip: styles.endAnnouncementTooltip }}
                    title="End Announcement"
                  >
                    <div>
                      <Button
                        onClick={() => {
                          setCurrentAnnouncement(row.original);
                          SetOpenModalAction({
                            isOpen: true,
                            type: ["endAnnouncement"]
                          });
                        }}
                        className={styles.endButton}
                        variant="text"
                        preIcon={{
                          kind: "x_octagon",
                          hoverKind: "x_octagon_warning"
                        }}
                        size="small"
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <div>
                      <Button
                        onClick={() => {
                          setCurrentAnnouncement(
                            parceAnnouncmentDatesToTimeZone(row.original)
                          );
                          SetOpenModalAction({
                            isOpen: true,
                            type: ["editAnnouncement"]
                          });
                        }}
                        variant="text"
                        preIcon={{
                          kind: "edit_outlined",
                          hoverKind: "edit_outlined_bold"
                        }}
                        size="small"
                      />
                    </div>
                  </Tooltip>
                </>
              }
              Falsy={
                <Tooltip title="Delete">
                  <div>
                    <Button
                      onClick={() => {
                        setCurrentAnnouncement(row.original);
                        SetOpenModalAction({
                          isOpen: true,
                          type: ["deleteAnnouncement"]
                        });
                      }}
                      variant="text"
                      preIcon={{ kind: "bin", hoverKind: "bin_warning" }}
                      size="small"
                    />
                  </div>
                </Tooltip>
              }
            />
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <Header>
          <h1 className={styles.title}>Client Portal Announcements</h1>
          <div className={styles.buttonsContainer}>
            <Button
              onClick={() => {
                history.push("/user-management");
              }}
              preIcon={{ kind: "exit" }}
              size="small"
            >
              Exit Announcements
            </Button>
            <Button
              onClick={openAnnouncemntModal}
              preIcon={{ kind: "plus" }}
              size="large"
            >
              Create New Announcement
            </Button>
          </div>
        </Header>
        <AnnouncementsTable
          loading={isAnnouncementsLoading}
          columns={columns}
          defaultSortBy={[{ id: "status", desc: false }]}
          data={announcements}
        />
      </div>
      <Condition
        condition={modal.isOpen}
        Truthy={
          <AnnouncementModal
            endAnnouncement={endAnnouncement}
            currentAnnouncement={currentAnnouncement}
            clearCurrentAnnouncement={() => setCurrentAnnouncement(null)}
            createAnnouncement={createAnnouncement}
            updateAnnouncement={updateAnnouncement}
            deleteAnnouncement={deleteAnnouncement}
          />
        }
      />
    </>
  );
};

export default Announcements;
