import React, { useState } from "react";

import styles from "./SearchBar.module.scss";
import { ReactComponent as Loop } from "../../../icons/loop.svg";

import TextField from "../../shared/UiTextField";

const SearchBar = () => {
  const [showInput, setShowInput] = useState(false);
  return (
    <div className={styles.searchBar}>
      <div className={styles.inputWrap}>
        {showInput && (
          <TextField placeholder={"Search by Name or Payee Code"} />
        )}
      </div>
      <Loop className={styles.loop} onClick={() => setShowInput(!showInput)} />
    </div>
  );
};

export default SearchBar;
