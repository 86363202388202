import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import ReactDatepicker from "react-datepicker";
import { useController, UseControllerProps } from "react-hook-form";

import styles from "./Datepicker.module.scss";

import Icons from "../../shared/Icon";
import { commonPickerProps } from "./constants";
import Input from "./Input";

type DatepickerProps = UseControllerProps & {
  minDate?: Date;
};
const Datepicker: React.FC<DatepickerProps> = ({
  // Round to minutes
  minDate = new Date(new Date().setSeconds(0, 0)),
  ...rest
}) => {
  const { field } = useController(rest);
  const { ref, ...fieldWithoutRef } = field;
  const filterTime = (time: Date) => time > minDate;

  return (
    <ReactDatepicker
      {...fieldWithoutRef}
      {...commonPickerProps}
      calendarClassName={styles.timesheetWrapper}
      calendarIconClassname={styles.icon}
      customInput={<Input ref={ref} />}
      dateFormat="H:mm"
      filterTime={filterTime}
      icon={<Icons kind="down_icon_dropdown" />}
      popperModifiers={[
        {
          name: "offset",
          fn: data => {
            data.y = data.rects.reference.y + data.rects.reference.height + 3;
            data.rects.floating.width = 120;
            data.rects.floating.height = 10;
            return data;
          }
        }
      ]}
      renderCustomHeader={() => null}
      selected={field.value}
      showTimeSelect
      showTimeSelectOnly
      timeClassName={() => styles.time}
      timeFormat="H:mm"
      timeIntervals={30}
      wrapperClassName={styles.timepickerWrapper}
    />
  );
};

export default Datepicker;
