import React from "react";
import cn from "classnames";

import styles from "./UserDownloadBtn.module.scss";
import { ReactComponent as DownLdBtn } from "../../../icons/dwnld_btn.svg";

export interface UserDownloadBtnProps {
  click?: (e: any) => void;
}

const UserDownloadBtn = ({ click }: UserDownloadBtnProps) => {
  return (
    <div className={cn(styles.btn)} onClick={e => click && click(e)}>
      <span className={cn(styles.txt)}>Download User Data</span>
      <DownLdBtn className={cn(styles.icon)} />
    </div>
  );
};
export default UserDownloadBtn;
