import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import styles from "../InfoComponent/info-component.module.scss";
import { ReactComponent as InfoIcon } from "../../../icons/white_info_icon.svg";
import { ReactComponent as InfoIconHovered } from "../../../icons/white_info_icon_hovered.svg";

import { CurrencyFormat } from "../../../configs/utils";
export interface CurrencyRateProps {
  rate: number;
  id: number | string;
  hasIcon: boolean;
}
const CurrencyRateShort: any = ({
  rate,
  hasIcon = false
}: CurrencyRateProps) => {
  const { ledgers } = useSelector((state: any) => state.dealsReducer);
  return (
    <>
      {Math.abs(rate) >= 1000 && !hasIcon ? (
        <Tooltip
          title={CurrencyFormat(navigator.language, ledgers?.currency, rate)}
        >
          <span
            className={cn(
              styles.shortNumber,
              !hasIcon ? styles.shortNumber_inline : "",
              !hasIcon && Math.abs(rate) >= 1000
                ? styles.shortNumber_underline
                : ""
            )}
          >
            {CurrencyFormat(
              navigator.language,
              ledgers?.currency,
              rate,
              "compact"
            )}
          </span>
        </Tooltip>
      ) : (
        <span
          className={cn(
            styles.shortNumber,
            !hasIcon ? styles.shortNumber_inline : "",
            !hasIcon && Math.abs(rate) >= 1000
              ? styles.shortNumber_underline
              : ""
          )}
        >
          {CurrencyFormat(
            navigator.language,
            ledgers?.currency,
            rate,
            "compact"
          )}
          {Math.abs(rate) >= 1000 && hasIcon && (
            <span className={cn(styles.iconBlock)}>
              <InfoIcon className={cn(styles.icon)} />
              <InfoIconHovered
                className={cn(styles.icon, styles.iconHovered)}
              />
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default CurrencyRateShort;
