import React from "react";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import styles from "../../History.module.scss";

import { fileUtils } from "../../../../utils";

const FileNameColumnCell = ({ row, tableType, fetchedSearchValue }: any) => {
  const documents = row?.original?.documents;
  return (
    <div className={styles.files}>
      {/* There will be the files list , for example // row.original.files */}
      {documents &&
        documents.slice(0, 3).map((el: { fileName: string; id: string }) => {
          const payeeCodeMatchesSearchValue =
            fetchedSearchValue &&
            el.fileName
              .toLowerCase()
              .includes(fetchedSearchValue.toLowerCase());
          return (
            <div key={el.fileName}>
              <Tooltip title={`Download - ${el.fileName}`}>
                <a
                  className={cn(styles.file, {
                    [styles.payeeCodeMatch]: payeeCodeMatchesSearchValue
                  })}
                  onClick={event => {
                    event.stopPropagation();
                    const { id, fileName } = el;
                    fileUtils.getSignedURL({
                      documentId: id,
                      tableType,
                      name: fileName
                    });
                  }}
                >
                  {el.fileName}
                </a>
              </Tooltip>
            </div>
          );
        })}
      {documents.length > 3 ? (
        <>
          <Tooltip title="View all files">
            <span className={cn(styles.files_count)}>
              + {documents.length - 3}
            </span>
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default FileNameColumnCell;
