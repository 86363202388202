import React, { useState } from "react";

import styles from "./Button.module.scss";
import { ReactComponent as ChevronDown } from "../../../icons/chevron_down.svg";

import { jc } from "../../../lib/styles";
import Link from "../Link";
import Popover from "../Popover";

export enum ButtonType {
  Approve,
  Danger,
  Default,
  Gray,
  Primary,
  Text
}

export interface ButtonMenuItemProps extends React.PropsWithChildren<unknown> {
  className?: string;
  disabled?: boolean;
  href?: string;
  key?: React.Key;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => void;
  text?: string;
}

export interface ButtonProps extends React.PropsWithChildren<unknown> {
  className?: string;
  disabled?: boolean;
  href?: string;
  menuItems?: ButtonMenuItemProps[];
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => void;
  text?: string;
  type?: ButtonType;
}

const getButtonClassName = (props: ButtonProps): string => {
  return jc(
    `${styles.button} focus:outline-none font-suisse-intl inline-flex items-center px-4 text-13`,
    (typeof props.type === "undefined" || props.type === ButtonType.Default) &&
      `${styles.default} bg-wcm-dark border border-wcm-dark text-white`,
    props.type === ButtonType.Approve &&
      `${styles.approve} bg-wcm-green focus:bg-wcm-green-darker hover:bg-wcm-green-darker border` +
        ` border-wcm-green focus:border-wcm-green-darker hover:border-wcm-green-darker text-white`,
    props.type === ButtonType.Danger &&
      `${styles.danger} bg-wcm-danger focus:bg-wcm-danger-darker hover:bg-wcm-danger-darker border` +
        ` border-wcm-danger focus:border-wcm-danger-darker hover:border-wcm-danger-darker text-white`,
    props.type === ButtonType.Gray &&
      `${styles.gray} bg-wcm-gray focus:bg-gray-dark hover:bg-gray-dark border` +
        ` border-wcm-gray focus:border-gray-dark hover:border-gray-dark text-white`,
    props.type === ButtonType.Primary &&
      `${styles.primary} bg-wcm-accent focus:bg-wcm-accent-darker hover:bg-wcm-accent-darker border` +
        ` border-wcm-accent focus:border-wcm-accent-darker hover:border-wcm-accent-darker text-white`,
    props.type === ButtonType.Text &&
      `${styles.text} px-3 text-gray-dark focus:text-wcm-accent hover:text-wcm-accent`,
    props.className
  );
};

const buttonRender = (
  props: ButtonProps,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
): JSX.Element => {
  const children =
    typeof props.children === "undefined" ? props.text : props.children;
  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    props.onClick && props.onClick(e);
    props.menuItems && setAnchorEl(e.currentTarget);
  };
  // if (props.href && !props.disabled) {
  //   return (
  //     <Link href={props.href}>
  //       <a
  //         className={getButtonClassName(props)}
  //         onClick={props.onClick}
  //         role={props.onClick && "button"}
  //       >
  //         {children}
  //         {props.menuItems && (
  //           <ChevronDown className="h-3 ml-2 w-3" strokeWidth="1.5" />
  //         )}
  //       </a>
  //     </Link>
  //   );
  // }
  return (
    <button
      className={getButtonClassName(props)}
      disabled={false}
      onClick={onClick}
    >
      {children}
      {props.menuItems && (
        <ChevronDown className="h-3 ml-2 w-3" strokeWidth="1.5" />
      )}
    </button>
  );
};

const menuItemRender = (
  props: ButtonMenuItemProps,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
): JSX.Element => {
  const className = jc(
    styles.button,
    "focus:outline-none font-suisse-intl flex items-center px-3 text-13 text-gray-dark" +
      " focus:text-wcm-accent hover:text-wcm-accent w-full",
    props.className
  );
  const children =
    typeof props.children === "undefined" ? props.text : props.children;
  const onClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAnchorEl(null);
    props.onClick && props.onClick(e);
  };
  if (props.href && !props.disabled) {
    return (
      <Link href={props.href} key={props.key}>
        <a
          className={className}
          onClick={props.onClick}
          role={props.onClick && "button"}
        >
          {children}
        </a>
      </Link>
    );
  }
  return (
    <button
      className={className}
      disabled={props.disabled || !props.onClick}
      key={props.key}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Button = (props: ButtonProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onMenuClose = () => setAnchorEl(null);
  return (
    <>
      {buttonRender(props, setAnchorEl)}
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          onClose={onMenuClose}
          open={true}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <div className="py-2">
            {props.menuItems?.map((item, i) =>
              menuItemRender({ ...item, key: i }, setAnchorEl)
            )}
          </div>
        </Popover>
      )}
    </>
  );
};

export default Button;
