import { combineReducers } from "redux";

import { advanceReducer } from "./advance";
import { analyticsReducer } from "./analytics";
import { appStaticReducer } from "./appStatic";
import { architectUsersReducer } from "./architectUsersStore";
import { dealsReducer } from "./dealsStore";

const rootReducer = combineReducers({
  architectUsersReducer,
  dealsReducer,
  appStaticReducer,
  analyticsReducer,
  advanceReducer
});

export default rootReducer;
