import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { defaultLocale, locales } from "../../../lang/config";

const CustomLink = (props: any): JSX.Element => {
  const { locale } = useIntl();
  const isAbsoluteLink =
    props.href.indexOf("//") === 0 || props.href.indexOf("/") !== 0;
  const noLocaleHref = locales.reduce(
    (r, lang) => r.replace(new RegExp(`^/${lang}/`), "/"),
    props.href
  );
  const localeHref =
    locale === defaultLocale ? noLocaleHref : `/${locale}${noLocaleHref}`;
  return <Link {...props} href={isAbsoluteLink ? props.href : localeHref} />;
};

export default CustomLink;
