import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import classNames from "classnames";
import { DateTime } from "luxon";

import styles from "./Datepicker.module.scss";

import Condition from "../../shared/Condition";
import Icons from "../../shared/Icon";
import Button from "../WCMButton";

type HeaderProps = ReactDatePickerCustomHeaderProps & {
  // Array of numbers that represent common dates in hours
  commonPeriods?: number[];
  handlePeriodClick?: (period: number) => void;
  selectedPeriod?: number;
};

const Header: React.FC<HeaderProps> = ({
  decreaseMonth,
  increaseMonth,
  date,
  commonPeriods,
  handlePeriodClick,
  selectedPeriod
}) => {
  const month = DateTime.fromJSDate(date).toFormat("MMM");
  const year = DateTime.fromJSDate(date).year;
  return (
    <section className={styles.headerContainer}>
      <Condition
        condition={!!commonPeriods?.length}
        Truthy={
          <div className={styles.commonPeriods}>
            {commonPeriods &&
              commonPeriods.map(period => (
                <Button
                  className={classNames({
                    [styles.selectedPeriod]: selectedPeriod === period
                  })}
                  variant="outlined"
                  size="small"
                  key={period}
                  onClick={() => {
                    if (handlePeriodClick) handlePeriodClick(period);
                  }}
                >
                  {period >= 24
                    ? `${period / 24} ${period / 24 === 1 ? "day" : "days"}`
                    : `${period} ${period === 1 ? "hour" : "hours"}`}
                </Button>
              ))}
          </div>
        }
      />
      <div className={styles.header}>
        <Icons
          kind="calendar_left_arrow"
          onClick={decreaseMonth}
          className={styles.arrow}
        />
        <span className={styles.month}>{month}</span>
        <span className={styles.year}>{year}</span>
        <Icons
          kind="calendar_right_arrow"
          onClick={increaseMonth}
          className={styles.arrow}
        />
      </div>
    </section>
  );
};

export default Header;
