import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import i18n from "i18next";

import styles from "../Faq.mobile.module.scss";

import { languageSelector } from "../../../../store/appStatic/selectors";
import { getUrlParams, textParams } from "../../../../utils";
declare const window: any;

const PreLoginMobileFaq = () => {
  const { t } = useTranslation("translation");
  const languages = useSelector(languageSelector);
  const location = useLocation();
  const getUrlCode = getUrlParams(location.search).lang?.toUpperCase();
  const languageCode =
    languages.find((e: { value: string }) => e.value === getUrlCode) ||
    languages[0];
  useEffect(() => {
    if (languageCode?.value) {
      i18n.changeLanguage(languageCode?.value || "EN");
    }
  }, [languageCode]);
  return (
    <div className={styles.faq}>
      <div className={styles.headTxt}>
        {t(`faqPage.preLogin.howRegisterQuestion`)}
      </div>
      <div className={styles.txt}>
        {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[0]}
        <span
          onClick={() => window.ReactNativeWebView.postMessage("Registration")}
          className={styles.link}
        >
          {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[1]}
        </span>{" "}
        {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[2]}{" "}
        <a href={"https://www.mywarnerchappell.com/"} className={styles.link}>
          {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[3]}
        </a>{" "}
        {textParams(t(`faqPage.preLogin.howRegisterAnswer`))[4]}
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.preLogin.contLoginQuestion`)}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.preLogin.contLoginAnswer`))[0]}
          <span
            onClick={() =>
              window.ReactNativeWebView.postMessage("TroubleToLogin")
            }
            className={styles.link}
          >
            {textParams(t(`faqPage.preLogin.contLoginAnswer`))[1]}
          </span>
          {textParams(t(`faqPage.preLogin.contLoginAnswer`))[2]}
          <span
            onClick={() => window.ReactNativeWebView.postMessage("ContactUs")}
            className={styles.link}
          >
            {textParams(t(`faqPage.preLogin.contLoginAnswer`))[3]}
          </span>
        </div>
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.preLogin.troubleLoggingQuestion`)}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.preLogin.troubleLoggingAnswer`))[0]}{" "}
          <span
            onClick={() => window.ReactNativeWebView.postMessage("ContactUs")}
            className={styles.link}
          >
            {textParams(t(`faqPage.preLogin.troubleLoggingAnswer`))[1]}
          </span>{" "}
          {textParams(t(`faqPage.preLogin.troubleLoggingAnswer`))[2]}
        </div>
      </div>
      <div>
        <div className={styles.headTxt}>
          {t(`faqPage.preLogin.otherQueriesQuestion`)}
        </div>
        <div className={styles.txt}>
          {textParams(t(`faqPage.otherQueriesAnswer`))[0]}{" "}
          <span
            onClick={() => window.ReactNativeWebView.postMessage("ContactUs")}
            className={styles.link}
          >
            {textParams(t(`faqPage.otherQueriesAnswer`))[1]}
          </span>{" "}
          {textParams(t(`faqPage.otherQueriesAnswer`))[2]}
        </div>
      </div>
    </div>
  );
};

export default PreLoginMobileFaq;
