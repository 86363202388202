const innerRole = {
  Admin: "ADMIN",
  ExternalUser: "EXTERNAL_USER",
  // SENIOR_ADMIN: "SENIOR_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  Internal: "Partners_WMG_Users_Internal"
};

const userTypes: any = {
  ACCOUNTANT: "Accountant",
  AGENT: "Agent",
  BUSINESS: "Business",
  LAWYER: "Lawyer",
  MANAGER: "Manager",
  PERSONAL: "Personal",
  PUB: "Pub (limited access)",
  WORK: "Work"
};

export { innerRole, userTypes };
