import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./Header.module.scss";
import { ReactComponent as BurgerMenuIcon } from "../../../icons/burger_menu.svg";
import { ReactComponent as ProfileIcon } from "../../../icons/new_profile_icon.svg";

import AlertBanner from "../../../containers/AlertBanner";
import {
  clientsSelector,
  languageSelector,
  profileSelector,
  singleClientSelector
} from "../../../store/appStatic/selectors";
import { getUrlParams } from "../../../utils";
import Condition from "../../shared/Condition";
import SetContactUsModal from "../../shared/Modals/SetContactUsModal";
import SetDeleteAccountModal from "../../shared/Modals/SetDeleteAccount";
import UiDropdown from "../../shared/UiDropdown";
import Button from "../../userManagement/WCMButton";
import NavBar from "../NavBar";
import SearchBar from "../SearchBar";
import Menu from "./Menu";

const Header = () => {
  const { i18n } = useTranslation("translation");
  const history = useHistory();
  const profile = useSelector(profileSelector);
  const params = getUrlParams(history.location.search);
  const singleClient = useSelector(singleClientSelector);
  const languages = useSelector(languageSelector);
  const withSearch = useMemo(() => {
    return history.location.pathname.includes("payee-account");
  }, [history.location.pathname]);
  const defaultLanguage = localStorage.getItem("i18nextLng");
  const [dropdownValue, setDropdownValue] = useState<any>(null);
  const clients = useSelector(clientsSelector);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const userTermAccepted = profile?.settings?.isTermAccepted;
  const isAlertPreview = history.location.pathname.startsWith("/alert-preview");

  useEffect(() => {
    if (languages?.length) {
      setDropdownValue(
        languages?.find((el: any) => el.value === defaultLanguage)
      );
    }
  }, [languages, defaultLanguage, history.location.pathname]);
  useEffect(() => {
    if (dropdownValue?.value) {
      i18n.changeLanguage(dropdownValue?.value || "EN");
      i18n.init({ debug: false, fallbackLng: "EN" });
    }
  }, [dropdownValue]);
  useEffect(() => {
    setOpenMenu(false);
  }, [history.location]);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const isRedirect = useMemo(() => {
    const list: any = [];
    clients.forEach((el: any) => list.push(...el.payeeAccounts));
    return list.length === 1;
  }, [clients]);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <Helmet>
        <html
          lang={defaultLanguage ? defaultLanguage.toLowerCase() : undefined}
        />
      </Helmet>
      <div
        className={cn(
          styles.header,
          !params?.workCode &&
            !history.location.pathname.includes("landing") &&
            !history.location.pathname.includes("/home/") &&
            styles.header_fixheight
        )}
      >
        <div
          className={cn({
            [styles.bg]: profile && userTermAccepted
          })}
        >
          <div className={cn(styles.wrap, "container")}>
            <div className={styles.brandWrap} onClick={() => history.push("/")}>
              <div className={styles.logo}>
                <img src="/images/logo.svg" alt="WMG_LOGO" />
              </div>
              <span className={styles.logoTitle}>Client portal</span>
            </div>
            {profile && userTermAccepted && (
              <div className={styles.nav}>
                {!history.location.pathname.includes("accounts") &&
                  !isRedirect && (
                    <div
                      className={cn(
                        styles.iconWrapper,
                        styles.iconWrapper_profIcon
                      )}
                      onClick={() => history.push("/accounts")}
                    >
                      <ProfileIcon className={styles.profileIcon} />
                    </div>
                  )}

                {dropdownValue && (
                  <UiDropdown
                    isShortValue
                    options={languages}
                    className={styles.select}
                    isDropTop
                    value={dropdownValue}
                    onChange={setDropdownValue}
                    menuClassName={styles.lng_menu}
                    isGlob
                    onFocus={() => setOpenMenu(false)}
                  />
                )}
                <Condition
                  condition={false}
                  Truthy={
                    <Button
                      variant="contained"
                      size="large"
                      preIcon={{
                        kind: "notification_bell",
                        iconColor: "white"
                      }}
                    />
                  }
                />

                <div ref={wrapperRef}>
                  <div
                    className={cn(
                      styles.iconWrapper,
                      openMenu ? styles.iconWrapper_active : ""
                    )}
                    onClick={toggleMenu}
                  >
                    <BurgerMenuIcon className={styles.icon} />
                  </div>
                  <Menu isMenuOpen={openMenu} setOpenMenu={setOpenMenu} />
                </div>
              </div>
            )}
          </div>
        </div>
        <AlertBanner
          className={styles.alertBanner}
          isPreview={isAlertPreview}
        />
        {clients &&
          profile &&
          !params?.workCode &&
          !history.location.pathname.includes("/settings") &&
          !history.location.pathname.includes("/accounts") &&
          !history.location.pathname.includes("/analytics") &&
          !history.location.pathname.includes("/profile") &&
          !history.location.pathname.includes("/home/") &&
          !history.location.pathname.includes("/faq") &&
          !history.location.pathname.includes("/terms-of-use") &&
          !history.location.pathname.includes("/privacy-policy") &&
          !history.location.pathname.includes("/cookies-policy") &&
          (withSearch ? (
            <SearchBar />
          ) : (
            (singleClient?.data || isAlertPreview) && (
              <NavBar isPreview={isAlertPreview} />
            )
          ))}
        {profile && history.location.pathname.includes("/analytics") && (
          <NavBar />
        )}
        <SetContactUsModal />
        <SetDeleteAccountModal />
      </div>
    </>
  );
};
export default Header;
