import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import styles from "./NotFound.module.scss";
import { ReactComponent as Line } from "../../icons/line_icon.svg";

import Button, { ButtonType } from "../../components/shared/UiButton";
import { textParams } from "../../utils";

const NotFound = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className={"container"}>
      <div className={styles.notFound}>
        <div className={styles.iconWrap}>
          <h4>{t(`pageNotFound.title`)}</h4>
          <Line className={styles.decor} />
        </div>
        <div className={styles.description}>{t(`pageNotFound.text`)}</div>
        <Button
          variant={ButtonType.Primary}
          className={styles.button}
          onClick={() => history.push("/")}
        >
          {t(`buttons.close`)}
        </Button>
        <div>
          {textParams(t(`pageNotFound.bottomText`))[0]}{" "}
          <NavLink className={styles.link} to={"/contact-us"}>
            {textParams(t(`pageNotFound.bottomText`))[1]}{" "}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
