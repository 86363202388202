import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./ChartCard.module.scss";
import { ReactComponent as DownIcon } from "../../../icons/down_vector.svg";
import { ReactComponent as UpIcon } from "../../../icons/up.svg";

import CurrencyRateShort from "../CurrencyRateShort";
import LoadingCircle from "../LoadingCircle";

type ChartCard = {
  info?: {
    amount: number;
    title: string;
    percentage: number;
    name?: string;
    direction?: string;
  }[];
  title: string;
  totalTitle?: string;
  isList?: boolean;
  isLoading?: boolean;
  to?: string;
  totals?: any;
  name?: string;
};

const ChartCard: React.FC<ChartCard> = ({
  info,
  title,
  totalTitle = "",
  children,
  isList = false,
  isLoading = false,
  to,
  totals,
  name
}) => {
  const { t } = useTranslation();

  const colors = ["#AD076A", "#E8BE04", "#79A13C", "#007BD9"];
  return (
    <div className={styles.chartCard}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        {info?.length ? (
          <NavLink to={`/analytics/${to}`} className={styles.itemsHeader}>
            {t(`common.viewMore`)}
          </NavLink>
        ) : (
          ""
        )}
      </div>
      {isLoading && <LoadingCircle isBig className={styles.load} />}
      <div className={styles.wrap}>
        <div
          className={cn({ [styles.circle]: !isList, [styles.isList]: isList })}
        >
          {children}
        </div>
        {!isList && info?.length && !isLoading ? (
          <div className={styles.declarerCode}>
            {info?.map((code, index) => (
              <div key={index} className={styles.items}>
                <div className={styles.laneWrap}>
                  <div
                    className={styles.line}
                    style={{ background: colors[index] }}
                  />
                  <p className={styles.value}>{code?.name || code.title}</p>
                </div>
                <div className={styles.wrapCount}>
                  <p className={styles.count}>
                    <CurrencyRateShort
                      id={`${name}_${index + 1}`}
                      rate={code.amount}
                    />
                  </p>
                  {!!code?.percentage && (
                    <p
                      className={cn(styles.percent, {
                        [styles.isIncrement]: code?.direction !== "INCREMENT"
                      })}
                    >
                      {`${code?.percentage}%`}
                      {code?.direction === "INCREMENT" ? (
                        <UpIcon className={styles.upIcon} />
                      ) : (
                        <DownIcon className={styles.upIcon} />
                      )}
                    </p>
                  )}
                </div>
              </div>
            ))}
            <div className={styles.items}>
              <p className={styles.value}>{totalTitle}</p>
              <div className={styles.wrapCount}>
                <p className={styles.count}>
                  <CurrencyRateShort
                    id={`${name}_total`}
                    rate={totals?.total}
                  />
                </p>
                {!!totals?.percentage && (
                  <p
                    className={cn(styles.percent, {
                      [styles.isIncrement]: totals?.direction !== "INCREMENT"
                    })}
                  >
                    {`${totals?.percentage}%`}
                    {totals?.direction === "INCREMENT" ? (
                      <UpIcon className={styles.upIcon} />
                    ) : (
                      <DownIcon className={styles.upIcon} />
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ChartCard;
