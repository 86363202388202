import {
  PulseInsightsContext,
  PulseInsightsUpdateOptions,
  PulseInsightsWindow
} from "./PulseInsights.types";

/**
 * Initiates Pulse Insights feedback form
 * @param context Context data
 */
const initPi = (context: PulseInsightsContext): void => {
  const w: PulseInsightsWindow = window;

  if (w.isPiInitiated) {
    updatePi(context);
    return;
  }
  w.isPiInitiated = true;

  const s = document.createElement("script");
  s.async = true;
  s.src = "//js.pulseinsights.com/surveys.js";
  const f = document.getElementsByTagName("script")[0];
  f.parentNode?.insertBefore(s, f);

  class PulseInsightsCommands extends Array {
    push(...commands: unknown[]) {
      commands.forEach(c => w.PulseInsightsObject?.processCommand(c));
      return 0;
    }
  }

  function waitPulseInsightsObject(): Promise<unknown> {
    const timeoutPromise = new Promise((_, reject) => setTimeout(reject, 5000));
    const waitPromise = new Promise<void>(function(resolve) {
      function wait() {
        if (typeof w.PulseInsightsObject === "object") {
          resolve();
        } else {
          setTimeout(wait, 100);
        }
      }
      wait();
    });
    return Promise.race([timeoutPromise, waitPromise]);
  }

  waitPulseInsightsObject()
    .then(function() {
      w.pi = function(...args) {
        if (w.pi) {
          w.pi.commands = w.pi.commands || new PulseInsightsCommands();
          w.pi.commands.push(args);
        }
      };
      w.pi("host", "survey.pulseinsights.com");
      w.pi("identify", "PI-93152826");
      w.pi("pushBeforeGet", true);

      updatePi(context);
    })
    .catch(() => {
      console.error("Failed to initialize window.PulseInsightsObject");
    });
};

let updateTimeoutId: NodeJS.Timeout | null = null;

/**
 * Updates Pulse Insights feedback form context
 * @param context Context data
 * @param options Update options
 */
export const updatePi = (
  context: PulseInsightsContext,
  options?: PulseInsightsUpdateOptions
): void => {
  const retries = options?.retries ?? 50;
  const retryDelay = options?.retryDelay ?? 100;
  const w: PulseInsightsWindow = window;

  // Init Pulse Insights instead of update
  if (!w.isPiInitiated) {
    initPi(context);
    return;
  }

  // Cleanup old update
  if (updateTimeoutId) {
    clearTimeout(updateTimeoutId);
    updateTimeoutId = null;
  }

  // Pulse Insights API is not ready yet and there is no more retries
  if (!w.pi && retries <= 0) {
    console.error(
      "Cannot change the context for Pulse Insights. API is not ready."
    );
    return;
  }

  // Pulse Insights API is not ready yet so set timeout to try again
  if (!w.pi) {
    updateTimeoutId = setTimeout(() => {
      updatePi(context, { retries: retries - 1, retryDelay });
    }, retryDelay);
    return;
  }

  try {
    const contextData = w.PulseInsightsObject?.customData || {};
    contextData.localizationCode = context.localizationCode || null;
    w.pi("identify_client", context.userId || null);
    w.pi("set_context_data", contextData);
    w.pi("get", "surveys");
  } catch (e) {
    console.error("Cannot change the context for Pulse Insights", e);
  }
};
