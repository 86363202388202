/**
 * Downloads the file
 * @param fileName File name to save
 * @param href Link to the file
 */
export const downloadFile = (fileName: string, href: string): void => {
  const aElement = document.createElement("a");
  aElement.download = fileName;
  aElement.href = href;
  aElement.rel = "noopener noreferrer";
  aElement.style.display = "none";
  aElement.target = "_blank";
  document.body.appendChild(aElement);
  aElement.click();
  document.body.removeChild(aElement);
};
