import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { profileSelector } from "../../store/appStatic/selectors";
import { UserProfile } from "../../types/userProfile";
import { convertToIntlLocale } from "../../utils/format";
import { updatePi } from "./PulseInsights.utils";

/**
 * Pulse Insights feedback form
 * @returns null
 */
const PulseInsights = (): null => {
  const { i18n } = useTranslation("translation");
  const profile = useSelector<unknown, UserProfile | null>(profileSelector);

  useEffect(() => {
    updatePi({
      localizationCode: convertToIntlLocale(i18n.language),
      userId: profile?.oktaUserIdHashed
    });
  }, [profile?.oktaUserIdHashed, i18n.language]);

  return null;
};

export default PulseInsights;
