import { DataGridColDef } from "./IDataGrid";

const gridColConfigPref = "wcm-grid-col-config_";

export const getThemeClass = (theme?: string | string[]): string => {
  let themeClass = `ag-theme-material`; // default but is replaced anyways
  if (!theme || theme === "wcm") {
    // wcm is based on material so adding both
    themeClass = "ag-theme-material ag-theme-wcm";
  } else if (typeof theme === "string") {
    themeClass = `ag-theme-${theme}`;
  } else {
    themeClass = theme.map(theme => `ag-theme-${theme}`).join(" ");
  }
  return themeClass;
};

export const capitalize = (name: string): string => {
  const firstChar = name.slice(0, 1).toUpperCase();
  return firstChar + name.slice(1);
};

export const persistColumnsSettings = <T>(
  gridId: string,
  columns: DataGridColDef<T>[]
) => {
  const cacheKey = `${gridColConfigPref}${gridId}`;
  window.localStorage.setItem(
    cacheKey,
    JSON.stringify(
      columns.map(c => {
        return {
          field: c.field,
          hide: c.hide
        } as Partial<DataGridColDef<T>>;
      })
    )
  );
};

export const restoreColumnsSettings = (
  gridId: string | undefined,
  columns: unknown
): unknown => {
  if (typeof window === "undefined") return columns; // SSR check
  if (!gridId) return columns; // return columns right aways for a grid without persistence key

  const cacheKey = `${gridColConfigPref}${gridId}`;
  const colSetStr = window.localStorage.getItem(cacheKey);
  if (!colSetStr) {
    return columns;
  }

  let colConf: Partial<DataGridColDef<unknown>>[] = [];
  try {
    colConf = JSON.parse(colSetStr);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    columns = columns.map(c => {
      const conf = colConf.find(({ field }) => c.field === field) || {};
      return { ...c, ...conf };
    });
  } catch (ex) {
    /**/
  }

  return columns;
};

const timeout: { [key: string]: NodeJS.Timeout } = {};
export const debounceCallback = (
  id: string,
  callback: () => void,
  wait = 300
): void => {
  timeout[id] && clearTimeout(timeout[id]);
  timeout[id] = setTimeout(() => {
    callback();
    delete timeout[id];
  }, wait);
};

export const sleepPromise = (delay: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
