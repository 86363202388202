import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./AnalyticsNavLink.module.scss";

const AnalyticsNavLink = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  return (
    <div className={styles.analyticsNavLink}>
      <NavLink
        to={`/analytics/topSongs`}
        className={cn(styles.navLink, {
          [styles.active]: pathname.includes("topSongs")
        })}
      >
        {t(`analytics.topSongs`)}
      </NavLink>
      <NavLink
        to={`/analytics/incomeGroups/`}
        className={cn(styles.navLink, {
          [styles.active]: pathname.includes("incomeGroups")
        })}
      >
        {t(`analytics.topIncomeGroups`)}
      </NavLink>
      <NavLink
        to={`/analytics/territoriesBar`}
        className={cn(styles.navLink, {
          [styles.active]: pathname.includes("territoriesBar")
        })}
      >
        {t(`analytics.topTerritories`)}
      </NavLink>
      <NavLink
        to={`/analytics/topSources`}
        className={cn(styles.navLink, {
          [styles.active]: pathname.includes("topSources")
        })}
      >
        {t(`analytics.topSources`)}
      </NavLink>
    </div>
  );
};

export default AnalyticsNavLink;
