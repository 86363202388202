import axios from "axios";

import { oktaAuth } from "../../../lib/oktaAuth";
import { downloadFile } from "../../../utils/file";

const getSignedURL = ({
  documentId,
  tableType,
  name
}: {
  documentId: string | number;
  tableType: any;
  name: string;
}) => {
  axios
    .get(`/api/cms/${tableType}/download/${documentId}`, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        accept: "*/*",
        ContentType: `application/pdf`
      }
    })
    .then(async res => {
      await downloadFile(name, res.data.url);
    });
};

const batchRefetchDelay = {
  xs: 2000, // 1 - 100 files
  s: 4000, // 100 - 500
  m: 6000, // 500 - 1000
  l: 8000, // 1000 - 2000
  xl: 10000 // 2000 - 5000
};

const getBatchRefetchDelay = (fileSize: number) =>
  fileSize < 100
    ? batchRefetchDelay.xs
    : fileSize < 500
    ? batchRefetchDelay.s
    : fileSize < 1000
    ? batchRefetchDelay.m
    : fileSize < 2000
    ? batchRefetchDelay.l
    : batchRefetchDelay.xl;

export default {
  getBatchRefetchDelay,
  downloadFile,
  getSignedURL
};
