import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cn from "classnames";

import styles from "./SetAddUserModal.module.scss";
import { ReactComponent as Clear } from "../../../../icons/clear.svg";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as Loop } from "../../../../icons/loop.svg";
import { ReactComponent as UserIcon } from "../../../../icons/user_plus.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warning.svg";

import { useActions } from "../../../../hooks/useActions";
import { oktaAuth } from "../../../../lib/oktaAuth";
import {
  bodySelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import LoadingCircle from "../../LoadingCircle";
import Button from "../../UiButton";

const SetAddUserModal = () => {
  const { register, getValues } = useForm({
    defaultValues: { weather: "ADMIN" }
  });
  const history = useHistory();
  const modal = useSelector(modalSelector);
  const body = useSelector(bodySelector);
  const { SetOpenModalAction, GetArchitectUsersAction } = useActions();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [step, setStep] = useState({
    position: 1,
    title: "Add new user",
    description: "Locate user to add",
    buttonTitle: "Add User"
  });

  const [user, setUser] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    location: string;
    isEntered?: boolean;
  } | null>(null);

  const handleClearState = useCallback(() => {
    setValue("");
    setIsOpen(false);
    setIsSuccess(false);
    setError("");
    setUser(null);
    setStep({
      position: 1,
      title: "Add new user",
      description: "Locate user to add",
      buttonTitle: "Add User"
    });
  }, []);

  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
    handleClearState();
  }, [SetOpenModalAction, handleClearState]);

  const handleInputChange = useCallback(e => {
    setIsOpen(false);
    setUser(null);
    setError("");
    setValue(e.target.value);
  }, []);

  const handleClick = useCallback(() => {
    setError("");
    if (step.position === 1 && user) {
      setStep({
        position: 2,
        title: `${user.firstName} ${user.lastName}`,
        description: user.email,
        buttonTitle: "Save"
      });
    }
    if (step.position === 2 && user && getValues().weather) {
      setIsLoading(true);
      axios
        .post(
          "/api/users/internal",
          {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            location: user.location,
            role: getValues().weather
          },
          {
            headers: {
              authorization: `Bearer ${oktaAuth.getAccessToken()}`
            }
          }
        )
        .then(() => {
          GetArchitectUsersAction(body, false);
          setIsSuccess(true);
          history.push("/user-management/internal-users");
        })
        .catch(() => {
          setError("Unable to save user.");
        })
        .finally(() => setIsLoading(false));
    }
  }, [user, step, getValues, oktaAuth, body, GetArchitectUsersAction, history]);

  const handleSearch = useCallback(() => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value.trim())) {
      setIsOpen(true);
      setUser(null);
      setError("");
      axios
        .post(
          "/api/users/internal/search",
          {
            email: value.trim()
          },
          {
            headers: { authorization: `Bearer ${oktaAuth.getAccessToken()}` }
          }
        )
        .then(({ data }) => {
          setUser(data);
        })
        .catch(() => {
          setError("No Internal User Found");
        });
    }
  }, [value, setUser, oktaAuth]);

  const handleRowClick = useCallback(() => {
    if (user) {
      setUser({ ...user, isEntered: true });
      setValue(`${user?.firstName} ${user?.lastName} (${user?.email})`);
    }
    setIsOpen(false);
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        toggleModal();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, toggleModal]);

  return (
    <div className={styles.setAddUserModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "addUser"}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal, {
          [styles.roleModal]: step.position === 2,
          [styles.success]: isSuccess
        })}
        overlayClassName={styles.myoverlay}
      >
        {isSuccess ? (
          <div className={styles.addUser}>
            <UserIcon className={styles.userIcon} />
            <span>New User Added</span>
          </div>
        ) : (
          <>
            <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
            <h2 className={styles.title}>{step.title}</h2>
            <p className={styles.description}>{step.description}</p>
            {step.position === 1 && (
              <div className={styles.inputWrap}>
                <input
                  className={cn(styles.input, { [styles.open]: isOpen })}
                  value={value}
                  onChange={handleInputChange}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <Loop className={styles.loop} onClick={handleSearch} />
                {!!value.length && (
                  <Clear className={styles.clear} onClick={handleClearState} />
                )}
                {isOpen && (
                  <div className={styles.user}>
                    {error ? (
                      <div className={styles.error}>{error}</div>
                    ) : (
                      <>
                        {user ? (
                          <div
                            className={styles.userInfo}
                            onClick={handleRowClick}
                          >
                            <span
                              className={styles.fullName}
                            >{`${user.firstName} ${user.lastName}`}</span>
                            <span>{` (${user.email})`}</span>
                          </div>
                        ) : (
                          <div>
                            <LoadingCircle />
                            <span>Searching for Internal User</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {step.position === 2 && (
              <div className={styles.radio}>
                <form>
                  <p className={styles.radioTitle}>Role*</p>
                  <div className={styles.radioButtons}>
                    <div className={styles.radioWrap}>
                      <label>Admin</label>
                      <input
                        {...register("weather")}
                        type="radio"
                        name="weather"
                        value="ADMIN"
                        id="field-admin"
                      />
                    </div>
                    {/*<div className={styles.radioWrap}>*/}
                    {/*  <label>Senior Admin</label>*/}
                    {/*  <input*/}
                    {/*    {...register("weather")}*/}
                    {/*    type="radio"*/}
                    {/*    name="weather"*/}
                    {/*    value="SENIOR_ADMIN"*/}
                    {/*    id="field-superAdmin"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className={styles.radioWrap}>
                      <label>Super Admin</label>
                      <input
                        {...register("weather")}
                        type="radio"
                        name="weather"
                        value="SUPER_ADMIN"
                        id="field-superAdmin"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            <div className={styles.buttonWrap}>
              {isLoading && <LoadingCircle className={styles.loadingCircle} />}
              {error && step.position === 2 && (
                <div className={styles.userError}>
                  <WarningIcon className={styles.warning} />
                  {error}
                </div>
              )}
              <Button
                className={styles.button}
                onClick={handleClick}
                disabled={!user?.isEntered && step.position === 1}
              >
                {step.buttonTitle}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default SetAddUserModal;
