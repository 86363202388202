import { AuthTransaction } from "@okta/okta-auth-js";

export interface MfaFormProps {
  onError: () => void;
  onSuccess: (sessionToken: string) => void;
  transaction: AuthTransaction;
}

export enum AuthFactorType {
  PUSH = "push",
  TOKEN_SOFTWARE_TOTP = "token:software:totp"
}

export enum AuthFactorProvider {
  OKTA = "OKTA"
}

export interface AuthFactorTransaction {
  factorResult: "ERROR" | "REJECTED" | "TIMEOUT" | "WAITING";
  sessionToken?: string;
  status: "SUCCESS";
}

export interface AuthFactorVerifyResult {
  poll: (options: {
    autoPush: boolean | (() => boolean);
  }) => Promise<AuthFactorTransaction>;
  sessionToken?: string;
  status: "MFA_CHALLENGE" | "SUCCESS";
}

export interface AuthFactor {
  id: string;
  factorType: AuthFactorType;
  provider: AuthFactorProvider;
  verify: (options: {
    autoPush: boolean;
    passCode?: string;
  }) => Promise<AuthFactorVerifyResult>;
}

export interface AuthPolicy {
  factorsPolicyInfo: Record<
    string,
    | {
        autoPushEnabled?: boolean;
      }
    | undefined
  >;
}
