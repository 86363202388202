import React from "react";
import cx from "classnames";

import styles from "./Items.module.scss";

type ItemsType = {
  label: string;
  title: string | any;
  desc?: string;
  className?: string;
};

const Items: React.FC<ItemsType> = ({ label, title, desc, className }) => {
  return (
    <div className={cx(styles.item, className)}>
      <span className={styles.label}>{label}</span>
      {!desc ? (
        <span className={styles.title}>
          {title === false ? null : title || "-"}
        </span>
      ) : (
        <div className={styles.desc}>
          <p className={styles.description}>{desc}</p>
          <span className={styles.title}>{title || "-"}</span>
        </div>
      )}
    </div>
  );
};

export default Items;
