import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./SetDeleteAccountModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";

import { useActions } from "../../../../hooks/useActions";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";
import Button, { ButtonType } from "../../UiButton";

const SetDeleteAccountModal = () => {
  const dealsModal = useSelector(dealsModalSelector);
  const { t } = useTranslation("translation");
  const { SetOpenModal } = useActions();
  const toggleModal = () => {
    closeModal();
  };

  const closeModal = () => {
    SetOpenModal({ type: "", isOpen: false });
  };

  const handleProceed = useCallback(() => {
    SetOpenModal({ type: "contactUs", isOpen: true });
  }, []);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={dealsModal.isOpen && dealsModal.type === "deleteAccount"}
      onRequestClose={toggleModal}
      className={styles.contactUsModal}
      overlayClassName={styles.myoverlay}
    >
      <div className={styles.wrapper}>
        <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
        <h2 className={styles.title}>Delete Account</h2>
        <p className={styles.desc}>Account deletion is by request only.</p>
        <p className={styles.desc}>
          Are you sure you want to delete your account?
        </p>
        <div className={styles.buttonsWrap}>
          <Button onClick={toggleModal} variant={ButtonType.Text}>
            {t(`buttons.cancel`)}
          </Button>
          <Button variant={ButtonType.Primary} onClick={handleProceed}>
            {"Proceed"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SetDeleteAccountModal;
