import { dateUtils, formUtils } from "./utils";

const { generateYearsArray } = dateUtils;
const { optionsFromArray } = formUtils;

export const tabs = [
  {
    label: "Upload Documents",
    value: "upload",
    slug: "upload/uploader"
  },
  {
    label: "History",
    value: "history",
    slug: "upload/history"
  },
  {
    label: "Saved Uploads",
    value: "saved",
    slug: "upload/saved"
  }
];
const ammountOfYears = 7;
const years = generateYearsArray(ammountOfYears);

export const options = {
  uploadType: [
    { label: "Remittance Advice", value: "remittance-advice" },
    { label: "Self-billing Invoice", value: "self-billing-invoice" },
    { label: "Statement Summary", value: "statement-doc" },
    { label: "Tax Document", value: "tax-doc" }
  ],
  statementFrequency: [
    { label: "Quarterly", value: "quarterly" },
    { label: "Semi-Annual", value: "semiAnnual" }
  ],
  statementYear: optionsFromArray(years),
  statementDate: (selectedYear: string) => {
    return {
      quarterly: [
        {
          label: "January - March",
          value: {
            startDate: `${selectedYear}-01-01`,
            endDate: `${selectedYear}-03-31`
          }
        },
        {
          label: "April - June",
          value: {
            startDate: `${selectedYear}-04-01`,
            endDate: `${selectedYear}-06-30`
          }
        },
        {
          label: "July - September",
          value: {
            startDate: `${selectedYear}-07-01`,
            endDate: `${selectedYear}-09-30`
          }
        },
        {
          label: "October - December",
          value: {
            startDate: `${selectedYear}-10-01`,
            endDate: `${selectedYear}-12-31`
          }
        }
      ],
      semiAnnual: [
        {
          label: "January - June",
          value: {
            startDate: `${selectedYear}-01-01`,
            endDate: `${selectedYear}-06-30`
          }
        },
        {
          label: "July - December",
          value: {
            startDate: `${selectedYear}-07-01`,
            endDate: `${selectedYear}-12-31`
          }
        }
      ]
    };
  }
};
