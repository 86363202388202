import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import axiosRetry from "axios-retry";

import { oktaAuth } from "../../../lib/oktaAuth";
import { chunkifyArray } from "../../../utils/array";
import { Document, DocumentList, DocumentType, UploadedFile } from "../types";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  return config;
});

type UploadProgress = {
  [key: string]: number;
};

const useUploadFile = (
  documentList: DocumentList | null,
  documentType?: DocumentType
) => {
  const [uploadsProgress, setUploadsProgress] = useState<UploadProgress>({});
  const [filesFetching, setFilesFetching] = useState(false);
  const [uploadCancelTokens, setUploadCancelTokens] = useState<{
    [key: string]: CancelTokenSource;
  }>({});
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const cancelUploadRef = useRef(false);

  const totalProgress = useMemo(() => {
    const values: number[] = Object.values(uploadsProgress);
    if (!values?.length) return null;
    const totalValues = values.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
    return (totalValues / (values.length * 100)) * 100;
  }, [uploadsProgress]);

  const isUploading = totalProgress && totalProgress !== 100;

  const allUploaded = useCallback((uploadedFiles: any) => {
    return {
      uploadedFiles,
      uploadsProgress
    };
  }, []);

  const getDraftUploads = useCallback((uploadedFiles: UploadedFile[]) => {
    return uploadedFiles.filter(
      (file: UploadedFile) => file.fileStatus === "DRAFT"
    );
  }, []);

  const refetchUploadedFiles = useCallback(
    async (batchId: string, documentType: DocumentType) => {
      if (!batchId) return [];
      setFilesFetching(true);
      let res = [];
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/${batchId}`
        );
        res = data;
      } finally {
        setFilesFetching(false);
      }
      if (!res) return null;

      // Filter out the files with DELETED or REJECTED statuses.
      const filteredData = res.filter(
        (file: UploadedFile) =>
          file.fileStatus !== "DELETED" && file.fileStatus !== "REJECTED"
      );
      setUploadedFiles(filteredData);
      return filteredData;
    },
    [setUploadedFiles]
  );

  const deleteUploadedFile = useCallback(
    async (
      batchId: string,
      documentType: DocumentType,
      documentIds: string[]
    ) => {
      if (!documentIds?.length) return;
      const res: any = await axiosInstance.delete(
        `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/${batchId}`,
        {
          data: documentIds
        }
      );

      return res?.data;
    },
    []
  );

  const failUploadedFile = useCallback(
    async (
      batchId: string,
      documentType: DocumentType,
      documentIds: string[]
    ) => {
      if (!documentIds?.length) return;
      const res: any = await axiosInstance.put(
        `${process.env.REACT_APP_BASE_URI}api/cms/${documentType}/batches/${batchId}/fail`,
        documentIds
      );

      return res?.data;
    },
    []
  );

  const handlePercentagechange = (e: ProgressEvent, document: Document) => {
    const loaded = e.loaded;
    const total = e.total;
    const percentCompleted = Math.round((loaded / total) * 100);
    setUploadsProgress((previousProgress: UploadProgress) => {
      return {
        ...previousProgress,
        [document.id]: percentCompleted
      };
    });
  };

  const resetUploadById = useCallback(
    (id: string) => {
      if (!id) return;
      setUploadedFiles(previousFiles => {
        return previousFiles.filter(previousVile => previousVile.id !== id);
      });
      setUploadsProgress(previousProgress => {
        const newProgress = { ...previousProgress };
        delete newProgress[id];
        return newProgress;
      });
    },
    [setUploadsProgress]
  );

  useEffect(() => {
    if (!documentList) return;
    const newUploadsProgress: UploadProgress = {};
    Object.keys(uploadsProgress).forEach(id => {
      if (documentList?.result.some(signedUrl => signedUrl.id === id)) {
        newUploadsProgress[id] = uploadsProgress[id];
      }
    });
    setUploadsProgress(newUploadsProgress);
  }, [documentList, setUploadsProgress]);

  const uploadChunk = (
    signedUrl: Document[],
    newFileList: File[],
    batchId: string
  ) =>
    signedUrl.map(signedUrl => {
      if (signedUrl.validationStatus || !signedUrl.url) return null;
      const file = newFileList.find(file => file.name === signedUrl?.fileName);
      if (!file) return null;
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      setUploadCancelTokens(previousTokens => {
        return {
          ...previousTokens,
          [signedUrl.id]: source
        };
      });
      const url = signedUrl.url;
      axiosRetry(axios, {
        retries: 3,
        retryDelay: axiosRetry.exponentialDelay
      });
      return axios
        .put(url, file, {
          cancelToken: source.token,
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: e => handlePercentagechange(e, signedUrl)
        })
        .catch(async e => {
          if (!documentType) return null;
          resetUploadById(signedUrl.id);
          if (axios.isCancel(e)) return null;
          await failUploadedFile(batchId, documentType, [signedUrl.id]);
        });
    });

  const uploadFiles = async (signedUrls: DocumentList, newFileList: File[]) => {
    cancelUploadRef.current = false;
    setUploadsProgress(previousProgress => {
      const newProgress: UploadProgress = {};
      signedUrls.result.forEach(file => {
        if (file.validationStatus) return;
        newProgress[file.id] = 0;
      });
      return { ...previousProgress, ...newProgress };
    });

    const signedUrlsChunkified = chunkifyArray(signedUrls.result, 10);

    for (const signedUrlsChunk of signedUrlsChunkified) {
      if (cancelUploadRef.current) break;
      if (!signedUrlsChunk) continue;
      const uploadChunkRequests = uploadChunk(
        signedUrlsChunk,
        newFileList,
        signedUrls.batchId
      );
      await Promise.all(uploadChunkRequests);
    }
  };

  return {
    uploadCancelTokens,
    resetUploadById,
    uploadsProgress,
    totalProgress,
    isUploading,
    allUploaded,
    uploadFiles,
    cancelUploadRef,
    filesFetching,
    refetchUploadedFiles,
    deleteUploadedFile,
    uploadedFiles,
    getDraftUploads
  };
};

export default useUploadFile;
