import { AlertItem } from "../types/alertItem";

const ALERT_PREVIEW_STORAGE_KEY = "alert-preview-items";

/**
 * Deletes alert items from a local storage
 */
export const delAlertPreviewItems = (): void => {
  window.localStorage.removeItem(ALERT_PREVIEW_STORAGE_KEY);
};

/**
 * Gets alert items if they exist in a local storage and can be parsed as JSON
 * @returns Alert items or undefined
 */
export const getAlertPreviewItems = (): AlertItem[] | undefined => {
  const data = window.localStorage.getItem(ALERT_PREVIEW_STORAGE_KEY);
  if (data) {
    try {
      return JSON.parse(data) as AlertItem[];
    } catch {
      console.error("Cannot parse local storage data for alert banner");
    }
  }
  return undefined;
};

/**
 * Sets alert items in a local storage
 * @param Data Alert items
 */
export const setAlertPreviewItems = (items: AlertItem[]): void => {
  window.localStorage.setItem(ALERT_PREVIEW_STORAGE_KEY, JSON.stringify(items));
};
