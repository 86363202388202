import React from "react";

import styles from "./CookiesPolicy.module.scss";

const CookiesPolicy = () => {
  return (
    <div className={styles.cookies}>
      <div className={styles.header}>COOKIES POLICY</div>
      <div className={styles.content}>
        <div className={styles.subHeader}>
          <a href={"https://sso.wmg.com/"}>sso.wmg.com</a>
          <div>LAST UPDATED: May 2022</div>
        </div>
        <div className={styles.txt}>
          This Cookies Policy describes how cookies and similar technologies are
          used on our websites and web properties (e.g., widgets and
          applications) that contain or link to this Cookies Policy (which are
          referred to in this policy collectively and individually as the
          “Site”). It is intended to operate with and be read together with the
          cookies banner and privacy preference center that link to it.
          <br />
          <br />
          <br />
          “We” (or “us” or “our”) means Warner Music Group Corp. or
          Warner-Elektra-Atlantic Corporation (for e-commerce sites) or the
          relevant member of our group that operates the specific Site (as
          indicated on the Site or in our Privacy Policy).
          <br />
          <br />
          <br />
          We may change this Cookies Policy from time to time, so please check
          back periodically for updates. This Cookies Policy was last revised on
          the date specified at the top of the policy.
          <br />
          <br />
          <br />
          <span className={styles.headTxt}>1. What is a Cookie?</span>
          <br />
          <br />
          A cookie is a small piece of information that the Site’s server
          transfers to the cookie file of the browser on your device so that the
          contents of that cookie can be retrieved and read.
          <br />
          <br />
          <br />
          Cookies do not usually record your name or specific contact details,
          but some cookies may assign an identifier to an individual. To the
          extent that cookies collect and process personal data, this is handled
          in accordance with our{" "}
          <span className={styles.txtUnderline}>Privacy Policy</span>.
          <br />
          <br />
          <br />
          There are other technologies we use on the Site that work in a similar
          way to cookies by storing or accessing information on a user’s device,
          such as:
          <br />
          <br />
          <br />
          HTML5 local storage – this is mainly used to store and retrieve data
          in HTML pages from the same domain.
          <br />
          <br />
          Local Shared Objects – this is commonly called a “Flash cookie” and is
          a piece of data that websites which use Adobe Flash may store on a
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {/* eslint-disable-next-line react/no-unescaped-entities */} user's
          computer, typically to support website advertisements and videos.
          <br />
          <br />
          Device fingerprinting – this is a way of identifying a unique device
          by combining certain attributes of the device, without necessarily
          having to know the IP address or setting cookies.
          <br />
          <br />
          Scripts – these are lists of commands executed by programs or
          scripting engines, which are used to generate Web pages and to
          automate computer processes.
          <br />
          <br />
          Tracking pixels / web beacons – these are small pieces of code loaded
          when a user visits a website, which can be used to track site visits,
          user behaviour, ad impressions and other online activities.
          <br />
          <br />
          Plugins – these are add-ons for a program that adds functionality to
          it – for example, a plugin is used to enable you to watch a video on
          the Site.
          <br />
          <br />
          <br />
          <span className={styles.headTxt}>2. How we use Cookies</span>
          <br />
          <br />
          We use a combination of strictly necessary cookies, functional
          cookies, targeting cookies and performance cookies, as described in
          the privacy preference center.
          <br />
          <br />
          <br />
          This link sets out the 1st and 3rd party cookies we use on our Site
          where the relevant features are enabled, and it explains their purpose
          and duration.
          <br />
          <br />
          <br />
          We currently use both session and persistent cookies on our Site –
          session cookies expire at the end of your browser session; persistent
          cookies last for longer.
          <br />
          <br />
          <br />
          We generally request cookies consent every 12 months. If you close
          your online shop account, we will erase all cookies data associated
          with your account within 2 hours. If you opt out of marketing, we will
          erase all cookies data used for marketing purposes within 1 month.
          <br />
          <br />
          <br />
          <span className={styles.headTxt}>3rd Party Cookies and tools</span>
          <br />
          <br />
          We use a range of 3rd party content and tools on the Site and click on
          this link for more information about them. As a result, when you visit
          a page with content embedded from, for example, Facebook or YouTube,
          cookies may be placed by the Site. Below are the 3rd party services we
          use and where to find out further privacy information about them:
          <br />
          <br />
          <br />
          Facebook – to help users register with our services via their Facebook
          account, Like or Share website content to the Facebook platform, and
          display content from Facebook :{" "}
          <a href={"https://www.facebook.com/help/cookies"}>
            https://www.facebook.com/help/cookies
          </a>
          <br />
          <br />
          Twitter – to display content from Twitter and allow users to connect
          with the artist’s Twitter accounts; to quickly share information via
          the Twitter social network without having to log into Twitter again :
          <a href={"https://twitter.com/privacy"}>
            https://twitter.com/privacy
          </a>
          <br />
          <br />
          Youtube – to display video content in embedded players from YouTube :
          <a href={"https://www.google.com/policies/privacy/"}>
            https://www.google.com/policies/privacy/
          </a>
          <br />
          <br />
          Brightcove – to display video content in embedded players :
          <a href={"https://www.brightcove.com/en/legal/privacy"}>
            https://www.brightcove.com/en/legal/privacy
          </a>
          <br />
          <br />
          SoundCloud – to display audio content in embedded players from
          SoundCloud:{" "}
          <a href={"https://soundcloud.com/pages/privacy"}>
            https://soundcloud.com/pages/privacy
          </a>
          <br />
          <br />
          Akamai – to display audio and video content in embedded players :
          <a href={"https://www.akamai.com/legal/privacy-and-policies"}>
            https://www.akamai.com/legal/privacy-and-policies
          </a>
          <br />
          <br />
          Add This – to enable users to share website content on a variety of
          social and content networks :{" "}
          <a href={"https://www.addthis.com/privacy"}>
            https://www.addthis.com/privacy
          </a>
          <br />
          <br />
          Google – to enable users to be shown search or YouTube advertising
          relevant to the artist site they have visited and web analytics :
          <a href={"https://www.google.com/policies/privacy/"}>
            https://www.google.com/policies/privacy/
          </a>
          <br />
          <br />
          Tumblr– to display content from Tumblr :
          <a href={"https://www.tumblr.com/policy/en/privacy"}>
            https://www.tumblr.com/policy/en/privacy
          </a>
          <br />
          <br />
          DoubleClick– to measure effectiveness of online marketing campaigns :
          <a href={"https://www.google.com/policies/technologies/ads/"}>
            https://www.google.com/policies/technologies/ads/
          </a>
          <br />
          <br />
          Stumbleupon - to enable users to share website content on a variety of
          social and content networks :{" "}
          <a href={"https://www.stumbleupon.com/privacy"}>
            https://www.stumbleupon.com/privacy
          </a>
          <br />
          <br />
          PayPal – to recognize a user as a PayPal customer; customize PayPal
          services, content and advertising; measure promotional effectiveness;
          collect information about a user’s computer or other access device to
          mitigate risk, help prevent fraud and promote trust and safety :
          <a href={"https://www.paypal.com/in/webapps/mpp/ua/privacy-full"}>
            https://www.paypal.com/in/webapps/mpp/ua/privacy-full
          </a>
          <br />
          <br />
          Webgains – to remember when a user clicks on or views an ad during the
          same browser session; also to de-duplicate clicks from the same source
          :{" "}
          <a href={"https://www.webgains.com/public/en/privacy-2/"}>
            https://www.webgains.com/public/en/privacy-2/
          </a>
          <br />
          <br />
          Adobe Flash Player – to improve the user experience and to save
          preferences :{" "}
          <a href={"https://www.adobe.com/uk/privacy/policy.html"}>
            https://www.adobe.com/uk/privacy/policy.html
          </a>
          <br />
          <br />
          Flickr – to display photo content in embedded players from Flickr :
          <a
            href={"https://info.yahoo.com/privacy/us/yahoo/flickr/details.html"}
          >
            https://info.yahoo.com/privacy/us/yahoo/flickr/details.html
          </a>
          <br />
          <br />
          SmartURL – we may use SmartURL to rebrand some links to our Sites
          properties as well as the websites of our third party partners to
          which our Sites link. SmartURL provides anonymised, aggregated
          statistics relating to use of a shortened URL, for example by telling
          us how many times it has been clicked on. We do not have access to the
          IP address of a user’s computer collected by SmartURL when a user uses
          its service. SmartURLdoes not have any access to data collected on our
          Sites.
          <br />
          <br />
          AuDigent – we may use AuDigent pixel tags, web beacons and other
          similar technology. Such technologies may be used to, among other
          things, track the actions of the website users for the purpose of
          serving ads on third party websites, measure the success of marketing
          campaigns and compile statistics about the website usage and response
          rates.
          <br />
          <br />
          Third-party advertisers on our Site may use cookies and other
          technologies in their advertisements for statistical or analytical
          purposes.
          <br />
          <br />
          When we talk about “cookies” in this Cookies Policy, we mean these
          other similar technologies as well.
          <br />
          <br />
          <br />
          <span className={styles.headTxt}>
            3. How do I change my cookies settings?
          </span>
          <br />
          <br />
          You can change your cookie preferences at any time by clicking on the
          “Cookies Settings” link that is available from any webpage. You can
          then adjust the available sliders to ‘On’ or ‘Off’, then clicking
          ‘Confirm My Choices’. You may need to refresh your page for your
          settings to take effect. You may also be given the option to change
          your cookies settings if you are visiting a different area of the Site
          that has different functionality or if it would optimise your online
          experience to choose different settings (but this will always be
          optional).
          <br />
          <br />
          <br />
          Please be aware that, if cookies are disabled, not all features of the
          Site may operate as intended and this may impact your online
          experience.
          <br />
          <br />
          <br />
          The browser settings of most web browsers also allow some control of
          most cookies. To find out more about cookies, including how to check
          what cookies have been set, visit{" "}
          <a href={"www.aboutcookies.org"}>www.aboutcookies.org</a> or
          <a href={"www.allaboutcookies.org"}>www.allaboutcookies.org</a>.
          <br />
          <br />
          <br />
          You can manage cookies on popular browsers by visiting their
          developers’ sites, such as:
          <br />
          <br />
          <br />
          Google Chrome
          <br />
          <br />
          Microsoft Edge
          <br />
          <br />
          Mozilla Firefox
          <br />
          <br />
          Microsoft Internet Explorer
          <br />
          <br />
          Opera
          <br />
          <br />
          Apple Safari
          <br />
          <br />
          To opt out of being tracked by Google Analytics across all websites,
          visit{" "}
          <a href={"http://tools.google.com/dlpage/gaoptout"}>
            http://tools.google.com/dlpage/gaoptout
          </a>
          . There is also software available for download that cleans out
          tracking cookies from browsing sessions.
          <br />
          <br />
          <br />
          <hr />
          <br />
          <span className={styles.headTxt}>Cookies List</span>
          <br />
          <br />
          A cookie is a small piece of data (text file) that a website – when
          visited by a user – asks your browser to store on your device in order
          to remember information about you, such as your language preference or
          login information. Those cookies are set by us and called first-party
          cookies. We also use third-party cookies – which are cookies from a
          domain different than the domain of the website you are visiting – for
          our advertising and marketing efforts. More specifically, we use
          cookies and other tracking technologies for the following purposes:
          <br />
          <br />
          <span className={styles.smallBoldTxt}>
            Strictly Necessary Cookies
          </span>
          <br />
          <br />
          These cookies are necessary for the website to function. They are
          usually only set in response to actions you take, such as setting your
          privacy preferences, logging in or filling in forms. You can set your
          browser to block these cookies, but some parts of the site will then
          not work.
          <br />
          <br />
          <div className={styles.subsSubContentWrapper}>
            <div className={styles.subSubContent1}>
              <span className={styles.txt3}>Cookie Subgroup</span>
            </div>
            <div className={styles.subSubContent2}>
              <span className={styles.txt3}>Cookies</span>
            </div>
            <div className={styles.subSubContent3}>
              <span className={styles.txt3}>Cookies Used</span>
            </div>
            <div className={styles.subSubContent4}>
              <span className={styles.txt3}>Lifespan</span>
            </div>
          </div>
          <div className={styles.subsSubContentWrapper}>
            <div className={styles.subSubContent1}>
              <a className={styles.txt2} href={"https://sso.wmg.com/"}>
                sso.wmg.com
              </a>
            </div>
            <div className={styles.subSubContent2}>
              <span className={styles.txtUnderline}>
                eupubconsent, OptanonConsent, original_request_uri, __VCAP_ID__,
                locale, OptanonAlertBoxClosed
              </span>
            </div>
            <div className={styles.subSubContent3}>
              <span className={styles.txt2}>First Party</span>
            </div>
            <div className={styles.subSubContent4}>
              <span className={styles.txt2}>
                2914149 Days, 365 Days, A few seconds, Session, Session, 365
                Days
              </span>
            </div>
          </div>
          <div className={styles.subsSubContentWrapper}>
            <div className={styles.subSubContent1}>
              <a className={styles.txt2} href={"https://sso.wmg.com/"}>
                sso.wmg.com
              </a>
            </div>
            <div className={styles.subSubContent2}>
              <span className={styles.txt2}>JSESSIONID</span>
            </div>
            <div className={styles.subSubContent3}>
              <span className={styles.txt2}>Third Party</span>
            </div>
            <div className={styles.subSubContent4}>
              <span className={styles.txt2}>Session</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicy;
