import React from "react";

import styles from "./Duration.module.scss";

import { getTimeDifferece } from "../../../../utils/time";

type DurationProps = {
  startDate?: Date | string;
  endDate?: Date | string;
};

const Duration: React.FC<DurationProps> = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return null;
  return (
    <span className={styles.duration}>{`Duration: ${getTimeDifferece(
      new Date(startDate),
      new Date(endDate)
    )}`}</span>
  );
};

export default Duration;
