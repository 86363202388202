import React, { useCallback } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import styles from "./DeleteSuccessPopup.module.scss";

import { useActions } from "../../../../hooks/useActions";
import { modalSelector } from "../../../../store/architectUsersStore/selectors";
import Icon from "../../../shared/Icon";
import Button from "../../WCMButton";

const DeleteSuccessPopup = () => {
  const { SetOpenModalAction } = useActions();
  const modal = useSelector(modalSelector);
  const closeModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
  }, [SetOpenModalAction]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modal.isOpen && modal.type === "DeleteSuccessPopup"}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <Icon kind="success_circle" />
            {`${modal.queryParams} ${
              modal.queryParams > 1 ? "documents" : "document"
            } successfully deleted`}
          </div>
        </div>
        <div className={styles.exitButtonContainer}>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSuccessPopup;
