import { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import axios from "axios";
import cn from "classnames";

import styles from "./SetUserControlModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as UserLock } from "../../../../icons/user_lock.svg";
import { ReactComponent as UserReactivateIcon } from "../../../../icons/user_reactivated.svg";
import { ReactComponent as UserSlash } from "../../../../icons/user_slash.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warning.svg";

import { useActions } from "../../../../hooks/useActions";
import { oktaAuth } from "../../../../lib/oktaAuth";
import {
  bodySelector,
  modalSelector
} from "../../../../store/architectUsersStore/selectors";
import LoadingCircle from "../../LoadingCircle";
import Button from "../../UiButton";
import SetImpersonatePopup from "./SetImpersonatePopup";

const SetUserControlModal = () => {
  const body = useSelector(bodySelector);
  const modal = useSelector(modalSelector);
  const { SetOpenModalAction } = useActions();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isActivate = modal.type === "ACTIVATE";
  const isSuspendList = Array.isArray(modal?.user);
  const [successList, setSuccessList] = useState([]);

  const handleClearState = useCallback(() => {
    setIsSuccess(false);
    setError("");
  }, []);

  const content = useMemo(() => {
    switch (modal.type) {
      case "activate": {
        return {
          title: "Activate User",
          description:
            "The user will regain access to Client Portal. Are you sure you want to activate this user?",
          buttonTitle: "Activate",
          type: "Internal Users",
          successTitle: "User Activated"
        };
      }
      case "deactivate": {
        if (isSuspendList) {
          return {
            title: "Deactivate",
            description:
              "These users will no longer have access to Client Portal. Are you sure you want to deactivate them?",
            buttonTitle: "Deactivate",
            type: "Internal Users",
            successTitle: "Users Deactivated",
            icon: <UserSlash className={styles.userIcon} />
          };
        } else {
          return {
            title: "Deactivate User",
            description:
              "The user will no longer have access to Client Portal. Are you sure you want to deactivate this user?",
            buttonTitle: "Deactivate",
            type: "Internal Users",
            successTitle: "User Deactivated",
            icon: <UserSlash className={styles.userIcon} />
          };
        }
      }
      case "reactivate": {
        if (!isSuspendList) {
          return {
            title: "Reactivate User",
            description:
              "Reactivating will allow this user to log in. Are you sure you want to reactivate this user?",
            buttonTitle: "Reactivate",
            type: "clients",
            successTitle: "User Reactivated",
            icon: <UserReactivateIcon className={styles.userIcon} />
          };
        } else {
          return {
            title: "Reactivate",
            description:
              "Reactivating will allow users to log in. Are you sure you want to reactivate selected users?",
            buttonTitle: "Reactivate",
            type: "clients",
            successTitle: `Users Reactivated`,
            icon: <UserReactivateIcon className={styles.userIcon} />
          };
        }
      }
      case "suspend": {
        if (!isSuspendList) {
          return {
            title: "Suspend User",
            description:
              "The user will no longer have access to Client Portal. Are you sure you want to suspend this user?",
            buttonTitle: "Suspend",
            type: "clients",
            successTitle: "User Suspended",
            icon: <UserLock className={styles.userIcon} />
          };
        } else {
          return {
            title: "Suspend",
            description:
              "These users will no longer have access to Client Portal. Are you sure you want to suspend them?",
            buttonTitle: "Suspend",
            type: "clients",
            successTitle: `Users Suspended`,
            icon: <UserLock className={styles.userIcon} />
          };
        }
      }
      case "impersonate": {
        if (!isSuspendList) {
          return {
            title: "Suspend User",
            description:
              "The user will no longer have access to Client Portal. Are you sure you want to suspend this user?",
            buttonTitle: "Suspend",
            type: "clients",
            successTitle: "User Suspended",
            icon: <UserLock className={styles.userIcon} />
          };
        } else {
          return {
            title: "Suspend",
            description:
              "These users will no longer have access to Client Portal. Are you sure you want to suspend them?",
            buttonTitle: "Suspend",
            type: "clients",
            successTitle: `Users Suspended`,
            icon: <UserLock className={styles.userIcon} />
          };
        }
      }
      default: {
        return {
          title: "",
          type: "",
          description: "",
          buttonTitle: "",
          successTitle: ""
        };
      }
    }
  }, [modal, isSuspendList]);

  const toggleModal = useCallback(() => {
    SetOpenModalAction({ isOpen: false, type: "" });
    handleClearState();
  }, [SetOpenModalAction, handleClearState]);
  const { GetArchitectUsersAction, MetaDataAction } = useActions();
  const handleClick = useCallback(() => {
    const params =
      modal.type === "suspend"
        ? "external/suspend"
        : modal.type === "reactivate"
        ? "external/reactivate"
        : "internal/deactivate";
    setError("");
    setIsLoading(true);
    const requestData = isSuspendList
      ? modal.user.map((el: { id: string }) => el.id)
      : [modal?.user?.id];
    axios
      .put(`/api/users/${params}`, requestData, {
        headers: {
          authorization: `Bearer ${oktaAuth.getAccessToken()}`
        }
      })
      .then(res => {
        setSuccessList(res.data.success);
        setIsSuccess(true);
        GetArchitectUsersAction(body, false);
        MetaDataAction();
      })
      .catch(() => {
        setError("Unable to Deactivate user.");
      })
      .finally(() => setIsLoading(false));
  }, [
    modal.type,
    modal.user,
    isSuspendList,
    GetArchitectUsersAction,
    body,
    MetaDataAction
  ]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        toggleModal();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, toggleModal]);

  const role: any = {
    ADMIN: "Admin",
    SUPER_ADMIN: "Super Admin",
    EXTERNAL_USER: "Songwriter"
  };

  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={
          modal.isOpen &&
          modal?.user &&
          (modal.type === "deactivate" ||
            isActivate ||
            modal.type === "suspend" ||
            modal.type === "reactivate")
        }
        onRequestClose={toggleModal}
        className={cn(styles.mymodal, {
          [styles.success]: isSuccess,
          [styles.suspendModal]: isSuspendList
        })}
        overlayClassName={styles.myoverlay}
      >
        {isSuccess ? (
          <div className={styles.successModal}>
            {content.icon}
            {isSuspendList && <span>{successList.length} </span>}
            <span> {"  " + content.successTitle}</span>
          </div>
        ) : (
          <>
            <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
            <h2 className={styles.title}>{content.title}</h2>
            <div>
              <div>
                {!isSuspendList ? (
                  <>
                    {" "}
                    <span className={styles.userName}>
                      {modal?.user?.title}
                      {"  "}
                    </span>
                    <span className={styles.userRole}>
                      {modal?.user?.role && role[modal?.user?.role]}
                    </span>
                    <p className={styles.userValue}>{modal?.user?.email}</p>
                  </>
                ) : (
                  <div className={styles.suspendWrap}>
                    <p>{modal?.user.length}</p>
                    <span>{content.type}</span>
                  </div>
                )}

                <p className={styles.description}>{content.description}</p>
              </div>
            </div>
            <div className={styles.buttonWrap}>
              {isLoading && <LoadingCircle className={styles.loadingCircle} />}
              {error && (
                <div className={styles.userError}>
                  <WarningIcon className={styles.warning} />
                  {error}
                </div>
              )}
              <Button
                className={styles.impersonateButton}
                onClick={handleClick}
              >
                {content.buttonTitle}
              </Button>
            </div>
          </>
        )}
      </Modal>
      <SetImpersonatePopup />
    </div>
  );
};

export default SetUserControlModal;
