import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./LandingForMobile.module.scss";
import { ReactComponent as Cursor } from "../../../icons/cursor.svg";

import PaginationNumbers from "../../../components/shared/Pagination";
import SearchInput from "../../../components/shared/SearchInput";
import UiSelect from "../../../components/shared/UiSelect";
import { useActions } from "../../../hooks/useActions";
import LandingMobileCard from "./LandingMobileCard";

type LandingForMobileType = {
  setActiveState: any;
  activeState: any;
  currentList: any;
};

const LandingForMobile: React.FC<LandingForMobileType> = ({
  setActiveState,
  activeState,
  currentList
}) => {
  const { t } = useTranslation("translation");
  const { SetOpenModal } = useActions();
  const [payeeAccountsList, setPayeeAccountsList] = useState(
    activeState.payeeAccounts
  );

  useEffect(() => {
    setPayeeAccountsList(activeState.payeeAccounts);
  }, [activeState]);

  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [pageIndex, setPageIndex] = useState(0);
  const [paginateData, setPaginateData] = useState<any>([]);
  const options = useMemo(() => {
    return [...currentList].map((el: any) => ({
      label: el.fullName,
      value: el.id
    }));
  }, [currentList]);
  const value = useMemo(() => {
    return { value: activeState.id, label: activeState.fullName };
  }, [activeState]);

  useEffect(() => {
    if (payeeAccountsList?.length) {
      setPaginateData(
        payeeAccountsList
          ?.map((el: any, index: any) => ({ ...el, id: index + 1 }))
          .slice(pageIndex * 5, (pageIndex + 1) * 5) || []
      );
    } else {
      setPaginateData([]);
    }
  }, [pageIndex, payeeAccountsList, activeState]);

  const onFilterTextBoxChanged = useCallback(
    e => {
      const filteredData = activeState.payeeAccounts?.filter(
        (account: any) =>
          account?.payeeAccount
            ?.toUpperCase()
            ?.includes(e?.target?.value?.toUpperCase()) ||
          account?.payeeCode
            ?.toUpperCase()
            ?.includes(e?.target?.value?.toUpperCase())
      );
      setPayeeAccountsList(filteredData);
      if (pageIndex > 0) {
        setPageIndex(0);
      }
      setSearchInputValue(e.target.value ? e.target.value : "");
    },
    [activeState, pageIndex]
  );

  return (
    <div className={"container"}>
      <div className={styles.info}>
        <button
          className={cn(styles.backButton)}
          onClick={() => {
            SetOpenModal({ isOpen: false, type: "" });
            setActiveState(null);
          }}
          role="button"
        >
          <Cursor className={styles.backIcon} />
          <p>{t(`buttons.back`)}</p>
        </button>
        <UiSelect
          hasShadowOnMobile
          placeholder="Search accounts by name"
          noOptionsMessage={() => "No results found"}
          options={options}
          className={cn(styles.select, styles.select_searchable)}
          isDropTop
          value={value}
          isSearchable
          onChange={(e: any) => {
            setPageIndex(0);
            setSearchInputValue("");
            setActiveState(
              [...currentList].find((el: any) => el.id === e.value)
            );
          }}
          menuClassName={styles.menu}
          isBig
          filterOption={(option: any, value: any) => {
            return option.data.label
              .toLowerCase()
              .includes(value.toLowerCase());
          }}
        />
        <div className={cn(styles.table_filter_wrapper)}>
          <SearchInput
            placeholder={t(`myAccountPage.search`)}
            mobilePlaceholder={t(`myAccountPage.search`)}
            onInput={onFilterTextBoxChanged}
            onclickClearIcon={() => {
              setPageIndex(0);
              setSearchInputValue("");
              setPayeeAccountsList(activeState.payeeAccounts);
            }}
            value={searchInputValue}
          />
        </div>
        <LandingMobileCard data={paginateData} />
        {/*<AgGridReact*/}
        {/*  suppressCellSelection={true}*/}
        {/*  suppressRowHoverHighlight={true}*/}
        {/*  suppressContextMenu={true}*/}
        {/*  rowClass={cn(styles.table_row)}*/}
        {/*  headerHeight={46}*/}
        {/*  ref={tableRef}*/}
        {/*  masterDetail={true}*/}
        {/*  className={cn("ag-theme-alpine", styles.header)}*/}
        {/*  animateRows={true}*/}
        {/*  columnDefs={columnDefs}*/}
        {/*  defaultColDef={defaultColDef}*/}
        {/*  enableRangeSelection={true}*/}
        {/*  // rowData={modal?.data?.payeeAccounts}*/}
        {/*  rowData={paginateData}*/}
        {/*  rowSelection="multiple"*/}
        {/*  overlayNoRowsTemplate={"No Results Found"}*/}
        {/*  suppressRowClickSelection={true}*/}
        {/*  // detailCellRenderer={detailCellRenderer}*/}
        {/*  rowHeight={55}*/}
        {/*  detailRowHeight={55}*/}
        {/*  domLayout="autoHeight"*/}
        {/*  onRowClicked={el => handleRowClick(el)}*/}
        {/*/>*/}
        <PaginationNumbers
          showSizeSelect={false}
          changePageRequest={(index: number) => {
            setPageIndex(index);
          }}
          totalAmount={Math.ceil(payeeAccountsList?.length / 5)}
          currentPage={pageIndex + 1}
          isWhite
        />
      </div>
      <img
        src={require("../../../img/bg-full.png")}
        className={styles.image}
        alt={"img"}
      />
    </div>
  );
};

export default LandingForMobile;
