export enum DealsTypes {
  DEALS_REQUEST = "DealsTypes/DEALS_REQUEST",
  DEALS_LOADING = "DealsTypes/DEALS_LOADING",
  DEALS_SUCCESS = "DealsTypes/DEALS_SUCCESS",

  LEDGERS_REQUEST = "LEDGERS_REQUEST",
  LEDGERS_LOADING = "LEDGERS_LOADING",
  LEDGERS_SUCCESS = "LEDGERS_SUCCESS",

  DEALS_ERROR = "DealsTypes/DEALS_ERROR",

  PAYEECODE_REQUEST = "DealsTypes/PAYEECODE_REQUEST",
  PAYEECODE_LOADING = "DealsTypes/PAYEECODE_LOADING",
  PAYEECODE_SUCCESS = "DealsTypes/PAYEECODE_SUCCESS",

  SET_LEDGER = "SET_LEDGER",
  SET_OPEN_MODAL = "DealsTypes/SET_OPEN_MODAL",
  RESET_DEALS_STATE = "DealsTypes/RESET_DEALS_STATE",
  SET_QUERY_OPTIONS_DEALS = "DealsTypes/SET_QUERY_OPTIONS_DEALS",
  SET_SONGS_QUERY_OPTIONS = "DealsTypes/SET_QUERY_OPTIONS_SONGS",
  CLOSED_PERIODS_REQUEST = "CLOSED_PERIODS_LEDGERS_REQUEST",

  CLOSED_PERIODS_LOADING = "CLOSED_PERIODS_LOADING",
  CLOSED_PERIODS_SUCCESS = "CLOSED_PERIODS_SUCCESS",

  SET_SELECTED_CLOSED_PERIOD = "SET_SELECTED_CLOSED_PERIOD",

  GET_WORKS_LOADING = "DealsTypes/GET_WORKS_LOADING",
  GET_WORKS = "DealsTypes/GET_WORKS",
  GET_WORKS_SUCCESS = "DealsTypes/GET_WORKS_SUCCESS",

  RESET_WORKS_LIST = "DealsTypes/RESET_WORKS_LIST",
  SET_WORKS_ERROR = "DealsTypes/SET_WORKS_ERROR"
}
