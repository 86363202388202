export interface ButtonMenuItemProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string | JSX.Element;
}

export enum ButtonType {
  Default,
  Primary,
  Text
}

export enum ButtonIconPosition {
  IconLeft,
  IconRight
}

export interface ButtonProps extends React.PropsWithChildren<unknown> {
  className?: string;
  disabled?: boolean | any;
  hideDropdownIcon?: boolean;
  href?: string;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => void;
  menuItems?: ButtonMenuItemProps[];
  text?: string;
  variant?: ButtonType;
  type?: "button" | "submit" | "reset" | undefined;
  iconPosition?: ButtonIconPosition;
  icon?: HTMLElement;
  loading?: boolean | undefined | null;
}
