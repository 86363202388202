import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import cn from "classnames";

import styles from "./TermsOfUse.module.scss";

export interface TermsOfUseProps {
  showDate?: boolean;
  shadowPosition?: string | "op" | "down";
  handleNavigation?: () => void;
  scrolledRef?: any;
}
const TermsOfUse = (props: TermsOfUseProps) => {
  const { t } = useTranslation("translation");
  const history = useLocation();
  const {
    showDate = true,
    shadowPosition,
    handleNavigation,
    scrolledRef
  } = props;
  return (
    <div className={!history.pathname.includes("/login") ? "container" : ""}>
      <div className={cn(styles.terms, "terms-container")}>
        <div className={cn(styles.header, "terms-header")}>
          {t(`termsOfUse.title`)}
        </div>
        <div
          className={cn(
            styles.content,
            shadowPosition === "up"
              ? "terms-content_shadowUp"
              : "terms-content_shadowDown",
            "terms-content"
          )}
        >
          <div
            className="terms-wrapper"
            onScroll={handleNavigation}
            ref={scrolledRef}
          >
            {showDate && (
              <div className={styles.updateTxt}>
                {t(`termsOfUse.lastUpdated`)} 2019.07.10
              </div>
            )}
            <div className={styles.headTxt}>{t(`termsOfUse.block1`)}</div>
            <br />
            <div className={styles.txt}>
              <Trans i18nKey="termsOfUse.block2" />
              <br />
              <br />
              <br />
              {t(`termsOfUse.block3`)}
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section1" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section2" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section3" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section4" />
              <br />
              <br />
              <div style={{ marginLeft: "10px" }}>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_1`)}</div>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <div style={{ display: "flex" }}>
                    <div>&#x2022;&nbsp;</div>
                    <div>{t(`termsOfUse.section4_1_1`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&#x2022;&nbsp;</div>
                    <div>{t(`termsOfUse.section4_1_2`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&#x2022;&nbsp;</div>
                    <div>{t(`termsOfUse.section4_1_3`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&#x2022;&nbsp;</div>
                    <div>{t(`termsOfUse.section4_1_4`)}</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>&#x2022;&nbsp;</div>
                    <div>{t(`termsOfUse.section4_1_5`)}</div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_2`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_3`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_4`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_5`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_6`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_7`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_8`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_9`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_10`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_11`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_12`)}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>&#x2022;&nbsp;</div>
                  <div>{t(`termsOfUse.section4_13`)}</div>
                </div>
              </div>
              <br />
              {t(`termsOfUse.section4_title`)}
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section5" />
              <br />
              <br />
              <br />
              <span className={styles.headTxt}>{t(`termsOfUse.section6`)}</span>
              <br />
              <Trans i18nKey="termsOfUse.section6_a" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section6_b" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section6_c" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section6_d" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section6_e" />
              <br />
              <br />
              <br />
              {t(`termsOfUse.section6_f`)}
              <br />
              <br />
              <br />
              {t(`termsOfUse.section6_g`)}
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section7" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section8" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section9" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section10" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section11" />
              <br />
              <br />
              <br />
              {t(`termsOfUse.section11_text1`)}
              <br />
              <br />
              <br />
              {t(`termsOfUse.section11_text2`)}
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section12" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section13" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section14" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section15" />
              <br />
              <br />
              <br />
              {t(`termsOfUse.section15_text1`)}
              <br />
              <br />
              <br />
              {t(`termsOfUse.section15_text2`)}
              <br />
              <br />
              <br />
              <Trans
                i18nKey="termsOfUse.section15_text3"
                components={{ underline: <u /> }}
              />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section16" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section17" />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section18" />
              <br />
              <br />
              <br />
              {t(`termsOfUse.section18_text`)}
              <br />
              <br />
              <br />
              <Trans
                i18nKey="termsOfUse.section19"
                components={{
                  link1: (
                    <a
                      className={styles.txtUnderline}
                      href={"https://www.getnetwise.org/"}
                    />
                  ),
                  link2: (
                    <a
                      className={styles.txtUnderline}
                      href={"http://onguardonline.gov/"}
                    />
                  )
                }}
              />
              <br />
              <br />
              <br />
              <Trans
                i18nKey="termsOfUse.section20"
                components={{ underline: <u /> }}
              />
              <br />
              <br />
              <br />
              <Trans
                i18nKey="termsOfUse.section21"
                components={{
                  linkSec: (
                    <a
                      className={styles.txtUnderline}
                      href={"https://www.sec.gov"}
                    />
                  )
                }}
              />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section22" />
              <br />
              <br />
              <br />
              {t(`termsOfUse.contactUserName`)}
              <br />
              <br />
              {t(`termsOfUse.contactUserTitle`)}
              <br />
              <br />
              {t(`termsOfUse.address1`)}
              <br />
              <br />
              {t(`termsOfUse.address2`)}
              <br />
              <br />
              {t(`termsOfUse.telephone`)} 212-275-2000
              <br />
              <br />
              {t(`termsOfUse.email`)}{" "}
              <span className={styles.txtUnderline}>dmcaagent@wmg.com</span>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {t(`termsOfUse.section22_text`)}
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section23" />
              <br />
              <br />
              <br />
              <Trans
                i18nKey="termsOfUse.section24"
                components={{ underline: <u /> }}
              />
              <br />
              <br />
              <br />
              <Trans i18nKey="termsOfUse.section25" />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
