import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import styles from "./SelectTableType.module.scss";

import Select from "../../../../components/userManagement/Select";
import { options } from "../../constants";
import { FieldTypes, SelectTableTypeProps } from "./types";

const SelectTableType: React.FC<SelectTableTypeProps> = ({
  setTableType,
  disabled,
  defaultValue
}) => {
  const { control, watch } = useForm<FieldTypes>({
    defaultValues: {
      uploadType: null
    }
  });

  const { uploadType } = watch();
  useEffect(() => {
    if (!uploadType?.value) return;
    setTableType(uploadType.value);
  }, [uploadType?.value]);

  return (
    <div className={styles.wrapper} data-testid="select-table-type-component">
      <Select
        defaultValue={defaultValue}
        disabled={disabled}
        control={control}
        name={"uploadType"}
        placeholder="Select Document Type"
        options={options.uploadType}
      />
    </div>
  );
};

export default SelectTableType;
