import { SelectOption } from "../../types/select";
import { documentTypes } from "./constants";

export const mapQueryParamToOption = (
  query: string | null,
  options: SelectOption[]
): SelectOption => {
  const value = query || documentTypes.all;
  const label = options.find(option => option.value === value)?.label || "";
  return {
    value,
    label
  };
};
