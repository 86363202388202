const locales = ["en", "es", "de", "fr", "it", "ru"];
const defaultLocale = "en";

const localStorageLocaleKey = "wcmSiteLocale";

const getStaticPaths = () => {
  const paths = locales
    .filter(lang => lang !== defaultLocale)
    .map(lang => ({ params: { lang } }));
  return { paths, fallback: false };
};

module.exports = {
  defaultLocale,
  getStaticPaths,
  locales,
  localStorageLocaleKey
};
