/**
 * Validates that the value corresponds to another email field value
 * @param message Error message
 * @param email Email value from another field
 * @returns Error message or undefined if the value is valid
 */
export const emailConfirmValidaton = (
  message: string,
  email: string | null | undefined
): ((value: unknown) => string | undefined) => value => {
  return value === email ? undefined : message;
};

/**
 * Validates that the value corresponds to email format
 * @param message Error message
 * @returns Error message or undefined if the value is valid
 */
export const emailValidaton = (
  message: string
): ((value: unknown) => string | undefined) => value => {
  if (typeof value === "string" && value) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
      ? undefined
      : message;
  }
  return undefined;
};

/**
 * Validates that required value exists. It also trims the value before validation.
 * @param message Error message
 * @returns Error message or undefined if the value is valid
 */
export const requiredValidaton = (
  message: string
): ((value: unknown) => string | undefined) => value => {
  return typeof value === "undefined" ||
    value === null ||
    (typeof value === "string" && !value.trim())
    ? message
    : undefined;
};
