import { useEffect, useRef } from "react";

export const useDidUpdate = (
  effect: React.EffectCallback,
  deps?: React.DependencyList
): void => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      return effect();
    } else {
      mounted.current = true;
    }
  }, deps);
};
