import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import i18n from "i18next";
import moment from "moment";

import styles from "./Songs.module.scss";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { ReactComponent as Cursor } from "../../icons/cursor.svg";
import { ReactComponent as FileDnwldIcon } from "../../icons/file_dnld_icon.svg";

import InfoComponent from "../../components/shared/InfoComponent";
import Loader from "../../components/shared/Loading";
import SetCountryModal from "../../components/shared/Modals/SetCountryModal";
import SearchInput from "../../components/shared/SearchInput";
import Table from "../../components/shared/Table";
import Button, { ButtonType } from "../../components/shared/UiButton";
import UiSelect from "../../components/shared/UiSelect";
import { debounceCallback } from "../../components/userManagement/DataGrid/utils";
import { getSortedData } from "../../configs/utils";
import { useActions } from "../../hooks/useActions";
import { oktaAuth } from "../../lib/oktaAuth";
import {
  payeeCodeSelector,
  profileSelector,
  singleClientSelector
} from "../../store/appStatic/selectors";
import {
  songsQueryOptionsSelector,
  worksErrorSelector,
  worksLoadingSelector,
  worksSelector
} from "../../store/dealsStore/selectors";
import { getUrlParams, textParams } from "../../utils";
import SingleSongs from "./SingleSongs";
import SongsPageCard from "./SongsPageCard";

let songsQuery: any = false;
let previousPayeeCode: any = false;
const Songs = () => {
  const m = useMediaQuery({ maxWidth: 567 });
  const inputRef = useRef<any>(null);
  const { t } = useTranslation("translation");
  const { deals, payeeCodes } = useSelector((state: any) => state.dealsReducer);
  const payeeCode = useSelector(payeeCodeSelector);
  const params: any = useParams();
  const history = useHistory();
  const works = useSelector(worksSelector);
  const worksError = useSelector(worksErrorSelector);
  const worksLoading = useSelector(worksLoadingSelector);
  const profile = useSelector(profileSelector);
  const songsQueryOptions = useSelector(songsQueryOptionsSelector);
  const queryParams = getUrlParams(history.location.search);
  const singleClient = useSelector(singleClientSelector);
  const [currentList, setCurrentList] = useState([]);
  const [paginateData, setPaginateData] = useState<any>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>(
    songsQueryOptions.value || ""
  );
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const desc = useMediaQuery({ maxWidth: 1181 });
  const md = useMediaQuery({ maxWidth: 858 });
  const mb = useMediaQuery({ maxWidth: 750 });
  const {
    GetDealsAction,
    GetPayeeCodeAction,
    resetWorksList,
    SetOpenModal,
    getWorks
  } = useActions();

  useEffect(() => {
    if (inputRef && queryParams?.value && queryParams.page === "0") {
      inputRef?.current?.focus();
    }
  }, [searchInputValue]);

  const singleDeal = deals?.deals.length === 1 ? deals.deals[0] : null;
  const paramsDealId =
    !works || !profile ? null : params?.id ?? singleDeal?.dealId;

  // Fetch Works
  useEffect(() => {
    const localeSongsOptionState = localStorage.getItem("songOption")
      ? JSON.parse(localStorage.getItem("songOption") || "")
      : {};

    if (
      (songsQuery?.page !== songsQueryOptions.page &&
        (!queryParams.workCode || !works)) ||
      previousPayeeCode !== payeeCode
    ) {
      previousPayeeCode = payeeCode;
      songsQuery = songsQueryOptions;
      getWorks({
        query: {
          ...songsQueryOptions,
          ...localeSongsOptionState
        },
        params: { deals: params.id }
      });
    }
  }, [params.id, payeeCode]);

  useEffect(() => {
    return () => {
      if (
        !location.href.includes(params.id) &&
        !location.href.includes("songs")
      ) {
        songsQuery = false;
        localStorage.setItem("songOption", "");
        resetWorksList();
      }
    };
  }, [history.location]);

  useEffect(() => {
    if (currentList?.length) {
      const sortedData = getSortedData(currentList, {
        sort: songsQueryOptions.sortBy.id,
        order: songsQueryOptions.sortBy.desc
      });
      setCurrentList(sortedData);
    }
  }, [currentList, songsQueryOptions]);

  const defaultSortBy = useMemo(() => {
    return [
      { id: songsQueryOptions.sortBy.id, desc: songsQueryOptions.sortBy.desc }
    ];
  }, [songsQueryOptions]);

  const handleSort = useCallback(
    ({ sortBy: sort }) => {
      if (
        sort[0]?.id !== songsQueryOptions.sortBy.id ||
        sort[0]?.desc !== songsQueryOptions.sortBy.desc
      ) {
        getWorks({
          query: {
            ...songsQueryOptions,
            sortBy: sort[0]
          },
          params: { deals: params.id }
        });
      }
    },
    [songsQueryOptions]
  );

  const fetchData = useCallback(
    ({ pageIndex: index }: { pageSize: number; pageIndex: number }) => {
      if (songsQueryOptions.page !== index) {
        getWorks({
          query: { ...songsQueryOptions, page: index },
          params: { deals: params.id }
        });
      }
    },
    [params.id, songsQueryOptions]
  );
  const dummyDataPayeeCode = useMemo(() => {
    if (deals?.deals) {
      return deals?.deals.map(
        (el: { contractingParty: string; dealId: string; works: any[] }) => ({
          label: (
            <div className={styles.selectLabel}>
              <p className={styles.contractingParty}>{el.contractingParty}</p>
              <p>{`${el.dealId}`}</p>
            </div>
          ),
          code: el.contractingParty,
          value: el.dealId
        })
      );
    }
    return [];
  }, [deals?.deals]);

  const [dropdownValue, setDropdownValue] = useState<any>({});
  useEffect(() => {
    if (payeeCodes.length && !params.id) {
      history.push(
        queryParams?.workCode ? `?workCode=${queryParams?.workCode}` : ""
      );
    }
  }, [payeeCodes, history, params.id, queryParams?.workCode]);
  const activeState = useMemo(() => {
    const dealData = singleDeal
      ? singleDeal
      : deals?.deals?.find((el: any) => el.dealId === dropdownValue?.value);
    return {
      ...dealData,
      works: works?.result || []
    };
  }, [deals?.deals, dropdownValue?.value, history.location.pathname, works]);

  useEffect(() => {
    if (activeState?.works) {
      setCurrentList(
        activeState?.works.map((el: any, index: number) => ({
          ...el,
          id: index + 1
        }))
      );
    }
  }, [activeState, songsQueryOptions]);

  useEffect(() => {
    if (currentList?.length) {
      setPaginateData(
        currentList
          ?.map((el: any, index: any) => ({ ...el, id: index + 1 }))
          .slice(
            songsQueryOptions.page * songsQueryOptions.size,
            (songsQueryOptions.page + 1) * songsQueryOptions.size
          ) || []
      );
    } else {
      setPaginateData([]);
    }
  }, [currentList, songsQueryOptions, queryParams.page]);

  useEffect(() => {
    if (dummyDataPayeeCode.length) {
      setDropdownValue(
        dummyDataPayeeCode.find((el: any) => el.value === params?.id)
      );
    }
  }, [dummyDataPayeeCode, params]);

  useEffect(() => {
    if (singleClient?.data?.emailType?.includes("PUB")) {
      history.push("/not-found");
    } else if (deals?.payeeAccountCode !== payeeCode) {
      GetDealsAction(payeeCode, history);
    }
  }, [singleClient, payeeCode, singleClient?.data]);

  useEffect(() => {
    if (profile?.id && !deals?.deals?.length) {
      GetPayeeCodeAction(profile?.id);
    }
  }, [GetPayeeCodeAction, profile?.id, deals]);

  const chooseDealId = (deal: any) => {
    history.push(`/home/${deal.value}`);
    setDropdownValue({ label: deal.label, value: deal.value, code: deal.code });
  };
  const getDeliveryDate = ({ row }: any) => {
    const deliveryDate = row.original.deliveryDate;
    return (
      <div className={styles.capitalize}>
        {deliveryDate
          ? moment
              .utc(new Date(deliveryDate))
              .locale(i18n.language)
              .format("ll")
          : "-"}
      </div>
    );
  };
  const altTitle = {
    accessor: "altTitles",
    withoutSort: true,
    Header: () => {
      return (
        <span
          className="table_heading"
          title={t(`dealAndSongs.alternativeTitle`)}
        >
          {t(`dealAndSongs.alternativeTitle`)}
        </span>
      );
    },
    flex: 1,
    Cell: ({ row }: any) => {
      const data = row.original;
      return (
        <div className={cn(styles.names, styles.names_gray)}>
          {data?.altTitles?.length
            ? data.altTitles.map((el: any, index: number) => (
                <span key={el.title}>
                  {el.title
                    ? `${el.title} ${
                        index !== data.altTitles.length - 1 ? " | " : ""
                      }`
                    : "-"}
                </span>
              ))
            : "-"}
        </div>
      );
    }
  };
  const creators = {
    accessor: "creators",
    withoutSort: true,
    Header: () => {
      return (
        <span className="table_heading" title={t(`dealAndSongs.creators`)}>
          {t(`dealAndSongs.creators`)}
        </span>
      );
    },
    Cell: ({ row }: any) => {
      return (
        <div className={cn(styles.names, styles.names_gray)}>
          {row.original.creators.map((el: { name: string }, index: number) => (
            <span key={el.name}>
              {el.name}
              {index !== row.original.creators?.length - 1 ? " | " : ""}
            </span>
          ))}
        </div>
      );
    }
  };
  const workTitle = {
    accessor: "workTitle",
    Header: () => {
      return (
        <span className="table_heading" title={t(`dealAndSongs.primaryTitle`)}>
          {t(`dealAndSongs.primaryTitle`)}
        </span>
      );
    },
    maxWidth: mb ? 90 : 200,
    Cell: ({ row }: any) => {
      return (
        <div className={cn(styles.names, styles.names_gray)}>
          <p>{row?.original?.workTitle}</p>
          <p className={styles.code}>{row?.original?.workCode}</p>
        </div>
      );
    }
  };
  const deliveryDate = {
    accessor: "deliveryDate",
    Header: () => {
      return (
        <span className="table_heading" title={t(`dealAndSongs.deliveryDate`)}>
          {t(`dealAndSongs.deliveryDate`)}
        </span>
      );
    },
    flex: 1,
    maxWidth: 90,
    Cell: getDeliveryDate
  };
  const header = {
    Header: "#",
    withoutSort: true,
    maxWidth: 40,
    Cell: ({ row }: any) => {
      return +row?.id + 1 + songsQueryOptions.page * songsQueryOptions.size;
    }
  };

  const dealId = useMemo(() => {
    return params.id || singleDeal
      ? []
      : [
          {
            accessor: "dealId",
            Header: () => {
              return (
                <span
                  className="table_heading"
                  title={t(`dealAndSongs.dealId`)}
                >
                  {t(`dealAndSongs.dealId`)}
                </span>
              );
            },
            maxWidth: 80
          }
        ];
  }, [params.id, singleDeal, songsQueryOptions, t]);

  const columnDefs = useMemo(
    () => [header, workTitle, altTitle, creators, ...dealId, deliveryDate],
    [dealId, t]
  );
  const ipadColumnsLandscape = useMemo(
    () => [header, workTitle, creators, deliveryDate],
    [dealId, songsQueryOptions, t]
  );

  const ipadColumnsPortrait = useMemo(
    () => [header, workTitle, creators, deliveryDate],
    [dealId, songsQueryOptions, t]
  );
  const mobileColumns = useMemo(() => [header, workTitle, deliveryDate], [
    dealId,
    songsQueryOptions,
    t
  ]);

  const tableColumns = useMemo(() => {
    if (mb) {
      return mobileColumns;
    }
    if (md) {
      return ipadColumnsPortrait;
    }
    if (desc) {
      return ipadColumnsLandscape;
    }
    return columnDefs;
  }, [desc, mb, md, currentList, t]);

  const onFilterTextBoxChanged = useCallback(
    e => {
      debounceCallback(
        "search",
        () => {
          getWorks({
            query: {
              ...songsQueryOptions,
              page: 0,
              value: e.target.value
            },
            params: { deals: params.id }
          });
        },
        700
      );
      setSearchInputValue(e.target.value ? e.target.value : "");
    },
    [activeState?.works, songsQueryOptions, params.id, payeeCode]
  );

  const onFilterClear = useCallback(() => {
    getWorks({
      query: {
        ...songsQueryOptions,
        page: 0,
        value: ""
      },
      params: { deals: params.id }
    });
    setSearchInputValue("");
  }, [activeState?.works, songsQueryOptions, params.id, payeeCode]);

  const handleRowClick = useCallback(
    row => {
      history.push(
        `?workCode=${row?.original?.workCode?.replaceAll(" ", "%20")}&dealId=${
          row?.original?.dealId
        }`
      );
    },
    [history]
  );
  const isFirst = dummyDataPayeeCode[0]?.code === dropdownValue?.code;
  const isLast =
    dummyDataPayeeCode[dummyDataPayeeCode.length - 1]?.code ===
    dropdownValue?.code;
  const clickNextPrevBack = useCallback(
    (type: string) => {
      let active = { value: "" };
      dummyDataPayeeCode.forEach((el: any, index: number) => {
        if (el.code === dropdownValue.code) {
          active =
            type === "back"
              ? dummyDataPayeeCode[index - 1]
              : dummyDataPayeeCode[index + 1];
        }
      });
      if (active) {
        history.push(`/home/${active?.value}`);
      }
    },
    [dropdownValue, dummyDataPayeeCode, history, payeeCodes]
  );

  if (queryParams?.workCode) {
    return (
      <SingleSongs
        activeState={activeState}
        id={params?.id}
        activeDeal={deals?.deals}
      />
    );
  }

  function downloadFile(fileName: string, urlData: any) {
    const aLink = document.createElement("a");
    aLink.href = urlData;
    aLink.download = fileName;
    document.body.appendChild(aLink);
    aLink.click();
    aLink.remove();
  }

  const downloadSongs = (payeeCode: string, exportType: string) => {
    setBtnLoading(true);
    axios
      .get(`/api/royalty/${payeeCode}/songs/export/${exportType}`, {
        headers: {
          authorization: `Bearer ${oktaAuth.getAccessToken()}`,
          accept: "*/*",
          "Content-Type": "application/json"
        }
      })
      .then(async (response: any) => {
        setBtnLoading(false);
        await downloadFile(
          `${singleClient?.data?.fullName.replaceAll(".", "_")}-${moment(
            Date.now()
          ).format("L")}`,
          "data:text/csv;charset=UTF-8," + encodeURIComponent(response.data)
        );
      });
  };

  if (!profile || (!works && worksLoading)) return <Loader />;

  return (
    <div className="container">
      <div className={cn(styles.container)}>
        {!!params?.id && works && (
          <div className={styles.filters}>
            <button
              className={cn(styles.backButton)}
              onClick={() => {
                history.push(`/deals`);
              }}
              role="button"
            >
              <Cursor className={styles.backIcon} />
              <p>{t(`dealAndSongs.myDeals`)}</p>
            </button>
            <div className={styles.actions}>
              <button
                className={cn(styles.wrapButton, {
                  [styles.disabled]: isFirst
                })}
                onClick={() => clickNextPrevBack("back")}
                role="button"
              >
                <Arrow className={styles.back} />
                <p>{t(`buttons.previousDeal`)}</p>
              </button>
              <button
                className={cn(styles.wrapButton, {
                  [styles.disabled]: isLast
                })}
                onClick={() => clickNextPrevBack("next")}
              >
                <p>{t(`buttons.nextDeal`)}</p>
                <Arrow className={styles.next} />
              </button>
            </div>
          </div>
        )}
        {!!paramsDealId && (
          <>
            {!singleDeal && (
              <div className={cn(styles.payeeCodeDropDown)}>
                <UiSelect
                  hasShadowOnMobile
                  placeholder={t(`myAccountPage.search`)}
                  noOptionsMessage={() => t(`common.noResult`)}
                  options={dummyDataPayeeCode}
                  className={cn(styles.select, styles.select_searchable)}
                  isDropTop
                  value={{
                    label: dropdownValue?.label,
                    value: dropdownValue?.value
                  }}
                  isSearchable
                  onChange={(e: any) => chooseDealId(e)}
                  menuClassName={styles.menu}
                  isBig
                  filterOption={(option: any, value: any) => {
                    return (
                      option.data.code
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                      option.data.value
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    );
                  }}
                />
              </div>
            )}
            <div className={styles.wrap}>
              <div className={styles.payeeCodeBlock}>
                <InfoComponent
                  title={t(`dealAndSongs.dealStartDate`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.dealStartDateTooltip`)}
                >
                  <span className={cn(styles.infoText)}>
                    {activeState?.accountDealStartDate
                      ? moment
                          .utc(new Date(activeState?.accountDealStartDate))
                          .locale(i18n.language)
                          .format("ll")
                      : "-"}
                  </span>
                </InfoComponent>
                <InfoComponent
                  title={t(`dealAndSongs.dealExecutedDate`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.dealExecutedDateTooltip`)}
                >
                  <span className={cn(styles.infoText)}>
                    {activeState?.dealExecutedDate
                      ? moment
                          .utc(new Date(activeState?.dealExecutedDate))
                          .locale(i18n.language)
                          .format("ll")
                      : "-"}
                  </span>
                </InfoComponent>

                <InfoComponent
                  title={t(`dealAndSongs.dealEndDate`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.dealEndDateTooltip`)}
                >
                  <span className={cn(styles.infoText)}>
                    {activeState?.dealEndDate
                      ? moment
                          .utc(new Date(activeState?.dealEndDate))
                          .locale(i18n.language)
                          .format("ll")
                      : "-"}
                  </span>
                </InfoComponent>

                <InfoComponent
                  title={t(`dealAndSongs.contractType`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.contractTypeTooltip`)}
                >
                  <span className={cn(styles.infoText)}>
                    {activeState?.contractType}
                  </span>
                </InfoComponent>

                <InfoComponent
                  title={t(`dealAndSongs.rightsTermPeriodStatus`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.rightsTermPeriodStatusTooltip`)}
                >
                  <span className={cn(styles.infoText)}>
                    {activeState?.status}
                  </span>
                </InfoComponent>

                <InfoComponent
                  title={t(`dealAndSongs.dealSigningTerritory`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.dealSigningTerritoryTooltip`)}
                >
                  <span className={cn(styles.infoText)}>
                    {activeState?.dealSigningTerritory}
                  </span>
                </InfoComponent>

                <InfoComponent
                  title={t(`dealAndSongs.administeredTerritory`)}
                  hasIcon={true}
                  iconSize="big"
                  titleSize="middle"
                  information={t(`dealAndSongs.administeredTerritoryTooltip`)}
                >
                  <span
                    className={cn(styles.infoText, styles.infoText_underline)}
                    onClick={() =>
                      activeState?.territories?.length > 5 &&
                      SetOpenModal({ isOpen: true, type: "countryList" })
                    }
                  >
                    {!!activeState?.territories?.length &&
                    activeState?.territories?.length > 1
                      ? `${activeState?.territories?.length} ${t(
                          "common.territories"
                        )}`
                      : activeState?.territories?.length === 1
                      ? activeState?.territories[0]
                      : "-"}
                  </span>
                </InfoComponent>
                {singleDeal && (
                  <InfoComponent
                    hasIcon={false}
                    title={t(`dealAndSongs.dealId`)}
                    titleSize="middle"
                  >
                    <span className={cn(styles.infoText)}>
                      {singleDeal.dealId}
                    </span>
                  </InfoComponent>
                )}
              </div>
            </div>
          </>
        )}
        <div
          className={cn(
            styles.table,
            paginateData?.length === 0 ? styles.table_nodata : ""
          )}
        >
          {!params?.id && (
            <div className={cn(styles.table_head_pre)}>
              {works?.result ? (
                <Button
                  variant={ButtonType.Primary}
                  className={cn(styles.button, styles.button_showOnDesktop)}
                  disabled={btnLoading}
                  loading={btnLoading}
                  onClick={() => downloadSongs(payeeCode, "CSV")}
                >
                  {!btnLoading && (
                    <FileDnwldIcon
                      className={cn(styles.button_icon, "hideOnDesktop")}
                    />
                  )}
                  <span className="hideOnMobile">
                    {t(`buttons.downloadSongs`)}
                  </span>
                </Button>
              ) : null}
            </div>
          )}
          {!!works && (
            <div
              className={cn(
                styles.table_head,
                params?.id && styles.table_head_changed
              )}
            >
              <div className={cn(styles.table_subHeading)}>
                <h4 className={cn(styles.table_title)}>
                  {!params?.id
                    ? (works.totalCount ?? 0) + " " + t(`dealAndSongs.songs`)
                    : t(`dealAndSongs.songs`)}
                </h4>
                {!params?.id && (
                  <Button
                    variant={ButtonType.Primary}
                    className={cn(styles.button, styles.button_showOnMobile)}
                    disabled={btnLoading}
                    loading={btnLoading}
                    onClick={() => downloadSongs(payeeCode, "CSV")}
                  >
                    {!btnLoading && (
                      <FileDnwldIcon
                        className={cn(styles.button_icon, "hideOnDesktop")}
                      />
                    )}
                    <span className="hideOnMobile">
                      {t(`buttons.downloadSongs`)}
                    </span>
                  </Button>
                )}
              </div>
              <div className={cn(styles.input_wrapper)}>
                <SearchInput
                  ref={inputRef}
                  onInput={onFilterTextBoxChanged}
                  placeholder={t(`dealAndSongs.songsSearch`)}
                  mobilePlaceholder={t(`dealAndSongs.songsSearch`)}
                  value={searchInputValue}
                  onclickClearIcon={onFilterClear}
                  className={cn(
                    styles.input,
                    !params?.id && styles.input_change
                  )}
                />
              </div>
            </div>
          )}
          {!!worksError?.length && !worksLoading && (
            <div className={cn(styles.payeeCodeBlock_nodata)}>
              <h1>{t(`common.noDataHeadline`)}</h1>
              <p>
                {textParams(t(`common.noDataText`))[0]}
                <Link to="/contact-us">
                  {textParams(t(`common.noDataText`))[1]}.
                </Link>
              </p>
            </div>
          )}
          {works ? (
            <>
              {m && (
                <SongsPageCard
                  data={works.result ?? []}
                  defaultSortBy={defaultSortBy}
                  handleSort={handleSort}
                  worksLoading={worksLoading}
                />
              )}
              <Table
                isMobile={m}
                data={works.result ?? []}
                columns={tableColumns}
                totalItems={works?.totalCount}
                onRowClick={handleRowClick}
                isWhitePagination={true}
                defaultPage={songsQueryOptions.page}
                loading={worksLoading}
                defaultPageSize={songsQueryOptions.size}
                fetchData={fetchData}
                onSort={handleSort}
                defaultSortBy={defaultSortBy}
                onChangeSize={(e: any) => {
                  getWorks({
                    query: {
                      ...songsQueryOptions,
                      page: 0,
                      size: +e.value
                    },
                    params: { deals: params.id }
                  });
                }}
              />
            </>
          ) : null}
        </div>
      </div>
      {!!activeState?.territories?.length &&
        activeState?.territories?.length > 5 && (
          <SetCountryModal
            countries={activeState?.territories}
            modalTitle={activeState?.contractingParty}
          />
        )}
    </div>
  );
};

export default Songs;
