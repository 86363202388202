import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "./DealsPageCard.module.scss";

import Card from "../../../components/shared/Card";
import SortDropdown from "../../../components/shared/SortDropdown";
// import SortDropdown from "../../../components/shared/SortDropdown";

const DealsPageCard = ({ data, defaultSortBy, handleSort }: any) => {
  const history = useHistory();
  const { t } = useTranslation("translation");

  const handleRowClick = useCallback(el => {
    history.push(`/home/${el.dealId}`);
  }, []);

  const sort = useMemo(() => {
    const sortBy = defaultSortBy[0];
    if (sortBy.id === "contractingParty") {
      return { index: sortBy.desc ? 2 : 1, sortAZ: "contractingParty" };
    }
    if (sortBy.id === "dealExecutedDate") {
      return { index: sortBy.desc ? 4 : 3, sortDate: "dealExecutedDate" };
    }
    return { index: 1, sortAZ: "contractingParty" };
  }, [defaultSortBy]);

  return (
    <>
      <SortDropdown
        defaultValue={sort.index}
        onChangeCallback={data => handleSort({ sortBy: data })}
        sortDate="dealExecutedDate"
        sortAZ="contractingParty"
      />

      {data?.map((el: any) => (
        <Card
          key={`${el?.workTitle}${el?.dealId}`}
          handleCardClick={() => handleRowClick(el)}
        >
          <Card.Content>
            <Card.Items
              label={
                <p>
                  <p>{el?.contractingParty}</p>
                  <p className={styles.dealId}>{el?.dealId}</p>
                </p>
              }
              title={" "}
            />
            <Card.Items
              desc={t(`dealAndSongs.songs`)}
              title={el?.worksCount || "-"}
              label={` `}
            />
          </Card.Content>
        </Card>
      ))}
    </>
  );
};

export default DealsPageCard;
