import { getUserLocalLanguage } from "../i18n/utils";

/**
 * Cleanups local storage but saves required settings
 */
export const cleanupLocalStorage = (): void => {
  // Save language settings before cleanup
  const language = localStorage.getItem("i18nextLng") ?? getUserLocalLanguage();
  localStorage.clear();
  localStorage.setItem("i18nextLng", language);
};
