import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./SongsPageCard.module.scss";

import Card from "../../../components/shared/Card";
import SortDropdown from "../../../components/shared/SortDropdown";

const SongsPageCard = ({
  data,
  defaultSortBy,
  handleSort,
  worksLoading
}: any) => {
  const history = useHistory();
  const { t } = useTranslation("translation");
  const handleRowClick = useCallback(el => {
    history.push(
      `?workCode=${el?.workCode?.replaceAll(" ", "%20")}&dealId=${el?.dealId}`
    );
  }, []);
  const sort = useMemo(() => {
    const sortBy = defaultSortBy[0];
    if (sortBy.id === "workTitle") {
      return { index: sortBy.desc ? 2 : 1, sortAZ: "workTitle" };
    }
    if (sortBy.id === "deliveryDate") {
      return { index: sortBy.desc ? 4 : 3, sortDate: "deliveryDate" };
    }
    return { index: 1, sortAZ: "workTitle" };
  }, [defaultSortBy]);

  return (
    <div>
      <SortDropdown
        loading={worksLoading}
        defaultValue={sort.index}
        onChangeCallback={data => handleSort({ sortBy: data })}
        sortDate="deliveryDate"
        sortAZ="workTitle"
      />
      {data?.map((el: any) => (
        <Card
          key={`${el?.workTitle}${el?.dealId}${el?.workCode}`}
          handleCardClick={() => handleRowClick(el)}
          className={cn({ [styles.loading]: worksLoading })}
        >
          <Card.Content>
            <Card.Items
              title={el?.creators?.map((e: any, index: number) => (
                <span key={e?.name}>
                  {e?.name}
                  {index !== el?.creators?.length - 1 ? " | " : ""}
                </span>
              ))}
              label={el?.workTitle}
            />
            <Card.Items
              desc={t(`dealAndSongs.deliveryDate`)}
              title={el?.deliveryDate || "-"}
              label={` `}
            />
          </Card.Content>
        </Card>
      ))}
    </div>
  );
};

export default SongsPageCard;
