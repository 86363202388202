import React from "react";
import { components, ControlProps } from "react-select";

import styles from "../SelectField.module.scss";

const Control: React.FC<ControlProps> = ({ children, ...props }) => (
  <components.Control {...props} className={styles.control}>
    {children}
  </components.Control>
);

export default Control;
