import React from "react";
import cn from "classnames";

type LoadingCircleType = {
  className?: string;
  isBig?: boolean;
  isL?: boolean;
};

const LoadingCircle: React.FC<LoadingCircleType> = ({
  className,
  isBig = false,
  isL = false
}) => {
  return (
    <svg
      className={cn(className)}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={isL ? "150px" : isBig ? "80px" : "40px"}
      height={isL ? "150px" : isBig ? "80px" : "40px"}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M30 50A20 20 0 0 0 70 50A20 21.3 0 0 1 30 50"
        fill="rgba(217, 168, 27, 1)"
        stroke="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50.65;360 50 50.65"
        ></animateTransform>
      </path>
    </svg>
  );
};

export default LoadingCircle;
